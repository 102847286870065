import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { useForm } from '../../../hooks/useForm';

export const useAdvancedEventSettingsPage = () => {
  const { eventId } = useParams();
  const { goBack } = useHistory();
  const [eventSettings, setEventSettings] = useState({});
  const { showNotification } = useNotifications();

  const { formState, handleInputChange, formValues } = useForm({
    secondaryMarketEnable: { value: eventSettings.secondaryMarketEnable },
    secondaryMarketEventButtonEnable: { value: eventSettings.secondaryMarketEventButtonEnable },
    // shouldBlacklistSellers: { value: eventSettings.shouldBlacklistSellers },
    // shouldExtendStopOfSalesToInvitations: {
    //   value: eventSettings.shouldExtendStopOfSalesToInvitations,
    // },
    // shouldConvertPriceForUsd: { value: eventSettings.shouldConvertPriceForUsd },
    // hoursBeforeRelease: { value: eventSettings.hoursBeforeRelease },
    // hoursBeforeReleaseSecondaryMarket: { value: eventSettings.hoursBeforeReleaseSecondaryMarket },
    qrValidationMode: { value: eventSettings.qrValidationMode },
  });

  const getEventSettings = async () => {
    await handleRequestHelper({
      endpoint: () => api.getEventSettings(eventId),
      onSuccess: ({ settings }) => {
        setEventSettings(settings);
      },
      showNotification,
    });
  };

  useEffect(() => {
    getEventSettings();
  }, []);

  const updateEventSettings = async () => {
    await handleRequestHelper({
      endpoint: () => api.updateEventSettings(eventId, formValues),
      onSuccess: () => goBack(),
      showNotification,
    });
  };

  const validationModeOptions = [
    { label: 'Molinetes y app de escaneo', value: 'all' },
    { label: 'Solo molinetes', value: 'turnstile-gate' },
    { label: 'Solo app de escaneo', value: 'scan-app' },
  ];

  return {
    formState,
    validationModeOptions,
    handleInputChange,
    updateEventSettings,
  };
};
