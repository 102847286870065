import React from 'react';
import { useTheme, withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import VIcon from '../../../Venti-UI-Kit/VIcon/VIcon';
import styles from './AutocompleteOption.styles';
import { MATERIAL_UI_ICONS } from '../../../constants/types';
import api from '../../../api/api';
import { handleRequestHelper } from '../../../utils/helpers';

const AutocompleteOption = ({
  classes,
  searchQuery,
  option,
  onSelectOption,
  setIsLoadingVenue,
}) => {
  const { label, isPlaceName, placeId } = option;
  const matches = label.toLowerCase().indexOf(searchQuery.toLowerCase());
  const beforeMatch = label.substring(0, matches);
  const matchText = label.substring(matches, matches + searchQuery.length);
  const afterMatch = label.substring(matches + searchQuery.length);

  const theme = useTheme();

  const createVenue = async () => {
    setIsLoadingVenue(true);

    if (isPlaceName) {
      const placeName = label.slice(6, -1);
      onSelectOption({ placeName });
    } else {
      await handleRequestHelper({
        endpoint: () => api.createVenue({ placeId }),
        onSuccess: ({ venue }) => onSelectOption({ venue, venueId: venue.id }),
      });
    }

    setIsLoadingVenue(false);
  };

  return (
    <Stack className={classes.optionBox} onClick={createVenue}>
      {!isPlaceName && <VIcon name="place" baseClassName={MATERIAL_UI_ICONS.DEFAULT} />}
      <VTypography component="span" color={theme.palette.text.primary}>
        {beforeMatch}
        <strong>{matchText}</strong>
        {afterMatch}
      </VTypography>
    </Stack>
  );
};

export default withStyles(styles)(AutocompleteOption);
