import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { useIntercom } from 'react-use-intercom';
import styles from './FooterStyles';
import { ThemeContext } from '../../Providers/Theme/Theme';
import { WHITE_LABELS } from '../../constants/whiteLabels';
import { useAuth } from '../../Providers/AuthProvider/AuthProvider';
import { TERMS_BY_COUNTRY, POLICIES_BY_COUNTRY } from '../../constants/constants';

const Footer = ({ classes, country }) => {
  const { show } = useIntercom();
  const { user } = useAuth();

  const COPYRIGHT_TEXT = '© Venti - 2024 Todos los derechos reservados.';
  const SOCIALS_MEDIA = [
    { label: 'Facebook', link: 'https://www.facebook.com/ventiglobal' },
    { label: 'Instagram', link: 'https://www.instagram.com/venti.la' },
    { label: 'Twitter', link: 'https://twitter.com/SomosVenti' },
  ];

  const LEFT_COLUMN_BUTTONS = [
    { label: 'Home', link: '/', target: '_self' },
    { label: "FAQ's", link: 'https://intercom.help/venti-latam/es', target: '_blank' },
    { label: 'Contacto', onClick: show },
  ];

  const termsLink = TERMS_BY_COUNTRY[country] ?? TERMS_BY_COUNTRY.AR;
  const policiesLink = POLICIES_BY_COUNTRY[country] ?? POLICIES_BY_COUNTRY.AR;

  return (
    <ThemeContext.Consumer>
      {({ icons, theme }) => (
        <div className={classes.footerContainer}>
          <div id="footerLeftColumn" className={classes.footerColumnContainer}>
            <div className={classes.footerAssetContainer}>
              <img src={icons.footerAsset} className={classes.footerAsset} alt="footerAsset" />
            </div>
            <Typography className={`${classes.footerBottomText} ${classes.mobileHidden}`}>
              {COPYRIGHT_TEXT}
            </Typography>
          </div>
          <div className={classes.footerColumnContainer}>
            <div className={classes.footerButtonsRowContainer}>
              <div className={classes.footerButtonsColumnContainer}>
                {LEFT_COLUMN_BUTTONS.map(({ label, link, target, onClick }) =>
                  onClick ? (
                    <button
                      type="button"
                      key={label}
                      onClick={onClick}
                      className={classes.footerLink}
                    >
                      {label}
                    </button>
                  ) : (
                    <a
                      key={label}
                      target={target}
                      href={link}
                      className={classes.footerLink}
                      rel="noreferrer"
                    >
                      {label}
                    </a>
                  )
                )}
                {!user && (
                  <a href="/login" className={classes.footerLink}>
                    Acceder como usuario o productor
                  </a>
                )}
              </div>
              <div className={classes.footerButtonsColumnContainer}>
                {SOCIALS_MEDIA.map(({ label, link }) => (
                  <a
                    key={label}
                    target="_blank"
                    href={link}
                    className={classes.footerLink}
                    rel="noreferrer"
                  >
                    {label}
                  </a>
                ))}
              </div>
            </div>
            <div id="dataFiscalContainer" className={classes.footerButtonsRowContainer}>
              {theme !== WHITE_LABELS.BNP && (
                <a href="https://form.jotform.com/202365071601646" className={classes.footerLink}>
                  Botón de arrepentimiento
                </a>
              )}
            </div>
            <div className={classes.footerButtonsRowContainer}>
              <div className={classes.termsAndPolitycsContainer}>
                <a href={termsLink} className={classes.footerBottomText}>
                  Términos y Condiciones
                </a>
                <a href={policiesLink} className={classes.footerBottomText}>
                  Políticas de privacidad
                </a>
                {country === 'AR' && (
                  <a
                    target="_blank"
                    href="https://autogestion.produccion.gob.ar/consumidores"
                    className={classes.footerBottomText}
                    rel="noreferrer"
                  >
                    Defensa al consumidor
                  </a>
                )}
                {theme === WHITE_LABELS.BNP && (
                  <a
                    href="https://form.jotform.com/202365071601646"
                    className={classes.footerBottomText}
                  >
                    Botón de arrepentimiento
                  </a>
                )}
              </div>
            </div>

            <div className={classes.desktopHidden}>
              <Typography className={classes.footerBottomText}>{COPYRIGHT_TEXT}</Typography>
            </div>
          </div>
        </div>
      )}
    </ThemeContext.Consumer>
  );
};

export default withStyles(styles)(Footer);
