import { useEffect, useState } from 'react';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';
import { useForm } from '../../../hooks/useForm';
import { NO_PINNED_EVENT_VALUE } from './ProducerForm.constants';
import { WHITE_LABELS } from '../../../constants/whiteLabels';
import { EVENT_STATUS } from '../../../constants/types';

export const useProducerForm = (onSubmit, producerId) => {
  const [producer, setProducer] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [events, setEvents] = useState([]);

  const pinnedEventId =
    events.find(({ id }) => id === producer?.pinnedEventId)?.id || NO_PINNED_EVENT_VALUE;

  const { formState, handleInputChange, validateFormInputs } = useForm(
    {
      name: { value: producer?.name },
      country: { value: producer?.country, required: false },
      city: { value: producer?.city, required: false },
      description: { value: producer?.description, required: false },
      theme: { value: producer?.theme || WHITE_LABELS.VENTI },
      web: { value: producer?.socials?.web, required: false },
      instagram: { value: producer?.socials?.instagram, required: false },
      x: { value: producer?.socials?.x, required: false },
      facebook: { value: producer?.socials?.facebook, required: false },
      youtube: { value: producer?.socials?.youtube, required: false },
      tiktok: { value: producer?.socials?.tiktok, required: false },
      logoImg: { value: producer?.logoImg, required: false },
      bannerImg: { value: producer?.bannerImg, required: false },
      pinnedEventId: { value: pinnedEventId, required: false },
    },
    [producer, events]
  );

  const getProducerInfo = async () => {
    await handleRequestHelper({
      endpoint: () => api.getProducer(producerId),
      onSuccess: ({ producer }) => setProducer(producer),
    });
  };

  const getProducerEvents = async () => {
    await handleRequestHelper({
      endpoint: () =>
        api.getPrivateEvents({
          producersIdsIncluded: producerId,
          status: EVENT_STATUS.ACTIVE,
          endDate: new Date(),
        }),
      onSuccess: ({ events }) => setEvents(events),
    });
  };

  const getInitialData = async () => {
    if (!producerId) return;
    setIsLoading(true);
    await Promise.all([getProducerInfo(), getProducerEvents()]);
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    setIsSubmiting(true);
    const isFormValid = validateFormInputs();

    if (!isFormValid) {
      setIsSubmiting(false);
      return;
    }

    await onSubmit(formState);
    setIsSubmiting(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return {
    formState,
    isLoading,
    isSubmiting,
    events,
    handleSubmit,
    handleInputChange,
  };
};
