import React from 'react';
import { withStyles } from '@material-ui/core';
import { Box, Modal } from '@mui/material';
import styles from './VModal.styles';
import VTypography from '../VTypography/VTypography';

const VModal = ({ classes, children, open, width, maxWidth, title, subtitle, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} className={classes.modalContainer} disableEscapeKeyDown>
      <Box width={width} className={classes.modalBoxContainer} maxWidth={maxWidth}>
        {title && <VTypography variant="h4">{title}</VTypography>}
        {subtitle && <VTypography variant="body1">{subtitle}</VTypography>}
        {children}
      </Box>
    </Modal>
  );
};

export default withStyles(styles)(VModal);
