import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import { CircularProgress } from '@mui/material';
import VTypography from '../VTypography/VTypography';

class VentiButton extends React.Component {
  getButtonClasses(classes) {
    const buttonClasses = [classes.base];
    const color = this.props.color ? this.props.color : 'main';
    if (color === 'main') buttonClasses.push(classes.main);
    if (color === 'alternative') buttonClasses.push(classes.alternative);
    if (color === 'white') buttonClasses.push(classes.white);
    if (color === 'black') buttonClasses.push(classes.black);
    if (color === 'transparent') buttonClasses.push(classes.noColor);
    if (this.props.icon) buttonClasses.push(classes.movingText);
    if (this.props.fullWidth) buttonClasses.push(classes.fullWidth);

    buttonClasses.push(this.props.className);

    return buttonClasses.join(' ');
  }

  render() {
    const { classes } = this.props;
    const buttonClasses = this.getButtonClasses(classes);

    function IconButton(props) {
      if (!props.icon) return null;
      return <img alt="icon" className={classes.icon} src={props.icon} />;
    }

    return (
      <ButtonBase
        style={this.props.style}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
        className={buttonClasses}
        type={this.props.type}
        autoFocus={this.props.autoFocus}
        href={this.props.href}
        target={this.props.target}
      >
        <IconButton icon={this.props.icon} style={{ color: 'white' }} />
        {this.props.loading ? (
          <CircularProgress size={20} style={{ color: 'white' }} />
        ) : (
          <VTypography variant="button1" align="center">
            {this.props.children}
          </VTypography>
        )}
      </ButtonBase>
    );
  }
}

const styles = (theme) => ({
  base: {
    minWidth: 120,
    minHeight: 35,
    borderRadius: 8,
    textTransform: 'none',
    fontSize: 17,
    padding: 10,
    transition: 'all 0.4s',
    '&:disabled': {
      backgroundColor: theme.palette.buttons.disabled,
      color: theme.palette.buttons.disabledText,
    },
  },
  main: {
    backgroundColor: theme.palette.buttons.main.background,
    color: theme.palette.buttons.main.text,
    '&:hover': {
      backgroundColor: theme.palette.buttons.main.hover,
    },
  },
  mainText: {
    backgroundImage: theme.palette.primary.main,
  },
  alternative: {
    backgroundColor: theme.palette.buttons.alternative.background,
    color: theme.palette.buttons.alternative.text,
    border: `1px solid ${theme.palette.buttons.alternative.hover}`,
    '&:hover': {
      backgroundColor: theme.palette.buttons.alternative.hover,
    },
  },
  white: {
    backgroundColor: 'white',
    color: 'black',
  },
  noColor: {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
  },
  black: {
    backgroundColor: 'black',
    color: 'white',
  },
  title: {
    fontSize: 17,
    backgroundColor: 'transparent',
  },
  movingText: {
    opacity: 1,
    overflow: 'hidden',
    '&:hover': {
      '& img': {
        transform: 'translateX(340%)',
        transition: 'all 0.6s',
      },
      '& h1': {
        transform: 'translateX(200%)',
        transition: 'all 0.6s',
      },
    },
  },
  icon: {
    height: 30,
    left: '-35%',
    position: 'absolute',
    transition: 'all 0.6s',
  },
  fullWidth: {
    width: '100%',
  },
});

export default withStyles(styles)(VentiButton);
