import { Typography } from '@material-ui/core';
import React from 'react';
import CustomCircularProgress from '../CustomCircularProgress/CustomCircularProgress';

const LoadingListContent = ({ loading, list, notFoundMessage, children }) => {
  return loading ? (
    <CustomCircularProgress />
  ) : !list.length ? (
    <Typography>{notFoundMessage}</Typography>
  ) : (
    children
  );
};

export default LoadingListContent;
