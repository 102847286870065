import { useState } from 'react';

export const useQueryParam = (paramName) => {
  const updateParam = (newValue) => {
    const params = new URLSearchParams(window.location.search);
    params.set(paramName, newValue);
    window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
  };

  const getParam = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get(paramName) ?? '';
  };

  const [paramValue, setParamValue] = useState(getParam());

  const onChange = (value) => {
    setParamValue(value);
    updateParam(value);
  };

  return {
    paramValue,
    onChange,
  };
};
