import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    searcherContainer: {
      width: '100%',
    },
  });

export default styles;
