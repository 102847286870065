import React, { useEffect, useState } from 'react';
import { Box, withStyles } from '@material-ui/core';
import { Paper, Stack } from '@mui/material';
import styles from './ProducersListFormStyles';
import TitledSwitch from '../../../../Venti-UI-Kit/Switches/TitledSwitch';
import { useProducersListForm } from '../useProducersListForm';
import EventsAccessesAccordion from '../../../Accordions/EventsAccessesAccordion/EventsAccessesAccordion';
import TeamLeadersAccordion from '../../../Accordions/TeamLeadersAccordion/TeamLeadersAccordion';
import LoadingContainer from '../../../LoadingContainer/LoadingContainer';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';

const NO_PRODUCER = 0;

const ProducersListForm = ({
  classes,
  currentProducerId,
  disabled,
  roleIsBlacklisteable,
  roleCanHaveTeamLeader,
  onChange,
  initialUserData,
}) => {
  const {
    selectedProducersEvents,
    selectedProducers,
    selectedTeamLeaders,
    producers,
    isLoadingData,
    handleToggleProducer,
    getAmountOfSelectedEventsByProducerId,
    modifyAllSelectedProducerEventsStates,
    modifySelectedProducerEventState,
    handleSelectTeamLeader,
  } = useProducersListForm({
    initialUserData,
    currentProducerId,
  });

  useEffect(
    () => onChange({ selectedProducers, selectedProducersEvents, selectedTeamLeaders }),
    [selectedProducers, selectedProducersEvents, selectedTeamLeaders]
  );

  const [producerIdEventsAccessesAccordionOpen, setProducerIdEventsAccessesAccordionOpen] =
    useState();
  const [producerIdTeamLeaderAccordionOpen, setProducerIdTeamLeaderAccordionOpen] = useState();

  const handleChangeEventsAccessesAccordionOpen = (producerId) => {
    setProducerIdEventsAccessesAccordionOpen(
      producerIdEventsAccessesAccordionOpen === producerId ? NO_PRODUCER : producerId
    );
    setProducerIdTeamLeaderAccordionOpen(
      producerIdTeamLeaderAccordionOpen !== producerId ? NO_PRODUCER : producerId
    );
  };

  const handleChangeTeamLeaderAccordionOpen = (producerId) => {
    setProducerIdTeamLeaderAccordionOpen(
      producerIdTeamLeaderAccordionOpen === producerId ? NO_PRODUCER : producerId
    );
    setProducerIdEventsAccessesAccordionOpen(
      producerIdEventsAccessesAccordionOpen !== producerId ? NO_PRODUCER : producerId
    );
  };

  return (
    <Box>
      <VTypography variant="subtitle1" mb={8}>
        Productoras asociadas
      </VTypography>
      <Stack className={classes.producersContainer}>
        <LoadingContainer isLoading={isLoadingData}>
          {producers.map(
            ({ id: producerId, name, teamLeaders, events, generatesSellerReports }, index) => (
              <Paper className={classes.paper} key={name}>
                <TitledSwitch
                  name={`${producerId}`}
                  onChange={handleToggleProducer}
                  checked={selectedProducers[producerId]}
                  disabled={disabled}
                  title={name}
                  className={classes.formItemContainer}
                  switchOnTheRight={false}
                  titleVariant="subtitle5"
                />
                {selectedProducers[producerId] && roleIsBlacklisteable && !disabled && (
                  <EventsAccessesAccordion
                    index={index}
                    producerId={producerId}
                    events={events}
                    expanded={producerIdEventsAccessesAccordionOpen === producerId}
                    onChange={() => handleChangeEventsAccessesAccordionOpen(producerId)}
                    getAmountOfSelectedEventsByProducerId={getAmountOfSelectedEventsByProducerId}
                    modifyAllSelectedProducerEventsStates={modifyAllSelectedProducerEventsStates}
                    selectedProducersEvents={selectedProducersEvents}
                    modifySelectedProducerEventState={modifySelectedProducerEventState}
                  />
                )}
                {selectedProducers[producerId] &&
                  generatesSellerReports &&
                  roleCanHaveTeamLeader &&
                  !disabled &&
                  teamLeaders?.length && (
                    <TeamLeadersAccordion
                      index={index}
                      producerId={producerId}
                      teamLeaders={teamLeaders}
                      expanded={producerIdTeamLeaderAccordionOpen === producerId}
                      onChange={() => handleChangeTeamLeaderAccordionOpen(producerId)}
                      selectedTeamLeaders={selectedTeamLeaders}
                      handleSelectTeamLeader={handleSelectTeamLeader}
                    />
                  )}
              </Paper>
            )
          )}
        </LoadingContainer>
      </Stack>
    </Box>
  );
};

export default withStyles(styles)(ProducersListForm);
