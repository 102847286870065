import React from 'react';
import { Stack } from '@mui/material';
import CustomCircularProgress from '../CustomCircularProgress/CustomCircularProgress';

const LoadingContainer = ({ children, isLoading }) => {
  return isLoading ? (
    <Stack justifyContent="center" alignItems="center" padding={2}>
      <CustomCircularProgress />
    </Stack>
  ) : (
    children
  );
};

export default LoadingContainer;
