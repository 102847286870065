import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    pageContainer: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    paperContainer: {
      borderRadius: '40px',
      backgroundColor: 'transparent',
      flex: 1,
      justifyContent: 'center',
      display: 'flex',
      margin: 'auto',
      flexDirection: 'column',
      alignItems: 'center',
      rowGap: 20,
      [theme.breakpoints.down('md')]: {
        padding: 0,
        width: '100%',
      },
    },
  });

export default styles;
