import { createStyles } from '@material-ui/core';

const limitedText = (lines) => ({
  display: '-webkit-box',
  WebkitLineClamp: lines,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  lineClamp: lines,
  textOverflow: 'ellipsis',
});

const styles = (theme) => {
  const border = `0.5px solid ${theme.palette.background.toolbar}`;

  return createStyles({
    cardContainer: {
      width: 357,
      height: 140,
      borderRadius: 8,
      overflow: 'hidden',
      border,
      display: 'flex',
      transition: 'transform 300ms, box-shadow 100ms ease-out',
      textDecoration: 'none',
      color: theme.palette.text.primary,
      animationFillMode: 'none',
      '-webkit-animation-fill-mode': 'none',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },

      '& .image': {
        height: 140,
        minWidth: 112,
        borderRight: border,
        flex: 1,
        backgroundSize: 'cover',
        borderBottom: border,
        position: 'relative',
        backgroundPosition: 'center',
      },

      '& .cardInfoContainer': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      },

      '& .nameContainer': {
        height: 54,
        display: 'flex',
        padding: 8,
        justifyContent: 'left',
        alignItems: 'center',
        borderBottom: border,
        flex: 1,
      },

      '& .name': {
        lineHeight: '19px',
        ...limitedText(3),
        fontWeight: 'bold',
        fontSize: 16,
      },

      '& .dateContainer': {
        height: 24,
        display: 'flex',
        borderBottom: border,
        '& :first-child': {
          borderRight: border,
        },
        '& :last-child': {
          borderLeft: border,
        },
      },

      '& .dateTimeText': {
        fontSize: 12,
        lineHeight: '14px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 7px',
        letterSpacing: '-0.03em',
        flex: 1,
        textTransform: 'capitalize',
      },

      '& .locationContainer': {
        height: 24,
        borderBottom: border,
        padding: '0 7px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'left',
        gap: 8,
      },

      '& .locationText': {
        fontWeight: 600,
        fontSize: 12,
        ...limitedText(1),
      },

      '& .assetContainer': {
        height: 10,
        display: 'flex',
        overflow: 'hidden',
        width: '100%',
        position: 'relative',
      },

      '& .asset': {
        height: 10,
        position: 'absolute',

        '& path': {
          fill: theme.palette.text.primary,
        },
      },

      '&:hover, &:active': {
        transform: 'translate(-4px, -4px)',
        transition: 'transform 300ms, box-shadow 100ms ease',
        boxShadow: `4px 4px 0px ${theme.palette.background.toolbar}`,
        cursor: 'pointer',

        '& *': {
          transition: 'all 300ms ease',
        },

        '& .nameContainer': {
          backgroundColor: theme.palette.primary.main,
        },

        '& .name': {
          color: theme.palette.primary.contrastText,
        },

        '& .asset': {
          '& path': {
            fill: theme.palette.primary.main,
          },
        },

        '& .image::after': {
          content: '""',
          display: 'block',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: theme.palette.primary.main,
          mixBlendMode: 'lighten',
          opacity: 0.6,
        },

        '& .leftToRight': {
          animationDuration: '70s !important',
        },
      },
    },
  });
};

export default styles;
