import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'start',
      width: '100%',
      backgroundColor: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[400]}`,
      padding: 15,
      borderRadius: 10,
      rowGap: 10,
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
      justifyContent: 'start',
      alignItems: 'start',
      flex: 1,
    },
    titleAndImageContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      columnGap: 10,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        rowGap: 10,
      },
    },
    infoTextsContainer: {
      display: 'flex',
      columnGap: 5,
      flexDirection: 'column',
    },
    ticketName: {
      fontWeight: 600,
      fontSize: 17,
      textAlign: 'left',
      color: theme.palette.text.primary,
    },
    infoText: {
      textAlign: 'left',
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.text.primary,
      '& b': {
        fontWeight: 700,
      },
    },
    buttonsContainer: {
      display: 'flex',
      rowGap: 10,
      flexDirection: 'row',
      columnGap: 15,
      alignSelf: 'center',
    },
    editIcon: {
      color: theme.palette.text.primary,
    },
    deleteIcon: {
      color: theme.palette.error.main,
    },
    button: {
      padding: '0 !important',
      border: 'none',
      background: 'none',
      cursor: 'pointer',
    },
    ticketImage: {
      borderRadius: 10,
      height: 70,
    },
  });

export default styles;
