import React from 'react';
import { Divider, Typography, withStyles } from '@material-ui/core';
import { Stack, Box } from '@mui/material';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import AddLocationOutlinedIcon from '@mui/icons-material/AddLocationOutlined';
import InputTitle from '../../InputTitle/InputTitle';
import styles from './StadiumCustomizerStyles';
import StadiumImage from '../../StadiumImage/StadiumImage';
import SectionForm from '../SectionForm/SectionForm';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';
import { validateUserRole } from '../../../utils/utils';
import { USER_PERMISSIONS } from '../../../constants/types';

const StadiumCustomizer = ({ event, handleEventChange, classes }) => {
  const { user } = useAuth();
  const { stadium } = event;
  const isSuperAdmin = validateUserRole(user.role, USER_PERMISSIONS.SUPER_ADMIN);

  return (
    <Box className={classes.stadiumContainer}>
      <Box flex={1}>
        <InputTitle title={stadium.name} materialIcon={<MapOutlinedIcon />} />
        <Stack alignSelf="center" width="100%" maxWidth={360}>
          <StadiumImage image={stadium.image} />
        </Stack>
      </Box>
      <Box flex={2}>
        <InputTitle title="Selectores y capacidades" materialIcon={<AddLocationOutlinedIcon />} />
        <Typography className={classes.subtitle} align="left">
          {'Sectores: '}
          <strong>{stadium.numberOfSections}</strong>
          {isSuperAdmin && (
            <>
              {' Capacidad total: '}
              <strong>{stadium.capacity} loc.</strong>
            </>
          )}
        </Typography>
        <Divider className={classes.divider} />
        <Box display="flex" flexDirection="column" gap={2} maxHeight={320} overflow="auto">
          {stadium.sections.map((section, index) => (
            <SectionForm
              isSuperAdmin={isSuperAdmin}
              key={section.id}
              event={event}
              section={section}
              index={index}
              handleEventChange={handleEventChange}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(StadiumCustomizer);
