import { createStyles } from '@material-ui/core';

const styles = (theme) => {
  const animation = {
    animation: '$wave 1s ease-in-out infinite',
    transition: 'transform 300ms ease-in-out',
  };

  return createStyles({
    arrowsContainer: {
      display: 'flex',
      gap: 48,
      padding: '0 0 20px',
      margin: '40px 0 56px',

      '& .leftArrow': {
        ...animation,
        animationDelay: '300ms',
      },

      '& .centerArrow': {
        ...animation,
        animationDelay: '150ms',
      },

      '& .rightArrow': {
        ...animation,
      },

      '& .paintedArrow path': {
        stroke: theme.palette.primary.main,
      },
    },

    '@keyframes wave': {
      '0%': {
        transform: 'translateY(0)',
        '-webkit-transform': 'translateY(0)',
      },
      '50%': {
        transform: 'translateY(20px)',
        '-webkit-transform': 'translateY(20px)',
      },
      '100%': {
        transform: 'translateY(0)',
        '-webkit-transform': 'translateY(0)',
      },
    },
  });
};

export default styles;
