// import timeIconDark from '../../assets/icon-time_dark.svg';
import timeIconLight from '../../assets/icon-time_light.svg';
import timeIconDataboy from '../../assets/icon-time_databoy.svg';

// import LocationIconDark from '../../assets/icon-location_dark.svg';
import LocationIconLight from '../../assets/icon-location_light.svg';
import LocationIconDataboy from '../../assets/icon-location_databoy.svg';

import userIconLight from '../../assets/icon-user_light.svg';
import userIconDataboy from '../../assets/icon-user_databoy.svg';

// import DateIconDark from '../../assets/icon-date_dark.svg';
import DateIconDataboy from '../../assets/icon-date_databoy.svg';
import DateIconLight from '../../assets/icon-date_light.svg';

import passIconDataboy from '../../assets/icon-pass_databoy.svg';
// import passIconDark from '../../assets/icon-pass_dark.svg';
import passIconLight from '../../assets/icon-pass_light.svg';

import ImageIconDark from '../../assets/icon-image_dark.svg';
import ImageIconLight from '../../assets/icon-image_light.svg';

import TitleIconDark from '../../assets/icon-title_dark.svg';
import TitleIconLight from '../../assets/icon-title_light.svg';

import EventDateIconDark from '../../assets/icon-eventdate_dark.svg';
import EventDateIconLight from '../../assets/icon-eventdate_light.svg';

import descriptionIconDark from '../../assets/icon-description_dark.svg';
import descriptionIconLight from '../../assets/icon-description_light.svg';

import ticketIconDark from '../../assets/icon-ticket_dark.svg';
import ticketIconLight from '../../assets/icon-ticket_light.svg';

import pencilIconDataboy from '../../assets/icon-pencil_databoy.svg';
// import pencilIconDark from '../../assets/icon-pencil_dark.svg';
import pencilIconLight from '../../assets/icon-pencil_light.svg';

import checkIconWhite from '../../assets/iconCheck_white.svg';
import checkIconBlack from '../../assets/iconCheck_black.svg';

import cashInfoIconWhite from '../../assets/info/CashSaleInfo_white.svg';
import cashInfoIconBlack from '../../assets/info/CashSaleInfo_black.svg';

import eventInfoIconWhite from '../../assets/info/EventInfo_white.svg';
import eventInfoIconBlack from '../../assets/info/EventInfo_black.svg';

import listInfoIconWhite from '../../assets/info/ListInfo_white.svg';
import listInfoIconBlack from '../../assets/info/ListInfo_black.svg';

import reportInfoIconWhite from '../../assets/info/ReportsInfo_white.svg';
import reportInfoIconBlack from '../../assets/info/ReportsInfo_black.svg';

import ticketInfoIconWhite from '../../assets/info/TicketInfo_white.svg';
import ticketInfoIconBlack from '../../assets/info/TicketInfo_black.svg';

import duplicateInfoIconBlack from '../../assets/info/DuplicateInfo_black.svg';
import duplicateInfoIconWhite from '../../assets/info/DuplicateInfo_white.svg';

import helpInfoIconBlack from '../../assets/info/Help_black.svg';
import helpInfoIconWhite from '../../assets/info/Help_white.svg';

import lemonInfoIconBlack from '../../assets/info/Lemon_black.svg';
import lemonInfoIconWhite from '../../assets/info/Lemon_white.svg';

import locationInfoIconBlack from '../../assets/info/Location_black.svg';
import locationInfoIconWhite from '../../assets/info/Location_white.svg';

import userInfoIconBlack from '../../assets/add_person_black.svg';
import userInfoIconWhite from '../../assets/add_person_white.svg';

import visibilityBlack from '../../assets/visibility_dark.svg';
import visibilityWhite from '../../assets/visibility_light.svg';

import arrowWhite from '../../assets/arrow_white.svg';
import arrowBlack from '../../assets/arrow_black.svg';
import arrowVenti from '../../assets/arrow_venti.svg';

import arrowNavigationVenti from '../../assets/arrow_navigation_venti.svg';

import cardWhite from '../../assets/icon-card_white.svg';
import cardBlack from '../../assets/icon-card_black.svg';

import mailWhite from '../../assets/icon-mail_white.svg';
import mailBlack from '../../assets/icon-mail_black.svg';

import priceIconLight from '../../assets/price_light.svg';
import priceIconDark from '../../assets/price_dark.svg';

import nftIconDark from '../../assets/nftIcon_white.svg';
import nftIconLight from '../../assets/nftIcon_black.svg';

import lemon from '../../assets/lemon/lemon-logo.svg';

import { getThemeByBaseUrl } from '../../utils/utils';

// footer assets
import footerVentiAsset from '../../assets/footer/footerVentiAsset.svg';
import footerMandarineAsset from '../../assets/footer/footerMandarineAsset.svg';
import footerUnderclubAsset from '../../assets/footer/footerUnderclubAsset.svg';
import footerBNPAsset from '../../assets/footer/footerBNPAsset.svg';

// header assets
import headerDarkVentiAsset from '../../assets/header/headerDarkVentiAsset.svg';

import ventiLogo from '../../assets/header/headerVentiLogo.svg';
import mandarineLogo from '../../assets/header/headerMandarineLogo.png';
import headerBNPAsset from '../../assets/header/headerBNPAsset.svg';
import { WHITE_LABELS } from '../../constants/whiteLabels';

class IconsProvider {
  iconsLight = {
    time: timeIconLight,
    location: LocationIconLight,
    price: priceIconLight,
    user: userIconLight,
    image: ImageIconLight,
    title: TitleIconLight,
    eventDate: EventDateIconLight,
    description: descriptionIconLight,
    date: DateIconLight,
    ticket: ticketIconLight,
    buttonTicket: ticketIconDark,
    pass: passIconLight,
    pencil: pencilIconLight,
    check: checkIconBlack,
    infoCash: cashInfoIconBlack,
    infoEvent: eventInfoIconBlack,
    infoList: listInfoIconBlack,
    infoReport: reportInfoIconBlack,
    infoTicket: ticketInfoIconBlack,
    infoDuplicate: duplicateInfoIconBlack,
    infoHelp: helpInfoIconBlack,
    infoLemon: lemonInfoIconBlack,
    infoLocation: locationInfoIconBlack,
    infoUser: userInfoIconBlack,
    arrow: arrowBlack,
    card: cardBlack,
    mail: mailBlack,
    navArrow: arrowNavigationVenti,
    visibility: visibilityBlack,
    lemon,
    nft: nftIconLight,
  };

  iconsDark = {
    time: timeIconDataboy,
    location: LocationIconDataboy,
    price: priceIconDark,
    user: userIconDataboy,
    image: ImageIconDark,
    title: TitleIconDark,
    eventDate: EventDateIconDark,
    description: descriptionIconDark,
    date: DateIconDataboy,
    ticket: ticketIconDark,
    buttonTicket: ticketIconDark,
    pass: passIconDataboy,
    pencil: pencilIconDataboy,
    check: checkIconWhite,
    infoCash: cashInfoIconWhite,
    infoEvent: eventInfoIconWhite,
    infoList: listInfoIconWhite,
    infoReport: reportInfoIconWhite,
    infoTicket: ticketInfoIconWhite,
    infoDuplicate: duplicateInfoIconWhite,
    infoHelp: helpInfoIconWhite,
    infoLemon: lemonInfoIconWhite,
    infoLocation: locationInfoIconWhite,
    infoUser: userInfoIconWhite,
    arrow: arrowWhite,
    card: cardWhite,
    mail: mailWhite,
    navArrow: arrowNavigationVenti,
    visibility: visibilityWhite,
    lemon,
    nft: nftIconDark,
  };

  iconsDarkVenti = {
    ...this.iconsDark,
    logo: ventiLogo,
    logoInverse: ventiLogo,
    arrow: arrowVenti,
    footerAsset: footerVentiAsset,
    headerAsset: headerDarkVentiAsset,
  };

  iconsVenti = {
    ...this.iconsLight,
    logo: ventiLogo,
    logoInverse: ventiLogo,
    arrow: arrowVenti,
    footerAsset: footerVentiAsset,
    headerAsset: headerDarkVentiAsset,
  };

  iconsMandarine = {
    ...this.iconsLight,
    logo: mandarineLogo,
    arrow: arrowVenti,
    footerAsset: footerMandarineAsset,
  };

  iconsUnderClub = {
    ...this.iconsDark,
    logo: footerUnderclubAsset,
    arrow: arrowVenti,
    footerAsset: footerUnderclubAsset,
  };

  iconsBNP = {
    ...this.iconsLight,
    logo: headerBNPAsset,
    arrow: arrowVenti,
    footerAsset: footerBNPAsset,
  };

  getIcons(theme) {
    switch (theme) {
      case WHITE_LABELS.DARK_VENTI:
        return this.iconsDarkVenti;
      case WHITE_LABELS.VENTI:
        return this.iconsVenti;
      case WHITE_LABELS.MANDARINE:
        return this.iconsMandarine;
      case WHITE_LABELS.UNDER_CLUB:
        return this.iconsUnderClub;
      case WHITE_LABELS.BNP:
        return this.iconsBNP;
      case WHITE_LABELS.HERNAN_CATTANEO:
        return this.iconsBNP;
      default:
        return this.iconsVenti;
    }
  }
}

const iconsProvider = new IconsProvider();
const theme = getThemeByBaseUrl();
const icons = iconsProvider.getIcons(theme);

export { icons, iconsProvider };
