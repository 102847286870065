import React, { useState, cloneElement } from 'react';
import { Step, StepLabel, Stepper, Typography, withStyles } from '@material-ui/core';
import styles from './HorizontalStepper.styles';

const HorizontalStepper = ({ classes, steps }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!steps[activeStep].optional) {
      throw new Error("You can't skip a step that isn't optional.");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  return (
    <div className={classes.horizontalStepperContainer}>
      <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel>
        {steps.map(({ title, optional }, index) => {
          const stepProps = {
            completed: isStepSkipped(index),
          };
          const labelProps = {
            optional: optional && <Typography variant="caption">Opcional</Typography>,
          };
          return (
            <Step key={title} {...stepProps}>
              <StepLabel {...labelProps} className={classes.stepTitle}>
                {title}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div className={classes.horizontalStepperContent}>
        {steps.map(({ title, component }, index) => {
          const cloned = cloneElement(component, { handleNext, handleBack, handleSkip });
          return (
            activeStep === index && <React.Fragment key={title + index}>{cloned}</React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default withStyles(styles)(HorizontalStepper);
