import React from 'react';
import { withStyles } from '@material-ui/core';
import { Box, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import styles from './AppearanceForm.styles';
import VFormCard from '../../../../Venti-UI-Kit/VForms/VFormCard/VFormCard';
import VImageUploader from '../../../../Venti-UI-Kit/VImageUploader/VImageUploader';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';
import { DEFAULT_IMAGES } from '../ProducerForm.constants';
import { WHITE_LABELS } from '../../../../constants/whiteLabels';
import { useAppearanceForm } from './AppearanceForm.hook';

const AppearanceForm = ({ classes, onChange, formState }) => {
  const {
    selectedTheme,
    bgColor,
    fontColor,
    handleSelectTheme,
    handleSelectLogoImg,
    handleSelectBannerImg,
  } = useAppearanceForm(onChange, formState);

  return (
    <VFormCard label="Apariencia">
      <RadioGroup defaultValue={selectedTheme} name="theme" onChange={handleSelectTheme}>
        <Stack direction="row">
          <FormControlLabel value={WHITE_LABELS.VENTI} control={<Radio />} label="Claro" />
          <FormControlLabel value={WHITE_LABELS.DARK_VENTI} control={<Radio />} label="Oscuro" />
        </Stack>
      </RadioGroup>
      <Stack className={classes.previewContainer} bgcolor={bgColor} color={fontColor}>
        <Stack position="relative">
          <VImageUploader
            height={{ xs: 150, sm: 'unset' }}
            imageHeight={1000}
            imageWidth={2600}
            initialImageSrc={formState?.bannerImg.value || DEFAULT_IMAGES.BANNER_IMAGE}
            className={classes.logoBannerContainer}
            onChange={handleSelectBannerImg}
          />
          <VImageUploader
            className={classes.logoImgContainer}
            width={{ xs: 100, sm: 130, md: 200 }}
            imageHeight={1000}
            imageWidth={1000}
            initialImageSrc={formState?.logoImg.value || DEFAULT_IMAGES.LOGO_IMAGE}
            onChange={handleSelectLogoImg}
          />
        </Stack>
        <Stack direction="column" gap={2} marginTop={7} alignItems="end">
          <Box className={classes.buttonPreview} />
          <VTypography variant="body1" width="100%" align="left">
            Texto de prueba para ver cómo se ve el texto en la página.
          </VTypography>
        </Stack>
      </Stack>
    </VFormCard>
  );
};

export default withStyles(styles)(AppearanceForm);
