const styles = (theme) => ({
  stadiumContainer: {
    gap: 20,
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
  subtitle: {
    fontSize: 16,
    color: theme.palette.text.primary,
    marginTop: 12,
  },
  divider: {
    margin: '10px 0',
  },
});

export default styles;
