import { useMemo } from 'react';
import { useInfinitScroll } from '../../../../hooks/useInfinitScroll';
import api from '../../../../api/api';

export const useEventsSlider = ({ onlyPastEvents }) => {
  const eventsFormatter = (prevEvents, newEvents) => {
    const newEventsGrouped = newEvents.reduce((acc, event) => {
      const [year, month] = event.startDate.split('-');
      const yearKey = year;
      const monthKey = month;

      if (!acc[yearKey]) acc[yearKey] = {};
      if (!acc[yearKey][monthKey]) acc[yearKey][monthKey] = [];

      acc[yearKey][monthKey].push(event);
      return acc;
    }, {});

    const updatedEvents = { ...prevEvents };

    for (const year in newEventsGrouped) {
      if (!updatedEvents[year]) updatedEvents[year] = {};
      for (const month in newEventsGrouped[year]) {
        updatedEvents[year][month] = [
          ...(updatedEvents[year][month] || []),
          ...newEventsGrouped[year][month],
        ];
      }
    }

    return updatedEvents;
  };

  const currentDate = useMemo(() => new Date().toISOString(), []);

  const {
    items: events,
    isGettingMoreItems,
    scrollRef,
  } = useInfinitScroll({
    endpoint: api.getUserEvents,
    options: { ...(onlyPastEvents ? { beforeOf: currentDate } : { afterOf: currentDate }) },
    formatter: eventsFormatter,
    itemsLimit: 7,
  });

  function formatDateString(month) {
    const dateObject = new Date(1, month - 1, 1);
    const formattedMonth = dateObject.toLocaleString('es-ES', { month: 'long' });
    return `${formattedMonth.charAt(0).toUpperCase() + formattedMonth.slice(1)}`;
  }

  const getSortedEventsKeys = (object, shouldReverse) => {
    const keys = Object.keys(object);
    return shouldReverse ? keys.reverse() : keys;
  };

  const eventsLength = events && Object.keys(events).length;

  return {
    scrollRef,
    events,
    formatDateString,
    getSortedEventsKeys,
    isGettingMoreItems,
    eventsLength,
  };
};
