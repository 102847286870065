import { useEffect, useState } from 'react';
import CartStorage from '../../../Providers/CartStorage/CartStorage';
import { handleRequestHelper } from '../../../utils/helpers';
import { getUrlSearchParams } from '../../../utils/utils';
import { HTTP_CODE, ORDER_STATUS } from '../../../constants/types';
import api from '../../../api/api';

export const usePurchaseResultPages = () => {
  const { code, externalId, internalErrorCode } = getUrlSearchParams();

  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [orderSuccess, setOrderSuccess] = useState(parseInt(code) === HTTP_CODE.SUCCESS);

  const getOrder = async () => {
    setIsLoading(true);

    await handleRequestHelper({
      endpoint: () => api.getOrderByExternalId(externalId),
      onSuccess: ({ order }) => {
        setOrder(order);
        setOrderSuccess(order?.status === ORDER_STATUS.PAID);
      },
    });

    setIsLoading(false);
  };

  useEffect(() => {
    CartStorage.deleteCart();
    CartStorage.deleteStoredUserCart();
    CartStorage.deleteStoredMercadoPagoKey();
    getOrder();
  }, []);

  return {
    orderSuccess,
    isLoading,
    order,
    internalErrorCode,
  };
};
