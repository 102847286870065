import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { Box, Stack } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import styles from './OrderTicketsCard.styles';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';
import OrderTicketInfoCard from '../OrderTicketInfoCard/OrderTicketInfoCard';
import SliderButtons from '../../../../Venti-UI-Kit/SliderButtons/SliderButtons';
import Button from '../../../../Components/Buttons/Button/Button';
import ResendOrderBottomSheet from '../../../../Components/BottomSheets/ResendOrderBottomSheet/ResendOrderBottomSheet';
import { ORDER_STATUS } from '../../../../constants/types';

const OrderTicketsCard = ({ classes, order }) => {
  const { tickets, id: orderId } = order;
  const [currentTicketIndex, setCurrentTicketIndex] = useState(0);
  const [openResendOrderBottomSheet, setOpenResendOrderBottomSheet] = useState(false);

  return (
    <Stack
      direction="column"
      padding={2}
      borderRadius={2}
      boxShadow="0px 2px 4px 0px #00000040"
      backgroundColor="white"
      gap={2}
      width="100%"
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <VTypography variant="subtitle3">Tickets: {tickets.length} </VTypography>
        <Button
          color="secondary"
          size="small"
          fullwidth={false}
          variant="outlined"
          icon={<EmailOutlinedIcon />}
          title="Reenviar tickets"
          width={190}
          disabled={order.status !== ORDER_STATUS.PAID}
          onClick={() => setOpenResendOrderBottomSheet(true)}
        />
      </Stack>

      <Box gap={3} display={{ xs: 'none', sm: 'flex' }} className={classes.ticketsSlider}>
        {tickets.map((ticket) => (
          <OrderTicketInfoCard ticket={ticket} key={ticket.orderCountNumber} orderId={orderId} />
        ))}
      </Box>
      <OrderTicketInfoCard
        display={{ xs: 'flex', sm: 'none' }}
        ticket={tickets[currentTicketIndex]}
        orderId={orderId}
      />

      <Box
        display={{ xs: 'flex', sm: 'none' }}
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <SliderButtons
          setCurrentTicketIndex={setCurrentTicketIndex}
          currentTicketIndex={currentTicketIndex}
          length={tickets.length}
        />
      </Box>
      <ResendOrderBottomSheet
        open={openResendOrderBottomSheet}
        orderId={order.id}
        onClose={() => setOpenResendOrderBottomSheet(false)}
        qrMails={order.qrMails}
      />
    </Stack>
  );
};

export default withStyles(styles)(OrderTicketsCard);
