import React from 'react';
import { withStyles } from '@material-ui/core';
import { Checkbox, FormControlLabel } from '@mui/material';
import styles from './CheckBoxStyles';

const CheckBox = ({ classes, label, onChange, size = 'small' }) => {
  return (
    <FormControlLabel
      className={classes.termsCheckbox}
      control={<Checkbox className="checkbox" size={size} />}
      label={label}
      onChange={onChange}
    />
  );
};

export default withStyles(styles)(CheckBox);
