import React, { useState } from 'react';
import { Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';
import IconButton from '../../../../Components/Buttons/IconButton/IconButton';
import CardField from '../CardField/CardField';
import ChangeOrderMailBottomSheet from '../../../../Components/BottomSheets/ChangeOrderMailBottomSheet/ChangeOrderMailBottomSheet';
import { ORDER_STATUS } from '../../../../constants/types';

const OrderOwnerInfoCard = ({ order, getOrder }) => {
  const { id, owner } = order;
  const [openChangeOrderMailBottomSheet, setOpenChangeOrderMailBottomSheet] = useState(false);

  return (
    <Stack
      direction="column"
      flex={1}
      padding={2}
      borderRadius={2}
      boxShadow="0px 2px 4px 0px #00000040"
      backgroundColor="white"
      gap={2}
    >
      <Stack direction="row" justifyContent="space-between">
        <VTypography variant="subtitle3">Titular</VTypography>
        <IconButton
          Icon={<EditIcon />}
          onClick={() => setOpenChangeOrderMailBottomSheet(true)}
          disabled={order.status !== ORDER_STATUS.PAID}
          variant="outlined"
        />
      </Stack>
      <Stack direction="row" gap={2}>
        <CardField title="Nombre y Apellido" textValue={`${owner.firstName} ${owner.lastName}`} />
        <CardField title={owner.identificationType} textValue={owner.identificationValue} />
      </Stack>
      <Stack direction="row" gap={2}>
        <CardField title="Lugar de residencia" textValue={owner.province} />
        <CardField title="Teléfono" textValue={owner.phone} />
      </Stack>
      <Stack gap="inherit">
        <CardField title="Mail" textValue={owner.mail} />
        <CardField title="Customer ID" textValue={owner.customerId} />
      </Stack>

      <ChangeOrderMailBottomSheet
        open={openChangeOrderMailBottomSheet}
        onClose={() => setOpenChangeOrderMailBottomSheet(false)}
        ownerMail={owner.mail}
        orderId={id}
        getOrder={getOrder}
      />
    </Stack>
  );
};

export default OrderOwnerInfoCard;
