import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    userDataPage: {
      padding: '0 16px 24px',
      height: 'auto',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      maxWidth: 900,
      width: '100%',
      alignSelf: 'center',
      gap: 32,

      [theme.breakpoints.down('sm')]: {
        alignSelf: 'center',
      },
    },

    formContainer: {
      display: 'grid',
      marginTop: '24px',
      gridTemplateColumns: '1fr 1fr',
      gap: '16px 24px',

      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  });

export default styles;
