import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    textField: {
      '& .MuiOutlinedInput-root': {
        padding: 10,
        borderRadius: 8,
        '& fieldset': {
          borderColor: theme.palette.grey[300],
        },
        '&:hover fieldset': {
          borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.primary.main,
        },
      },
    },
    error: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.error.main,
        },
        '&:hover fieldset': {
          borderColor: theme.palette.error.main,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.error.main,
        },
      },
    },
  });

export default styles;
