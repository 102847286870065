import { useState } from 'react';
import { moveItemToFirstPlaceInList } from '../utils/utils';
import { handleRequestHelper } from '../utils/helpers';
import { useNotifications } from '../Providers/NotificationsProvider/NotificationsProvider';
import api from '../api/api';

export const NO_TEAM_LEADER = {
  initialTeamLeaderId: 0,
  teamLeaderId: 0,
  code: 'Sin Team Leader',
};

const useProducers = (producerId) => {
  const { showNotification } = useNotifications();
  const [producers, setProducers] = useState([]);

  const addProducersTeamLeaders = async (producers) => {
    for (const producer of producers) {
      if (!producer.generatesSellerReports) continue;

      await handleRequestHelper({
        endpoint: () => api.getProducerTeamLeaders(producer.id),
        showNotification,
        onSuccess: ({ teamLeaders }) => {
          if (!teamLeaders.length) return;
          producer.teamLeaders = [NO_TEAM_LEADER].concat(
            teamLeaders.map(({ id: teamLeaderId, code }) => ({
              teamLeaderId,
              code,
            }))
          );
        },
      });
    }

    setProducers(producers);
  };

  const getUserProducers = async () => {
    await handleRequestHelper({
      endpoint: () => api.getAllProducersWithEvents(),
      showNotification,
      onSuccess: async ({ producers }) => {
        const currentProducer = producers.find(({ id }) => id === parseInt(producerId));
        const sortedProducersList = moveItemToFirstPlaceInList(currentProducer, producers);
        await addProducersTeamLeaders(sortedProducersList);
      },
    });
  };

  return {
    producers,
    getUserProducers,
  };
};

export default useProducers;
