import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    title: {
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        padding: '0 10px',
      },
    },
    producerTitle: {
      marginTop: 20,
      fontWeight: 600,
      transition: 'all 0.6s',
      [theme.breakpoints.down('xs')]: {
        fontSize: 19,
        padding: 0,
      },
    },
    pageContent: {
      width: '70%',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      rowGap: 20,
      [theme.breakpoints.down('xs')]: {
        width: '80%',
      },
    },
  });

export default styles;
