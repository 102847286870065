import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    title: {
      fontWeight: 600,
      fontSize: 20,
      padding: '0 16px',
    },
    slider: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 24,
      gap: 8,
    },
    imageStroke: {
      '& > path[fill="#000"]': {
        fill: theme.palette.text.primary,
      },
      '& > path[stroke="#000"]': {
        stroke: theme.palette.text.primary,
      },
      '& > rect[stroke="#000"]': {
        stroke: theme.palette.text.primary,
      },
    },
  });

export default styles;
