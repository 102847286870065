import React from 'react';
import CustomLink from '../../../../Components/CustomLink/CustomLink';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';

const TermsAndConditionsLabel = ({ leftText, rightText }) => {
  const termsAndConditions = (
    <CustomLink
      href="https://legals.venti.live/documentacion/terminosycondiciones"
      title="términos y condiciones"
      fontSize="inherit"
      underline
    />
  );

  const privacyPolicy = (
    <CustomLink
      href="https://legals.venti.live/documentacion/politicas-de-privacidad"
      title="políticas de privacidad"
      fontSize="inherit"
      underline
    />
  );

  return (
    <VTypography variant="body2">
      {leftText} {termsAndConditions} y {privacyPolicy} {rightText}
    </VTypography>
  );
};

export default TermsAndConditionsLabel;
