import React from 'react';
import CSVDownloadQrsButton from '../../../../Components/Buttons/CSVDownloadQrsButton/CSVDownloadQrsButton';

import { icons } from '../../../../Providers/Icons/IconsProvider';
import { getEventLink, validateUserRole } from '../../../../utils/utils';
import { USER_PERMISSIONS } from '../../../../constants/types';

export const infoCards = ({ user, classes, event }) => {
  const { id: eventId, producerId, name: eventName, endDate } = event;

  const currentDate = new Date().toISOString();
  const eventIsActive = endDate > currentDate;

  return [
    {
      title: 'Ver mi evento',
      text: 'Un link para ver tu evento :)',
      link: getEventLink(event, user),
      icon: icons.infoLemon,
      permissions: validateUserRole(user.role, USER_PERMISSIONS.BACKOFFICE),
      newTab: true,
    },
    {
      title: 'Reportes',
      text: 'Seguí tus ventas de este evento.',
      link: `/backoffice/productoras/${producerId}/eventos/${eventId}/reportes`,
      icon: icons.infoReport,
      permissions: validateUserRole(user.role, USER_PERMISSIONS.BACKOFFICE),
    },
    {
      title: 'Editar evento',
      link: `/backoffice/productoras/${producerId}/eventos/${eventId}/editar`,
      text: 'Editá y agregá los productos que quieras cambiando su estado, precio y nombre, entre otros.',
      icon: icons.infoEvent,
      permissions: validateUserRole(user.role, USER_PERMISSIONS.ADMIN),
    },
    {
      title: 'Editar tickets e invitaciones',
      text: 'Crea y edita tickets e invitaciones. En este apartado podes modificar cantidades y todas las propiedades de los accesos de tu evento',
      link: `/backoffice/productoras/${producerId}/eventos/${eventId}/tickets`,
      icon: icons.infoTicket,
      permissions: validateUserRole(user.role, USER_PERMISSIONS.ADMIN),
    },
    {
      title: 'Crear usuario',
      text: 'Crea administradores o vendedores para tu evento',
      link: `/backoffice/productoras/${producerId}/eventos/${eventId}/crearUsuario`,
      icon: icons.infoUser,
      permissions: validateUserRole(user.role, USER_PERMISSIONS.ADMIN) && eventIsActive,
    },
    {
      title: 'Asignar invitaciones a RRPPs',
      text: 'Asigná invitaciones a los RRPP para que puedan enviarlas a sus clientes.',
      link: `/backoffice/productoras/${producerId}/eventos/${eventId}/asignarInvitaciones`,
      icon: icons.infoTicket,
      permissions: validateUserRole(user.role, USER_PERMISSIONS.ADMIN),
    },
    {
      title: 'Enviar invitaciones',
      text: 'Envía invitaciones de tu evento.',
      link: `/backoffice/productoras/${producerId}/eventos/${eventId}/enviarInvitaciones`,
      icon: icons.infoTicket,
      permissions: validateUserRole(user.role, USER_PERMISSIONS.SELLERS),
    },
    {
      title: 'Enviar múltiples invitaciones',
      text: 'Envía múltiples invitaciones de tu evento a través de un CSV.',
      link: `/backoffice/productoras/${producerId}/eventos/${eventId}/enviarMultiplesInvitaciones`,
      icon: icons.infoTicket,
      permissions: validateUserRole(user.role, USER_PERMISSIONS.ADMIN),
    },
    {
      title: 'Administrar blacklist',
      text: 'Administrá la blacklist de este evento: habilitá o inhabilitá la visibilidad para distintos usuarios.',
      link: `/backoffice/productoras/${producerId}/eventos/${eventId}/blacklist`,
      icon: icons.infoList,
      permissions: validateUserRole(user.role, USER_PERMISSIONS.ADMIN),
    },
    {
      title: 'Venta cash',
      text: 'Realizá ordenes en efectivo para este evento.',
      link: `/backoffice/productoras/${producerId}/eventos/${eventId}/ventaCash`,
      icon: icons.infoCash,
      permissions: validateUserRole(user.role, USER_PERMISSIONS.SELLER_WITH_CASH),
    },
    {
      title: 'Lista de ordenes',
      text: 'Mirá la lista de ordenes del evento. Podrás manejar los accesos y los pagos tambien.',
      link: `/backoffice/productoras/${producerId}/eventos/${eventId}/ordenes`,
      icon: icons.infoList,
      permissions: validateUserRole(user.role, USER_PERMISSIONS.ADMIN),
    },
    // TODO: Add this feature when the endpoint is fixed
    // {
    //   title: 'Duplicar evento',
    //   text: 'Hacé un evento igual con sus tickets, editando la información que quieras',
    //   link: `/backoffice/productoras/${producerId}/eventos/${eventId}/duplicarEvento`,
    //   icon: icons.infoList,
    //   permissions: validateUserRole(user.role, USER_PERMISSIONS.ADMIN),
    // },
    {
      title: 'Descargar QRs',
      text: 'Descargá un csv con la información de los QRs de las entradas vendidas del evento.',
      link: `/backoffice/productoras/${producerId}/eventos/${eventId}/reportes`,
      icon: icons.infoTicket,
      permissions: validateUserRole(user.role, USER_PERMISSIONS.ADMIN_WITH_READ_ONLY),

      button: (
        <CSVDownloadQrsButton
          eventId={eventId}
          eventName={eventName}
          style={classes.downloadButton}
        />
      ),
    },
    {
      title: 'Liquidaciones',
      text: 'Cargá adelantos, servicios, rebates, y liquidaciones del evento.',
      link: `/backoffice/productoras/${producerId}/eventos/${eventId}/liquidaciones`,
      icon: icons.infoReport,
      permissions: validateUserRole(user.role, USER_PERMISSIONS.SUPER_ADMIN),
    },
    {
      title: 'Opciones avanzadas',
      text: 'Opciones avanzadas del evento.',
      link: `/backoffice/productoras/${producerId}/eventos/${eventId}/opcionesAvanzadas`,
      icon: icons.infoHelp,
      permissions: validateUserRole(user.role, USER_PERMISSIONS.SUPER_ADMIN),
    },
  ];
};
