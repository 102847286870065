import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    modalContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 15,
    },
    modalBoxContainer: {
      width: 328,
      padding: '24px 16px',
      background: theme.palette.grey[50],
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
    },
  });

export default styles;
