import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import styles from './AccessesReportCardStyles';

const AccessesReportCard = ({ classes, data }) => {
  const { name, totalSold, totalUsed } = data;

  return (
    <div className={classes.cardContainer}>
      <div className={classes.cardInfo}>
        <Typography className={classes.eventTitle}>{name}</Typography>
        <div className={classes.eventInfo}>
          <Typography>
            Ingresaron: {totalUsed}/{totalSold}
          </Typography>
        </div>
      </div>
      <div className={classes.amountContainer}>
        <div className={classes.totalSoldContainer}>
          <Typography className={classes.totalSoldValue}>{totalSold - totalUsed}</Typography>
          <Typography className={classes.totalSoldLabel}>Faltan ingresar</Typography>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(AccessesReportCard);
