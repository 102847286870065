import React, { useCallback, useEffect } from 'react';
import { useTheme, withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import styles from './HeaderStyles';
import { ThemeContext } from '../../Providers/Theme/Theme';
import UserMenu from '../UserMenu/UserMenu';
import { useAuth } from '../../Providers/AuthProvider/AuthProvider';
import EventSearcher from '../EventSearcher/EventSearcher';

export const HEADER_HEIGHT = 60;
export const HEADER_CHILDREN_HEIGHT = 75;

const Header = ({ classes, showSearchButton = false, showLoginButton = true, children }) => {
  const { user } = useAuth();
  const theme = useTheme();

  const updateMetaTabColor = useCallback(() => {
    document
      .querySelector('[name="theme-color"]')
      .setAttribute('content', `${theme.palette.header.background}`);
  }, [theme.palette.header.background]);

  useEffect(() => {
    updateMetaTabColor();
  }, [updateMetaTabColor]);

  return (
    <ThemeContext.Consumer>
      {({ icons }) => (
        <Stack
          className={classes.headerContainer}
          id="VentiHeader"
          height="min-content"
          position={!children && 'fixed'}
        >
          <Stack direction="row" maxHeight={HEADER_HEIGHT} className={classes.mainHeaderContainer}>
            <a href="/" className={classes.logoContainer}>
              <img src={icons.logo} className={classes.logo} alt="headerAsset" />
            </a>
            <Stack direction="row" gap={2} alignItems="center">
              {showSearchButton && <EventSearcher />}
              {user ? (
                <UserMenu />
              ) : (
                showLoginButton && (
                  <a className={classes.loginButton} href="/login">
                    Ingresar
                  </a>
                )
              )}
            </Stack>
          </Stack>
          {children && (
            <Stack className={classes.subHeaderContainer} height={HEADER_CHILDREN_HEIGHT}>
              {children}
            </Stack>
          )}
        </Stack>
      )}
    </ThemeContext.Consumer>
  );
};

export default withStyles(styles)(Header);
