export const NO_STADIUM_ID = 0;

export const EVENT_STATUS_OPTIONS = [
  { value: 'active', label: 'Público' },
  { value: 'hidden', label: 'Privado' },
];

export const BLACKLISTED = 'visible';
export const NOT_BLACKLISTED = 'hidden';

export const SELLER_BLACKLIST_STATUS = [
  { value: BLACKLISTED, label: 'Visible' },
  { value: NOT_BLACKLISTED, label: 'Oculto' },
];
