import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    cardContainer: {
      position: 'relative',
      padding: 16,
      borderRadius: 8,
      backgroundColor: theme.palette.grey[200],
      maxWidth: 450,
      gap: 16,
    },
    closeButton: {
      position: 'absolute',
      top: 12,
      right: 12,
      width: 32,
      height: 32,
      minWidth: 0,
    },
    mapContainer: {
      gap: 4,
      flexDirection: 'row !important',
      width: 'fit-content',
    },
  });

export default styles;
