import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      rowGap: 20,
      columnGap: 20,
      width: '80%',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
  });

export default styles;
