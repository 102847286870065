import { Stack } from '@mui/material';
import React, { useContext } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  GOOGLE_RECAPTCHA_VERSION,
  GOOGLE_VERSIONS_PUBLIC_KEY,
  GoogleReCaptchaContext,
} from '../../Providers/GoogleReCaptchaProvider/GoogleReCaptchaProvider';

const GoogleReCaptcha = ({ validation, my, m, mt, mb }) => {
  const { googleReCaptchaVersion } = useContext(GoogleReCaptchaContext);

  return (
    googleReCaptchaVersion === GOOGLE_RECAPTCHA_VERSION.v2 && (
      <Stack width="100%" alignItems="center" my={my} m={m} mt={mt} mb={mb}>
        <ReCAPTCHA sitekey={GOOGLE_VERSIONS_PUBLIC_KEY.v2} onChange={validation} />
      </Stack>
    )
  );
};

export default GoogleReCaptcha;
