import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    descriptionMenuItem: {
      '&.MuiMenuItem-root': {
        '&:hover': {
          backgroundColor: 'unset',
          cursor: 'default',
          userSelect: 'auto',
        },
        '&:focus': {
          backgroundColor: 'unset',
        },
      },
    },
  });

export default styles;
