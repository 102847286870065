import { createStyles } from '@material-ui/core';

const styles = (theme) => {
  return createStyles({
    pageContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    whiteSpace: {
      height: 20,
      width: '100%',
    },

    homeTitle: {
      fontSize: 24,
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
  });
};

export default styles;
