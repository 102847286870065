import React, { useRef } from 'react';
import { TableSortLabel, withStyles } from '@material-ui/core';
import { LABELS } from '../FinancialOperationsTable.constants';
import styles from '../FinancialOperationsTable.styles';
import { SORT_DIRECTION } from '../../../../../constants/types';
import { sortObjectArray } from '../../../../../utils/utils';

const Header = ({ classes, index, label, name, financialOperations, setFinancialOperations }) => {
  const sortIndex = useRef();
  const sortDirection = useRef(SORT_DIRECTION.ASC);

  const sort = (index, label, name) => {
    if (label === LABELS.ACTIONS || !financialOperations.length) return;

    if (sortIndex.current === index) {
      sortDirection.current =
        sortDirection.current === SORT_DIRECTION.DESC ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC;
    } else {
      sortDirection.current = SORT_DIRECTION.DESC;
      sortIndex.current = index;
    }

    if (label === LABELS.CREATED_AT) name = 'createdTimestamp';

    const orderedFinancialOperations = sortObjectArray(
      financialOperations,
      name,
      sortDirection.current
    );

    setFinancialOperations([...orderedFinancialOperations]);
  };

  return (
    <th
      onClick={() => sort(index, label, name)}
      className={`${classes.header} ${label !== LABELS.ACTIONS && classes.arrow}`}
    >
      {label}
      {label !== LABELS.ACTIONS && (
        <TableSortLabel
          active={sortIndex.current === index}
          direction={sortDirection.current || SORT_DIRECTION.ASC}
          className={classes.sortLabel}
        />
      )}
    </th>
  );
};

export default withStyles(styles)(Header);
