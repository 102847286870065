import React, { useEffect, useState } from 'react';
import { handleRequestHelper } from '../../../utils/helpers';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';
import api from '../../../api/api';
import { validateUserRole } from '../../../utils/utils';
import {
  BACKOFFICE_PRODUCER_CARD_CATEGORY,
  PRODUCER_SIDEBAR_SECTIONS,
  USER_PERMISSIONS,
} from '../../../constants/types';

import EventList from '../../../Components/Lists/EventList/EventList';
import CardsList from '../../../Components/Lists/CardsList/CardsList';
import history from '../../../appHistory';
import ConfigProducerPage from '../ConfigProducerPage/ConfigProducerPage';
import ProducerReports from '../ProducerReports/ProducerReports';

export const useProducerPage = (producerId) => {
  const { user } = useAuth();
  const [producer, setProducer] = useState();

  const getProducer = async () => {
    await handleRequestHelper({
      endpoint: () => api.getProducer(producerId),
      onSuccess: ({ producer }) => setProducer(producer),
      onFailure: () => history.push('/error/404'),
    });
  };

  useEffect(() => {
    getProducer();
  }, []);

  const sections = [
    {
      icon: 'dashboard',
      title: 'Dashboard',
      key: PRODUCER_SIDEBAR_SECTIONS.DASHBOARD,
      component: <ProducerReports producerId={producerId} />,
      show: validateUserRole(user.role, USER_PERMISSIONS.ADMIN_WITH_READ_ONLY),
    },
    {
      icon: 'event',
      title: 'Eventos activos',
      key: PRODUCER_SIDEBAR_SECTIONS.ACTIVE_EVENTS,
      component: <EventList producer={producer} />,
      show: validateUserRole(user.role, USER_PERMISSIONS.BACKOFFICE),
    },
    {
      icon: 'event_busy',
      title: 'Eventos pasados',
      key: PRODUCER_SIDEBAR_SECTIONS.PAST_EVENTS,
      component: <EventList past producer={producer} />,
      show: validateUserRole(user.role, USER_PERMISSIONS.BACKOFFICE),
    },
    {
      icon: 'people',
      title: 'Equipo',
      key: PRODUCER_SIDEBAR_SECTIONS.TEAM,
      component: (
        <CardsList
          producerId={producerId}
          generatesSellerReports={producer?.generatesSellerReports}
          category={BACKOFFICE_PRODUCER_CARD_CATEGORY.TEAM}
        />
      ),
      show: validateUserRole(user.role, USER_PERMISSIONS.ADMIN),
    },
    {
      icon: 'settings',
      title: 'Configuración',
      key: 'configuration',
      component: <ConfigProducerPage producerId={producerId} />,
      show: validateUserRole(user.role, USER_PERMISSIONS.ADMIN),
    },
    {
      icon: 'help',
      title: 'Ayuda',
      key: PRODUCER_SIDEBAR_SECTIONS.HELP,
      show: true,
      url: 'https://intercom.help/venti-latam/es',
    },
    {
      icon: 'home',
      title: 'Ver Perfil',
      key: PRODUCER_SIDEBAR_SECTIONS.PRODUCER_PROFILE,
      show: true,
      url: `/organizadores/${producer?.urlName}`,
    },
  ];

  return { sections, producer };
};
