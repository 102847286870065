import React from 'react';
import { Stack } from '@mui/material';
import { IconButton, withStyles } from '@material-ui/core';
import VIcon from '../../../../../Venti-UI-Kit/VIcon/VIcon';
import ConfirmModal from '../../../../../Venti-UI-Kit/Modals/ConfirmModal';
import styles from '../FinancialOperationsTable.styles';
import { useActionsCell } from './ActionsCell.hook';

const ActionsCell = ({
  classes,
  rowIndex,
  edit,
  financialOperations,
  setFinancialOperations,
  initialFinancialOperations,
  setInitialFinancialOperations,
}) => {
  const {
    openModal,
    handleEdit,
    handleCancelEdit,
    handleSave,
    openConfirmModal,
    handleCloseConfirmModal,
    handleDelete,
  } = useActionsCell(
    rowIndex,
    financialOperations,
    setFinancialOperations,
    initialFinancialOperations,
    setInitialFinancialOperations
  );

  return (
    <Stack alignItems="center">
      {edit ? (
        <Stack flexDirection="row" gap={1}>
          <IconButton onClick={handleCancelEdit} className={classes.iconButton}>
            <VIcon name="block" />
          </IconButton>
          <IconButton onClick={handleSave} className={classes.iconButton}>
            <VIcon name="check" />
          </IconButton>
        </Stack>
      ) : (
        <Stack flexDirection="row" gap={1}>
          <IconButton onClick={handleEdit} className={classes.iconButton}>
            <VIcon name="edit" />
          </IconButton>
          <IconButton onClick={openConfirmModal} className={classes.iconButton}>
            <VIcon name="delete" />
          </IconButton>
          <ConfirmModal
            title="Confirmación de eliminación"
            subtitle="¿Estás seguro de que quieres borrar esta operación financiera?"
            open={openModal}
            handleClose={handleCloseConfirmModal}
            handleAccept={handleDelete}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default withStyles(styles)(ActionsCell);
