import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    horizontalStepperContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflow: 'auto',
    },
    stepper: {
      padding: 0,
      marginBottom: 20,
    },
    horizontalStepperContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflowY: 'auto',
      overflowX: 'hidden',
      width: '70%',
      alignSelf: 'center',
      transition: 'all 0.6s',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    stepTitle: {
      wordBreak: 'break-word',
    },
  });

export default styles;
