import React from 'react';
import { withStyles, FormHelperText, Chip } from '@material-ui/core';
import { Autocomplete, Stack } from '@mui/material';
import styles from './VenueAutocomplete.styles';
import { useVenueAutocomplete } from './VenueAutocomplete.hook';
import AutocompleteInput from './AutocompleteInput/AutocompleteInput';
import AutocompleteOption from './AutocompleteOption/AutocompleteOption';
import VenueCard from '../VenueCard/VenueCard';
import VTypography from '../../Venti-UI-Kit/VTypography/VTypography';

const VenueAutocomplete = ({
  classes,
  venue,
  placeName,
  checkInputError,
  onSelectOption,
  showErrors,
}) => {
  const {
    openAutocomplete,
    handleOpenAutocomplete,
    handleCloseAutocomplete,
    venueOptions,
    searchQuery,
    onChangeSearch,
    isLoading,
    setIsLoadingVenue,
    errorMessage,
    suggestedVenues,
  } = useVenueAutocomplete(checkInputError, venue, placeName);

  return (
    <Stack>
      {!venue && !placeName ? (
        <>
          {suggestedVenues.length > 0 && (
            <>
              <VTypography variant="body2">Usadas recientemente</VTypography>
              <Stack className={classes.suggestions} flexWrap="wrap" direction="row" gap={1}>
                {suggestedVenues.map((suggestedVenue) => (
                  <Chip
                    key={suggestedVenue.id}
                    label={suggestedVenue.placeName}
                    variant="outlined"
                    onClick={() =>
                      onSelectOption({
                        venue: suggestedVenue,
                        venueId: suggestedVenue.id,
                      })
                    }
                  />
                ))}
              </Stack>
            </>
          )}
          <Autocomplete
            className={classes.autocompleteContainer}
            open={openAutocomplete}
            onOpen={handleOpenAutocomplete}
            onClose={handleCloseAutocomplete}
            options={isLoading ? [] : venueOptions}
            loading={isLoading}
            freeSolo={!searchQuery}
            clearOnBlur={false}
            noOptionsText="Loading..."
            renderInput={(props) => (
              <AutocompleteInput
                onChange={onChangeSearch}
                showErrors={errorMessage && showErrors}
                {...props}
              />
            )}
            renderOption={(props, option) => (
              <AutocompleteOption
                option={option}
                searchQuery={searchQuery}
                onSelectOption={onSelectOption}
                setIsLoadingVenue={setIsLoadingVenue}
                key={option.label}
              />
            )}
          />
        </>
      ) : (
        <VenueCard
          venue={venue || { readableAddress: placeName }}
          onClose={() => {
            onSelectOption({ venue: null, placeName: null, venueId: null });
          }}
        />
      )}
      {showErrors && <FormHelperText className={classes.helper}>{errorMessage}</FormHelperText>}
    </Stack>
  );
};

export default withStyles(styles)(VenueAutocomplete);
