import React, { useState } from 'react';
import { CircularProgress, Dialog, Typography, withStyles } from '@material-ui/core';
import { CSVReader } from 'react-papaparse';
import { CSVLink } from 'react-csv';
import styles from './CSVUploaderModal.styles';
import Button from '../../../Venti-UI-Kit/Buttons/Button';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { NOTIFICATION_VARIANT } from '../../../constants/types';

const CSVUploaderModal = ({
  classes,
  title,
  message,
  handleOpen,
  handleClose,
  handleConfirm,
  confirmButtonTitle = 'Confirmar',
  cancelButtonTitle = 'Cancelar',
  columns,
}) => {
  const { showNotification } = useNotifications();
  const [file, setFile] = useState();
  const [fileTypeIsValid, setFileTypeIsValid] = useState(false);
  const [uploading, setUploading] = useState(false);

  const handleReadCSV = (data, file) => {
    if (!data.length) {
      showNotification('El archivo esta vacio.', NOTIFICATION_VARIANT.ERROR);
      return;
    }
    if (file.type === 'text/csv' || file.type === 'application/vnd.ms-excel') {
      const headers = columns.map(({ label }) => label);
      const fileHeaders = data[0].meta.fields;

      if (headers.every((field) => fileHeaders.includes(field))) {
        setFile(data);
        setFileTypeIsValid(true);
      } else {
        showNotification('Las columnas no son las correctas', NOTIFICATION_VARIANT.ERROR);
        setFileTypeIsValid(false);
      }
      return;
    }
    showNotification('Se debe subir un archivo con formato CSV', NOTIFICATION_VARIANT.ERROR);
    setFileTypeIsValid(false);
  };

  const onConfirm = () => {
    setUploading(true);
    const rows = file.map(({ data }) =>
      columns.reduce((rowInfo, { value, label }) => ({ ...rowInfo, [value]: data[label] }), {})
    );

    handleConfirm(rows);
    setUploading(false);
    handleClose();
  };

  const onCancel = () => {
    handleClose();
  };

  const handleRemoveFile = () => setFile();

  return (
    <Dialog
      open={handleOpen}
      PaperProps={{ className: classes.modalContainer }}
      onClose={handleClose}
    >
      {title && <Typography className={classes.modalTitle}>{title}</Typography>}
      {message && <Typography className={classes.modalMessage}>{message}</Typography>}
      <div className={classes.dropZoneContainer}>
        <CSVLink
          headers={columns.map(({ label, value }) => ({ label, key: value }))}
          data={[columns.reduce((rowInfo, { value }) => ({ ...rowInfo, [value]: '' }), {})]}
          filename="Template.csv"
          className={classes.downloadTemplateText}
        >
          Descargar template
        </CSVLink>
        <CSVReader
          onFileLoad={handleReadCSV}
          onError={(error) => console.error(error)}
          onRemoveFile={handleRemoveFile}
          addRemoveButton
          removeButtonColor="#659cef"
          config={{
            header: true,
            skipEmptyLines: true,
          }}
        >
          Arrastre o haga click para subir un archivo CSV
        </CSVReader>
      </div>
      <div>
        {uploading ? (
          <CircularProgress size={20} />
        ) : (
          <div className={classes.modalButtonsContainer}>
            <Button color="transparent" onClick={onCancel}>
              {cancelButtonTitle}
            </Button>
            <Button color="main" disabled={!fileTypeIsValid} onClick={onConfirm}>
              {confirmButtonTitle}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(CSVUploaderModal);
