import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    userEventsTabsContainer: {
      '&.MuiTabs-root': {
        minHeight: 35,
      },
      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.main,
      },
      '& button': {
        ...theme.typography.button2,
        color: theme.palette.text.primary,
        minHeight: 35,

        padding: '8px 16px',
        '&.Mui-selected': {
          color: theme.palette.primary.main,
        },
      },
    },
    userEventsTab: {
      minHeight: 35,
    },
  });

export default styles;
