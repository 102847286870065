import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    circularProgress: {
      color: theme.palette.primary.main,
    },
  });

export default styles;
