export const tableTranslations = {
  filterType: 'multiselect',
  selectableRows: 'none',
  responsive: 'scrollMaxHeight',
  downloadOptions: {
    filename: 'Reporte.csv',
    filterOptions: {
      useDisplayedRowsOnly: true,
    },
  },
  textLabels: {
    body: {
      noMatch: 'No se encontraron ordenes',
      toolTip: 'Ordenar',
      columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
    },
    pagination: {
      next: 'Proxima página',
      previous: 'Previous Page',
      rowsPerPage: 'Filas por página:',
      displayRows: 'de',
    },
    toolbar: {
      search: 'Buscar',
      downloadCsv: 'Descargar CSV',
      print: 'Imprimir',
      viewColumns: 'Ver columnas',
      filterTable: 'Filtrar tabla',
    },
    filter: {
      all: 'Todos',
      title: 'Filtros',
      reset: 'Reiniciar',
    },
    viewColumns: {
      title: 'Mostrar columnas',
      titleAria: 'Mostrar/esconder columnas',
    },
    selectedRows: {
      text: 'fila(s) selecionadas',
      delete: 'Borrar',
      deleteAria: 'Borrar las filas seleccionadas',
    },
  },
};
