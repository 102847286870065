import React from 'react';
import { Stack } from '@mui/material';
import Button from '../../Components/Buttons/Button/Button';
import VModal from '../VModal/VModal';

const ConfirmModal = ({
  open,
  acceptLabel = 'Aceptar',
  cancelLabel = 'Cancelar',
  loading = false,
  title,
  subtitle,
  handleAccept,
  handleClose,
}) => {
  return (
    <VModal open={open} title={title} subtitle={subtitle} onClose={handleClose}>
      <Stack direction="column" spacing={2}>
        <Button
          fullWidth
          disabled={loading}
          title={cancelLabel}
          onClick={handleClose}
          variant="outlined"
        />
        <Button
          fullWidth
          loading={loading}
          title={acceptLabel}
          onClick={handleAccept}
          variant="contained"
        />
      </Stack>
    </VModal>
  );
};

export default ConfirmModal;
