import React from 'react';
import { withStyles } from '@material-ui/core';
import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import styles from './PaymentMethodSelectorStyles';
import { PAYMENT_METHODS } from '../../constants/types';
import { ReactComponent as MercadoPagoIcon } from '../../assets/paymentMethods/mercadopago_icon.svg';
import { ReactComponent as MacroClickIcon } from '../../assets/paymentMethods/macroClick_icon.svg';
import { ReactComponent as GaliciaIcon } from '../../assets/paymentMethods/galicia_icon.svg';

const PaymentMethodSelector = ({ classes, paymentMethods, onChange, value }) => {
  const getPaymentMethodLabel = (method) => {
    switch (method) {
      case PAYMENT_METHODS.MERCADO_PAGO:
        return (
          <span className="methodLabel">
            <AccountBalanceWalletIcon fontSize="small" /> Mercado Pago
            <MercadoPagoIcon className="mercadoPagoIcon" />
          </span>
        );
      case PAYMENT_METHODS.MACRO:
        return (
          <span className="methodLabel">
            <CreditCardIcon fontSize="small" /> Pagar con
            <MacroClickIcon className="paymentMethodIcon" />
          </span>
        );
      case PAYMENT_METHODS.DLOCAL:
        return (
          <span className="methodLabel">
            <CreditCardIcon fontSize="small" /> Pagos en Uruguay 🇺🇾
          </span>
        );
      default:
        return (
          <span className="methodLabel">
            <CreditCardIcon fontSize="small" /> Pagar con
            <GaliciaIcon className="galiciaIcon" />
          </span>
        );
    }
  };

  const handlePaymentChange = ({ target }) => {
    onChange(target.value);
  };

  return (
    <Box className={classes.paymentMethodSelectorContainer}>
      <RadioGroup value={value} onChange={handlePaymentChange}>
        {paymentMethods.map((method) => (
          <FormControlLabel
            labelPlacement="start"
            key={method}
            value={method}
            control={<Radio />}
            label={getPaymentMethodLabel(method)}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};

export default withStyles(styles)(PaymentMethodSelector);
