import api from '../../../api/api';
import history from '../../../appHistory';
import { NOTIFICATION_VARIANT } from '../../../constants/types';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { handleRequestHelper } from '../../../utils/helpers';

export const useCreateProducerPage = () => {
  const { showNotification } = useNotifications();

  const handleSubmit = async (formState) => {
    const producerData = {
      name: formState.name.value,
      country: formState.country.value,
      city: formState.city.value,
      description: formState.description.value,
      theme: formState.theme.value,
      socials: {
        web: formState.web.value,
        instagram: formState.instagram.value,
        x: formState.x.value,
        facebook: formState.facebook.value,
        youtube: formState.youtube.value,
        tiktok: formState.tiktok.value,
      },
      logoImg: formState.logoImg.value,
      bannerImg: formState.bannerImg.value,
      pinnedEventId: formState.pinnedEventId.value || null,
    };

    await handleRequestHelper({
      endpoint: () => api.createProducer(producerData),
      showNotification,
      onSuccess: ({ producer }) => {
        showNotification('Productora creada correctamente!', NOTIFICATION_VARIANT.SUCCESS);
        history.push(`/backoffice/productoras/${producer.id}`);
      },
    });
  };

  return {
    handleSubmit,
  };
};
