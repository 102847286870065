import React from 'react';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import RenderInput from './RenderInput';
import { icons } from '../../Providers/Icons/IconsProvider';

const CustomTimePicker = ({
  minDate = new Date(),
  value = '',
  onChange,
  helperText,
  showErrors,
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <TimePicker
        label="Hora"
        name="time"
        ampm={false}
        value={value}
        minDate={minDate}
        onChange={onChange}
        icon={icons.time}
        TextFieldComponent={RenderInput}
        okLabel="ok"
        cancelLabel="Cancelar"
        helperText={helperText}
        showErrors={showErrors}
      />
    </MuiPickersUtilsProvider>
  );
};

export default CustomTimePicker;
