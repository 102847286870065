import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    cardContainer: {
      border: '0.5px solid #ccc',
      borderRadius: 8,
      padding: '10px 15px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      transition: '1s ease all',
      position: 'relative',
      [theme.breakpoints.down(400)]: {
        padding: '10px 6px 10px 10px',
      },
    },
    cardContent: {
      display: 'flex',
      padding: '0 20px',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
    },
    informationContainer: {
      flex: 2,
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '70%',
    },
    actionsContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'end',
    },
    actionsText: {
      color: theme.palette.text.primary,
    },
    errorMessage: {
      position: 'absolute',
      bottom: 0,
      right: 10,
      fontSize: 13,
      color: theme.palette.error.main,
      [theme.breakpoints.down(400)]: {
        bottom: -1,
        fontSize: 12,
      },
    },
    ticketNameLabel: {
      alignSelf: 'start',
      fontWeight: 'bold',
      textTransform: 'capitalize',
      fontSize: 17,
    },
    priceLabel: {
      flex: 1,
      display: 'flex',
    },
  });

export default styles;
