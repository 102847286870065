import React from 'react';
import { withStyles } from '@material-ui/core';
import { useIntercom } from 'react-use-intercom';

import styles from './HomeStyles';
import MoreEventsSection from './Components/MoreEventsSection/MoreEventsSection';
import NextEventsSection from './Components/NextEventsSection/NextEventsSection';
import ShadowButton from '../../../Components/ShadowButton/ShadowButton';
import FeaturedEventsSection from './Components/FeaturedEventsSection/FeaturedEventsSection';
import { useHome } from '../../../hooks/useHome';
import PageContainer from '../../../Components/PageContainer/PageContainer';

const NEXT_EVENTS_MAX = 6;

const VentiHome = ({ classes, urlParams }) => {
  const { featuredEvents, events, getHomeEvents, hasMoreEvents } = useHome(urlParams);
  const { show } = useIntercom();

  const searchParams = new URLSearchParams(location.search);
  const openIntercom = searchParams.get('openIntercom');

  if (openIntercom) {
    show();
  }

  return (
    <PageContainer showSearchButton withFooter withPaddingTop={false}>
      <div className={classes.pageContentContainer}>
        <FeaturedEventsSection events={featuredEvents} />
        <NextEventsSection events={events.slice(0, NEXT_EVENTS_MAX)} />
        <MoreEventsSection events={events.slice(NEXT_EVENTS_MAX)} />
        {hasMoreEvents() && <ShadowButton text="Cargar más" onClick={getHomeEvents} />}
      </div>
    </PageContainer>
  );
};

export default withStyles(styles)(VentiHome);
