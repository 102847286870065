import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      marginBottom: 5,
      maxWidth: '48%',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
      },
    },
    fullWidth: {
      maxWidth: '100%',
    },
    inputHeaderContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    titleAndIconContainer: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 10,
    },
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 20,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        rowGap: 10,
      },
    },
    textFieldRoot: {
      width: '100%',
      '& .MuiOutlinedInput-input': {
        color: theme.palette.text.secondary,
        paddingLeft: 10,
      },
      '& .MuiOutlinedInput-root': {
        background: theme.palette.grey[50],
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 10,
        color: theme.palette.text.primary,
        '& fieldset': {
          border: '0px',
        },
        '&:hover fieldset': {
          borderWidth: 0,
        },
        '&.Mui-focused fieldset': {
          border: `2px solid ${theme.palette.primary.main}`,
        },
        '&.Mui-focused': {
          backgroundColor: theme.palette.grey[50],
        },
        '&-adornedStart': {
          padding: 0,
        },
        '&.Mui-disabled fieldset': {
          borderColor: 'transparent',
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.grey[400],
        },
      },
    },
    textFieldRootError: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: `2px solid ${theme.palette.error.main}`,
        },
        '&:hover fieldset': {
          border: `2px solid ${theme.palette.error.main}`,
        },
        '&.Mui-focused fieldset': {
          border: `2px solid ${theme.palette.error.main}`,
        },
      },
    },
    inputRoot: {
      borderRadius: 10,
      color: theme.palette.text.primary,
      '& .MuiSelect-select': {
        borderRadius: 10,
      },
      '&.Mui-focused input': {
        backgroundColor: theme.palette.grey[50],
      },
      '&::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
      },
    },
    errorAndHintContainer: {
      display: 'flex',
      paddingTop: 4,
    },
    menuItem: {
      whiteSpace: 'normal',
    },
  });

export default styles;
