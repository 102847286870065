import React from 'react';
import Button from '../../Components/Buttons/Button/Button';
import VModal from '../VModal/VModal';

const InformationModal = ({
  open,
  okLabel = 'Ok',
  title,
  subtitle,
  handleClose,
  handleAccept,
  disabled,
}) => {
  return (
    <VModal open={open} onClose={handleClose} title={title} subtitle={subtitle}>
      <Button
        fullWidth
        title={okLabel}
        onClick={handleAccept}
        disabled={disabled}
        variant="contained"
      />
    </VModal>
  );
};

export default InformationModal;
