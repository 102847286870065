import React from 'react';
import { useTheme, withStyles } from '@material-ui/core';
import { Button, Stack } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import VTypography from '../VTypography/VTypography';
import styles from './SliderButtons.styles';

const SliderButtons = ({ classes, currentTicketIndex, setCurrentTicketIndex, length, margin }) => {
  const theme = useTheme();

  const getPreviousTicket = () => {
    setCurrentTicketIndex((prevTicketIndex) => (prevTicketIndex > 0 ? prevTicketIndex - 1 : 0));
  };

  const getNextTicket = () => {
    setCurrentTicketIndex((prevTicketIndex) =>
      prevTicketIndex < length - 1 ? prevTicketIndex + 1 : prevTicketIndex
    );
  };

  const canGetPreviousTicket = currentTicketIndex > 0;
  const canGetNextTicket = currentTicketIndex < length - 1;

  return (
    <Stack direction="row" margin={margin} className={classes.ticketsArrowsContainer}>
      <Button
        className={classes.ticketsArrowButton}
        onClick={getPreviousTicket}
        disabled={!canGetPreviousTicket}
      >
        <ArrowBackIosNewIcon fontSize="small" htmlColor={theme.palette.text.primary} />
      </Button>
      <VTypography className={classes.amountOfTickets} align="center" variant="button2">
        Tickets {currentTicketIndex + 1} de {length}
      </VTypography>
      <Button
        className={classes.ticketsArrowButton}
        onClick={getNextTicket}
        disabled={!canGetNextTicket}
      >
        <ArrowForwardIosIcon fontSize="small" htmlColor={theme.palette.text.primary} />
      </Button>
    </Stack>
  );
};

export default withStyles(styles)(SliderButtons);
