import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    pageContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    tableContainer: {
      width: '100%',
      maxWidth: 900,
      alignSelf: 'center',
      backgroundColor: theme.palette.grey[50],
      padding: 16,
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    tableHeaderContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: 16,
      },
    },
    invitationSelectorContainer: {
      display: 'flex',
      gap: 8,
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: 4,
      },
    },
    sendButton: {
      alignSelf: 'center',
    },
  });

export default styles;
