import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    inputContainer: {
      maxWidth: '100%',
      overflow: 'hidden',

      '& .MuiOutlinedInput-root': {
        borderRadius: 8,
        backgroundColor: theme.palette.grey[50],
        WebkitTextFillColor: theme.palette.grey[800],

        '& .MuiSelect-icon': {
          color: theme.palette.grey[800],
        },

        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.grey[300],
        },

        '& .error': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.error.main,
          },
        },

        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
          },
        },

        '&:hover': {
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.grey[300],
            },
          },

          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
          },
        },

        '&.Mui-disabled': {
          backgroundColor: theme.palette.grey[200],

          '& .MuiSelect-icon': {
            color: theme.palette.grey[300],
          },

          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[300],
          },
        },

        '&.error': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.error.main,
          },
        },

        '&.success': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.success.main,
          },
        },
      },

      '& .MuiOutlinedInput-input': {
        padding: 10,
        '&.Mui-disabled': {
          WebkitTextFillColor: theme.palette.grey[600],
        },
        whiteSpace: 'pre-wrap !important',
      },
    },

    bottomInputBox: {
      marginTop: 4,
      minHeight: 16,
    },

    menu: {
      maxHeight: 400,
    },
  });

export default styles;
