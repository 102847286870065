import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    '@global': {
      '& .Mui-expanded': {
        margin: 0,
      },
    },
    formItemContainer: {
      padding: 16,
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      columnGap: 10,
      [theme.breakpoints.up('lg')]: {
        maxWidth: '33.3%',
      },
      '&.MuiAccordion-root': {
        boxShadow: 'none',
        backgroundColor: theme.palette.grey[50],
        '&::before': {
          display: 'none',
        },
      },
      '& .MuiAccordion-region': {
        backgroundColor: theme.palette.grey[50],
        [theme.breakpoints.up('lg')]: {
          maxHeight: 245,
          border: `1px solid ${theme.palette.grey[400]}`,
          overflowY: 'auto',
          borderRadius: '0 0 8px 8px',
          width: '100%',
          position: 'absolute',
          zIndex: 10,
        },
      },
      '&-MuiStack-root': {
        display: 'none',
      },
      '&.MuiPaper-root': {
        padding: 0,
        [theme.breakpoints.up('lg')]: {
          borderRadius: '10px !important',
        },
        '&.Mui-expanded': {
          margin: 0,
          minHeight: 'unset',
          justifyContent: 'center',
        },
      },
      '&.MuiFormControlLabel-root': {
        margin: 0,
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
      },
      '&[aria-controls="accordion"]': {
        flexDirection: 'column',
      },
      '& .MuiAccordionSummary': {
        '&-root': {
          padding: 16,
          minHeight: 'unset',
          backgroundColor: theme.palette.grey[50],
          color: theme.palette.text.primary,
          borderTop: `1px solid ${theme.palette.grey[400]}`,
          [theme.breakpoints.up('lg')]: {
            padding: '14px 16px',
            borderTop: 'none',
            minHeight: 'unset !important',
            borderRadius: 10,
          },
          '& .MuiSvgIcon-root': {
            fill: theme.palette.text.primary,
          },
        },
        '&-content': {
          margin: 0,
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    checkboxContainer: {
      maxWidth: 'unset',
    },
    checkbox: {
      '&.MuiCheckbox-root': {
        padding: 0,
        '&.Mui-checked': {
          color: theme.palette.primary.main,
        },
      },
    },
  });

export default styles;
