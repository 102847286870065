import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import styles from './ClaimedNFTList.styles';

const ClaimedNFTList = ({ classes, data }) => {
  return (
    <div className={classes.container}>
      {data?.length ? (
        data.map(({ qrId, lemontag, orderCountNumber, ticketTypeName }) => (
          <div key={qrId} className={classes.claimedContainer}>
            <Typography className={classes.claimedText}>
              {orderCountNumber} - {ticketTypeName}
            </Typography>
            <Typography className={classes.lemontag}>Lemontag: ${lemontag}</Typography>
          </div>
        ))
      ) : (
        <Typography className={classes.noClaimedText}>
          ¡No has reclamado ningún NFT todavía!
        </Typography>
      )}
    </div>
  );
};

export default withStyles(styles)(ClaimedNFTList);
