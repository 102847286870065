import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import ProducerUserCard from '../../../../Components/Cards/ProducerUserCard/ProducerUserCard';
import { NOTIFICATION_VARIANT } from '../../../../constants/types';
import styles from './UserPanelStyles';
import Searcher from '../../../../Components/Backoffice/Searcher/Searcher';
import LoadingListContent from '../../../../Components/LoadingListContent/LoadingListContent';
import PageContainer from '../../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import { handleRequestHelper } from '../../../../utils/helpers';
import { useNotifications } from '../../../../Providers/NotificationsProvider/NotificationsProvider';
import api from '../../../../api/api';

const UserPanel = ({ classes, urlParams }) => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const { showNotification } = useNotifications();

  const { producerId } = urlParams;

  const getUsers = async (searchQuery) => {
    setLoading(true);

    await handleRequestHelper({
      endpoint: () => api.getAllProducerUsers(producerId, { page: currentPage, searchQuery }),
      showNotification,
      onSuccess: ({ users, totalPages }) => {
        setLoading(false);
        setUsers(users);
        setTotalPages(totalPages);
      },
      onFailure: () => setLoading(false),
    });
  };

  useEffect(() => {
    getUsers();
  }, [currentPage]);

  const handleNewPage = async (_, newPageNumber) => {
    setCurrentPage(newPageNumber);
  };

  const deleteUser = async (userToDelete) => {
    const { id, alias } = userToDelete;
    await handleRequestHelper({
      endpoint: () => api.deleteProducerUser(producerId, id),
      showNotification,
      onSuccess: () => {
        showNotification(
          `El usuario ${alias} se eliminó correctamente`,
          NOTIFICATION_VARIANT.SUCCESS
        );
        getUsers();
      },
      onFailure: ({ errorMessage }) =>
        showNotification(
          `El usuario ${alias} no se pudo eliminar: ${errorMessage}`,
          NOTIFICATION_VARIANT.ERROR
        ),
    });
  };

  return (
    <PageContainer title="Backoffice - Usuarios">
      <VBreadcrumbs />
      <div className={classes.pageContainer}>
        <div className={classes.usersContainer}>
          <Typography variant="h3">Usuarios</Typography>
          <Searcher onSubmit={getUsers} placeholder="Buscar usuario" />
          <LoadingListContent
            list={users}
            loading={loading}
            notFoundMessage="No se encontraron usuarios"
          >
            {users.map((producerUser) => (
              <ProducerUserCard
                producerId={producerId}
                producerUser={producerUser}
                key={producerUser.id}
                onConfirm={deleteUser}
              />
            ))}
            <Pagination count={totalPages} page={currentPage} onChange={handleNewPage} />
          </LoadingListContent>
        </div>
      </div>
    </PageContainer>
  );
};

export default withStyles(styles)(UserPanel);
