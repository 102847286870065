import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import { Router } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import IntercomButton from './Components/IntercomButton/IntercomButton';
import Routes from './Providers/Routes/Routes';
import { GoogleReCaptchaProvider } from './Providers/GoogleReCaptchaProvider/GoogleReCaptchaProvider';
import { getThemeByBaseUrl } from './utils/utils';
import { icons, iconsProvider } from './Providers/Icons/IconsProvider';
import { THEME_BY_WHITE_LABEL } from './constants/whiteLabels';

import config from './config/config';
import './App.css';
import AuthProvider from './Providers/AuthProvider/AuthProvider';
import history from './appHistory';
import NotificationsProvider from './Providers/NotificationsProvider/NotificationsProvider';
import { ThemeContext } from './Providers/Theme/Theme';

export const App = () => {
  const initialTheme = getThemeByBaseUrl();
  const [theme, setTheme] = useState(THEME_BY_WHITE_LABEL[initialTheme]);

  const [appState, setAppState] = useState({
    theme: initialTheme,
    icons,
  });

  const checkThemeLabel = (theme) => {
    if (theme !== appState.theme) {
      setAppState({
        theme,
        icons: iconsProvider.getIcons(theme),
      });
    }
  };

  useEffect(() => {
    setTheme(THEME_BY_WHITE_LABEL[appState.theme]);
  }, [appState.theme]);

  useEffect(() => {
    ReactGA.initialize(config.googleAnalyticsId);

    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: window.location.pathname,
    });
  }, []);

  return (
    <AuthProvider>
      <GoogleReCaptchaProvider>
        <ThemeContext.Provider value={appState}>
          <ThemeProvider theme={theme}>
            <IntercomProvider appId={config.intercomId}>
              <NotificationsProvider>
                <IntercomButton />
                <CssBaseline />
                <Router history={history}>
                  <Routes setTheme={checkThemeLabel} />
                </Router>
              </NotificationsProvider>
            </IntercomProvider>
          </ThemeProvider>
        </ThemeContext.Provider>
      </GoogleReCaptchaProvider>
    </AuthProvider>
  );
};
