import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './SectionButtonStyles';
import Marquee from '../../../../../Components/Animations/Marquee/Marquee';
import VentiTexture from '../../../../../Components/Assets/VentiTexture/VentiTexture';
import { HEADER_HEIGHT } from '../../../../../Components/Header/Header';

const SECTION_BUTTON_HEIGHT = 46;

const SectionButton = ({ classes, children, position = 0, sectionRef, animationTime }) => {
  const calculatedPosition = HEADER_HEIGHT + SECTION_BUTTON_HEIGHT * position;

  const scrollToSection = () => {
    sectionRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  return (
    <>
      <div ref={sectionRef} className={classes.refDiv} />
      <button
        onClick={scrollToSection}
        className={classes.section}
        style={{ top: calculatedPosition }}
        type="button"
      >
        <div className={classes.marquee}>
          <Marquee animationTime={animationTime}>{children}</Marquee>
        </div>
        <VentiTexture />
      </button>
    </>
  );
};

export default withStyles(styles)(SectionButton);
