import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    backgroundImg: {
      filter: 'blur(15px)',
      opacity: 0.5,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    backgroundContainer: {
      maxHeight: 600,
      minHeight: 300,
      height: '50vw',
      position: 'absolute',
      width: '100%',
      left: 0,
      top: 0,
      zIndex: 0,
    },
    gradient: {
      maxHeight: 680,
      minHeight: 380,
      width: '100%',
      height: '80vw',
      position: 'absolute',
      left: 0,
      top: 0,
      backgroundImage: `linear-gradient(to top, ${theme.palette.background.default} 20%, transparent 100%)`,
      filter: 'blur(15px)',
      opacity: 1,
      zIndex: 0,
    },
  });

export default styles;
