import React from 'react';
import { withStyles } from '@material-ui/core';
import { infoCards } from '../../../Pages/Backoffice/ProducerPage/ProducerPage.cards';
import InfoCard from '../../Cards/InfoCard/InfoCard';
import styles from './CardsList.styles';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';

const CardsList = ({ classes, category, generatesSellerReports, producerId }) => {
  const { user } = useAuth();

  const cards = infoCards({
    category,
    generatesSellerReports,
    userRole: user.role,
    producerId,
  }).filter((card) => card.permissions);

  return (
    <div className={classes.container}>
      {cards.map(({ link, icon, title, newTab, text }, index) => (
        <InfoCard key={index} href={link} icon={icon} title={title} newTab={newTab} text={text} />
      ))}
    </div>
  );
};

export default withStyles(styles)(CardsList);
