import { ORDER_STATUS, ORDER_TYPE } from '../../../constants/types';
import { useInfinitScroll } from '../../../hooks/useInfinitScroll';
import api from '../../../api/api';
import { useSelectedOptions } from '../../../hooks/useSelectedOptions';
import { useQueryParam } from '../../../hooks/useQueryParam';

export const useEventOrdersPage = (eventId) => {
  const { paramValue: searchQuery, onChange: setSearchQuery } = useQueryParam('searchQuery');

  const defaultStatusOptions = Object.values(ORDER_STATUS).filter(
    (status) => status !== ORDER_STATUS.NOT_PAID
  );

  const {
    selectedOptions: statusSelectedOptions,
    allOptions: allStatusOptions,
    onChange: onChangeStatusOptions,
  } = useSelectedOptions('statuses', ORDER_STATUS, defaultStatusOptions);

  const {
    selectedOptions: typeSelectedOptions,
    allOptions: allTypeOptions,
    onChange: onChangeTypeOptions,
  } = useSelectedOptions('types', ORDER_TYPE, [ORDER_TYPE.ONLINE]);

  const { items: orders, isGettingMoreItems } = useInfinitScroll({
    endpoint: api.getEventOrders,
    params: [eventId],
    options: { statuses: statusSelectedOptions, types: typeSelectedOptions, searchQuery },
    itemsLimit: 10,
  });

  return {
    statusSelectedOptions,
    allStatusOptions,
    onChangeStatusOptions,
    typeSelectedOptions,
    allTypeOptions,
    onChangeTypeOptions,
    orders,
    isGettingMoreItems,
    searchQuery,
    setSearchQuery,
  };
};
