import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import styles from './TicketTypesBarChartStyles';
import { sortObjectArray } from '../../../utils/utils';
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import { SORT_DIRECTION } from '../../../constants/types';

const MAX_AMOUNT_TICKETS_TO_SHOW = 15;
const MAX_TICKET_TITLE_LENGHT = 14;

const TicketTypeBarChart = ({ classes, ticketTypeList, size = 500 }) => {
  let dataToShow = ticketTypeList.map(({ name, totalSold }) => ({
    label: `${name.substring(0, MAX_TICKET_TITLE_LENGHT)}`,
    fullLabel: name,
    value: totalSold,
  }));

  dataToShow = sortObjectArray(dataToShow, 'value', SORT_DIRECTION.DESC).slice(
    0,
    MAX_AMOUNT_TICKETS_TO_SHOW
  );

  return (
    <div>
      {!dataToShow.length ? (
        <Typography className={classes.noInformationText}>No hay información.</Typography>
      ) : (
        <ResponsiveContainer width="100%" height={size}>
          <BarChart layout="vertical" data={dataToShow} margin={{ right: 70 }}>
            <CartesianGrid opacity={0.2} />
            <XAxis type="number" />
            <Tooltip
              wrapperStyle={{ outline: 'none' }}
              cursor={false}
              offset={5}
              content={<CustomTooltip text="Cantidad" label={dataToShow.fullLabel} />}
            />
            <YAxis
              tick={({ y, payload }) => (
                <g transform={`translate(${0},${y})`}>
                  <text x={0} y={0} textAnchor="start" className={classes.tick}>
                    {payload.value}
                  </text>
                </g>
              )}
              width={120}
              dataKey="label"
              type="category"
            />
            <Bar dataKey="value" barSize={25} className={classes.bar}>
              <LabelList dataKey="value" offset={5} position="right" className={classes.label} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default withStyles(styles)(TicketTypeBarChart);
