import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    bar: {
      fill: theme.palette.charts.primary,
    },
    tick: {
      fill: theme.palette.charts.text,
      fontWeight: 600,
      fontSize: 12,
      transform: 'translate(0, 5px)',
    },
    label: {
      fill: theme.palette.charts.text,
      fontWeight: 600,
    },
  });

export default styles;
