import React from 'react';
import { Stack } from '@mui/material';
import VTypography from '../../../../../Venti-UI-Kit/VTypography/VTypography';
import CustomDatePicker from '../../../../../Components/DatePickerComponents/CustomDatePicker';
import { formatDatetime } from '../../../../../utils/utils';

const PaymentDateCell = ({ rowIndex, value, edit, handlePaymentDateChange }) => {
  const paymentDate = value ? formatDatetime(value).date : null;

  return (
    <Stack alignItems="center" width={180}>
      {edit ? (
        <CustomDatePicker
          value={value || null}
          onChange={(value) => handlePaymentDateChange(value, rowIndex)}
          minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
          format="dd/MM/yyyy"
        />
      ) : (
        <VTypography>{paymentDate}</VTypography>
      )}
    </Stack>
  );
};

export default PaymentDateCell;
