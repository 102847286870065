import { translate } from '../utils/translate';
import { GENDER, USER_ROLES } from './types';

export const AVAILABLE_ROLES_TO_CREATE_OPTIONS = [
  {
    value: USER_ROLES.ADMIN,
    label: translate(USER_ROLES.ADMIN),
  },
  {
    value: USER_ROLES.SELLER,
    label: translate(USER_ROLES.SELLER),
  },
  {
    value: USER_ROLES.SELLER_WITH_CASH,
    label: translate(USER_ROLES.SELLER_WITH_CASH),
  },
  {
    value: USER_ROLES.READ_ONLY_ADMIN,
    label: translate(USER_ROLES.READ_ONLY_ADMIN),
  },
  {
    value: USER_ROLES.VALIDATOR,
    label: translate(USER_ROLES.VALIDATOR),
  },
];

export const GENDERS = [
  { label: translate(GENDER.MALE), value: GENDER.MALE },
  { label: translate(GENDER.FEMALE), value: GENDER.FEMALE },
  { label: translate(GENDER.OTHER), value: GENDER.OTHER },
];
