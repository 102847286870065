import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../AuthProvider/AuthProvider';
import { validateUserRole } from '../../utils/utils';
import { USER_ROLES } from '../../constants/types';
import { useNotifications } from '../NotificationsProvider/NotificationsProvider';
import LoadingContainer from '../../Components/LoadingContainer/LoadingContainer';

const CustomComponent = ({ urlParams, rolePermission, Component, ...props }) => {
  const { user, checkJWTToken } = useAuth();
  const [validatingUser, setValidatingUser] = useState(true);

  const { producerId } = urlParams;
  const { showNotification } = useNotifications();

  const userBelongsToProducer = () => {
    if (user.role !== USER_ROLES.SUPER_ADMIN && producerId)
      return user.producers?.some((producer) => Number(producer.id) === Number(producerId));
    return true;
  };

  const showComponent =
    !rolePermission ||
    (user && userBelongsToProducer() && validateUserRole(user.role, rolePermission));

  const validateUser = async () => {
    setValidatingUser(true);
    await checkJWTToken();
    setValidatingUser(false);
  };

  useEffect(() => validateUser(), []);

  return (
    <LoadingContainer isLoading={validatingUser}>
      {showComponent ? (
        <Component
          urlParams={urlParams}
          showNotification={showNotification}
          user={user}
          {...props}
        />
      ) : (
        <Redirect key="redirectionDefault" to={!user ? '/login' : '/eventos'} />
      )}
    </LoadingContainer>
  );
};

export default CustomComponent;
