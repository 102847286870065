import React from 'react';
import { ListItemButton, ListItemIcon } from '@mui/material';
import { withStyles } from '@material-ui/core';
import VIcon from '../../../Venti-UI-Kit/VIcon/VIcon';
import styles from './SidebarItemButton.styles';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';

const SidebarItemButton = ({ classes, active, open, title, icon, onClick }) => {
  return (
    <ListItemButton
      onClick={onClick}
      className={`${active && classes.buttonActive} ${classes.buttonContainer}`}
      sx={{
        whiteSpace: 'nowrap',
        justifyContent: open ? 'initial' : 'center',
        minHeight: 48,
        px: 2.5,
      }}
    >
      <ListItemIcon sx={{ minWidth: 0, justifyContent: 'center', mr: open ? 3 : 'auto' }}>
        <VIcon name={icon} />
      </ListItemIcon>
      <VTypography style={{ opacity: open ? 1 : 0 }}>{title}</VTypography>
    </ListItemButton>
  );
};

export default withStyles(styles)(SidebarItemButton);
