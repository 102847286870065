import React from 'react';
import { withStyles } from '@material-ui/core';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import AddLocationOutlinedIcon from '@mui/icons-material/AddLocationOutlined';
import { Stack, Typography } from '@mui/material';
import styles from './StadiumSectionInfoStyles';
import InputTitle from '../../InputTitle/InputTitle';
import { translate } from '../../../utils/translate';
import priceProvider from '../../../Providers/Price/Price';

const StadiumSectionInfo = ({ section, classes }) => {
  return (
    <Stack direction={{ xs: 'column', md: 'row' }} gap={7}>
      <Stack alignItems="center">
        <InputTitle title="Mapa" materialIcon={<MapOutlinedIcon />} />
        <img alt="" src={section.image} className={classes.sectionImage} />
      </Stack>
      <Stack width="100%">
        <InputTitle title="Capacidad del sector" materialIcon={<AddLocationOutlinedIcon />} />
        <Stack gap={3}>
          <Stack direction="row">
            <Stack gap={2} flex={1}>
              <Typography className={classes.sectionTitleText}>Capacidad total</Typography>
              <Typography className={classes.sectionText}>{`${section.capacity} loc.`}</Typography>
            </Stack>
            <Stack gap={2} flex={1}>
              <Typography className={classes.sectionTitleText}>Stock disponible</Typography>
              <Typography
                className={classes.sectionText}
              >{`${section.stockAvailable} loc.`}</Typography>
            </Stack>
          </Stack>
          <Stack gap={2}>
            <Typography className={classes.sectionTitleText}>Tickets precargados</Typography>
            {!section.ticketTypes?.length ? (
              <Typography className={classes.sectionText}>-</Typography>
            ) : (
              <Stack maxHeight={75} overflow="auto" gap={2}>
                {section.ticketTypes.map(
                  ({ id, name, type, priceInCents, maxQuantity, currency }) => (
                    <Typography key={id} className={classes.sectionText}>{`${name} - 
                        ${translate(type)} - $${priceProvider.getPrice(priceInCents, currency)} 
                        - ${maxQuantity} loc.`}</Typography>
                  )
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default withStyles(styles)(StadiumSectionInfo);
