import { useState } from 'react';
import { translate } from '../utils/translate';

export const useSelectedOptions = (optionName, options, defaultSelectedOptions) => {
  const updateStatusesParam = (statuses) => {
    const params = new URLSearchParams(window.location.search);
    params.delete(optionName);

    if (statuses.length > 0) {
      params.append(optionName, JSON.stringify(statuses));
    }

    window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
  };

  const getStatusesParam = () => {
    const params = new URLSearchParams(window.location.search);
    const statusesParams = params.get(optionName);
    return statusesParams ? JSON.parse(statusesParams) : defaultSelectedOptions;
  };

  const [selectedOptions, setSelectedOptions] = useState(getStatusesParam());

  const allOptions = Object.values(options).map((status) => ({
    label: translate(status),
    value: status,
  }));

  const onChange = ({ target: { value } }) => {
    setSelectedOptions(value);
    updateStatusesParam(value);
  };

  return {
    selectedOptions,
    allOptions,
    onChange,
  };
};
