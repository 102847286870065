import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 40px',
      width: 600,
      margin: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      rowGap: 24,
      [theme.breakpoints.down('xs')]: {
        padding: 0,
        marginTop: 40,
        width: '80%',
      },
    },
    requirementsContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    title: {
      fontSize: 32,
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        fontSize: 28,
      },
    },
    subtitle: {
      marginTop: 10,
      fontSize: 17,
      lineHeight: 1,
      [theme.breakpoints.down('xs')]: {
        textAlign: 'left',
      },
    },
    requirementsList: {
      backgroundColor: '#FFE08D',
      padding: '10px 30px',
      borderRadius: 10,
      alignItems: 'start',
      width: 'fit-content',
      alignSelf: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    requirement: {
      fontSize: 15,
      textAlign: 'left',
    },
    button: {
      margin: 'auto',
    },
  });

export default styles;
