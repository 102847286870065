import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    success: {
      color: theme.palette.success.main,
    },
    error: {
      color: theme.palette.error.main,
    },
    warning: {
      color: theme.palette.warning.main,
    },
    info: {
      color: theme.palette.info.main,
    },
    base: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
    },
    ...theme.typography,
  });

export default styles;
