import React, { useEffect, useState } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import styles from './EditTeamLeader.styles';
import CustomInput from '../../../../Components/CustomInput/CustomInput';
import { useFormDeprecated } from '../../../../hooks/useFormDeprecated';
import Button from '../../../../Venti-UI-Kit/Buttons/Button';
import { NOTIFICATION_VARIANT } from '../../../../constants/types';
import PageContainer from '../../../../Components/PageContainer/PageContainer';
import history from '../../../../appHistory';
import { handleRequestHelper } from '../../../../utils/helpers';
import { useNotifications } from '../../../../Providers/NotificationsProvider/NotificationsProvider';
import api from '../../../../api/api';

const EditTeamLeader = ({ classes, urlParams }) => {
  const { showNotification } = useNotifications();
  const [teamLeader, setTeamLeader] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const { teamLeaderId } = urlParams;
  const { formState, handleInputChange, validateText, resetForm } = useFormDeprecated({
    newTeamLeaderCode: '',
  });

  const getTeamLeader = async () => {
    await handleRequestHelper({
      endpoint: () => api.getTeamLeaderById(teamLeaderId),
      onSuccess: ({ teamLeader }) => setTeamLeader(teamLeader),
      onFailure: () =>
        showNotification('Ocurrio un error al obtener el team leader', NOTIFICATION_VARIANT.ERROR),
    });
  };

  useEffect(() => {
    getTeamLeader();
  }, []);

  const validateForm = () => {
    const errors = {
      newTeamLeaderCode: validateText(formState.newTeamLeaderCode),
    };
    setFormErrors((prevState) => ({ ...prevState, ...errors }));
    const validationsError = Object.values(errors).reduce(
      (prevErrors, currentError) => prevErrors + currentError,
      ''
    );
    return validationsError === '';
  };

  const onSubmit = async () => {
    if (!validateForm()) {
      showNotification('Revisa los campos con errores', NOTIFICATION_VARIANT.ERROR);
      return;
    }

    await handleRequestHelper({
      endpoint: () =>
        api.updateTeamLeader(teamLeader.id, { ...teamLeader, code: formState.newTeamLeaderCode }),
      onFailure: () =>
        showNotification(
          `Ocurrio un error al crear al team leader: ${teamLeader.code}`,
          NOTIFICATION_VARIANT.ERROR
        ),
      onSuccess: () => {
        showNotification(
          `Team leader actualizado con éxito! Redirgiendo...`,
          NOTIFICATION_VARIANT.SUCCESS
        );
        getTeamLeader();
        resetForm();
        setTimeout(() => {
          history.goBack(1);
        }, 1000);
      },
    });
  };

  return (
    <PageContainer title="Backoffice - Editar Team Leader">
      <div className={classes.pageContent}>
        <Typography className={classes.title} variant="h5">
          Editar Team Leader: "{teamLeader.code}"
        </Typography>
        <CustomInput
          placeholder="Nuevo código"
          error={formErrors.newTeamLeaderCode}
          title="Código del Team Leader"
          titleIcon={<Person />}
          onChange={handleInputChange}
          name="newTeamLeaderCode"
          value={formState.newTeamLeaderCode}
        />
        <Button onClick={onSubmit}>Guardar</Button>
      </div>
    </PageContainer>
  );
};

export default withStyles(styles)(EditTeamLeader);
