import { Icon } from '@mui/material';
import React from 'react';
import { Facebook, Instagram, X, YouTube } from '@mui/icons-material';
import { MATERIAL_UI_ICONS } from '../../constants/types';

const VIcon = ({ name, color, size, baseClassName = MATERIAL_UI_ICONS.OUTLINED, ...props }) => {
  const styles = { color, fontSize: size };

  const getIconComponent = () => {
    switch (name) {
      case 'instagram':
        return Instagram;
      case 'facebook':
        return Facebook;
      case 'x':
      case 'twitter':
        return X;
      case 'youtube':
        return YouTube;
      default:
        return Icon;
    }
  };
  const Component = getIconComponent();
  const propsForComponent =
    Component === Icon ? { baseClassName, sx: styles, ...props } : { sx: styles, ...props };

  return <Component {...propsForComponent}>{name}</Component>;
};

export default VIcon;
