import { createTheme } from '@material-ui/core/styles';
import lightTheme from '../lightTheme';
import { changeThemeFontFamily } from '../themes.utils';

const bnpTheme = createTheme(lightTheme, {
  typography: changeThemeFontFamily("'Archivo', 'Roboto'", lightTheme.typography),
  palette: {
    background: {
      default: '#FFFFFF',
    },
    primary: {
      main: '#FF8103',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FFB162',
      contrastText: '#FFFFFF',
    },
    footer: {
      text: '#FFFFFF',
      background: '#222222',
    },
    header: {
      background: '#FFFFFF',
      buttonText: '#FFFFFF',
      buttonBackground: '#FF8103',
      subHeaderBackground: '#FFFFFF',
      text: '#121212',
    },
    buttons: {
      disabled: '#CBCBCB',
      disabledText: '#999999',
      main: {
        background: '#FF8103',
        text: '#FFFFFF',
        hover: '#FFB162',
      },
      alternative: {
        background: '#222222',
        text: '#FFFFFF',
        hover: '#454545',
      },
    },
    charts: {
      primary: '#FF8103',
      secondary: '#FFB162',
      text: '#4E4E4E',
    },
  },
});

export default bnpTheme;
