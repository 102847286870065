import React from 'react';
import { Button, useTheme, withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import styles from './VenueCard.styles';
import VTypography from '../../Venti-UI-Kit/VTypography/VTypography';
import VIcon from '../../Venti-UI-Kit/VIcon/VIcon';
import { translate } from '../../utils/translate';

const VenueCard = ({ classes, venue, onClose }) => {
  const { placeName, readableAddress, mapsUrl, city } = venue;
  const theme = useTheme();

  const isVenue = placeName && mapsUrl && city && readableAddress;

  return (
    <Stack className={classes.cardContainer}>
      <Button className={classes.closeButton} onClick={onClose}>
        <VIcon name="close" size={24} />
      </Button>
      {isVenue ? (
        <>
          <VTypography variant="h6" linesNumber={2}>
            {placeName}
          </VTypography>
          <VTypography variant="body1" linesNumber={2}>
            {readableAddress}
          </VTypography>
          <VTypography variant="body1" linesNumber={2}>
            {city.name}, {translate(city.country)}
          </VTypography>
          <Stack className={classes.mapContainer} component="a" href={mapsUrl} target="_blank">
            <VIcon name="map" color={theme.palette.primary.main} />
            <VTypography variant="body1" style={{ textDecoration: 'underline' }}>
              Maps
            </VTypography>
          </Stack>
        </>
      ) : (
        <VTypography variant="body1" linesNumber={2}>
          {readableAddress}
        </VTypography>
      )}
    </Stack>
  );
};

export default withStyles(styles)(VenueCard);
