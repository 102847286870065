import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    breadcrumb: {
      padding: '0 16px 16px',

      '& ol li:last-child': {
        pointerEvents: 'none',
        cursor: 'default',
        textDecoration: 'none',

        '& p': {
          fontWeight: 700,
          color: theme.palette.grey[800],
        },
      },
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.grey[700],

      '&:hover': {
        textDecoration: 'underline',
      },
    },
  });

export default styles;
