const styles = (theme) => ({
  zoomContainer: {
    '& .kvfysmfp': {
      maxWidth: 400,
      borderRadius: 8,
      aspectRatio: 1,
    },

    [theme.breakpoints.down('sm')]: {
      maxWidth: 260,
    },
  },
  stadiumImage: {
    width: '100%',
    maxWidth: 400,
    aspectRatio: 1,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 260,
    },
  },
});

export default styles;
