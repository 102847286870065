import { FormHelperText } from '@material-ui/core';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import AccessInput from '../Inputs/AccessInput';
import styles from './RenderInput.styles';

const RenderInput = ({
  onClick,
  id,
  value,
  disabled,
  icon,
  inputProps,
  showErrors,
  helperText,
  classes,
}) => (
  <>
    <AccessInput
      className={`${showErrors && helperText && classes.errorRoot}`}
      type="text"
      onClick={onClick}
      id={id}
      value={value}
      disabled={disabled}
      disableUnderline
      endAdornment={<img alt="icon" src={icon} style={{ width: '1.3em', height: '1.3em' }} />}
      {...inputProps}
    />
    {helperText && showErrors && (
      <FormHelperText className={classes.helper}>{helperText}</FormHelperText>
    )}
  </>
);

export default withStyles(styles)(RenderInput);
