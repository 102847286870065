import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    paper: {
      padding: 16,
      display: 'flex',
      rowGap: 20,
      textAlign: 'left',
      justifyContent: 'space-between',
      alignItems: 'center',
      columnGap: 20,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    submitButton: {
      width: 300,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  });

export default styles;
