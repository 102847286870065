import React from 'react';
import { List, ListItem, Stack } from '@mui/material';
import BottomSheet from '../../../Venti-UI-Kit/BottomSheet/BottomSheet';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import Button from '../../Buttons/Button/Button';
import { handleRequestHelper } from '../../../utils/helpers';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { NOTIFICATION_VARIANT } from '../../../constants/types';
import api from '../../../api/api';

const ResendOrderBottomSheet = ({ onClose, open, orderId, qrMails }) => {
  const { showNotification } = useNotifications();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleResend = async () => {
    setIsLoading(true);
    await handleRequestHelper({
      endpoint: () => api.resendOrderMails(orderId),
      onSuccess: () => {
        onClose();
        showNotification('Los tickets se enviaron con éxito.', NOTIFICATION_VARIANT.SUCCESS);
      },
      onFailure: () => onClose(),
      showNotification,
    });
    setIsLoading(false);
  };

  return (
    <BottomSheet open={open} onClose={onClose}>
      <Stack padding="16px" gap={2.5}>
        <Stack gap={1} paddingBottom={2}>
          <VTypography variant="h4">Estás por reenviar todos los tickets de la orden</VTypography>
          <VTypography variant="body2">
            Al continuar, le enviarás a cada uno de estos titulares su ticket correspondiente:{' '}
            <br />
          </VTypography>
          <List disablePadding>
            {qrMails.map((mail) => (
              <ListItem key={mail} disablePadding>
                <VTypography variant="button2">• {mail}</VTypography>
              </ListItem>
            ))}
          </List>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={1}>
          <Button size="small" fullwidth variant="outlined" title="Volver" onClick={onClose} />
          <Button
            size="small"
            fullwidth
            title="Continuar"
            onClick={handleResend}
            loading={isLoading}
          />
        </Stack>
      </Stack>
    </BottomSheet>
  );
};

export default ResendOrderBottomSheet;
