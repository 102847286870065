import { useTheme } from '@material-ui/core';
import { useHome } from '../../../hooks/useHome';

export const useProducerHomePage = (urlParams) => {
  const { userAlias } = urlParams;
  const theme = useTheme();

  const { events, getHomeEvents, hasMoreEvents, producer } = useHome(urlParams, 6);

  return {
    producer,
    events,
    hasMoreEvents,
    getHomeEvents,
    userAlias,
    theme,
  };
};
