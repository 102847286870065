import { useForm } from '../../../../hooks/useForm';
import { INPUT_TYPE } from '../../../../constants/types';

export const useCreateFinancialOperationModal = (handleAccept, handleClose) => {
  const { formState, formValues, handleInputChange, validateFormInputs, resetForm } = useForm({
    type: { required: true },
    amount: { type: INPUT_TYPE.FLOAT, required: true },
    notes: { required: false },
    paymentDate: { required: false },
  });

  const handleCreate = () => {
    const isFormValid = validateFormInputs();
    if (!isFormValid) return;

    handleAccept(formValues);
    resetForm();
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
    resetForm();
  };

  const handlePaymentDateChange = (date) => {
    handleInputChange({ target: { name: 'paymentDate', value: date } });
  };

  return { formState, handleInputChange, handleCreate, handleCancel, handlePaymentDateChange };
};
