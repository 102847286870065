import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './TicketTypesReportsDataAccordionStyles';
import TicketTypesAccordionHeader from '../TicketTypesReportsDataAccordionHeader/TicketTypesReportsDataAccordionHeader';
import ReportsSectionsAccordionBody from '../ReportsSectionsAccordionBody/ReportsSectionsAccordionBody';
import ReportsTicketTypeAccordionBody from '../ReportsSectionsAccordionBody/ReportsTicketTypeAccordionBody';
import { REPORTS_DATA_TYPE } from '../../../../constants/types';

const TicketTypesReportsDataAccordion = ({
  classes,
  data,
  title,
  subtitle,
  dataType = REPORTS_DATA_TYPE.SALES,
}) => {
  const { totalSold, totalStock, totalUsed, ticketsByType, ticketsBySectionId } = data;

  const totalValue = dataType === REPORTS_DATA_TYPE.SALES ? totalStock : totalSold;
  const currentValue = dataType === REPORTS_DATA_TYPE.SALES ? totalSold : totalUsed;

  return (
    <div className={classes.container}>
      <TicketTypesAccordionHeader
        title={title}
        subtitle={subtitle}
        totalValue={totalValue}
        currentValue={currentValue}
      />
      {ticketsBySectionId ? (
        <ReportsSectionsAccordionBody sections={ticketsBySectionId} dataType={dataType} />
      ) : (
        <ReportsTicketTypeAccordionBody sections={ticketsByType} dataType={dataType} />
      )}
    </div>
  );
};

export default withStyles(styles)(TicketTypesReportsDataAccordion);
