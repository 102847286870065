import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    powerBy: {
      fontSize: 10,
      lineHeight: '11.73px',
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 4,
    },
  });

export default styles;
