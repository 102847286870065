import { removeStringSpaces } from '../../../../utils/utils';

export const CARD_PATTERNS = {
  AMEX: {
    mask: '9999 999999 99999',
    cardLength: 15,
  },
  DEFAULT: {
    mask: '9999 9999 9999 9999',
    cardLength: 16,
  },
};
export const checkIsLemonCard = (cardNumber) => {
  // const LEMON_BIN = '434532'; // '40403100'; TO REDO
  const BRUBANK_BINES = [
    '41119710',
    '41119711',
    '41119712',
    '41119713',
    '41119714',
    '41119715',
    '41119716',
    '41119717',
    '41119718',
    '41119719',
    '41119720',
    '41119721',
    '41119722',
    '41119723',
    '41119724',
    '41119725',
    '41119726',
    '41119727',
    '41119728',
    '41119729',
    '45707400',
  ];
  // return cardNumber.startsWith(LEMON_BIN);
  return BRUBANK_BINES.includes(cardNumber.slice(0, 8));
};

export const checkIsAmexCard = (cardNumber) =>
  cardNumber.slice(0, 2) === '37' || cardNumber.slice(0, 2) === '34';

export const getCardType = (cardNumber = '') => {
  const cardNumberWithoutSpaces = removeStringSpaces(cardNumber);

  const isLemonCard = checkIsLemonCard(cardNumberWithoutSpaces);
  const cardPattern = checkIsAmexCard(cardNumberWithoutSpaces)
    ? CARD_PATTERNS.AMEX
    : CARD_PATTERNS.DEFAULT;
  return {
    ...cardPattern,
    isLemonCard,
  };
};

export const getMonthAndYearFromCardExpirationDate = (cardExpirationDate) => {
  const [month, year] = cardExpirationDate.split('/');
  return { month, year };
};
