import React from 'react';
import { Stack } from '@mui/material';
import { ReactComponent as ErrorImage } from '../../../../assets/images/errorScreenImage.svg';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';
import Button from '../../../../Components/Buttons/Button/Button';
import { ERRORS_CONTENT } from './ErrorTypesContent';

const ErrorPurchaseContent = ({ internalErrorCode }) => {
  const errorContent = ERRORS_CONTENT[internalErrorCode];

  return (
    <Stack maxWidth={500} alignSelf="center" flex={1} padding={2} gap={4}>
      <Stack alignItems="center" justifyContent="center" flex={1}>
        <ErrorImage />
      </Stack>
      <Stack gap={2} flex={2}>
        <VTypography align="center" variant="h2" className="mailValidationPageTitle">
          No pudimos procesar tu compra.
        </VTypography>
        {errorContent?.message && errorContent.message}
      </Stack>
      {errorContent?.button && (
        <Stack gap={2}>
          <Button href={errorContent.button.href} title={errorContent.button.title} />
        </Stack>
      )}
    </Stack>
  );
};

export default ErrorPurchaseContent;
