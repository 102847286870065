import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    ticketsArrowsContainer: {
      background: theme.palette.background.paper,
      height: 32,
      width: 215,
      alignSelf: 'center',
      borderRadius: 8,
      border: `1px solid ${theme.palette.grey[300]}`,
      overflow: 'hidden',
      alignItems: 'center',
      padding: 0,
    },
    amountOfTickets: {
      flex: 1,
      height: '100%',
      textAlign: 'middle',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
      borderRight: `1px solid ${theme.palette.grey[300]}`,
    },
    ticketsArrowButton: {
      minWidth: '24px !important',
      display: 'flex',
      backgroundColor: 'transparent',
      padding: '8px !important',

      '&.Mui-disabled': {
        '& path': {
          fill: theme.palette.buttons.disabled,
        },
      },

      '&:hover': {
        backgroundColor: 'transparent !important',

        '& path': {
          fill: theme.palette.buttons.main.hover,
        },
      },
    },
  });

export default styles;
