import React from 'react';
import MUIDataTable from 'mui-datatables';
import { tableTranslations } from './tableTranslations';

const SellersInvitationsTable = ({ data }) => {
  const formattedData = data.map(
    ({ sellerAlias, sellerMail, totalUsed, ticketName, teamLeaderCode }) => ({
      'Team Leader': teamLeaderCode,
      'Alias del publica': sellerAlias,
      'Mail del publica': sellerMail,
      'Nombre del ticket': ticketName,
      'Cantidad de ingresados': totalUsed,
    })
  );

  const tableOptions = {
    ...tableTranslations,
    downloadOptions: {
      filename: 'SellersInvitationsReport.csv',
    },
  };

  const columns = [
    'Team Leader',
    'Alias del publica',
    'Mail del publica',
    'Nombre del ticket',
    'Cantidad de ingresados',
  ];

  return <MUIDataTable data={formattedData} columns={columns} options={tableOptions} />;
};

export default SellersInvitationsTable;
