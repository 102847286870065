import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    modalContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& .modalBoxContainer': {
        width: 350,
        background: theme.palette.grey[50],
        borderRadius: 8,
      },
    },
    drawer: {
      '& .MuiPaper-root': {
        borderRadius: '24px 24px 0px 0px',
        background: theme.palette.grey[50],
        color: theme.palette.text.primary,
      },
    },
    drawerContainer: {
      padding: '32px 0 32px 0',
    },
    puller: {
      width: 40,
      height: 4,
      backgroundColor: theme.palette.grey[500],
      borderRadius: 3,
      position: 'absolute',
      top: 12,
      left: 'calc(50% - 20px)',
    },
  });

export default styles;
