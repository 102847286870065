import React, { useEffect, useState } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { Box } from '@mui/material';
import styles from './MailValidationPagesStyles';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import CustomLink from '../../../Components/CustomLink/CustomLink';
import { ReactComponent as Image } from '../../../assets/images/successMailValidation.svg';
import { handleRequestHelper } from '../../../utils/helpers';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import history from '../../../appHistory';
import api from '../../../api/api';

const SuccessMailValidationPage = ({ classes, urlParams }) => {
  const [isValidating, setIsValidating] = useState(true);
  const { showNotification } = useNotifications();
  const { token } = urlParams;
  const { hostname } = window.location;

  const validateToken = async () => {
    await handleRequestHelper({
      endpoint: () => api.validateMailToken(token, hostname),
      showNotification,
      onFailure: () => history.replace('/mailvalidation/error'),
      onSuccess: () => {
        setIsValidating(false);
        setTimeout(() => {
          history.replace('/login');
        }, 3000);
      },
    });
  };

  useEffect(() => validateToken(), []);

  return (
    <PageContainer isLoading={isValidating}>
      <Box className={classes.mailValidationPage}>
        <Box display="flex" gap={2} flexDirection="column">
          <Box className="mailValidationPageImage">
            <Image />
          </Box>

          <Box display="flex" gap={2} flexDirection="column">
            <Typography className="mailValidationPageTitle">
              Tu cuenta fue creada con éxito.
            </Typography>
            <Typography className="mailValidationPageText16">
              Ahora vas a poder acceder fácilmente a los tickets de tus eventos favoritos y comprar
              para los próximos.
            </Typography>
          </Box>
        </Box>

        <Box display="flex" gap={3} flexDirection="column">
          <Typography className="mailValidationPageText14">
            Serás redirigido automaticamente a la pantalla de ingreso en unos segundos.
          </Typography>
          <CustomLink to="/login" title="Ir al ingreso" />
        </Box>
      </Box>
    </PageContainer>
  );
};

export default withStyles(styles)(SuccessMailValidationPage);
