import React, { useEffect, useState } from 'react';
import DateFilter from '../Filters/DateFilter/DateFilter';

export const useEventFiltersButtons = () => {
  const [alignment, setAlignment] = useState();

  const filtersButtons = [
    { title: 'Fecha', value: 'date', Filter: <DateFilter key="dateFilter" /> },
  ];

  const [filters, setFilters] = useState({
    date: false,
    location: false,
    price: false,
  });

  const handleAlignment = (event, newAlignment) => setAlignment(newAlignment);

  const toggleOpenFilter = (filter) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      Object.keys(newFilters).forEach((key) => {
        newFilters[key] = false;
      });
      if (filter) newFilters[filter] = !prevFilters[filter];
      return newFilters;
    });
  };

  useEffect(() => {
    const searcherInput = document.getElementById('event-autocomplete-searcher');
    if (searcherInput) {
      searcherInput.addEventListener('click', () => {
        toggleOpenFilter();
        handleAlignment();
      });
    }

    return () => {
      if (searcherInput) {
        searcherInput.removeEventListener('click', () => {
          toggleOpenFilter();
          handleAlignment();
        });
      }
    };
  }, []);

  return {
    alignment,
    handleAlignment,
    filtersButtons,
    filters,
    toggleOpenFilter,
  };
};
