import { createTheme } from '@material-ui/core/styles';
import defaultTheme from './defaultTheme';

const darkTheme = createTheme(defaultTheme, {
  palette: {
    type: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: '#B3B3B3',
    },
    background: {
      default: '#121212',
      alternative: '#151515',
      toolbar: '#FFFFFF',
      paper: '#424242',
    },
    grey: {
      50: '#212121',
      100: '#323232',
      200: '#424242',
      300: '#757575',
      400: '#9E9E9E',
      500: '#BDBDBD',
      600: '#E0E0E0',
      700: '#EEEEEE',
      800: '#F5F5F5',
      900: '#FAFAFA',
      A100: '#616161',
      A200: '#BDBDBD',
      A400: '#EEEEEE',
      A700: '#F5F5F5',
    },
    table: {
      background: '#424242',
      text: '#FFFFFF',
      border: '1px solid rgba(255,255,255,0.2)',
      hover: '#373737',
      disabled: '#4E4E4E',
    },
  },
});

export default darkTheme;
