import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    textButton: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      border: 'none',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      fontSize: 14,
      padding: 0,
      fontFamily: theme.typography.fontFamily,

      '&:hover': {
        opacity: 0.8,
      },
    },
  });

export default styles;
