import React from 'react';
import { withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import styles from './EventCardsGrid.styles';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';
import MediumEventCard from '../../Home/Components/MediumEventCard/MediumEventCard';
import ShadowButton from '../../../../Components/ShadowButton/ShadowButton';

const EventCardsGrid = ({ classes, events, userAlias, hasMoreEvents, getMoreEvents }) => {
  return (
    events &&
    !!events.length && (
      <>
        <VTypography variant="h3">Próximos eventos</VTypography>
        <Stack alignItems="center">
          <Stack className={classes.nextEventsBox}>
            {events?.map((event) => (
              <MediumEventCard key={event.id} event={event} id={event.id} userAlias={userAlias} />
            ))}
          </Stack>
          {hasMoreEvents() && <ShadowButton text="Cargar más" onClick={getMoreEvents} />}
        </Stack>
      </>
    )
  );
};

export default withStyles(styles)(EventCardsGrid);
