import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    navigationButton: {
      '&.MuiButton-root': {
        backgroundColor: 'unset',
        width: 'min-content',
        minWidth: 'unset',
        color: theme.palette.grey[400],
        padding: '0 8px',

        '&.Mui-disabled': {
          color: theme.palette.grey[200],
        },

        '&:hover': {
          color: theme.palette.primary.main,
          opacity: 1,
          backgroundColor: 'unset',
        },
      },
    },
    monthLabel: {
      fontSize: 16,
      fontWeight: 700,
      textTransform: 'capitalize',
    },
  });

export default styles;
