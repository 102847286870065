import React from 'react';
import { withStyles } from '@material-ui/core';
import { Accordion, AccordionSummary, FormControlLabel, Radio } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './TeamLeadersAccordionStyles';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';

const TeamLeadersAccordion = ({
  index,
  producerId,
  teamLeaders,
  selectedTeamLeaders,
  handleSelectTeamLeader,
  classes,
  expanded,
  onChange,
}) => {
  return (
    <Accordion
      aria-controls="accordion"
      className={classes.formItemContainer}
      expanded={expanded}
      onChange={onChange}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id={index}>
        <VTypography variant="body2">
          Leader asignado: <b>{selectedTeamLeaders[producerId]?.code}</b>
        </VTypography>
      </AccordionSummary>
      {teamLeaders?.map(({ teamLeaderId, code }) => (
        <FormControlLabel
          key={code}
          name={code}
          control={
            <Radio
              className={classes.radioButton}
              checked={selectedTeamLeaders[producerId]?.teamLeaderId === teamLeaderId}
              onChange={({ target: { checked } }) =>
                checked && handleSelectTeamLeader(producerId, { teamLeaderId, code })
              }
            />
          }
          label={
            <VTypography
              className={classes.checkboxLabel}
              align="left"
              variant="body2"
              linesNumber={1}
            >
              {code}
            </VTypography>
          }
          className={`${classes.formItemContainer} ${classes.checkboxContainer}`}
          labelPlacement="start"
        />
      ))}
    </Accordion>
  );
};

export default withStyles(styles)(TeamLeadersAccordion);
