import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { useIntercom } from 'react-use-intercom';
import { INPUT_TYPE, HTTP_CODE, NOTIFICATION_VARIANT } from '../../../constants/types';
import { useForm } from '../../../hooks/useForm';
import { handleRequestHelper } from '../../../utils/helpers';
import BottomSheet from '../../../Venti-UI-Kit/BottomSheet/BottomSheet';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import TextButton from '../../Buttons/TextButton/TextButton';
import Button from '../../Buttons/Button/Button';
import TextInput from '../../../Venti-UI-Kit/Inputs/TextInput';
import { ReactComponent as SuccessImage } from '../../../assets/images/successMailValidation.svg';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import api from '../../../api/api';

const TransferQrBottomSheet = ({ open, onClose, qrExternalId, getEventQrs }) => {
  const { showNotification } = useNotifications();
  const [successTransfer, setSuccessTransfer] = useState(false);
  const [userDoNotExist, setUserDoNotExist] = useState(false);
  const { show } = useIntercom();
  const { formState, handleInputChange, validateFormInputs, resetForm } = useForm({
    mail: {
      title: 'Mail',
      type: INPUT_TYPE.MAIL,
    },
  });

  const handleCloseBottomSheet = () => {
    resetForm();
    setSuccessTransfer(false);
    setUserDoNotExist(false);
    onClose();
  };

  const closeAndRefresh = () => {
    handleCloseBottomSheet();
    getEventQrs();
  };

  const transferQr = async () => {
    const isMailValid = validateFormInputs();
    if (!isMailValid) return;

    const transferBody = {
      destinationCustomer: formState.mail.value,
      qrExternalId,
    };
    await handleRequestHelper({
      endpoint: () => api.transferQr(transferBody),
      onSuccess: () => setSuccessTransfer(true),
      onFailure: ({ statusCode }) => {
        if (statusCode !== HTTP_CODE.NOT_FOUND) {
          handleCloseBottomSheet();
          return;
        }
        setUserDoNotExist(true);
      },
      showNotification,
      errorCodesNotShowingSnackbar: [HTTP_CODE.NOT_FOUND],
    });
  };

  const inviteUser = async () => {
    await handleRequestHelper({
      endpoint: () => api.sendCustomerInvitation(formState.mail.value),
      onSuccess: () => {
        showNotification('El mail se envió de forma exitosa.', NOTIFICATION_VARIANT.SUCCESS);
        handleCloseBottomSheet();
      },
      onFailure: () => handleCloseBottomSheet(),
      showNotification,
    });
  };

  const openIntercom = () => {
    handleCloseBottomSheet();
    show();
  };

  return (
    <BottomSheet open={open} onClose={successTransfer ? closeAndRefresh : handleCloseBottomSheet}>
      <Stack padding="16px" gap={2.5}>
        {successTransfer ? (
          <>
            <Stack alignItems="center">
              <SuccessImage />
            </Stack>

            <Stack gap={1} paddingBottom="48px">
              <VTypography variant="h4" align="center">
                Tu transferencia fue exitosa
              </VTypography>
              <VTypography variant="body2" align="center">
                Los tickets fueron transferidos a <br />
                <strong>{formState.mail.value}</strong>.
              </VTypography>
              <VTypography variant="body2" align="center">
                En caso de que haya algún problema, ponete en contacto con{' '}
                <TextButton title="nuestro soporte" onClick={openIntercom} />.
              </VTypography>
            </Stack>
            <Button color="alternative" title="Volver a mis tickets" onClick={closeAndRefresh} />
          </>
        ) : userDoNotExist ? (
          <>
            <Stack gap={1} paddingBottom="16px">
              <VTypography variant="h4">El mail ingresado no tiene cuenta en Venti</VTypography>
              <VTypography variant="body2">
                Podés enviarle un mail a <strong>{formState.mail.value}</strong> para que se cree
                una cuenta y así después puedas transferirle los tickets.
              </VTypography>
            </Stack>
            <Button color="alternative" title="Invitar a Venti" onClick={inviteUser} />
            <Button
              color="alternative"
              variant="outlined"
              title="Volver"
              onClick={() => setUserDoNotExist(false)}
            />
          </>
        ) : (
          <>
            <Stack gap={1}>
              <VTypography variant="h4">Elegí a quien transferir</VTypography>
              <VTypography variant="body2">
                Ingresá el mail de la persona a quien querés transferirle el ticket, podrás hacerlo
                solo a usuarios con cuenta en Venti.
              </VTypography>
            </Stack>
            <TextInput
              title={formState.mail.title}
              onChange={handleInputChange}
              name="mail"
              errorMessage={formState.mail.error}
            />
            <Button
              color="alternative"
              title="Transferir"
              disabled={!formState.mail.value}
              onClick={transferQr}
            />
          </>
        )}
      </Stack>
    </BottomSheet>
  );
};

export default TransferQrBottomSheet;
