import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    arrowsContainer: {
      width: '100%',
      padding: '0 10px',
      top: '50%',
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      zIndex: 30,
    },
    arrowButton: {
      opacity: 0.2,
      borderRadius: '100px !important',
      padding: '10px !important',
      minWidth: '0 !important',
      '&:hover': {
        opacity: 1,
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
    },
    carouselContent: {
      width: '100%',
      height: '100%',

      '& .slick-slider': {
        height: '100%',
      },

      '& .slick-list': {
        height: '100%',
      },

      '& .slick-track': {
        display: 'flex',
        height: '100%',
      },

      '& .slick-slide': {
        transform: 'scale(0.9)',
        zIndex: 1,
        opacity: 0.5,
        transition: 'all 500ms ease',

        '& > div': {
          height: '100%',
        },
      },

      '& .slick-center': {
        opacity: 1,
        transform: 'scale(1)',
        zIndex: 2,
      },

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  });

export default styles;
