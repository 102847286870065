import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './TicketTypesAccordion.styles';
import MobileTicketTypeInfoCard from '../../Cards/TicketTypeInfoCard/MobileTicketTypeInfoCard/MobileTicketTypeInfoCard';

const TicketTypesAccordion = ({ classes, sections, handleDeleteTicket, handleCopyTicketLink }) => {
  const [selectedSection, setSelectedSection] = useState();

  const handleChangeSection = (index) => (event, newExpanded) => {
    setSelectedSection(newExpanded ? index : false);
  };

  return (
    <div className={classes.container}>
      {sections.map(({ label, data, index }) => (
        <Accordion
          key={index}
          aria-controls="accordion"
          expanded={selectedSection === index}
          onChange={handleChangeSection(index)}
          className={classes.accordionContainer}
        >
          <AccordionSummary
            className={classes.accordionSection}
            expandIcon={<ExpandMoreIcon />}
            id={index}
          >
            <Typography className={classes.sectionTitle}>
              {label} ({data.length})
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.cardsContainer}>
            {data.map((ticket) => (
              <MobileTicketTypeInfoCard
                key={ticket.id}
                ticket={ticket}
                handleDelete={handleDeleteTicket}
                handleCopyTicketLink={handleCopyTicketLink}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default withStyles(styles)(TicketTypesAccordion);
