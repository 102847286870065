/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { withStyles } from '@material-ui/core';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import styles from './FeaturedEventCardStyles';

const FeaturedEventCard = ({ classes, event }) => {
  const { promoImg, bannerImg, urlName } = event;
  const maxMobileSize = 768;

  const { width } = useWindowDimensions();

  return (
    <a
      className={`${classes.cardContainer} animate__animated animate__fadeIn`}
      href={`/evento/${urlName}`}
    >
      <img
        src={width > maxMobileSize ? bannerImg : promoImg}
        alt=""
        loading="lazy"
        className={classes.image}
        width={2600}
        height={500}
      />
    </a>
  );
};

export default withStyles(styles)(FeaturedEventCard);
