const styles = (theme) => ({
  ImgIcon: {
    display: 'flex',
    alignItems: 'left',
    width: '1.3em',
    height: '1.3em',
  },
  select: {
    backgroundColor: theme.palette.grey[200],
    width: '100%',
    borderRadius: 10,
    padding: '5px 10px',
    height: 40,
    '& .MuiInput-input': {
      padding: '6px 0 7px',
    },
  },
});

export default styles;
