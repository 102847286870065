import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import styles from './SalesReportCardStyles';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { TICKET_TYPE } from '../../../../constants/types';

const SalesReportCard = ({ classes, data }) => {
  const { name, onlyOnline, totalSold, totalStock, type } = data;

  if (totalSold === 0 && !totalStock) return null;

  return (
    <div className={classes.cardContainer}>
      <div className={classes.cardInfo}>
        <Typography className={classes.eventTitle}>{name}</Typography>
        {totalStock && (
          <div className={classes.eventInfo}>
            <Typography>{onlyOnline ? 'Online' : 'Online y Cash'}</Typography>
            <Typography>
              {totalSold}/{totalStock}
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.graphicContainer}>
        {totalStock ? (
          <ProgressCircle totalValue={totalStock} currentValue={totalSold} />
        ) : (
          <div className={classes.totalSoldContainer}>
            <Typography className={classes.totalSoldValue}>{totalSold}</Typography>
            <Typography className={classes.totalSoldLabel}>
              {type === TICKET_TYPE.INVITATION ? 'enviadas' : 'vendidas'}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(SalesReportCard);
