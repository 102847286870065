export class PaymentMethod {
  constructor(order, showNotification) {
    this.order = order;
    this.showNotification = showNotification;
    this.availableCards = [];
  }

  getIdentificationTypes() {
    throw new Error('getIdentificationTypes() must be implemented by subclasses ');
  }

  validateCardNumber() {
    throw new Error('validateCardNumber() must be implemented by subclasses ');
  }

  getAvailableCards() {
    throw new Error('getAvailableCards() must be implemented by subclasses ');
  }

  getInstallments() {
    throw new Error('getInstallments() must be implemented by subclasses ');
  }

  processPayment() {
    throw new Error('processPayment() must be implemented by subclasses');
  }
}
