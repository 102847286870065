import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import classNames from 'classnames';
import styles from './TitledInput.styles';
import InputTitle from '../../Components/InputTitle/InputTitle';

class TitledInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHelperText: false,
      value: false,
    };
  }

  getInputClasses(classes, error, showErrors) {
    const inputBorderClasses = [classes.root];

    if (error && (this.state.showHelperText || showErrors))
      inputBorderClasses.push(classes.errorRoot);

    return classNames(...inputBorderClasses);
  }

  showHelperText = () => {
    this.setState({ showHelperText: true });
  };

  hideHelperText = () => {
    this.setState({ showHelperText: false });
  };

  onChange = (event) => {
    this.setState({ value: event.target.value });
    this.props.onChange(event);
  };

  render() {
    const { classes } = this.props;
    const error = !!this.props.helperText;
    const inputBorderClasses = this.getInputClasses(classes, error, this.props.showErrors);

    const value = this.props.value || this.state.value || this.props.defaultValue || '';

    const HelperText = (props) => (
      <FormHelperText className={classes.helper}>
        {(props.showHelperText || props.showErrors) && props.helperText}
      </FormHelperText>
    );

    const InputLength = (props) => {
      if (!props.showLength) return null;
      return (
        <div className={classes.length}>
          {props.value ? (
            <div>
              {props.value.length} / {props.maxLength}
            </div>
          ) : (
            <div>0 / {props.maxLength}</div>
          )}
        </div>
      );
    };

    return (
      <div className={this.props.className ?? null}>
        {(this.props.title || this.props.icon) && (
          <InputTitle
            title={this.props.title}
            icon={this.props.icon}
            description={this.props.description}
          />
        )}
        <FormControl required fullWidth>
          <Input
            endAdornment={this.props.adorment}
            className={inputBorderClasses}
            classes={{ root: this.props.multiline && classes.multilineInputStyle }}
            disableUnderline
            onChange={this.onChange.bind(this)}
            disabled={this.props.disabled || false}
            onBlur={this.showHelperText.bind(this)}
            onFocus={this.hideHelperText.bind(this)}
            value={this.props.usePropValue ? this.props.value : value}
            name={this.props.name}
            autoComplete={this.props.autocomplete}
            placeholder={this.props.placeholder}
            inputProps={this.props.inputProps}
            multiline={this.props.multiline}
            type={this.props.type}
            readOnly={this.props.readOnly}
          />
        </FormControl>
        <InputLength
          value={this.props.value}
          showLength={this.props.showLength}
          maxLength={this.props.maxLength}
        />
        <HelperText
          showHelperText={this.state.showHelperText}
          showErrors={this.props.showErrors}
          helperText={this.props.helperText}
        />
      </div>
    );
  }
}

export default withStyles(styles)(TitledInput);
