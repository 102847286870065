import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    title: {
      marginTop: 20,
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        padding: '0 10px',
      },
    },
    producerTitle: {
      marginTop: 20,
      fontWeight: 600,
      transition: 'all 0.6s',
      [theme.breakpoints.down('xs')]: {
        fontSize: 19,
        padding: 0,
      },
    },
    pageContent: {
      marginTop: 20,
      width: '70%',
      margin: '0 auto',
    },
  });

export default styles;
