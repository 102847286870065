import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { Box, Paper, Stack } from '@mui/material';

import styles from './PersonalDataFormStyles';
import CustomInput from '../../../CustomInput/CustomInput';
import { useUser } from '../../../../hooks/useUser';
import { useFormDeprecated } from '../../../../hooks/useFormDeprecated';
import Alert from '../../../../Venti-UI-Kit/Alert/Alert';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';
import { AVAILABLE_ROLES_TO_CREATE_OPTIONS } from '../../../../constants/selectorOptions';
import { useAuth } from '../../../../Providers/AuthProvider/AuthProvider';
import { validateUserRole } from '../../../../utils/utils';
import { USER_PERMISSIONS } from '../../../../constants/types';

const PersonalDataForm = ({
  classes,
  handleInputChange,
  formState,
  formErrors,
  setFormState,
  setFormErrors,
  edit = false,
  handleExistingUser,
  setUserAlreadyExists,
  userAlreadyExists,
  userIsCustomer,
}) => {
  const { mail, alias, role, password } = formState;
  const { user } = useAuth();
  const enableSuperAdminEdition = validateUserRole(user.role, USER_PERMISSIONS.SUPER_ADMIN);
  const { validateMail } = useFormDeprecated({});
  const { getUserByMail } = useUser();

  useEffect(() => {
    if (!mail || edit) return;

    const delayDebounceFn = setTimeout(async () => {
      const mailError = validateMail(mail);
      setFormErrors((prevState) => ({ ...prevState, mail: mailError }));

      if (mailError) return;

      const user = await getUserByMail(mail);
      setUserAlreadyExists(!!user);
      if (!user) {
        setFormState((prevState) => ({
          ...prevState,
          alias: '',
          role: '',
        }));
        return;
      }

      await handleExistingUser(user);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [mail]);

  const creatingNewUser = !edit;
  const enableMailEdition = creatingNewUser || enableSuperAdminEdition;
  const eneableRoleEdition =
    (creatingNewUser && !userAlreadyExists) ||
    (creatingNewUser && userAlreadyExists && userIsCustomer) ||
    enableSuperAdminEdition;
  const eneableAliasEdition = (creatingNewUser && !userAlreadyExists) || enableSuperAdminEdition;

  return (
    <Box>
      <VTypography variant="subtitle1" mb={8}>
        Datos personales
      </VTypography>
      <Paper className={classes.formContainer}>
        <Stack direction={{ xs: 'column', md: 'row' }} gap={2}>
          <CustomInput
            title="Mail"
            name="mail"
            onChange={handleInputChange}
            value={mail}
            error={formErrors.mail}
            disabled={!enableMailEdition}
          />
          <CustomInput
            title="Alias"
            name="alias"
            onChange={handleInputChange}
            value={alias}
            disabled={!eneableAliasEdition}
            error={formErrors.alias}
          />
          <CustomInput
            title="Rol del usuario"
            select
            name="role"
            onChange={handleInputChange}
            value={role}
            disabled={!eneableRoleEdition}
            options={AVAILABLE_ROLES_TO_CREATE_OPTIONS}
          />
          {enableSuperAdminEdition && (
            <CustomInput
              title="Forzar contraseña nueva"
              name="password"
              onChange={handleInputChange}
              value={password}
              error={formErrors.password}
            />
          )}
        </Stack>
        {userAlreadyExists && !edit && (
          <Alert
            message="El email ingresado tiene un rol asignado. Para crearlo con un nuevo rol, utilizá un mail diferente."
            severity="warning"
          />
        )}
      </Paper>
    </Box>
  );
};

export default withStyles(styles)(PersonalDataForm);
