import { createStyles } from '@material-ui/core';

const styles = (theme) => {
  return createStyles({
    nextEventsBox: {
      '&.MuiStack-root': {
        maxWidth: '100%',
        margin: '0 auto',
        display: 'grid',
        gap: 16,
        gridTemplateColumns: 'repeat( auto-fit, minmax(356px, 1fr) )',
        [theme.breakpoints.down('xs')]: {
          margin: 0,
          gridTemplateColumns: 'unset',
          width: '100%',
        },
      },
    },
  });
};

export default styles;
