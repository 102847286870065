import React from 'react';
import { withStyles } from '@material-ui/core';
import { Box, Stack } from '@mui/material';
import styles from './EventsSlider.styles';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';
import EventCard from '../EventCard/EventCard';
import { useEventsSlider } from './EventsSlider.hook';
import { ReactComponent as TicketQrImage } from '../../../../assets/images/ticketQrImage.svg';
import { ReactComponent as CalendarImage } from '../../../../assets/images/calendarImage.svg';
import Button from '../../../../Components/Buttons/Button/Button';
import LoadingContainer from '../../../../Components/LoadingContainer/LoadingContainer';

const EventsSlider = ({ classes, onlyPastEvents }) => {
  const { events, eventsLength, formatDateString, getSortedEventsKeys, isGettingMoreItems } =
    useEventsSlider({ onlyPastEvents });

  return (
    <Box gap={1} flexDirection="column">
      {eventsLength === 0 && (
        <Box display="flex" alignItems="center" mt={4} flexDirection="column" gap={1}>
          <LoadingContainer isLoading={isGettingMoreItems}>
            {!onlyPastEvents ? (
              <>
                <TicketQrImage className={classes.imageStroke} />
                <VTypography variant="subtitle1" align="center">
                  Aún no compraste ningún ticket
                </VTypography>
                <VTypography variant="body2" align="center">
                  Cuando lo hagas los vas a poder ver acá.
                </VTypography>
                <Button
                  title="Comprar tickets"
                  color="alternative"
                  fullwidth={false}
                  href="/eventos"
                  target="_self"
                />
              </>
            ) : (
              <>
                <CalendarImage className={classes.imageStroke} />
                <VTypography variant="subtitle1" align="center">
                  No usaste ningún ticket
                </VTypography>
                <VTypography variant="body2" align="center">
                  Cuando asistas a algún evento y utilices tus tickets los vas ver acá.
                </VTypography>
              </>
            )}
          </LoadingContainer>
        </Box>
      )}
      {eventsLength > 0 && (
        <Box paddingX={2} className={classes.slider}>
          {getSortedEventsKeys(events, onlyPastEvents).map((year) => (
            <Stack key={year}>
              <VTypography variant="h3">{year}</VTypography>
              {getSortedEventsKeys(events[year], onlyPastEvents).map((month) => (
                <Stack key={year - month} mb={2}>
                  <VTypography variant="subtitle1" mb={20}>
                    {formatDateString(month)}
                  </VTypography>
                  <Stack direction="row" gap={2} flexWrap="wrap">
                    {events[year][month].map((event) => (
                      <EventCard key={event.id} event={event} />
                    ))}
                  </Stack>
                </Stack>
              ))}
            </Stack>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default withStyles(styles)(EventsSlider);
