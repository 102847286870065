import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 100,
      color: theme.palette.header.buttonText,
      backgroundColor: theme.palette.header.buttonBackground,
      height: 36,
      width: 36,
      padding: 0,
      fontSize: 20,
      border: 'none',
      cursor: 'pointer',
    },
    menuContainer: {
      padding: '0 16px',
      marginTop: 5,
    },
    menuItemContainer: {
      padding: '7px 0',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,.05)',
      },
    },
    menuInformationText: {
      padding: '5px 0',
    },
    menuTitle: {
      fontWeight: 'bold',
      textAlign: 'center',
      fontSize: 20,
      padding: '5px 0',
    },
    menuItem: {
      textDecoration: 'none',
      color: 'black',
      display: 'flex',
      alignItems: 'center',
      columnGap: 10,
      justifyContent: 'space-between',
      width: '100%',
    },
    divider: {
      margin: '4px 0 !important',
    },
    logout: {
      color: theme.palette.error.main,
    },
  });

export default styles;
