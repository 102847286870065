import React from 'react';
import { withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import styles from './styles';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import TitledSwitch from '../../../Venti-UI-Kit/Switches/TitledSwitch';
import SelectInput from '../../../Venti-UI-Kit/Inputs/SelectInput';
import Button from '../../../Components/Buttons/Button/Button';
import { useAdvancedEventSettingsPage } from './hook';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';

const AdvancedEventSettingsPage = ({ classes }) => {
  const { formState, validationModeOptions, handleInputChange, updateEventSettings } =
    useAdvancedEventSettingsPage();

  return (
    <PageContainer title="Backoffice - Lista de órdenes">
      <VBreadcrumbs pageTitle="Opciones Avanzadas" />
      <VTypography variant="h3" align="center">
        Configuración avanzada del evento
      </VTypography>
      <Stack className={classes.pageContent}>
        <TitledSwitch
          titleVariant="body1"
          name="secondaryMarketEnable"
          onChange={handleInputChange}
          checked={formState.secondaryMarketEnable.value}
          title="Activar Venti Swap"
        />
        <TitledSwitch
          titleVariant="body1"
          name="secondaryMarketEventButtonEnable"
          onChange={handleInputChange}
          checked={formState.secondaryMarketEventButtonEnable.value}
          title="Activar el botón de Venti Swap en el evento"
        />
        {/* <TitledSwitch
          titleVariant="body1"
          name="shouldBlacklistSellers"
          onChange={handleInputChange}
          checked={formState.shouldBlacklistSellers.value}
          title="Habilitar la blacklist de sellers"
        />
        <TitledSwitch
          titleVariant="body1"
          name="shouldExtendStopOfSalesToInvitations"
          onChange={handleInputChange}
          checked={formState.shouldExtendStopOfSalesToInvitations.value}
          title="Extender la fecha de corte de ventas hacia las invitaciones"
        />
        <TitledSwitch
          titleVariant="body1"
          name="shouldConvertPriceForUsd"
          onChange={handleInputChange}
          checked={formState.shouldConvertPriceForUsd.value}
          title="Convertir los precios a Dolares"
          description="Los precios de los tickets se mostrarán en dolares"
        />
        <TextInput
          title="Liberación de QRs"
          description="Cantidad de horas antes del evento para liberar los QRs"
          onChange={handleInputChange}
          name="hoursBeforeRelease"
          defaultValue={formState.hoursBeforeRelease.value}
          showHelpText={false}
          type={INPUT_TYPE.NUMBER}
        />
        <TextInput
          title="Finalización venta Venti Swap"
          description="Cantidad de horas antes del evento para finalizar la venta en Venti Swap"
          onChange={handleInputChange}
          name="hoursBeforeReleaseSecondaryMarket"
          defaultValue={formState.hoursBeforeReleaseSecondaryMarket.value}
          showHelpText={false}
          type={INPUT_TYPE.NUMBER}
        /> */}
        <SelectInput
          title="Modo de validación de QRs"
          description="Seleccione el modo de validación de QRs, indicando que disipositivo quiere utilizar para marcar como usado un QR"
          onChange={handleInputChange}
          name="qrValidationMode"
          options={validationModeOptions}
          value={formState.qrValidationMode.value}
          showHelpText={false}
        />
        <Stack alignItems="center">
          <Button title="Guardar" fullwidth={false} onClick={updateEventSettings} />
        </Stack>
      </Stack>
    </PageContainer>
  );
};

export default withStyles(styles)(AdvancedEventSettingsPage);
