import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    producerCardContainer: {
      border: 0,
      backgroundColor: theme.palette.background.paper,
      gap: 16,
      padding: 8,
      borderRadius: 8,
      width: '100%',
      alignItems: 'center',
    },
    producerCardImage: {
      height: 100,
      objectFit: 'cover',
      borderRadius: 8,
    },
  });

export default styles;
