import React, { useState } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import Slider from '@material-ui/core/Slider';
import styles from './SalesByTimeLineChartStyles';
import { formatPrice } from '../../../utils/utils';
import CustomTooltip from '../CustomTooltip/CustomTooltip';

const SCALES = {
  HOUR: 1,
  DAY: 2,
  WEEK: 3,
  MONTH: 4,
};

const SalesByTimeLineChart = ({ classes, data, size = 400 }) => {
  const { dataLastDay, dataLastHour, dataLastMonth, dataLastWeek } = data;

  const [dataToShow, setDataToShow] = useState(Object.values(dataLastMonth).reverse());
  const [chartScale, setChartScale] = useState(SCALES.MONTH);

  const handleSelectScale = (e, newScale) => {
    setChartScale(newScale);
    switch (newScale) {
      case SCALES.HOUR:
        setDataToShow(Object.values(dataLastHour).reverse());
        break;
      case SCALES.DAY:
        setDataToShow(Object.values(dataLastDay).reverse());
        break;
      case SCALES.WEEK:
        setDataToShow(Object.values(dataLastWeek).reverse());
        break;
      default:
        setDataToShow(Object.values(dataLastMonth).reverse());
    }
  };

  const priceFormatter = (number) => {
    if (isNaN(number)) return 0;
    return `${formatPrice(number, false)}`;
  };

  const dateFormatter = (dateTime) => {
    const [date, time] = dateTime.split(', ');
    const [day, month] = date.split('/');
    const [hour, minutes] = time.split(':');

    switch (chartScale) {
      case SCALES.MONTH:
        return `${day}/${month}`;
      case SCALES.WEEK:
        return `${day}/${month}`;
      case SCALES.DAY:
        return `${hour}:${minutes}`;
      case SCALES.HOUR:
        return `${hour}:${minutes}`;
      default:
        return date;
    }
  };

  const CustomizedDot = ({ cx, cy, r = 3 }) => {
    return <circle cx={cx} cy={cy} r={r} className={classes.activeDot} />;
  };

  return (
    <div>
      <Slider
        className={classes.slider}
        defaultValue={SCALES.MONTH}
        max={Object.keys(SCALES).length}
        min={SCALES.HOUR}
        onChange={handleSelectScale}
        aria-labelledby="discrete-slider-restrict"
        marks={[
          { label: 'Hora', value: SCALES.HOUR },
          { label: 'Día', value: SCALES.DAY },
          { label: 'Semana', value: SCALES.WEEK },
          { label: 'Mes', value: SCALES.MONTH },
        ]}
      />
      {!dataToShow.length ? (
        <Typography className={classes.noInformationText}>No hay información.</Typography>
      ) : (
        <ResponsiveContainer width="100%" height={size}>
          <LineChart
            width={500}
            data={dataToShow}
            margin={{
              top: 10,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid opacity={0.2} vertical={false} />
            <XAxis
              dataKey="dateTime"
              tickMargin={10}
              tickFormatter={dateFormatter}
              padding={{ left: 10 }}
            />
            <YAxis
              width={85}
              tickMargin={5}
              tickFormatter={priceFormatter}
              padding={{ bottom: 10 }}
            />
            <Tooltip
              wrapperStyle={{ outline: 'none' }}
              cursor={false}
              offset={5}
              content={<CustomTooltip text="Ingresos" />}
              formatter={priceFormatter}
            />
            <Line
              type="monotone"
              dataKey="totalSold"
              strokeWidth={2}
              className={classes.line}
              activeDot={<CustomizedDot r={8} />}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default withStyles(styles)(SalesByTimeLineChart);
