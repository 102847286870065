import { createTheme } from '@material-ui/core/styles';
import lightTheme from '../lightTheme';

const lightVentiTheme = createTheme(lightTheme, {
  palette: {
    primary: {
      main: '#5758F5',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#A8A6F2',
      contrastText: '#FFFFFF',
    },
    footer: {
      text: '#FFFFFF',
      background: '#222222',
    },
    header: {
      background: '#5758F5',
      buttonText: '#5758F5',
      buttonBackground: '#FFFFFF',
      subHeaderBackground: '#FFFFFF',
      text: '#FFFFFF',
    },
    buttons: {
      disabled: '#CBCBCB',
      disabledText: '#999999',
      main: {
        background: '#5758F5',
        text: '#FFFFFF',
        hover: '#4142B9',
      },
      alternative: {
        background: '#222222',
        text: '#FFFFFF',
        hover: '#454545',
      },
    },
    charts: {
      primary: '#5758F5',
      secondary: '#A8A6F2',
      text: '#4E4E4E',
    },
  },
});

export default lightVentiTheme;
