import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    buttonActive: {
      '&.MuiButtonBase-root, &.MuiButtonBase-root:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,

        '& .MuiIcon-root': {
          color: 'white !important',
        },
      },
    },
  });

export default styles;
