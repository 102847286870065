import React from 'react';
import { MenuItem } from '@mui/material';
import { CITIES } from '../LocationSelectors.constants';
import SelectInput from '../../../Venti-UI-Kit/Inputs/SelectInput';

const CitySelector = ({ country, ...selectProps }) => {
  const cities = CITIES[country?.toLowerCase()];

  return (
    cities?.length > 0 && (
      <SelectInput options={cities} title="Provincia / Ciudad" name="city" {...selectProps}>
        {cities.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </SelectInput>
    )
  );
};

export default CitySelector;
