import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: 5,
    },
    button: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    claimingIndicatorContainer: {
      display: 'flex',
      flexDirection: 'row',
      columnGap: 10,
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      columnGap: 20,
      minHeight: '98.5px',
      [theme.breakpoints.down('xs')]: {
        minHeight: '138.5px',
        flexDirection: 'column',
      },
    },
  });

export default styles;
