import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    mailFromModal: {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  });

export default styles;
