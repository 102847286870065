import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    pageContainer: {
      maxWidth: 1135,
      width: '100%',
      alignSelf: 'center',
      gap: 16,
      padding: 16,
      justifyContent: 'center',
    },
    eventsContainer: {
      gap: 16,
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridAutoRows: 'auto',

      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },

      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(1, 1fr)',
      },
    },
  });

export default styles;
