import React from 'react';
import { withStyles } from '@material-ui/core';
import { Autocomplete, Popper, Stack } from '@mui/material';
import styles from './EventAutocomplete.styles';
import AutocompleteOption from './AutocompleteOption/AutocompleteOption';
import { useEventAutocomplete } from './EventAutocomplete.hook';
import AutocompleteInput from './AutocompleteInput/AutocompleteInput';

const EventAutocomplete = ({ classes }) => {
  const {
    openAutocomplete,
    handleOpenAutocomplete,
    handleCloseAutocomplete,
    eventOptions,
    isLoadingOptions,
    searchQuery,
    onChangeSearch,
  } = useEventAutocomplete();

  return (
    <Stack
      id="event-autocomplete-searcher"
      className={`animate__animated animate__fadeIn ${classes.searcherContainer}`}
    >
      <Autocomplete
        className={classes.autocompleteContainer}
        open={openAutocomplete}
        onOpen={handleOpenAutocomplete}
        onClose={handleCloseAutocomplete}
        PopperComponent={(props) => <Popper {...props} className={classes.autocompletePopper} />}
        options={eventOptions}
        loading={isLoadingOptions}
        disableClearable
        freeSolo={!searchQuery}
        noOptionsText="No se encontraron eventos."
        renderOption={(props, option) => <AutocompleteOption option={option} {...props} />}
        renderInput={(params) => (
          <AutocompleteInput
            onChange={onChangeSearch}
            {...params}
            isLoadingOptions={isLoadingOptions}
          />
        )}
      />
    </Stack>
  );
};

export default withStyles(styles)(EventAutocomplete);
