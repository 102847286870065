import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './FeaturedEventsSectionStyles';
import Carousel from '../../../../../Components/Carousel/Carousel';
import FeaturedEventCard from '../FeaturedEventCard/FeaturedEventCard';

const FeaturedEventsSection = ({ classes, events, userAlias }) => {
  return (
    <div className={classes.featureSectionContainer}>
      <Carousel>
        {events?.map((event) => (
          <FeaturedEventCard event={event} key={event.id} userAlias={userAlias} />
        ))}
      </Carousel>
    </div>
  );
};

export default withStyles(styles)(FeaturedEventsSection);
