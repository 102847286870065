import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      width: 'calc(100% - 100px)',
      margin: 'auto',
      backgroundColor: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[400]}`,
      padding: 15,
      borderRadius: 10,
      columnGap: 20,
      [theme.breakpoints.up('md')]: {
        width: 600,
      },
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
      justifyContent: 'start',
      alignItems: 'start',
      flex: 1,
    },
    infoTextsContainer: {
      display: 'flex',
      columnGap: 5,
      flexWrap: 'wrap',
    },
    infoText: {
      textAlign: 'left',
      fontSize: 16,
      fontWeight: 400,
      '& b': {
        fontWeight: 800,
      },
    },
    infoDivider: {
      // [theme.breakpoints.down(breakPoint)]: {
      //   display: 'none',
      // },
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 15,
    },
    ticketName: {
      fontWeight: 600,
      fontSize: 19,
      textAlign: 'left',
    },
    editIcon: {
      color: theme.palette.text.primary,
    },
    deleteIcon: {
      color: theme.palette.error.main,
    },
    button: {
      padding: '0 !important',
      border: 'none',
      background: 'none',
      cursor: 'pointer',
    },
    ticketImage: {
      borderRadius: 10,
      height: 70,
    },
  });

export default styles;
