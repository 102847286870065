import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './MarqueeStyles';

const Marquee = ({
  classes,
  children: marqueeContent,
  animationTime = '10s',
  contentRepetiations = 8,
  gap = 5,
  backgroundColor,
  rightToLeft = false,
  height,
}) => {
  const marqueeContentRepeatsArray = Array(contentRepetiations).fill(marqueeContent);
  const direction = rightToLeft ? 'rightToLeft' : 'leftToRight';
  return (
    <div className={classes.marquee} style={{ gap, backgroundColor, height }}>
      <div
        className={`marqueeContent ${direction}`}
        style={{ animationDuration: animationTime, gap }}
      >
        {marqueeContentRepeatsArray.map((content) => content)}
      </div>
      <div
        className={`marqueeContent ${direction}`}
        style={{ animationDuration: animationTime, gap }}
      >
        {marqueeContentRepeatsArray.map((content) => content)}
      </div>
    </div>
  );
};

export default withStyles(styles)(Marquee);
