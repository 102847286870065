import priceProvider from '../../../../Providers/Price/Price';
import { formatPrice } from '../../../../utils/utils';

const PAYMENT_TYPES = {
  FEE: 'fee',
  TICKETS: 'tickets',
};

const getPaymentInfo = (payments, eventCurrency) => {
  const totalsToPay = payments.reduce(
    (accum, { priceInCents }, index) => {
      accum.totalToPay += priceInCents;
      accum.totalToPayWithLemon += payments[index].priceInCentsWithLemon;
      return accum;
    },
    { totalToPay: 0, totalToPayWithLemon: 0 }
  );

  totalsToPay.totalToPay = priceProvider.getPrice(totalsToPay.totalToPay, eventCurrency);
  totalsToPay.totalToPayWithLemon = priceProvider.getPrice(
    totalsToPay.totalToPayWithLemon,
    eventCurrency
  );

  const paymentFee = payments.find(({ type }) => type === PAYMENT_TYPES.FEE);

  const serviceCharges = {
    serviceCharge: priceProvider.getPrice(paymentFee.priceInCents, eventCurrency),
    serviceChargeWithLemon: priceProvider.getPrice(paymentFee.priceInCentsWithLemon, eventCurrency),
  };

  return { totalsToPay, serviceCharges };
};

const getTicketsFromQrs = (qrs, discountCodes, eventCurrency) => {
  const formatDiscountCodes = discountCodes.reduce(
    (discounts, { ticketTypeId, discountAmountInCents }) => {
      discounts[ticketTypeId] = discountAmountInCents;
      return discounts;
    },
    {}
  );

  const tickets = qrs.reduce((tickets, { TicketType }) => {
    const { id, name, priceInCents, priceInCentsWithLemon } = TicketType;

    const ticketPrice = priceProvider.getPrice(priceInCents, eventCurrency);

    const lemonDiscount = priceProvider.getPrice(
      priceInCents - priceInCentsWithLemon,
      eventCurrency
    );

    const codeDiscount =
      formatDiscountCodes[id] && priceProvider.getPrice(formatDiscountCodes[id], eventCurrency);

    if (!tickets[id]) {
      tickets[id] = {
        name,
        price: ticketPrice,
        quantity: 0,
        codeDiscount,
        lemonDiscount,
      };
    }

    tickets[id].quantity++;
    return tickets;
  }, {});

  return Object.values(tickets);
};

export const getOrderResume = (order) => {
  const { payments, currency, qrs, discountCodes } = order;
  const { totalsToPay, serviceCharges } = getPaymentInfo(payments, currency);
  const tickets = getTicketsFromQrs(qrs, discountCodes, currency);

  return {
    ...totalsToPay,
    ...serviceCharges,
    totalTicketsAmount: qrs.length,
    tickets,
  };
};

export const getInstallmentLabel = (installment, interest, totalToPay) => {
  const totalToPayWithInterest = (1 + interest / 100) * totalToPay;
  const installmentPrice = totalToPayWithInterest / installment;
  const formattedInstallmentPrice = formatPrice(installmentPrice);
  const formattedTotalToPayWithInterestPrice = formatPrice(totalToPayWithInterest);

  const interestLabel = !interest ? 'Sin interés' : '';

  return `${installment} cuotas de ${formattedInstallmentPrice} (${formattedTotalToPayWithInterestPrice}) ${interestLabel}`;
};
