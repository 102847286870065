import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    accordionsContainer: {
      width: '100%',
      borderRadius: '0 0 12px 12px',
      overflow: 'hidden',
      '& > div:last-child': {
        border: '0px solid transparent !important',
      },
    },
    accordionContainer: {
      boxShadow: 'none !important',
      backgroundColor: `${theme.palette.grey[50]} !important`,
      borderBottom: `1px solid ${theme.palette.grey[200]} !important`,
      '&:before': {
        display: 'none',
      },

      '& .MuiAccordionSummary-content': {
        margin: 0,
        justifyContent: 'space-between',
        padding: '14px 16px 14px 0',
        minHeight: 54,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.grey[50],
      },

      '& .MuiAccordionSummary-root': {
        padding: '0 16px',
        '&.Mui-expanded': {
          borderBottom: `1px solid ${theme.palette.grey[200]}`,
        },
      },

      '& .accordionItemExpandIcon': {
        color: theme.palette.text.primary,
        transform: 'rotate(-90deg)',
        width: 16,
      },

      '& .accordionItemTitle': {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: 20,
        alignItems: 'center',
        color: theme.palette.charts.text,
        display: 'flex',

        [theme.breakpoints.down('sm')]: {
          fontSize: 16,
        },

        '&.section': {
          fontWeight: 700,
        },
      },

      '& .accordionItemSubtitle': {
        fontFamily: `${theme.typography.fontFamily} !important`,
        fontWeight: '600 !important',
        paddingTop: 3,
        fontSize: 14,
        color: theme.palette.grey[400],
        [theme.breakpoints.down('sm')]: {
          fontSize: 12,
        },
      },

      '& .MuiAccordionDetails-root': {
        padding: 16,
        backgroundColor: 'transparent',
        justifyContent: 'center',
        margin: 'auto',
        columnGap: 16,
        rowGap: 16,
        display: 'flex',
        flexWrap: 'wrap',
      },
    },
  });

export default styles;
