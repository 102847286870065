export const LOCAL_ENCRYPT_PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIICITANBgkqhkiG9w0BAQEFAAOCAg4AMIICCQKCAgBkv/JY2PJaX7Tm1CffakkP
47al93Wzrnwky5u6IohKhCqteiwwHoWW2t+fyyHI6WuLdEJr5fUT7FGES7CfoyuZ
1k2QCg50Y+NnCBq20vsUE/SBkgH2nrgNMaSx5k5y7WtQYfYtX/xYGOL8ujyf8ZRl
K7R9sDwMROGYK4gZfMGOPIwRjJ8Rxj2WBqX7gC+eBda1g1SEl+OeEnus6N6yFhj3
fK07fqo811i3kZHhI5zc1V2GFrxFj4RqHmr02WnmzhpCj3qi5jAO9znFv9dQFPXB
BX5ucnpGzpQ15hkSnQiwDRLJgjtvQiIch+tLmAYCj76GnJLsef4gqcKMHaO1n3iw
rkSIhhFAZKMKXZ7DJX/tIN2cr6P0JsNSz1SILCIiPVnVQxu9N8v+sq4qq3j7E1DL
6Ad12k9lW593Ds/r3HWNeVZPjN3n1FM5rmEPqLtv+2q2SGUN5BpGClLSgrEpJMT0
ZbzsEkSQ+/fUYOs/o28GoPODY3DnVwqpY8Di2q/A0t77rrPi+qbr6IMEPbOtvR/V
YjHyRtKAgL5nOE7NHJYDnFhqj6mnMxzfgaWtZs+EajuZv8v1HYU8KriiMBVXRgYM
CGLkAJr3IWPZCyR/aCY5N1WBrRNkF30LRF4Ss3BEbV4JfDM7sn6lCuwelMB/9Sfu
+Mz7ETtBCvf48W0lc2WR3QIDAQAB
-----END PUBLIC KEY-----`;

export const STAGING_ENCRYPT_PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIICITANBgkqhkiG9w0BAQEFAAOCAg4AMIICCQKCAgB5cktWRQjZN1K6LTl0FITQ
/mRGOujJ4LYl+CXxs0xjZxiaH2Y8RxiTaFd41p11U+ne0aH+7Mh0clm1OWbZzmSY
tMJa3Q5t8q8m3wtOYBKWN5E8JaN6ZHU1eE/JRiqVOXgoLXAbXKlXNMoid0BR6Tl3
j3JoJUTk50JBiMz/towu44NwPhPSrFqbxwJXfQvLkDYsmhS8kTgp6CN20a11hHu0
w8ne5IsVyVAxM4KkQhcqZPL+XI55jQxyQr4IJACJR5JG5yS7kw9xqcFCdNTWSWpN
tKosDs+mgeEElBW9d58KNJOvFfObqGxJZHhrEWefv56VaCti+NV6LzDvrMjaOoQy
IgG61A1cVN3i0jIK/eT9hsBSg33OberjE3OmBnYiY5sn1o0eVd87PttDQEr1ZwwM
EnseODHlD03v/kaoObg9V0jvlM0Z9aWEseOtjcY13gLydQFFZu1rhsgovigENJjn
zPo31YBB1lY9edUOUiCFt3dCSkJZtfQ9t3HSl4fWxOpcs5uInKeBwPf0ebeU8bR9
UfdCm1mZt8bj/Lch65PQBVYbOoM1Ob+0EOAI5apWN526Cl/XhFYAtDacfRyJZ7gd
QAs1rI7c/hmtvxF7sP3hb3mq0uVpH/N7sGVJ/34wIuillAIEB0MCVqI19b93fxUK
qupjAVukQsoWMPDY83rurwIDAQAB
-----END PUBLIC KEY-----`;

export const PRODUCTION_ENCRYPT_PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAmWeKcjn8xzagzj+p4mOe
J75sz98tpZnqxtmHOtEl/kiGjhyrkucwFtibbou5gV0w7osX/OdJmJtb1UnqdsaT
uz+30gg4Tclb2nPCxNSIqxvLBJjNE/GoLxkDE6ex9t4punEmBt/QwwvKczkebxlf
D/O0G5zuHTZMMmwJVNcsSYPJZfsEhWJ/zRT/p1dcwF1uVl2EbPETwI+hY0Aa2Mlf
wJvv44k9lrgZXOzX2Yz+4zG2+izvfAvF0tQNR5XRDcB76XGcMp2dq2mHaWjUgRbP
31Wue9trM9aZ7mGenvsew5n9hjQ7oASecXpFzN/dN2IrXORN7kvgccEByvj3KI22
QOpWOdr3sWzgADZF+dBp6gSIcGU4CyRqNWkI407H/A/m/wX+ZtZOg5PSV/Y1YgpA
x8e6lqClrJh0ie9NhHWRyVGvdPjMlZfZ7acrt3EuzQ+EzcjIt2a9lzzsQTLkCTEh
0LMP4thjQ2SxmBzpE4ENMjNQvHtv4ZojV4s25LKjta7qvcgeeAbGgOp1ov3KtVFw
spifcdLuKj+AUrmQHyxeCEW5R46vrJUEcH+pnCvHTvJ4BSaCMyji7nKaqNK5LFHF
VtqVvrkBzzcpAR5hQpqi4HowGo9KGmuCAEekPoNgXFF0jKQZZHCiWTjAE7353L3t
MDaDEFTwQH11tZYXL8mSib8CAwEAAQ==
-----END PUBLIC KEY-----`;
