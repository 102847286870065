import React from 'react';
import VTable from '../../../Venti-UI-Kit/VTable/VTable';
import { useTopCustomersTable } from './TopCustomersTable.hook';

const TopCustomersTable = ({ producerId }) => {
  const { columns, metrics, page, handleChangePage } = useTopCustomersTable(producerId);
  return (
    <VTable
      title="Mejores usuarios"
      data={metrics}
      columns={columns}
      page={page}
      onPageChange={handleChangePage}
    />
  );
};

export default TopCustomersTable;
