import { useRef, useState } from 'react';
import { getTextHelperText, getNumericHelperText } from '../../../Venti-UI-Kit/Input/Errors';
import { getRadioHelperText } from '../../../Venti-UI-Kit/RadioButtons/Errors';
import { NOTIFICATION_VARIANT, TICKET_TYPE } from '../../../constants/types';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';

export const useTicketType = (initialState) => {
  const { showNotification } = useNotifications();
  const [ticketType, setTicketType] = useState(initialState);
  const [showErrors, setShowErrors] = useState(false);
  const ticketTypeErrors = useRef({
    name: null,
    price: null,
    priceWithLemon: null,
    maxQuantity: null,
    description: null,
    visibilityMode: null,
    activityStatus: null,
    type: null,
    maxQuantityPerOrder: null,
    groupSize: null,
  });

  const errorHelpers = {
    name: getTextHelperText,
    price: getNumericHelperText,
    priceWithLemon: getNumericHelperText,
    maxQuantity: getNumericHelperText,
    visibilityMode: getRadioHelperText,
    activityStatus: getRadioHelperText,
    type: getRadioHelperText,
  };

  const handleErrors = (name, value) => {
    if (!errorHelpers[name]) return;

    const errorMessage = errorHelpers[name](value) || null;
    ticketTypeErrors.current = { ...ticketTypeErrors.current, [name]: errorMessage };
    const shouldShowError = Object.values(ticketTypeErrors.current).some((error) => error !== null);
    setShowErrors(shouldShowError);
  };

  const validateTicketType = () => {
    let isValid = true;

    const mandatoryFields = [
      'name',
      'price',
      'priceWithLemon',
      'maxQuantity',
      'maxQuantityPerOrder',
      'type',
    ];

    isValid = mandatoryFields.every((field) => !!ticketType[field]);

    if (!isValid) {
      mandatoryFields.forEach((field) => handleErrors(field, ticketType[field]));
      showNotification('Faltan completar campos', NOTIFICATION_VARIANT.ERROR);
    }

    const { price, priceWithLemon, type, groupSize } = ticketType;

    const ticketPrice = Number(price);
    const ticketLemonPrice = Number(priceWithLemon);

    if (ticketPrice < 0 || ticketLemonPrice < 0) {
      isValid = false;
      showNotification('El precio debe ser mayor que cero', NOTIFICATION_VARIANT.ERROR);
    }

    if (ticketPrice > 0 && ticketLemonPrice === 0) {
      isValid = false;
      showNotification('El precio con Lemon debe ser mayor que cero', NOTIFICATION_VARIANT.ERROR);
    }

    if (showErrors) {
      isValid = false;
      showNotification('Los campos son incorrectos', NOTIFICATION_VARIANT.ERROR);
    }

    if (type === TICKET_TYPE.TABLE && !groupSize) {
      isValid = false;
      showNotification(
        'El ticket es del tipo "Acceso múltiple", la cantidad de entradas por ticket es obligatorio',
        NOTIFICATION_VARIANT.ERROR
      );
    }

    return isValid;
  };

  const validateTicketTypeSection = (eventHasStadium) => {
    let isValid = true;
    if (!eventHasStadium) return isValid;

    const { section, maxQuantity } = ticketType;

    if (!section) {
      isValid = false;
      showNotification(
        'No seleccionaste un sector del estadio para el ticket',
        NOTIFICATION_VARIANT.ERROR
      );
    }

    const otherSectionTickets = section.ticketTypes.filter(({ id }) => id !== ticketType.id);
    const usedStock = otherSectionTickets.reduce(
      (usedStock, { maxQuantity }) => usedStock + maxQuantity,
      0
    );

    if (usedStock + parseInt(maxQuantity, 10) > section.capacity) {
      isValid = false;
      showNotification('Superaste el stock disponible del sector', NOTIFICATION_VARIANT.ERROR);
    }

    return isValid;
  };

  return {
    ticketType,
    setTicketType,
    validateTicketType,
    validateTicketTypeSection,
    handleErrors,
    showErrors,
    ticketTypeErrors,
  };
};
