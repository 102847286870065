import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    searcherContainer: {
      height: 40,
      width: '100%',
      borderRadius: 8,
      padding: '0 16px',
      position: 'relative',
      backgroundColor: '#FFFFFF',
      overflow: 'hidden',
      flexDirection: 'row !important',
      alignItems: 'center',
    },
    autocompleteContainer: {
      width: '100%',
      height: '100%',
    },
    autocompletePopper: {
      marginTop: '15px !important',
      zIndex: 1300,
      maxWidth: 700,
      width: '100% !important',
      left: '0px !important',
      padding: '0 16px !important',
    },
  });

export default styles;
