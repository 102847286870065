import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    title: {
      fontWeight: 600,
    },
  });

export default styles;
