import React, { useMemo } from 'react';
import { IconButton, useTheme, withStyles } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { debounce } from '@mui/material';
import styles from './SearcherStyles';
import { useForm } from '../../../hooks/useForm';
import TextInput from '../../../Venti-UI-Kit/Inputs/TextInput';

const Searcher = ({ classes, onSubmit, placeholder, initialValue }) => {
  const theme = useTheme();
  const { formState, handleInputChange } = useForm({
    searchQuery: {
      value: initialValue || '',
    },
  });

  const handleChange = useMemo(
    () => debounce(({ target: { value } }) => onSubmit(value), 500),
    [onSubmit]
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formState.searchQuery.value);
  };

  return (
    <form onSubmit={handleSubmit} onChange={handleChange} className={classes.searcherContainer}>
      <TextInput
        placeholder={placeholder}
        onChange={handleInputChange}
        name="searchQuery"
        defaultValue={formState.searchQuery.value}
        endAdornment={
          <IconButton
            type="submit"
            style={{ color: theme.palette.text.secondary }}
            onClick={handleSubmit}
          >
            <SearchOutlined />
          </IconButton>
        }
      />
    </form>
  );
};

export default withStyles(styles)(Searcher);
