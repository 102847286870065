import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    modalContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 15,

      '& .modalBoxContainer': {
        width: 328,
        height: 281,
        padding: '48px 16px 16px',
        position: 'relative',
        background: theme.palette.grey[50],
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        textAlign: 'center',
      },

      '& .modalTextContainer': {
        display: 'flex',
        gap: 24,
        flexDirection: 'column',
      },

      '& .modalTitle': {
        fontWeight: 600,
        fontSize: 24,
        lineHeight: '28px',
        color: theme.palette.text.primary,
      },

      '& .modalSubtitle': {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '19px',
      },
    },
  });

export default styles;
