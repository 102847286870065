import React, { useEffect, useState } from 'react';
import { MenuItem, Select, withStyles } from '@material-ui/core';
import styles from './InvitationSelectorStyles';
import { TICKET_ACTIVITY_STATUS, TICKET_TYPE } from '../../constants/types';
import { handleRequestHelper } from '../../utils/helpers';
import { useNotifications } from '../../Providers/NotificationsProvider/NotificationsProvider';
import api from '../../api/api';

const NO_INVITATIONS_MESSAGE = '';
const NO_INVITATION_ID = -1;

const InvitationSelector = ({ classes, eventId, onInvitationIdChange }) => {
  const { showNotification } = useNotifications();
  const [invitations, setInvitations] = useState([]);
  const [selectedInvitation, setSelectedInvitation] = useState({
    id: NO_INVITATION_ID,
    name: NO_INVITATIONS_MESSAGE,
  });

  const getEventInvitations = async () => {
    await handleRequestHelper({
      endpoint: () => api.getEvent(eventId),
      showNotification,
      onFailure: () => onInvitationIdChange(NO_INVITATION_ID),
      onSuccess: ({ event }) => {
        const activeInvitations = event.TicketTypes.filter(
          ({ activityStatus, type }) =>
            activityStatus === TICKET_ACTIVITY_STATUS.ACTIVE && type === TICKET_TYPE.INVITATION
        ).map(({ name, id }) => ({ name, id }));

        const invitation = activeInvitations.length ? activeInvitations[0] : selectedInvitation;

        setInvitations(activeInvitations);
        setSelectedInvitation(invitation);
        onInvitationIdChange(invitation.id);
      },
    });
  };

  useEffect(() => {
    getEventInvitations();
  }, []);

  const handleInvitationChange = (event) => {
    const { value: invitationId } = event.target;
    const selectedInvitation = invitations.find(({ id }) => id === invitationId);
    setSelectedInvitation(selectedInvitation);
    onInvitationIdChange(selectedInvitation.id);
  };

  return (
    <Select
      className={classes.selector}
      onChange={handleInvitationChange}
      id="invitationSelector"
      name="invitationSelector"
      value={selectedInvitation.id}
      disableUnderline
      disabled={!invitations.length}
    >
      {invitations?.length ? (
        invitations.map(({ name, id }, index) => (
          <MenuItem className={classes.menuItem} key={index} value={id}>
            {name}
          </MenuItem>
        ))
      ) : (
        <MenuItem className={classes.menuItem} value={NO_INVITATION_ID}>
          {NO_INVITATIONS_MESSAGE}
        </MenuItem>
      )}
    </Select>
  );
};

export default withStyles(styles)(InvitationSelector);
