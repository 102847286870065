import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    flagBox: {
      borderRadius: 100,
      border: `1px solid ${theme.palette.grey[600]}`,
    },

    flag: {
      objectFit: 'cover',
    },
  });

export default styles;
