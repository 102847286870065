import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './ProgressCircleStyles';

const ProgressCircle = ({
  classes,
  size = 58,
  totalValue,
  currentValue,
  strokeWidth = 4,
  clockwise = false,
}) => {
  const currentPercent = totalValue > 0 ? ((currentValue * 100) / totalValue).toFixed(0) : 0;
  const viewBox = `0 0 ${size} ${size}`;
  const radius = (size - strokeWidth - 2) / 2;
  const circumference = radius * Math.PI * 2;
  const dash = (currentPercent * circumference) / 100;
  const cx = clockwise ? size / 2 : -size / 2;
  const transform = clockwise
    ? `scale(1, 1), rotate(-90 ${size / 2} ${size / 2})`
    : `scale(1, -1), rotate(90 ${size / 2} ${size / 2})`;

  return (
    <svg width={size} height={size} viewBox={viewBox}>
      <circle
        fill="none"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={strokeWidth}
        className={classes.background}
      />
      <circle
        fill="none"
        cx={cx}
        cy={size / 2}
        r={radius}
        strokeWidth={strokeWidth}
        transform={transform}
        strokeLinecap="round"
        strokeDasharray={[dash, circumference - dash]}
        className={classes.percertageBar}
      />
      <text
        fontSize={14}
        x="50%"
        y="50%"
        dy={5}
        textAnchor="middle"
        className={classes.text}
      >{`${currentPercent}%`}</text>
    </svg>
  );
};

export default withStyles(styles)(ProgressCircle);
