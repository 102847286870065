import React from 'react';
import { useProducerForm } from './ProducerForm.hook';
import LoadingContainer from '../../LoadingContainer/LoadingContainer';
import AppearanceForm from './AppearanceForm/AppearanceForm';
import SocialsForm from './SocialsForm/SocialsForm';
import GeneralInformationForm from './GeneralInformationForm/GeneralInformationForm';
import Button from '../../Buttons/Button/Button';
import PinnedEventForm from './PinnedEventForm/PinnedEventForm';

const ProducerForm = ({ producerId, onSubmit, submitLabel }) => {
  const { formState, events, isLoading, isSubmiting, handleSubmit, handleInputChange } =
    useProducerForm(onSubmit, producerId);

  return (
    <LoadingContainer isLoading={isLoading}>
      <GeneralInformationForm onChange={handleInputChange} formState={formState} />
      <PinnedEventForm formState={formState} events={events} onChange={handleInputChange} />
      <AppearanceForm
        onChange={handleInputChange}
        formState={formState}
        producerName={formState?.name.value}
      />
      <SocialsForm onChange={handleInputChange} formState={formState} />
      <Button fullwidth={false} onClick={handleSubmit} title={submitLabel} loading={isSubmiting} />
    </LoadingContainer>
  );
};

export default ProducerForm;
