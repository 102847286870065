import React from 'react';
import { withStyles } from '@material-ui/core';
import { Accordion, AccordionSummary, Checkbox, FormControlLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './EventsAccessesAccordionStyles';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';

const EventsAccessesAccordion = ({
  index,
  producerId,
  events,
  getAmountOfSelectedEventsByProducerId,
  modifyAllSelectedProducerEventsStates,
  selectedProducersEvents,
  modifySelectedProducerEventState,
  classes,
  expanded,
  onChange,
}) => {
  return (
    <Accordion
      aria-controls="accordion"
      className={classes.formItemContainer}
      expanded={expanded}
      onChange={onChange}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id={index}>
        <VTypography variant="body2">
          Eventos asignados <b>({getAmountOfSelectedEventsByProducerId(producerId)})</b>
        </VTypography>
      </AccordionSummary>
      <FormControlLabel
        className={`${classes.formItemContainer} ${classes.checkboxContainer}`}
        labelPlacement="start"
        label={
          <VTypography
            className={classes.checkboxLabel}
            align="left"
            variant="body2"
            linesNumber={1}
          >
            Dar acceso a todos los eventos
          </VTypography>
        }
        control={
          <Checkbox
            checked={selectedProducersEvents[producerId]?.allEvents}
            className={classes.checkbox}
            onChange={({ target: { checked } }) =>
              modifyAllSelectedProducerEventsStates(checked, producerId)
            }
          />
        }
      />
      {events?.map(({ id: eventId, name }) => (
        <FormControlLabel
          key={name}
          name={name}
          label={
            <VTypography
              className={classes.checkboxLabel}
              align="left"
              variant="body2"
              linesNumber={1}
            >
              {name}
            </VTypography>
          }
          className={`${classes.formItemContainer} ${classes.checkboxContainer}`}
          labelPlacement="start"
          control={
            <Checkbox
              checked={selectedProducersEvents[producerId][eventId]}
              className={classes.checkbox}
              onChange={({ target: { checked } }) =>
                modifySelectedProducerEventState(checked, producerId, eventId)
              }
            />
          }
        />
      ))}
    </Accordion>
  );
};

export default withStyles(styles)(EventsAccessesAccordion);
