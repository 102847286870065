import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import styles from './CustomTableStyles';

const DEFAULT_ROWS_PER_PAGE = 10;

const CustomTable = ({ classes, data, columns, options, noMatch }) => {
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

  const customOptions = {
    pagination: true,
    cellAlign: 'center',
    rowsPerPageOptions: [10, 25, 100],
    displayRowLabel: 'Filas por página:',
    ...options,
  };

  const { pagination, cellAlign, rowsPerPageOptions, displayRowLabel } = customOptions;

  const handleChangePage = (event, newPage) => {
    setTablePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setTablePage(0);
  };

  return (
    <Paper elevation={4} className={classes.paperContainer}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader className={classes.table} aria-label="customized table">
          <TableHead className={classes.tableHeader}>
            <TableRow className={classes.tableRow}>
              {columns.map(({ value, label }, index) => (
                <TableCell
                  className={classes.tableCell}
                  align={cellAlign}
                  key={value + index}
                  scope="row"
                >
                  <Typography className={classes.headerText}>{label}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!data.length && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <Typography className={`${classes.cellText} ${classes.noMatchText}`}>
                    {noMatch}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {data
              .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow className={classes.tableRow} hover key={index}>
                  {Object.values(row).map((property, index) => (
                    <TableCell
                      className={classes.tableCell}
                      key={property + index}
                      align={cellAlign}
                      scope="row"
                    >
                      <Typography className={classes.cellText}>{property}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={tablePage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={classes.pagination}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          labelRowsPerPage={displayRowLabel}
        />
      )}
    </Paper>
  );
};

export default withStyles(styles)(CustomTable);
