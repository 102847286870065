const styles = (theme) => ({
  sectionColorContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.grey[200],
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputLabel: {
    alignSelf: 'start',
  },
});

export default styles;
