import { useAuth } from '../../Providers/AuthProvider/AuthProvider';
import { USER_ROLES } from '../../constants/types';

export const useUserMenu = () => {
  const { user } = useAuth();

  const isSuperAdmin = user.role === USER_ROLES.SUPER_ADMIN;
  const isSeller = [USER_ROLES.SELLER, USER_ROLES.SELLER_WITH_CASH].includes(user.role);
  const isCustomer = user.role === USER_ROLES.CUSTOMER;
  const hasManyProducers = isSuperAdmin || user.producers?.length > 1;

  const superAdminOptions = [
    {
      label: 'Crear productora',
      href: '/backoffice/productoras/crear',
      leftIcon: 'add_box',
    },
  ];

  const backofficeUserOptions = [
    hasManyProducers
      ? {
          label: isSeller ? 'Seleccionar productora' : 'Administrar productoras',
          href: '/backoffice/productoras',
          leftIcon: 'dashboard',
        }
      : {
          label: isSeller ? 'Ver productora' : 'Administrar productora',
          href: `/backoffice/productoras/${user.producerId}`,
          leftIcon: 'dashboard',
        },
  ];

  const defaultOptions = [
    { label: 'Mis Tickets', href: '/mis-tickets', leftIcon: 'confirmation_number' },
    { label: 'Mis Datos', href: '/mis-datos', leftIcon: 'person' },
  ];

  const menuItems = [
    ...(isSuperAdmin ? superAdminOptions : []),
    ...(!isCustomer ? backofficeUserOptions : []),
    ...defaultOptions,
  ];

  return { menuItems };
};
