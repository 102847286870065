import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    paymentMethodSelectorContainer: {
      border: `1px solid ${theme.palette.text.primary}`,
      borderRadius: 12,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignSelf: 'center',

      '& .MuiFormGroup': {
        '&-root': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        },
      },

      '& .MuiFormControlLabel': {
        '&-root': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '20px 14px',
          margin: 0,
          width: '100%',

          '&:not(:first-child)': {
            borderTop: `1px solid ${theme.palette.text.primary}`,
          },
        },
      },

      '& .MuiRadio-root': {
        padding: 0,
        color: theme.palette.grey[400],

        '&.Mui-checked': {
          color: theme.palette.primary.main,
        },
      },

      '& .methodLabel': {
        display: 'flex',
        alignItems: 'center',
        gap: 14,
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '16px',
      },

      '& .paymentMethodIcon': {
        '& > path': {
          fill: theme.palette.text.primary,
        },
      },

      '& .mercadoPagoIcon': {
        height: 25,
        width: 30,
      },

      '& .galiciaIcon': {
        height: 25,
        width: 65,
      },
    },
  });

export default styles;
