import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography, withStyles } from '@material-ui/core';
import styles from './ClaimNFTPage.styles';
import NFTInput from '../../../../Components/NFTInput/NFTInput';
import { NFT_STATUS, NOTIFICATION_VARIANT } from '../../../../constants/types';
import ClaimedNFTList from '../ClaimedNFTList/ClaimedNFTList';
import { sortObjectArray } from '../../../../utils/utils';
import PageContainer from '../../../../Components/PageContainer/PageContainer';
import { handleRequestHelper } from '../../../../utils/helpers';
import { useNotifications } from '../../../../Providers/NotificationsProvider/NotificationsProvider';
import api from '../../../../api/api';

const ClaimNFTPage = ({ classes, urlParams }) => {
  const { showNotification } = useNotifications();
  const [orderNFTsClaimed, setOrderNFTsClaimed] = useState([]);
  const [orderNFTsUnclaimable, setOrderNFTsUnclaimable] = useState([]);
  const [orderNFTsClaimable, setOrderNFTsClaimable] = useState([]);
  const [orderId, setOrderId] = useState();
  const [loading, setLoading] = useState(true);
  const [nfts, setNfts] = useState([]);
  const { externalId } = urlParams;

  const getNFTs = async () => {
    await handleRequestHelper({
      endpoint: () => api.getNFTsByExternalId(externalId),
      options: [externalId],
      onFailure: () =>
        showNotification('Algo salió mal, vuelve a intentar', NOTIFICATION_VARIANT.ERROR),
      onSuccess: ({ nfts, orderId }) => {
        const sortedNFTs = sortObjectArray(nfts, 'orderCountNumber');
        setNfts(sortedNFTs);
        setOrderId(orderId);
        setOrderNFTsUnclaimable(
          sortedNFTs.filter(({ status }) => status === NFT_STATUS.UNCLAIMABLE)
        );
        setOrderNFTsClaimable(sortedNFTs.filter(({ status }) => status === NFT_STATUS.CLAIMABLE));
        setOrderNFTsClaimed(sortedNFTs.filter(({ status }) => status === NFT_STATUS.ASIGNED));
      },
    });
    setLoading(false);
  };

  useEffect(() => getNFTs(), []);

  return (
    <PageContainer withFooter>
      <div className={classes.pageContent}>
        {loading ? (
          <CircularProgress size={30} />
        ) : nfts.length ? (
          <>
            <Typography className={classes.title}>Reclamá tu/s NFT/s</Typography>
            <Typography className={classes.subtitle}>
              Completá tu lemontag y recibí tu NFT directo en tu Lemon App.
            </Typography>
            <div className={classes.qrInputsContainer}>
              <Typography variant="h6" className={classes.orderText}>
                Orden N°{orderId}
              </Typography>
              {orderNFTsClaimable.length || orderNFTsUnclaimable.length ? (
                <>
                  {orderNFTsClaimable?.map((nft) => (
                    <NFTInput readyToClaim key={nft.qrId} nft={nft} onClaim={getNFTs} />
                  ))}
                  {orderNFTsUnclaimable?.map((nft) => (
                    <NFTInput key={nft.qrId} nft={nft} />
                  ))}
                </>
              ) : (
                <Typography>¡Ya reclamaste todos tus NFTs!</Typography>
              )}
            </div>
            <div className={classes.claimedTicketsContainer}>
              <Typography variant="h6" className={classes.title}>
                Reclamados
              </Typography>
              <div className={classes.claimedSubtitleContainer}>
                <Typography className={classes.claimedListHeaderText}>Ticket</Typography>
                <Typography className={classes.claimedListHeaderText}>
                  {orderNFTsClaimed.length}/{nfts.length}
                </Typography>
              </div>
              <ClaimedNFTList data={orderNFTsClaimed} />
            </div>
          </>
        ) : (
          <>
            <Typography className={classes.title}>
              Esta órden no está asociada a un NFT 😔
            </Typography>
            <Typography className={classes.noNFTSubtitle}>
              ¡Lo sentimos! Esta orden no está asociada a un NFT.
            </Typography>
          </>
        )}
      </div>
    </PageContainer>
  );
};

export default withStyles(styles)(ClaimNFTPage);
