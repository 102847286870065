import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { Box } from '@mui/material';
import styles from './MailValidationPagesStyles';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import Button from '../../../Components/Buttons/Button/Button';
import { ReactComponent as Image } from '../../../assets/images/errorMailValidation.svg';
import history from '../../../appHistory';

const ErrorMailValidationPage = ({ classes }) => {
  const goToSignup = () => history.push('/signup');

  return (
    <PageContainer>
      <Box className={classes.mailValidationPage}>
        <Box display="flex" gap={2} flexDirection="column">
          <Box className="mailValidationPageImage">
            <Image />
          </Box>

          <Box display="flex" gap={2} flexDirection="column">
            <Typography className="mailValidationPageTitle">
              El enlace de verificación expiró
            </Typography>
            <Typography className="mailValidationPageText16">
              Por favor, volvé a crear tu cuenta para disfrutar todos los beneficios. Recordá que
              tenés solo 24 horas para verificar tu mail. ¡Te esperamos!
            </Typography>
          </Box>
        </Box>

        <Button title="Crear cuenta nuevamente" onClick={goToSignup} />
      </Box>
    </PageContainer>
  );
};

export default withStyles(styles)(ErrorMailValidationPage);
