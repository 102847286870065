import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    pageContentContainer: {
      gap: 24,
      padding: '0 16px',
      display: 'flex',
      flexDirection: 'column',
      margin: '0 auto',
      [theme.breakpoints.down('md')]: {
        width: 'unset',
        margin: 'unset',
      },
    },
  });

export default styles;
