import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    container: {
      '& > div': {
        overflow: 'hidden',
      },
      '& > div:before': {
        opacity: 0,
      },
      '& > div:first-child': {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
      },
      '& > div:last-child': {
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
      },
    },
    accordionSection: {
      backgroundColor: `${theme.palette.grey[50]} !important`,
      '& .MuiSvgIcon-fontSizeMedium': {
        color: theme.palette.text.primary,
      },
    },
    accordionContainer: {
      width: '90%',
      margin: 'auto !important',
      boxShadow: 'none !important',
      borderRadius: 20,
      backgroundColor: `${theme.palette.grey[50]} !important`,
    },
    sectionTitle: {
      fontFamily: `${theme.typography.fontFamily} !important`,
      fontWeight: '500 !important',
      color: theme.palette.text.primary,
      textAlign: 'left',
    },
    cardsContainer: {
      backgroundColor: 'transparent !important',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 20,
    },
  });

export default styles;
