import React from 'react';
import { withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import styles from './ProducerCard.styles';
import Button from '../../../../Components/Buttons/Button/Button';
import history from '../../../../appHistory';
import VIcon from '../../../../Venti-UI-Kit/VIcon/VIcon';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';
import { DEFAULT_IMAGES } from '../../../../Components/Forms/ProducerForm/ProducerForm.constants';

const ProducerCard = ({ classes, producer }) => {
  const { id, name, logoImg, urlName, description } = producer;
  const navigateToProducerPage = () => history.push(`/organizadores/${urlName}`);
  const navigateToProducerDashboard = () => history.push(`/backoffice/productoras/${id}`);
  const defaultProducerLogoImg = logoImg || DEFAULT_IMAGES.LOGO_IMAGE;

  return (
    <Stack className={classes.producerCardContainer} direction={{ xs: 'column', sm: 'row' }}>
      <Stack direction="row" gap={2} width="100%">
        <img
          src={defaultProducerLogoImg}
          alt="producer logo"
          className={classes.producerCardImage}
        />
        <Stack direction="column">
          <VTypography variant="subtitle3" linesNumber={1}>
            {name}
          </VTypography>
          <VTypography variant="subtitle6" linesNumber={2}>
            {description}
          </VTypography>
        </Stack>
      </Stack>
      <Stack gap={1} width={{ xs: '100%', sm: 'unset' }}>
        <Button
          color="alternative"
          size="small"
          icon={<VIcon name="dashboard" />}
          title="Gestionar"
          onClick={navigateToProducerDashboard}
        />
        <Button
          variant="outlined"
          color="alternative"
          size="small"
          icon={<VIcon name="home" />}
          title="Ver Perfil"
          onClick={navigateToProducerPage}
        />
      </Stack>
    </Stack>
  );
};

export default withStyles(styles)(ProducerCard);
