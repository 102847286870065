import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './PowerByVentiStyles';
import { ReactComponent as Logo } from '../../assets/logos/ventiLogo.svg';
import VTypography from '../../Venti-UI-Kit/VTypography/VTypography';

const PowerByVenti = () => {
  return (
    <VTypography variant="overline" align="center">
      Powered by <Logo />
    </VTypography>
  );
};

export default withStyles(styles)(PowerByVenti);
