import React, { useRef } from 'react';
import Slider from 'react-slick';
import { useTheme, withStyles } from '@material-ui/core';
import { Box, Button } from '@mui/material';
import styles from './CarouselStyles';

import useWindowDimensions from '../../hooks/useWindowDimensions';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import VIcon from '../../Venti-UI-Kit/VIcon/VIcon';

const Carousel = ({ classes, children }) => {
  const { width } = useWindowDimensions();
  const theme = useTheme();
  const isMobile = width < theme.breakpoints.values.md;
  const sliderRef = useRef();
  const hasOnlyOneItemToShow = children.length === 1;

  return (
    <Box
      className={classes.carouselContent}
      sx={{
        ...(hasOnlyOneItemToShow && {
          '& .slick-track': {
            justifyContent: 'center',
          },
        }),
      }}
    >
      {!isMobile && !hasOnlyOneItemToShow && (
        <Box className={classes.arrowsContainer}>
          <Button
            disableRipple
            onClick={() => sliderRef?.current?.slickPrev()}
            className={classes.arrowButton}
          >
            <VIcon name="arrow_back" color="#FFFFFF" />
          </Button>
          <Button
            disableRipple
            onClick={() => sliderRef?.current?.slickNext()}
            className={classes.arrowButton}
          >
            <VIcon name="arrow_forward" color="#FFFFFF" />
          </Button>
        </Box>
      )}
      <Slider
        infinite
        ref={sliderRef}
        speed={800}
        variableWidth
        arrows={false}
        slidesToShow={1}
        centerMode
        centerPadding={0}
        autoplay
        autoplaySpeed={5000}
      >
        {children}
      </Slider>
    </Box>
  );
};

export default withStyles(styles)(Carousel);
