import JSEncrypt from 'jsencrypt';
import { getEnviroment } from '../../../../../utils/utils';
import { ENVIRONMENTS } from '../../../../../constants/types';
import {
  LOCAL_ENCRYPT_PUBLIC_KEY,
  PRODUCTION_ENCRYPT_PUBLIC_KEY,
  STAGING_ENCRYPT_PUBLIC_KEY,
} from '../../../../../constants/publicKeys';

export const encrypt = new JSEncrypt();

const getPublicKey = () => {
  const environment = getEnviroment();
  switch (environment) {
    case ENVIRONMENTS.PRODUCTION:
      return PRODUCTION_ENCRYPT_PUBLIC_KEY;
    case ENVIRONMENTS.STAGING:
      return STAGING_ENCRYPT_PUBLIC_KEY;
    default:
      return LOCAL_ENCRYPT_PUBLIC_KEY;
  }
};

encrypt.setPublicKey(getPublicKey());
