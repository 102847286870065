import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { Box, Stack } from '@mui/material';
import AccessInput from '../../Inputs/AccessInput';
import AccessLabel from '../../Inputs/AccessLabel';
import Button from '../../../Venti-UI-Kit/Buttons/Button';
import api from '../../../api/api';
import { REQUEST_RESPONSE, USER_PERMISSIONS, USER_ROLES } from '../../../constants/types';
import permissions from '../../../Providers/Permissions/Permissions';
import { handleRequestHelper } from '../../../utils/helpers';
import { validateUserRole } from '../../../utils/utils';

class UploadOrderCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      name: '',
      lastname: '',
      dni: '',
      mail: '',
      customerId: undefined,
      quantity: 1,
      ticketTypeId: '',
      currency: undefined,
      loading: false,
      sellerId: props.user.id,
      sellersWithCash: permissions.isSellerWithCash(props.user.role) ? [props.user] : undefined,
    };
  }

  async componentDidMount() {
    const userRole = this.props.user.role;
    if (!permissions.isAdmin(userRole)) return;

    await handleRequestHelper({
      endpoint: () =>
        api.getAllProducerUsers(this.props.producerId, {
          roles: [USER_ROLES.SELLER_WITH_CASH, USER_ROLES.ADMIN],
        }),
      showNotification: this.props.showNotification,
      onSuccess: ({ users }) =>
        this.setState({
          sellersWithCash: validateUserRole(userRole, USER_PERMISSIONS.SUPER_ADMIN)
            ? [this.props.user, ...users]
            : users,
        }),
    });
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleTicketChange = (event) => {
    const id = event.target.value;
    const item = this.props.ticketTypes;
    const filteredItem = item.filter((item) => item.id === id);
    const obj = { ticketTypeId: id };
    this.setState({
      ...obj,
      currency: filteredItem[0]?.currency,
    });
  };

  handleSellerChange = (event) => {
    this.setState({
      sellerId: event.target.value,
    });
  };

  cleanInput = () => {
    this.setState({
      name: '',
      lastname: '',
      dni: '',
      mail: '',
      quantity: 1,
      ticketTypeId: '',
      phone: '',
      sellerId: this.props.user.id,
    });
  };

  async uploadOrder() {
    const { eventId } = this.props;
    const id = this.state.ticketTypeId;
    const items = this.props.ticketTypes;
    const picked = items.find((item) => item.id === id);

    if (!permissions.canSellCash(this.props.user.role)) {
      return this.props.showNotification('No estás habilitado para realizar esta acción', 'error');
    }

    if (!this.state.name || !this.state.mail || !id) {
      return this.props.showNotification('Le falta completar los campos requeridos', 'warning');
    }

    if (picked.status === 'inactive') {
      return this.props.showNotification(
        `Los tickets ${picked.name} no se encuentran disponibles`,
        'warning'
      );
    }

    if (isNaN(this.state.dni) || isNaN(this.state.phone)) {
      return this.props.showNotification(
        'Recuerde que el DNI y el teléfono deben ser numericos',
        'warning'
      );
    }

    if (this.state.quantity <= 0) {
      return this.props.showNotification('La cantidad de entradas debe ser al menos 1', 'warning');
    }

    if (isNaN(this.state.quantity) || this.state.quantity > 10) {
      return this.props.showNotification('La cantidad de entradas debe ser menor a 10', 'warning');
    }

    if (!this.state.sellerId) {
      return this.props.showNotification('Tenés que asignar la orden a un usuario', 'warning');
    }

    const order = {
      name: `${this.state.name} - ${this.state.lastname}`,
      phone: this.state.phone,
      dni: `DNI - ${this.state.dni}`,
      mail: this.state.mail.trim(),
      customerId: this.state.customerId,
      currency: this.state.currency,
      sellerId: this.state.sellerId,
    };

    const ticketType = this.props.ticketTypes.find((t) => t.id === this.state.ticketTypeId);
    let ticketQuantity = Number(this.state.quantity);
    if (ticketType?.groupSize > 1) {
      ticketQuantity *= ticketType.groupSize;
    }
    const ticket = { ticketTypeId: this.state.ticketTypeId };
    order.qrs = Array(ticketQuantity).fill(ticket);
    order.eventId = eventId;

    try {
      this.setState({ loading: true });
      const response = await api.createOrder(order);
      if (response.status === REQUEST_RESPONSE.ERROR) throw new Error(response.esmessage);
      this.props.showNotification('Se creo a la orden correctamente', 'success');
      this.setState({ loading: false });
      this.cleanInput();
    } catch (err) {
      this.setState({ loading: false });
      this.props.showNotification(err.message || 'error', 'error');
    }
  }

  render() {
    const { classes } = this.props;
    const disabledSubmitButton =
      !this.state.ticketTypeId ||
      !this.state.name ||
      !this.state.lastname ||
      !this.state.mail ||
      !this.state.sellerId ||
      !this.state.quantity ||
      !this.state.dni;

    return (
      <Stack gap={2} alignItems="center" maxWidth={900} width="100%">
        <Stack
          className={classes.paper}
          component="form"
          gap={2}
          width="100%"
          onSubmit={this.onSubmit}
          alignItems="center"
        >
          <Stack gap={2} alignItems="start" width="100%">
            <Typography className={classes.title}>
              <EditIcon /> Info
            </Typography>
            <Box className={classes.infoFormContainer}>
              <FormControl required>
                <AccessInput
                  id="name"
                  name="name"
                  autoComplete="off"
                  onChange={this.handleChange('name')}
                  disableUnderline
                  value={this.state.name}
                />
                <AccessLabel htmlFor="name">Nombre</AccessLabel>
              </FormControl>

              <FormControl required>
                <AccessInput
                  id="lastname"
                  name="lastname"
                  autoComplete="off"
                  onChange={this.handleChange('lastname')}
                  disableUnderline
                  value={this.state.lastname}
                />
                <AccessLabel htmlFor="lastname">Apellido</AccessLabel>
              </FormControl>

              <FormControl>
                <AccessInput
                  id="dni"
                  name="dni"
                  autoComplete="off"
                  onChange={this.handleChange('dni')}
                  disableUnderline
                  value={this.state.dni}
                />
                <AccessLabel htmlFor="dni">DNI</AccessLabel>
              </FormControl>

              <FormControl required>
                <AccessInput
                  id="mail"
                  name="mail"
                  autoComplete="off"
                  onChange={this.handleChange('mail')}
                  disableUnderline
                  value={this.state.mail}
                />
                <AccessLabel htmlFor="mail">Mail</AccessLabel>
              </FormControl>

              <FormControl>
                <AccessInput
                  id="phone"
                  name="phone"
                  autoComplete="off"
                  onChange={this.handleChange('phone')}
                  disableUnderline
                  value={this.state.phone}
                />
                <AccessLabel htmlFor="phone">Teléfono</AccessLabel>
              </FormControl>
            </Box>
          </Stack>
          <Stack gap={2} alignItems="start" width="100%">
            <Stack>
              <Typography className={classes.title}>
                <FormatListNumberedIcon /> Cantidad
              </Typography>
              <Typography className={classes.subtitle}>
                Si el ticket es una mesa serán cantidad de mesas
              </Typography>
            </Stack>
            <FormControl>
              <AccessInput
                id="quantity"
                className={classes.eachInput}
                name="quantity"
                autoComplete="off"
                onChange={this.handleChange('quantity')}
                disableUnderline
                value={this.state.quantity}
              />
              <AccessLabel htmlFor="quantity">Cantidad de Tickets</AccessLabel>
            </FormControl>
          </Stack>

          {this.props.ticketTypes && (
            <Stack gap={2} alignItems="start" width="100%">
              <Typography className={classes.title}>
                <ConfirmationNumberIcon /> Tipo de ticket
              </Typography>
              <FormControl required fullWidth>
                <Select
                  className={classes.ticketType}
                  name="ticketType"
                  onChange={this.handleTicketChange}
                  value={this.state.ticketTypeId}
                  disableUnderline
                >
                  {this.props.ticketTypes.map(({ name, id }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          )}

          {this.state.sellersWithCash && (
            <Stack gap={2} alignItems="start" width="100%">
              <Typography className={classes.title}>
                <PersonIcon /> Asignar la orden a un usuario
              </Typography>
              <FormControl required fullWidth>
                <Select
                  className={classes.ticketType}
                  name="seller"
                  onChange={this.handleSellerChange}
                  value={this.state.sellerId}
                  disableUnderline
                >
                  {this.state.sellersWithCash.map(({ alias, id }) => (
                    <MenuItem key={id} value={id}>
                      {alias}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          )}
        </Stack>
        {!this.state.loading ? (
          <Button
            color="main"
            className={classes.button}
            onClick={this.uploadOrder.bind(this)}
            disabled={disabledSubmitButton}
          >
            Enviar
          </Button>
        ) : (
          <CircularProgress />
        )}
      </Stack>
    );
  }
}

const styles = (theme) => ({
  infoFormContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 24,
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'stretch',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
  paper: {
    borderRadius: 8,
    maxWidth: 900,
    width: '100%',
    backgroundColor: theme.palette.grey[50],
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 16,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  ticketType: {
    border: 'solid 2px',
    borderRadius: 8,
    borderColor: theme.palette.grey[200],
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: 20,
    gap: 4,
  },
  subtitle: {
    fontSize: 14,
  },
  texts: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
});

export default withStyles(styles)(UploadOrderCard);
