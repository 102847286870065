import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import styles from './EventPaymentMethodsAndPromotionsButtonStyles';

const EventPaymentMethodsAndPromotionsButton = ({ classes, title, onClick, icon, variant }) => {
  return (
    <Button
      className={[classes.bankPromotionButton, classes[variant]]}
      onClick={onClick}
      endIcon={icon}
    >
      {title}
    </Button>
  );
};

export default withStyles(styles)(EventPaymentMethodsAndPromotionsButton);
