import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    mailValidationPage: {
      paddingBottom: 24,
      padding: '0 16px',
      height: 'auto',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      color: theme.palette.text.primary,

      [theme.breakpoints.up('sm')]: {
        width: 472,
        alignSelf: 'center',
      },

      '& .mailValidationPageImage': {
        width: 256,
        height: 254,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
      },

      '& .mailValidationPageTitle': {
        fontWeight: 600,
        fontSize: 28,
        lineHeight: '32.84px',
      },

      '& .mailValidationPageText16': {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '18.77px',
      },

      '& .mailValidationPageMailText': {
        color: theme.palette.primary.main,
        fontWeight: 600,
      },

      '& .mailValidationPageText14': {
        fontSize: 14,
        lineHeight: '16.42px',
      },
    },
  });

export default styles;
