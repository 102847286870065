import React from 'react';
import { Stack } from '@mui/material';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';

const CardField = ({ title, textValue, componentValue }) => {
  return (
    <Stack flex={1}>
      <VTypography variant="caption">{title}</VTypography>
      {componentValue || <VTypography variant="body2">{textValue}</VTypography>}
    </Stack>
  );
};

export default CardField;
