import React from 'react';
import CustomLink from '../../../../Components/CustomLink/CustomLink';

const LINKS = {
  MP_HELP: 'https://www.mercadopago.com.ar/ayuda/22754',
  CLIENT_SUPPORT: 'https://intercom.help/venti-latam/es/collections/3305705-entradas',
  EVENT: window.location.pathname.split('/').slice(0, 3).join('/'),
};

export const ERRORS_CONTENT = {
  100000: {
    type: 'VENTI_ERROR',
    message: 'El pago no se pudo procesar por un problema. Inténtalo de nuevo más tarde.',
  },
  100001: {
    type: 'UNAUTHORIZED_PAYMENT',
    message:
      'El pago fue rechazado. Esto puede deberse a saldo insuficiente o falta de autorización para la transacción. Por favor, comuníquese con la entidad bancaria de la cual es cliente para que puedan brindarle más información sobre el inconveniente.',
  },
  100002: {
    type: 'MP_ERROR',
    message: (
      <span>
        El pago no fue acreditado debido a una decisión del procesador de pagos, Mercado Pago. Te
        recomendamos intentar nuevamente la operación utilizando un mail que tengas registrado y
        activo en Mercado Pago o revisar este{' '}
        {<CustomLink fontSize={16} title="apartado" href={LINKS.MP_HELP} />} y comunicarte con su
        equipo de soporte.
      </span>
    ),
    button: { title: 'Contactarse con Mercado Pago', href: LINKS.MP_HELP },
  },
  100003: {
    type: 'MP_HIGH_RISK_ERROR',
    message: (
      <span>
        El pago no fue aprobado debido a una decisión de seguridad por parte del procesador de
        pagos, Mercado Pago. Te recomendamos intentar nuevamente la operación utilizando un mail que
        tengas registrado y activo en Mercado Pago o revisar este{' '}
        {<CustomLink fontSize={16} title="apartado" href={LINKS.MP_HELP} />} y comunicarte con su
        equipo de soporte.
      </span>
    ),
    button: { title: 'Contactarse con Mercado Pago', href: LINKS.MP_HELP },
  },
  110000: {
    type: 'MACRO_CANCEL_ERROR',
    message: 'Parece que hubo un problema. Por favor, vuelve a intentarlo nuevamente.',
    button: { title: 'Volver a comprar', target: '_blank', href: LINKS.EVENT },
  },
};
