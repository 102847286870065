import React, { useState } from 'react';
import { CircularProgress, Typography, withStyles } from '@material-ui/core';
import QrCodeIcon from '@mui/icons-material/QrCode';
import styles from './NFTInput.styles';
import CustomInput from '../CustomInput/CustomInput';
import Button from '../../Venti-UI-Kit/Buttons/Button';
import { useFormDeprecated } from '../../hooks/useFormDeprecated';
import { NOTIFICATION_VARIANT } from '../../constants/types';
import { useNotifications } from '../../Providers/NotificationsProvider/NotificationsProvider';
import { handleRequestHelper } from '../../utils/helpers';
import api from '../../api/api';

const NFTInput = ({ classes, nft, readyToClaim = false, onClaim }) => {
  const { showNotification } = useNotifications();
  const { qrId, ticketTypeName, orderCountNumber } = nft;
  const { formState, handleInputChange, validateRequiredField } = useFormDeprecated({
    lemontag: '',
  });
  const [claiming, setClaiming] = useState(false);
  const [formErrors, setFormErrors] = useState({ lemontag: '' });

  const validateForm = () => {
    const errors = {
      lemontag: validateRequiredField(formState.lemontag),
    };
    setFormErrors((prevState) => ({ ...prevState, ...errors }));
    const validationsError = Object.values(errors).reduce(
      (prevErrors, currentError) => prevErrors + currentError,
      ''
    );
    return validationsError === '';
  };

  const onSubmit = async () => {
    if (!validateForm()) return;
    setClaiming(true);

    await handleRequestHelper({
      endpoint: () => api.claimNFT(qrId, { lemontag: formState.lemontag }),
      onFailure: () =>
        showNotification('Algo salió mal, vuelve a intentar', NOTIFICATION_VARIANT.ERROR),
      onSuccess: () => {
        onClaim();
        showNotification('¡NFT reclamado con éxito!', NOTIFICATION_VARIANT.SUCCESS);
      },
    });
    setClaiming(false);
  };

  return (
    <div key={qrId} className={classes.container}>
      <div className={classes.content}>
        {claiming ? (
          <div className={classes.claimingIndicatorContainer}>
            <CircularProgress size={18} />
            <Typography>Reclamando... </Typography>
          </div>
        ) : (
          <CustomInput
            startAdornment="$"
            title={`${orderCountNumber} - ${ticketTypeName}`}
            error={formErrors.lemontag}
            titleIcon={<QrCodeIcon />}
            placeholder="lemontag"
            type="text"
            onChange={handleInputChange}
            name="lemontag"
            value={formState.lemontag}
            disabled={!readyToClaim}
            hint={!readyToClaim && 'El QR no fue escaneado todavía'}
            button={
              <Button onClick={onSubmit} className={classes.button} disabled={!readyToClaim}>
                Reclamar
              </Button>
            }
          />
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(NFTInput);
