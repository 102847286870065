import React, { useState } from 'react';
import { Stack } from '@mui/material';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import GoBackButton from '../../../../Components/GoBackButton/GoBackButton';
import Button from '../../../../Components/Buttons/Button/Button';
import { ORDER_STATUS, ORDER_TYPE, USER_PERMISSIONS } from '../../../../constants/types';
import RefundOrderBottomSheet from '../../../../Components/BottomSheets/RefundOrderBottomSheet/RefundOrderBottomSheet';
import CancelOrderBottomSheet from '../../../../Components/BottomSheets/CancelOrderBottomSheet/CancelOrderBottomSheet';
import RevertExpiredOrderBottomSheet from '../../../../Components/BottomSheets/RevertExpiredOrderBottomSheet/RevertExpiredOrderBottomSheet';
import { useAuth } from '../../../../Providers/AuthProvider/AuthProvider';
import { validateUserRole } from '../../../../utils/utils';

const OrderTitle = ({ order, getOrder }) => {
  const { user } = useAuth();
  const [openReturnOrderBottomSheet, setOpenReturnOrderBottomSheet] = useState(false);
  const [openCancelOrderBottomSheet, setOpenCancelOrderBottomSheet] = useState(false);
  const [openRevertExpiredOrderBottomSheet, setOpenRevertExpiredOrderBottomSheet] = useState(false);

  const canRefund =
    validateUserRole(user.role, USER_PERMISSIONS.SUPER_ADMIN) &&
    order.status === ORDER_STATUS.PAID &&
    order.type === ORDER_TYPE.ONLINE;
  const canRevertExpiredOrder =
    validateUserRole(user.role, USER_PERMISSIONS.SUPER_ADMIN) &&
    order.status === ORDER_STATUS.NOT_PAID &&
    order.type === ORDER_TYPE.ONLINE;

  return (
    <Stack direction="row" gap={2} flexWrap="wrap" width="100%" justifyContent="space-between">
      <GoBackButton label={order?.eventName} />
      <Stack
        gap={2}
        width={{ xs: '100%', sm: 'auto' }}
        direction="row"
        flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
        alignItems="center"
        justifyContent="flex-end"
      >
        {canRefund && (
          <Button
            color="secondary"
            variant="outlined"
            icon={<RotateLeftIcon />}
            title="Reembolsar"
            size="small"
            width={140}
            disabled={order.status !== ORDER_STATUS.PAID}
            onClick={() => setOpenReturnOrderBottomSheet(true)}
          />
        )}
        {canRevertExpiredOrder && (
          <Button
            color="main"
            icon={<CreditScoreIcon />}
            title="Marcar como pagada"
            size="small"
            width={230}
            disabled={order.status !== ORDER_STATUS.NOT_PAID}
            onClick={() => setOpenRevertExpiredOrderBottomSheet(true)}
          />
        )}
        <Button
          color="error"
          icon={<DoDisturbIcon />}
          title="Inhabilitar"
          size="small"
          width={140}
          disabled={order.status !== ORDER_STATUS.PAID}
          onClick={() => setOpenCancelOrderBottomSheet(true)}
        />
      </Stack>
      <RefundOrderBottomSheet
        open={openReturnOrderBottomSheet}
        onClose={() => setOpenReturnOrderBottomSheet(false)}
        orderId={order?.id}
        getOrder={getOrder}
      />
      <CancelOrderBottomSheet
        open={openCancelOrderBottomSheet}
        onClose={() => setOpenCancelOrderBottomSheet(false)}
        orderId={order?.id}
        getOrder={getOrder}
      />
      <RevertExpiredOrderBottomSheet
        open={openRevertExpiredOrderBottomSheet}
        onClose={() => setOpenRevertExpiredOrderBottomSheet(false)}
        orderId={order?.id}
        getOrder={getOrder}
      />
    </Stack>
  );
};

export default OrderTitle;
