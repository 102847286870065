import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    title: {
      marginBottom: 20,
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.8rem',
        marginBottom: 20,
      },
    },
    submit: {
      margin: '20px 0',
    },
  });

export default styles;
