import React from 'react';
import MUIDataTable from 'mui-datatables';
import { tableTranslations } from './tableTranslations';

const SellersSalesTable = ({ data, withCommissions = false }) => {
  const formattedData = data.map(
    ({
      sellerAlias,
      sellerMail,
      ticketQuantity,
      ticketPrice,
      ticketName,
      teamLeaderCode,
      comission1,
      comission2,
    }) => ({
      'Team Leader': teamLeaderCode || '-',
      'Alias del publica': sellerAlias || '-',
      'Mail del publica': sellerMail || '-',
      'Nombre del ticket': ticketName,
      'Precio del ticket ($)': `$${ticketPrice}`,
      'Cantidad vendida': ticketQuantity,
      'Total ($)': `$${ticketPrice * ticketQuantity}`,
      ...(withCommissions && { 'Comision 1 ($)': comission1, 'Comision 2 ($)': comission2 }),
    })
  );

  const tableOptions = {
    ...tableTranslations,
    downloadOptions: {
      filename: 'SellersSalesReport.csv',
    },
  };

  const columns = [
    'Team Leader',
    'Alias del publica',
    'Mail del publica',
    'Nombre del ticket',
    'Precio del ticket ($)',
    'Cantidad vendida',
    'Total ($)',
  ];

  if (withCommissions) columns.push('Comision 1 ($)', 'Comision 2 ($)');

  return <MUIDataTable data={formattedData} columns={columns} options={tableOptions} />;
};

export default SellersSalesTable;
