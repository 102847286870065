import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    table: {
      width: '100%',
    },
    iconButton: {
      height: 40,
      width: 40,
      padding: 0,
    },
    typeCell: {
      width: 220,
    },
    amountCell: {
      width: 200,
    },
    notesCell: {
      minWidth: 300,
    },
    dateCell: {
      textAlign: 'center',
      width: 200,
    },
    actionsCell: {
      width: 150,
    },
    header: {
      fontWeight: 500,
      cursor: 'pointer',
      paddingBottom: '10px',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    arrow: {
      paddingLeft: 25,
    },
    sortLabel: {
      paddingBottom: 4,
    },
  });

export default styles;
