import React, { useState } from 'react';
import { useTheme, withStyles } from '@material-ui/core';
import { Box, Stack, TextField } from '@mui/material';
import styles from './VTextArea.styles';
import VIcon from '../../VIcon/VIcon';
import VTypography from '../../VTypography/VTypography';

const VTextArea = ({
  classes,
  label,
  labelIcon,
  required,
  errorMessage,
  successMessage,
  hint,
  showHelpText = true,
  ...props
}) => {
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Stack gap={1}>
      {label && (
        <Box className="topInputBox">
          {labelIcon && <VIcon name={labelIcon} />}
          <VTypography variant="body2">{label}</VTypography>
          {required && (
            <VTypography variant="body2" color={theme.palette.error.main}>
              *
            </VTypography>
          )}
        </Box>
      )}
      <TextField
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        multiline
        disabled={false}
        minRows={2}
        fullWidth
        variant="outlined"
        className={`${classes.textField} ${errorMessage && classes.error}`}
        {...props}
      />
      {showHelpText && (
        <Box className="bottomInputBox">
          <VTypography
            variant="caption"
            color={(errorMessage && 'error') || (successMessage && 'success')}
          >
            {errorMessage || successMessage || (isFocused && hint)}
          </VTypography>
        </Box>
      )}
    </Stack>
  );
};

export default withStyles(styles)(VTextArea);
