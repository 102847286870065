import React from 'react';
import { Stack } from '@mui/material';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import ProducerForm from '../../../Components/Forms/ProducerForm/ProducerForm';
import { useCreateProducerPage } from './CreateProducerPage.hook';

const CreateProducerPage = () => {
  const { handleSubmit } = useCreateProducerPage();

  return (
    <PageContainer title="Backoffice - Crear Productora">
      <Stack width="100%" alignItems="center" gap={3} px={2}>
        <VTypography variant="h3" align="center">
          Crear nueva productora
        </VTypography>
        <ProducerForm onSubmit={handleSubmit} submitLabel="Crear" />
      </Stack>
    </PageContainer>
  );
};

export default CreateProducerPage;
