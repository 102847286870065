import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { withRouter } from 'react-router';
import { Stack } from '@mui/material';
import api from '../../api/api';
import styles from './Checkout.styles';
import CheckoutHorizontalStepperModal from '../CheckoutHorizontalStepperModal/CheckoutHorizontalStepperModal';
import Button from '../Buttons/Button/Button';
import BuyOnSecondaryMarketBottomSheet from '../BottomSheets/BuyOnSecondaryMarketBottomSheet/BuyOnSecondaryMarketBottomSheet';
import { handleRequestHelper } from '../../utils/helpers';

const StyledBadge = withStyles((theme) => ({
  badge: {
    left: 10,
    top: -10,
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.background.default,
    padding: '0 4px',
  },
}))(Badge);

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    const initialUser = {
      id: null,
      firstName: '',
      lastName: '',
      identification: {
        type: 'DNI',
        number: '',
      },
      mail: '',
      mailConfirm: '',
      phone: '',
      seller: null,
    };

    if (props.loggedUser) {
      initialUser.firstName = props.loggedUser.firstName;
      initialUser.lastName = props.loggedUser.lastName;
      initialUser.id = props.loggedUser.id;
      if (props.loggedUser.mail) {
        initialUser.mail = props.loggedUser.mail;
        initialUser.mailConfirm = props.loggedUser.mail;
      }
      if (props.loggedUser.dni) {
        initialUser.identification.number = props.loggedUser.dni;
      }
      initialUser.id = props.loggedUser.id;
    }

    this.state = {
      quantityDialogOpen: props.defaultOpen ?? false,
      openBuyOnSecondaryMarketBottomSheet: false,
      paymentMethods: [],
    };
  }

  async componentDidMount() {
    this.getPaymentMethods();
  }

  async getPaymentMethods() {
    await handleRequestHelper({
      endpoint: () => api.getEventPaymentMethodsByEventId(this.props.event.id),
      onSuccess: ({ paymentMethods }) => {
        this.setState({ paymentMethods });
      },
      showNotification: this.props.showNotification,
    });
  }

  getTotal() {
    if (this.props.cart.some(({ shouldConvertPriceFromUSD }) => shouldConvertPriceFromUSD)) {
      return this.props.cart.reduce((total, item) => {
        return total + Number(item.priceInUSD * item.quantity);
      }, 0);
    }
    return this.props.cart.reduce((total, item) => {
      return total + Number(item.price * item.quantity);
    }, 0);
  }

  openDialog() {
    this.setState({ quantityDialogOpen: true });
  }

  closeCheckout() {
    this.setState({ quantityDialogOpen: false });
  }

  mapCart(cartObjects) {
    return this.ticketsToQRs(cartObjects);
  }

  ticketsToQRs(ticketsObject) {
    let qrs = [];
    const tickets = Object.values(ticketsObject);

    tickets.forEach((ticket) => {
      qrs = [...qrs, ...Array(ticket.quantity).fill({ ticketTypeId: ticket.id })];
    });

    return qrs;
  }

  render() {
    const { classes } = this.props;
    const cartLength = this.mapCart(this.props.cart).length;
    const totalPrice = this.getTotal().toFixed(2);

    const item = {
      addItem: this.props.addItemToCart,
      deleteItem: this.props.deleteItemFromCart,
      items: this.props.cart,
    };

    const handleOpenCheckout = () => {
      this.openDialog();
    };

    const handleOpenBuyOnSecondaryMarketBottomSheet = () => {
      this.setState({ openBuyOnSecondaryMarketBottomSheet: true });
    };

    const handleCloseBuyOnSecondaryMarketBottomSheet = () => {
      this.setState({ openBuyOnSecondaryMarketBottomSheet: false });
    };

    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} gap={2}>
        {this.props.event.secondaryMarketLink && (
          <Button
            onClick={handleOpenBuyOnSecondaryMarketBottomSheet}
            title="Comprar en Venti Swap"
            fullwidth={false}
            variant="outlined"
          />
        )}
        <Button onClick={handleOpenCheckout} title={`Ver carrito: $${totalPrice}`} />
        <Fab alt="Checkout" onClick={handleOpenCheckout} className={classes.floatingButton}>
          <StyledBadge badgeContent={cartLength} overlap="rectangular">
            <ShoppingCartIcon />
          </StyledBadge>
        </Fab>
        {this.state.quantityDialogOpen && this.state.paymentMethods.length && (
          <CheckoutHorizontalStepperModal
            openModal={this.state.quantityDialogOpen}
            onClose={this.closeCheckout.bind(this)}
            cart={item.items.filter((ticket) => ticket)} // remove emptys
            cartActions={{
              increaseTicketQuantity: this.props.addItemToCart,
              decreaseTicketQuantity: this.props.deleteItemFromCart,
            }}
            initialOrderData={{
              paymentMethods: this.state.paymentMethods,
              seller: this.props.seller,
              event: this.props.event,
            }}
            refreshEvent={this.props.refreshEvent}
          />
        )}
        {this.state.openBuyOnSecondaryMarketBottomSheet && (
          <BuyOnSecondaryMarketBottomSheet
            open={this.state.openBuyOnSecondaryMarketBottomSheet}
            onClose={handleCloseBuyOnSecondaryMarketBottomSheet}
            secondaryMarketLink={this.props.event.secondaryMarketLink}
          />
        )}
      </Stack>
    );
  }
}

export default withRouter(withStyles(styles)(Checkout));
