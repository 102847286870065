import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    lineCheckoutModalResumeContainer: {
      width: '100%',

      [theme.breakpoints.up('lg')]: {
        width: 'unset',
        height: '100%',
      },

      '& .arrow': {
        color: theme.palette.primary.main,
      },

      '& p': {
        fontSize: 12,
        fontWeight: '600',
        lineHeight: '20px',
        color: theme.palette.grey[700],
        textAlign: 'left',
      },

      '& .background': {
        background: '#000',
        border: 'none',
        opacity: 0.3,
        position: 'fixed',
        mixBlendMode: 'multiply',
        width: '200vw',
        height: '300vh',
        top: '-100vh',
        left: 0,
        pointerEvents: 'all',
        zIndex: 15,

        [theme.breakpoints.up('lg')]: {
          display: 'none',
        },
      },

      '& .lineCheckoutResumeContainer': {
        background: theme.palette.grey[50],
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        width: 'calc(100% - 32px)',
        borderRadius: 8,
        position: 'fixed',
        bottom: 20,
        left: 0,
        right: 0,
        margin: '0 auto',
        overflow: 'hidden',
        transition: 'max-height 600ms ease',
        maxHeight: 58,
        zIndex: 15,

        [theme.breakpoints.up('lg')]: {
          width: 328,
          position: 'unset',
          margin: 0,
          maxHeight: 'unset',
        },

        '&.open': {
          maxHeight: 500,
        },

        '& .resumeTitleContainer': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          background: 'transparent',
          border: 'none',
          cursor: 'pointer',
          gap: 12,
          padding: 16,

          [theme.breakpoints.up('lg')]: {
            cursor: 'text',
            userSelect: 'text',
          },

          '& p': {
            fontSize: 14,
          },
        },

        '& .resumeItemContainer': {
          display: 'flex',
          width: '100%',
          alignItems: 'top',
          justifyContent: 'space-between',
          gap: 10,
        },

        '& .ticketContainer': {
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
        },

        '& .ticketsQuantityText > p': {
          color: theme.palette.primary.main,
        },

        '& .discountCode > p': {
          color: theme.palette.secondary.main,
        },

        '& .ticketsContainer': {
          display: 'flex',
          flexDirection: 'column',
          border: `1px solid ${theme.palette.grey[200]}`,
          borderWidth: '1px 0 1px 0',
          gap: 24,
          padding: 16,
        },

        '& .ticketInfo': {
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        },

        '& .downArrow': {
          transform: 'rotate(180deg)',
        },

        '& .upArrow': {
          transform: 'rotate(0deg)',
        },
      },
    },
  });

export default styles;
