import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    producerListPageContaienr: {
      gap: 32,
      padding: 16,
      width: '100%',
      maxWidth: 900,
      alignSelf: 'center',
    },
  });

export default styles;
