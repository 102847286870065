import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    lineCheckoutFormContainer: {
      padding: '36px 16px 24px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.25)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      backgroundColor: theme.palette.grey[50],
      borderRadius: 8,
      gap: 48,

      [theme.breakpoints.up('lg')]: {
        width: 694,
      },
    },

    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      width: '100%',

      '& .doubleInputContainer': {
        display: 'flex',
        gap: 16,
        flexDirection: 'column',

        [theme.breakpoints.up('lg')]: {
          flexDirection: 'row',
        },
      },
    },
  });

export default styles;
