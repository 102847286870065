import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { handleRequestHelper } from '../utils/helpers';
import {
  GOOGLE_RECAPTCHA_VERSION,
  GoogleReCaptchaContext,
} from '../Providers/GoogleReCaptchaProvider/GoogleReCaptchaProvider';
import api from '../api/api';
import history from '../appHistory';

export const useReCaptcha = ({ page, action }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { googleReCaptchaVersion } = useContext(GoogleReCaptchaContext);
  const isCaptchaValid = useRef(false);
  const [buttonActionDisabled, setButtonActionDisabled] = useState(true);

  useEffect(
    () => setButtonActionDisabled(googleReCaptchaVersion === GOOGLE_RECAPTCHA_VERSION.v2),
    [googleReCaptchaVersion]
  );

  const validateV2RecaptchaToken = async (token) => {
    await handleRequestHelper({
      endpoint: () => api.validateRecaptcha(token),
      onSuccess: () => setButtonActionDisabled(false),
      onFailure: () => setButtonActionDisabled(true),
    });
  };

  const validateV3RecaptchaToken = useCallback(async () => {
    if (!executeRecaptcha) {
      console.error('Execute recaptcha not yet available');
      isCaptchaValid.current = false;
      history.push('/error/captcha', { page });
    }

    const token = await executeRecaptcha(action);

    await handleRequestHelper({
      endpoint: () => api.validateRecaptcha(token),
      onSuccess: () => {
        isCaptchaValid.current = true;
      },
      onFailure: () => {
        isCaptchaValid.current = false;
        history.push('/error/captcha', { page });
      },
    });
  }, [executeRecaptcha]);

  const validateRecaptchaToken = async (token) => {
    if (googleReCaptchaVersion === GOOGLE_RECAPTCHA_VERSION.v2) {
      if (!token) return true;
      await validateV2RecaptchaToken(token);
    }
    if (googleReCaptchaVersion === GOOGLE_RECAPTCHA_VERSION.v3) {
      await validateV3RecaptchaToken();
    }
    return isCaptchaValid.current;
  };

  return {
    validateRecaptchaToken,
    buttonActionDisabled,
  };
};
