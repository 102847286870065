const ENVIRONMENTS = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
};

const serverURL = process.env.NODE_ENV === ENVIRONMENTS.DEVELOPMENT ? 'http://localhost:10010' : '';

const config = {
  googleAnalyticsId: 'G-NMHSCNY3BZ',
  intercomId: 'zgidd8i0',
  serverURL,
};

export default config;
