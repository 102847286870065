import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import PersonIcon from '@mui/icons-material/Person';
import EventIcon from '@mui/icons-material/Event';
import PlaceIcon from '@mui/icons-material/Place';
import FestivalIcon from '@mui/icons-material/Festival';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import styles from './EventHeader.styles';
import { getEventVenueAddress, getEventVenueName, getEventVenueLink } from '../../utils/utils';
import EventPaymentMethodsAndPromotions from '../EventPaymentMethodsAndPromotions/EventPaymentMethodsAndPromotions';

const NUMERIC = 'numeric';
const TWO_DIGITS = '2-digit';

const EventHeader = ({ classes, event, seller, eventPromotions }) => {
  const dateOptions = { day: NUMERIC, month: NUMERIC, year: NUMERIC };
  const timeOptions = { hour: TWO_DIGITS, minute: TWO_DIGITS };
  const eventHasVenue = !!event?.venue;
  const chips = [
    {
      show: event.startDate,
      info: 'DATE',
      icon: <EventIcon />,
      label: new Date(event.startDate).toLocaleDateString([], dateOptions),
    },
    {
      show: event.startDate,
      info: 'TIME',
      icon: <AccessTimeIcon />,
      label: new Date(event.startDate).toLocaleTimeString([], timeOptions),
    },
    {
      show: !eventHasVenue,
      info: 'UBICATION',
      icon: <PlaceIcon />,
      label: event.placeName,
    },
    {
      show: event.settings.secondaryMarketEnable,
      info: 'SECONDARY_MARKET',
      icon: <SwapHorizIcon />,
      label: 'Venti Swap',
    },
  ].filter(({ show }) => show);
  const chipsWithLinks = [
    {
      show: eventHasVenue,
      info: 'PLACE',
      icon: <FestivalIcon />,
      label: getEventVenueName(event),
      link: getEventVenueLink(event),
    },
    {
      show: eventHasVenue,
      info: 'ADDRESS',
      icon: <PlaceIcon />,
      label: getEventVenueAddress(event),
      link: getEventVenueLink(event),
    },
  ].filter(({ show }) => show);
  return (
    <div className={classes.container}>
      <img alt="" className={classes.banner} src={event.bannerImg} />
      <div className={classes.infoContainer}>
        <Typography className={classes.title} align="left" variant="h6">
          {event.name}
        </Typography>
        <div className={classes.chipsContainer}>
          {chips.map(({ info, icon, label }) => (
            <div className={classes.chip} key={info}>
              {icon}
              {label}
            </div>
          ))}
          {chipsWithLinks.map(({ info, icon, link, label }) => (
            <a className={classes.chip} key={info} target="_blank" rel="noreferrer" href={link}>
              {icon}
              {label}
            </a>
          ))}
        </div>
        {seller && (
          <div className={classes.chip}>
            <PersonIcon />
            {seller}
          </div>
        )}
        {!!eventPromotions?.length && (
          <div className={classes.eventPromotionsContainer}>
            <EventPaymentMethodsAndPromotions showArrowIcon eventPromotions={eventPromotions} />
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(EventHeader);
