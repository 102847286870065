import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      columnGap: 20,
      rowGap: 20,
      justifyContent: 'space-between',
      width: '100%',
    },
    sectionsHeader: {
      width: '100%',
      display: 'flex',
    },
    sectionsContent: {
      width: '100%',
      display: 'flex',
      columnGap: 20,
    },
    sectionContent: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 20,
      width: '100%',
    },
    sectionTitle: {
      width: '100%',
      fontWeight: 400,
      fontSize: 20,
      opacity: 0.9,
      color: theme.palette.text.secondary,
    },
  });

export default styles;
