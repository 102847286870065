import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    accordionSubHeader: {
      backgroundColor: theme.palette.grey[50],
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '14px 16px',
      columnGap: 5,
    },
    textSubHeader: {
      color: theme.palette.primary.main,
    },
    accordionTitle: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '24px',
      color: theme.palette.primary.contrastText,
    },
    accordionSubtitle: {
      fontWeight: 700,
      fontSize: 36,
      lineHeight: '39px',
      color: theme.palette.primary.contrastText,
    },
    accordionHeader: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 16,
      rowGap: 16,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '12px 12px 0px 0px',
    },
  });

export default styles;
