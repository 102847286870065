import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import styles from './MediumEventCardStyles';
import { formatDatetime, getEventVenueName } from '../../../../../utils/utils';

const MediumEventCard = ({ classes, event, userAlias }) => {
  const { name, bannerImg, promoImg, startDate, urlName } = event;
  const { dayOfWeek, date, time } = formatDatetime(startDate);
  const image = bannerImg || promoImg;
  const eventVenue = getEventVenueName(event);
  const eventUrl = userAlias ? `/evento/${urlName}/${userAlias}` : `/evento/${urlName}`;

  return (
    <a href={eventUrl} className={classes.cardContainer}>
      <div className="banner" style={{ backgroundImage: `url(${image})` }} alt="banner" />
      <div className="cardInfoContainer">
        <div className="nameContainer">
          <Typography className="name">{name}</Typography>
        </div>
        <div className="dateContainer">
          <Typography className="dateTimeText">{dayOfWeek}</Typography>
          <Typography className="dateTimeText">{date}</Typography>
          <Typography className="dateTimeText">{time}hs</Typography>
        </div>
        <div className="locationContainer">
          <FmdGoodOutlinedIcon style={{ height: 20 }} />
          <Typography className="locationText">{eventVenue}</Typography>
        </div>
      </div>
    </a>
  );
};

export default withStyles(styles)(MediumEventCard);
