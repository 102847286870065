import { createStyles } from '@material-ui/core';

const ROW_HEIGHT = 50;
const MIN_ROW_AMOUNT = 11;

const styles = (theme) =>
  createStyles({
    paperContainer: {
      width: '100%',
      '&.MuiPaper-root': {
        backgroundColor: theme.palette.table.background,
        '& *': {
          backgroundColor: 'transparent',
          color: theme.palette.table.text,
        },
        '& .Mui-disabled path': {
          color: theme.palette.table.disabled,
        },
      },
    },
    tableContainer: {
      '&.MuiTableContainer-root': {
        boxShadow: 'none',
        height: ROW_HEIGHT * MIN_ROW_AMOUNT,
      },
    },
    table: {
      '&.MuiTable-root': {
        minWidth: 400,
      },
    },
    tableRow: {
      '&.MuiTableRow-root': {
        maxHeight: ROW_HEIGHT,
        '&.MuiTableRow-hover:hover': {
          backgroundColor: theme.palette.table.hover,
        },
      },
    },
    tableCell: {
      '&.MuiTableCell-root': {
        height: ROW_HEIGHT,
        lineHeight: 'initial',
        width: '2%',
        padding: '0 10px',
        position: 'unset',
      },
    },
    headerText: {
      '&.MuiTypography-root': {
        fontWeight: 500,
        fontSize: 15,
        fontFamily: theme.typography.fontFamily,
        minWidth: 'max-content',
      },
    },
    cellText: {
      '&.MuiTypography-root': {
        fontWeight: 400,
        fontSize: 15,
        lineHeight: 'initial',
        fontFamily: theme.typography.fontFamily,
      },
    },
    noMatchText: {
      fontStyle: 'italic',
      opacity: 0.5,
      fontWeight: '600 !important',
      fontFamily: theme.typography.fontFamily,
    },
    paginationSelect: {
      '& .MuiPaper-root': {
        backgroundColor: 'red',
        color: 'red',
      },
    },
  });

export default styles;
