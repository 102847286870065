import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import styles from './ReportsAccordionItemBodyStyles';
import SalesReportCard from '../../Cards/SalesReportCard/SalesReportCard';
import AccessesReportCard from '../../Cards/AccessesReportCard/AccessesReportCard';
import { REPORTS_DATA_TYPE } from '../../../../constants/types';
import { translate } from '../../../../utils/translate';

const ReportsTicketTypeAccordionBody = ({
  classes,
  sections,
  dataType = REPORTS_DATA_TYPE.SALES,
}) => {
  const getTotalLabel = (type, totalSold, totalStock, totalUsed) => {
    return dataType === REPORTS_DATA_TYPE.SALES
      ? totalStock
        ? `${totalSold}/${totalStock}`
        : `${totalSold}`
      : `Faltan: ${totalSold - totalUsed}`;
  };

  return (
    <div className={classes.accordionsContainer}>
      {Object.entries(sections).map(
        ([type, { totalSold, totalStock, totalUsed, ticketsById }], index) => (
          <Accordion key={index} classes={{ root: classes.accordionContainer }}>
            <AccordionSummary
              expandIcon={<ArrowBackIosNewIcon className="accordionItemExpandIcon" />}
              id={index}
            >
              <Typography className="accordionItemTitle" component="div">
                {translate(type)}&nbsp;
                <Typography className="accordionItemSubtitle">
                  {getTotalLabel(type, totalSold, totalStock, totalUsed)}
                </Typography>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {Object.values(ticketsById).map((data, index) =>
                dataType === REPORTS_DATA_TYPE.SALES ? (
                  <SalesReportCard key={index} data={data} />
                ) : (
                  <AccessesReportCard key={index} data={data} />
                )
              )}
            </AccordionDetails>
          </Accordion>
        )
      )}
    </div>
  );
};

export default withStyles(styles)(ReportsTicketTypeAccordionBody);
