import React from 'react';
import { MenuItem, Stack } from '@mui/material';
import SelectInput from '../../../Venti-UI-Kit/Inputs/SelectInput';
import FlagIcon from '../../FlagIcon/FlagIcon';
import { COUNTRIES } from '../LocationSelectors.constants';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';

const CountrySelector = (selectProps) => {
  return (
    <Stack direction={{ xs: 'column', md: 'row' }}>
      <SelectInput options={COUNTRIES} title="País" name="country" {...selectProps}>
        {Object.values(COUNTRIES).map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            <Stack direction="row" gap={1} alignItems="center">
              <FlagIcon country={value} size={24} />
              <VTypography variant="body1">{label}</VTypography>
            </Stack>
          </MenuItem>
        ))}
      </SelectInput>
    </Stack>
  );
};

export default CountrySelector;
