import React, { useState } from 'react';
import { Stack } from '@mui/material';
import BottomSheet from '../../../Venti-UI-Kit/BottomSheet/BottomSheet';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import Button from '../../Buttons/Button/Button';
import SelectInput from '../../../Venti-UI-Kit/Inputs/SelectInput';
import { NOTIFICATION_VARIANT, REFUND_TYPE } from '../../../constants/types';
import { handleRequestHelper } from '../../../utils/helpers';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import api from '../../../api/api';

const RefundOrderBottomSheet = ({ onClose, open, orderId, getOrder }) => {
  const { showNotification } = useNotifications();
  const [isLoading, setIsLoading] = useState(false);
  const [refundTypeSelected, setRefundTypeSelected] = useState('');

  const refundOptions = [
    { label: 'Parcial (sin fee)', value: REFUND_TYPE.REFUND_WITHOUT_FEE },
    { label: 'Total (con fee)', value: REFUND_TYPE.REFUND_WITH_FEE },
  ];

  const handleClose = () => {
    setRefundTypeSelected();
    onClose();
  };

  const handleRefund = async () => {
    setIsLoading(true);

    await handleRequestHelper({
      endpoint: () =>
        refundTypeSelected === REFUND_TYPE.REFUND_WITHOUT_FEE
          ? api.refundOrderAmountWithoutFee(orderId)
          : api.refundOrderAmountWithFee(orderId),
      showNotification,
      onSuccess: () => {
        getOrder();
        showNotification(
          'El reembolso de la orden fue realizado con éxito.',
          NOTIFICATION_VARIANT.SUCCESS
        );
      },
    });
    handleClose();
    setIsLoading(false);
  };

  return (
    <BottomSheet open={open} onClose={handleClose}>
      <Stack padding={2} gap={2.5}>
        <Stack gap={1}>
          <VTypography variant="h4">Reembolsar orden</VTypography>
          <VTypography variant="body2">
            A continuación elegí que tipo de reembolso querés realizar. Reembolso total (con fee) o
            reembolso parcial (sin fee).
          </VTypography>
        </Stack>
        <SelectInput
          title="Tipo de reembolso"
          onChange={({ target }) => setRefundTypeSelected(target.value)}
          name="idType"
          options={refundOptions}
          value={refundTypeSelected}
        />
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={1}>
          <Button size="small" fullwidth variant="outlined" title="Volver" onClick={handleClose} />
          <Button
            size="small"
            fullwidth
            title="Continuar"
            onClick={handleRefund}
            loading={isLoading}
            disabled={!refundTypeSelected}
          />
        </Stack>
      </Stack>
    </BottomSheet>
  );
};

export default RefundOrderBottomSheet;
