import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    cropContainer: {
      margin: '0 20px',
      maxWidth: 'calc(70% - 40px)',
      [theme.breakpoints.down('md')]: {
        margin: '0 20px',
        maxWidth: 'calc(90% - 20px)',
      },
    },
    buttonsContainer: {
      marginTop: 20,
      display: 'flex',
      columnGap: 20,
    },
  });

export default styles;
