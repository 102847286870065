import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    card: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: '20px',
      backgroundColor: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[200]}`,
      width: 270,
      height: 330,
      padding: 20,
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        maxWidth: 330,
      },
      [theme.breakpoints.down('sm')]: {
        margin: 'auto',
      },
    },
    descriptionInfo: {
      display: 'flex',
      rowGap: 10,
      flexDirection: 'column',
      alignItems: 'center',
    },
    image: {
      width: 90,
      height: 90,
    },
    title: {
      fontWeight: 500,
    },
    description: {
      textAlign: 'left',
      width: '80%',
    },
    button: {
      fontSize: 14,
      height: '30px',
    },
  });

export default styles;
