import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    container: {
      rowGap: 20,
      flexDirection: 'column',
      display: 'flex',
    },
    claimedContainer: {
      display: 'flex',
      flexDirection: 'column',
      columnGap: 20,
    },
    noClaimedText: {
      alignSelf: 'center',
      fontSize: theme.typography.h6.fontSize,
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightLight,
      [theme.breakpoints.down('xs')]: {
        alignSelf: 'middle',
      },
    },
    lemontag: {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightLight,
      display: 'flex',
      wordBreak: 'break-word',
    },
    claimedText: {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightMedium,
      flex: 1,
      textAlign: 'left',
    },
  });

export default styles;
