import { useState } from 'react';
import { handleRequestHelper } from '../../../../../utils/helpers';
import api from '../../../../../api/api';
import { useNotifications } from '../../../../../Providers/NotificationsProvider/NotificationsProvider';

export const useActionsCell = (
  rowIndex,
  financialOperations,
  setFinancialOperations,
  initialFinancialOperations,
  setInitialFinancialOperations
) => {
  const [openModal, setOpenModal] = useState(false);
  const { showNotification } = useNotifications();
  const financialOperationId = financialOperations[rowIndex].id;

  const handleEdit = () => {
    setFinancialOperations((prevState) => {
      prevState[rowIndex].edit = true;
      return [...prevState];
    });
  };

  const handleCancelEdit = () => {
    setFinancialOperations((prevState) => {
      prevState[rowIndex] = { ...initialFinancialOperations[rowIndex], edit: false };
      return [...prevState];
    });
  };

  const handleSave = async () => {
    const { type, amount, notes, paymentDate } = financialOperations[rowIndex];
    const body = { type, amount, notes: notes || null, paymentDate };

    if (Number(amount) <= 0) {
      showNotification('El valor tiene que ser un número positivo.', 'error');
      return;
    }

    await handleRequestHelper({
      endpoint: () => api.updateFinancialOperationr(financialOperationId, body),
      onSuccess: () => {
        setFinancialOperations((prevState) => {
          prevState[rowIndex].edit = false;
          return [...prevState];
        });

        setInitialFinancialOperations((prevState) => {
          prevState[rowIndex] = { ...financialOperations[rowIndex], edit: false };
          return [...prevState];
        });
        showNotification('La operación fue editada con éxito.', 'success');
      },
      showNotification,
    });
  };

  const openConfirmModal = () => setOpenModal(true);
  const handleCloseConfirmModal = () => setOpenModal(false);

  const handleDelete = async () => {
    await handleRequestHelper({
      endpoint: () => api.deleteFinancialOperation(financialOperationId),
      onSuccess: () => {
        setFinancialOperations((prevState) => prevState.filter((_, index) => index !== rowIndex));
        setInitialFinancialOperations((prevState) =>
          prevState.filter((_, index) => index !== rowIndex)
        );
        showNotification('La operación fue eliminada con éxito.', 'success');
        handleCloseConfirmModal();
      },
      showNotification,
    });
  };

  return {
    openModal,
    handleEdit,
    handleCancelEdit,
    handleSave,
    openConfirmModal,
    handleCloseConfirmModal,
    handleDelete,
  };
};
