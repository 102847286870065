import React, { useState } from 'react';
import { Stack } from '@mui/material';
import BottomSheet from '../../../Venti-UI-Kit/BottomSheet/BottomSheet';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import Button from '../../Buttons/Button/Button';
import { handleRequestHelper } from '../../../utils/helpers';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import api from '../../../api/api';

const PostQrBottomSheet = ({ open, onClose, qrExternalId }) => {
  const { showNotification } = useNotifications();

  const [isLoading, setIsLoading] = useState(false);

  const goToPostQrLink = async () => {
    setIsLoading(true);

    await handleRequestHelper({
      endpoint: () => api.getLinkToPostQrOnSecondaryMarket(qrExternalId),
      onSuccess: ({ link }) => {
        setIsLoading(false);
        window.location.href = link;
      },
      onFailure: () => {
        onClose();
        setIsLoading(false);
      },
      showNotification,
    });
  };

  return (
    <BottomSheet open={open} onClose={onClose}>
      <Stack padding={2} gap={2.5}>
        <Stack gap={1}>
          <VTypography variant="h4">Publicá tu entrada en el mercado de Venti Swap</VTypography>
          <VTypography variant="body2">
            ¿No podés asistir al evento? Publicá tu entrada en Venti Swap en solo 3 pasos:
            <br />
            <br />
            <ol style={{ paddingLeft: 16 }}>
              <li>Ingresá los datos de tu cuenta bancaria para recibir el dinero.</li>
              <li>Elegí el monto al que vas a publicar tu ticket.</li>
              <li>Revisá la información.</li>
            </ol>
          </VTypography>
        </Stack>
        <Button color="alternative" title="Publicar" onClick={goToPostQrLink} loading={isLoading} />
      </Stack>
    </BottomSheet>
  );
};

export default PostQrBottomSheet;
