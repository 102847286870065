import { createTheme } from '@material-ui/core/styles';
import darkTheme from '../darkTheme';

const mandarineTheme = createTheme(darkTheme, {
  palette: {
    primary: {
      main: '#FB762C',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FB762C',
      contrastText: '#FFFFFF',
    },
    footer: {
      text: '#FFFFFF',
      background: '#000000',
    },
    header: {
      background: '#000000',
      buttonText: '#FFFFFF',
      buttonBackground: '#FB762C',
      subHeaderBackground: '#000000',
      text: '#FFFFFF',
    },
    buttons: {
      disabled: '#5C5C5C',
      disabledText: '#2A2A2A',
      main: {
        background: '#FB762C',
        text: '#FFFFFF',
        hover: '#C0451C',
      },
      alternative: {
        background: '#FFFFFF',
        text: '#000000',
        hover: '#CCCCCC',
      },
    },
    charts: {
      primary: '#FB762C',
      secondary: '#ffbaa3',
      text: '#FFFFFF',
    },
  },
});

export default mandarineTheme;
