import React from 'react';
import { Stack } from '@mui/material';
import TextButton from '../../../Components/Buttons/TextButton/TextButton';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import BottomSheet from '../../../Venti-UI-Kit/BottomSheet/BottomSheet';
import Button from '../../../Components/Buttons/Button/Button';

const CompleteUserDataModal = ({ open, handleAccept, handleCancel }) => {
  return (
    <BottomSheet open={open} alwaysModal>
      <Stack padding="16px" gap={2.5}>
        <Stack gap={1} paddingBottom="32px">
          <VTypography variant="h4">Faltan algunos datos</VTypography>
          <VTypography variant="body2">
            Necesitamos que completes algunos datos personales para mejorar tu experiencia en Venti.
          </VTypography>
        </Stack>
        <Stack direction="row" justifyContent="space-around">
          <TextButton title="Omitir" onClick={handleCancel} />
          <Button size="small" title="Editar mis datos" onClick={handleAccept} width={200} />
        </Stack>
      </Stack>
    </BottomSheet>
  );
};

export default CompleteUserDataModal;
