import { useEffect, useState } from 'react';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';
import { formatPrice } from '../../../utils/utils';
import { CURRENCY } from '../../../constants/types';

export const useActiveEventsTotalVolume = (producerId) => {
  const [totalVolume, setTotalVolume] = useState(null);

  const formatMetrics = (totalEventsVolume) => {
    if (!totalEventsVolume) return;

    const formatedVolume = formatPrice(totalEventsVolume, false, true, CURRENCY.ARS);

    setTotalVolume(formatedVolume);
  };

  const getMetrics = async () => {
    await handleRequestHelper({
      endpoint: () => api.getTotalEventsVolumeMetrics(producerId),
      onSuccess: ({ totalEventsVolume }) => formatMetrics(totalEventsVolume),
    });
  };

  useEffect(() => {
    getMetrics();
  }, []);

  return {
    totalVolume,
  };
};
