import React from 'react';
import { Stack } from '@mui/material';
import { withStyles } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import styles from './InvoicePreview.styles';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import { formatPriceWithCurrency } from '../../../utils/utils';

const TicketInvoicePreview = ({ classes, settlement }) => {
  const invoice = settlement?.ticketInvoice;
  if (!invoice) return null;

  const formatPrice = (number) => {
    return formatPriceWithCurrency(number, settlement.currency);
  };

  const title = settlement?.type === 'final' ? 'Liquidación' : 'Liquidación de Anticipo';

  return (
    <Stack className={classes.invoice}>
      <VTypography variant="h5" color="steelblue">
        {title}
      </VTypography>
      <VTypography>Productora: {settlement.producerName}</VTypography>
      <VTypography>Evento: {settlement.eventName}</VTypography>
      <VTypography>ID del Evento: {settlement.eventId}</VTypography>
      <VTypography>Fecha: {invoice.date}</VTypography>
      <VTypography mt={20} style={{ fontWeight: 'bold' }}>
        Reporte de Entradas:
      </VTypography>

      <Table size="small" aria-label="ticket-table">
        <TableHead>
          <TableRow>
            <TableCell>Tipo de Entrada</TableCell>
            <TableCell align="right">Precio Unitario</TableCell>
            <TableCell align="right">Cantidad</TableCell>
            <TableCell align="right">Precio Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoice.ticketBreakdown.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{formatPrice(row.pricePerUnit)}</TableCell>
              <TableCell align="right">{row.soldUnits}</TableCell>
              <TableCell align="right">{formatPrice(row.total)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <VTypography mt={20} style={{ fontWeight: 'bold' }}>
        Notas:
      </VTypography>
      <Stack className={classes.summary}>
        <Stack className={classes.summaryItem} direction="row">
          <VTypography variant="body2">Subtotal: </VTypography>
          <VTypography variant="body2" className={classes.summaryPrice}>
            {formatPrice(invoice.subtotalPrice)}
          </VTypography>
        </Stack>
        {invoice.invoiceSummary.map((item, index) => (
          <Stack className={classes.summaryItem} key={index} direction="row">
            <VTypography variant="body2">{item.title}: </VTypography>
            <VTypography variant="body2" color={item.type === 'addToTotal' ? 'success' : 'error'}>
              {item.type === 'addToTotal' ? '' : '- '}
              {formatPrice(item.amount)}
            </VTypography>
          </Stack>
        ))}
        <Stack className={classes.summaryItem} direction="row">
          <VTypography variant="body2">Total: </VTypography>
          <VTypography variant="body2" className={classes.summaryPrice}>
            {formatPrice(invoice.totalPrice)}
          </VTypography>
        </Stack>
        <Stack className={`${classes.summaryItem} ${classes.highlight}`} direction="row">
          <VTypography variant="body2">Total a recibir: </VTypography>
          <VTypography variant="body2" className={classes.summaryPrice}>
            {formatPrice(invoice.highlightedPrice)}
          </VTypography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default withStyles(styles)(TicketInvoicePreview);
