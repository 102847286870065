import React from 'react';
import { withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from './CustomLinkStyles';

const CustomLink = ({ classes, to, title, fontSize, href, target = '_self' }) => {
  return href ? (
    <a className={classes.customLink} style={{ fontSize }} href={href} target={target}>
      {title}
    </a>
  ) : (
    <Link className={classes.customLink} style={{ fontSize }} to={to}>
      {title}
    </Link>
  );
};

export default withStyles(styles)(CustomLink);
