import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      maxWidth: 800,
      width: '100%',
      margin: '0 auto',
      [theme.breakpoints.down('md')]: {
        padding: '0 16px',
      },
    },
    formContainer: {
      display: 'grid',
      margin: '24px 1px',
      gridTemplateColumns: '1fr 1fr',
      gap: '16px 24px',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    buttonsContainer: {
      marginTop: 15,
      display: 'flex',
      columnGap: 15,
      width: '100%',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
        rowGap: 15,
        marginTop: 0,
      },
    },
    button: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  });

export default styles;
