import React from 'react';
import { withStyles } from '@material-ui/core';
import { IconButton } from '@mui/material';
import styles from './IconButton.styles';

const VIconButton = ({ classes, Icon, onClick, disabled, variant = 'contained', size = 36 }) => {
  const iconButtonClassName = `${classes.base} ${classes[variant]}`;

  return (
    <IconButton
      className={iconButtonClassName}
      style={{ height: size, width: size }}
      onClick={onClick}
      disabled={disabled}
    >
      {Icon}
    </IconButton>
  );
};

export default withStyles(styles)(VIconButton);
