import React, { useEffect, useRef } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { Box } from '@mui/material';
import PageContainer from '../../../../Components/PageContainer/PageContainer';
import styles from './CheckoutStyles';
import { countdownTimer } from '../../../../utils/utils';
import CheckoutForm from './CheckoutForm/CheckoutForm';
import CheckoutResume from './CheckoutResume/CheckoutResume';
import InformationWithActionModal from '../../../../Components/Modals/InformationWithActionModal/InformationWithActionModal';
import Button from '../../../../Venti-UI-Kit/Buttons/Button';
import CustomCircularProgress from '../../../../Components/CustomCircularProgress/CustomCircularProgress';
import { useCheckout } from './useCheckout';
import EventPaymentMethodsAndPromotions from '../../../../Components/EventPaymentMethodsAndPromotions/EventPaymentMethodsAndPromotions';
import GoogleReCaptcha from '../../../../Components/GoogleReCaptcha/GoogleReCaptcha';

const Checkout = ({ classes, urlParams }) => {
  const { externalId } = urlParams;
  const timerRef = useRef(null);

  const {
    loadingInitialData,
    checkoutForm,
    order,
    showForm,
    installmentsOptions,
    identificationTypes,
    cardTypeData,
    paying,
    canPay,
    openTimeoutModal,
    remainingExpireTime,
    availableCards,
    eventPromotions,
    buttonActionDisabled,
    validateRecaptchaToken,
    payOrder,
    handleCloseTimeoutModal,
    onTimerFinished,
    handleBackToEvent,
  } = useCheckout(externalId);

  useEffect(() => {
    !loadingInitialData &&
      order &&
      countdownTimer(remainingExpireTime, timerRef.current, onTimerFinished);
  }, [loadingInitialData]);

  return (
    <PageContainer withFooter withPaddingTop={false} title={`Checkout - ${order?.event?.title}`}>
      {loadingInitialData ? (
        <CustomCircularProgress />
      ) : !order ? (
        <InformationWithActionModal
          title="No pudimos crear tu orden"
          description="Tuvimos un problema y no pudimos crear la orden con tus entradas."
          acceptLabel="Volver a comprar"
          open
          onClose={handleCloseTimeoutModal}
          onAccept={handleBackToEvent}
        />
      ) : (
        <>
          <div className={classes.lineCheckoutCounterHeaderContainer}>
            <div className="headerRow">
              <Typography className="title">{order.event?.title}</Typography>
              <Typography ref={timerRef} className="counter" />
            </div>
            <div className="headerRow">
              <Typography className="subtitle">{order.event?.startDate}</Typography>
              <Typography className="subtitle">Tiempo restante</Typography>
            </div>
          </div>
          <Typography className={classes.lineCheckoutPageTitle}>
            Ingresá los datos de tu tarjeta
          </Typography>
          <Box className={classes.checkoutContentContainer}>
            {(!!eventPromotions?.length || !!availableCards?.length) && (
              <EventPaymentMethodsAndPromotions
                textButtonVariant="text"
                eventPromotions={eventPromotions}
                availableCards={availableCards}
              />
            )}
            <Box className="formAndResumeContainer">
              <CheckoutForm
                formState={checkoutForm}
                identificationTypes={identificationTypes}
                showForm={showForm}
                installmentsOptions={installmentsOptions}
                cardTypeData={cardTypeData}
              />
              {order.resume && (
                <div className="resumeAndButtonContainer">
                  <CheckoutResume
                    resumeData={order.resume}
                    isLemonCard={cardTypeData.isLemonCard}
                  />
                  <GoogleReCaptcha validation={validateRecaptchaToken} />
                  <Button
                    fullWidth
                    disabled={!canPay || paying || buttonActionDisabled}
                    onClick={payOrder}
                    loading={paying}
                  >
                    Realizar pago
                  </Button>
                </div>
              )}
            </Box>
          </Box>
        </>
      )}
      <InformationWithActionModal
        title="El tiempo para realizar tu pago finalizó"
        description="Tus entradas ya no están reservadas. Deberás comenzar el proceso de compra nuevamente."
        acceptLabel="Volver a comprar"
        open={openTimeoutModal}
        onClose={handleCloseTimeoutModal}
        onAccept={handleBackToEvent}
      />
    </PageContainer>
  );
};

export default withStyles(styles)(Checkout);
