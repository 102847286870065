import React, { useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EventPaymentMethodsAndPromotionsButton from './EventPaymentMethodsAndPromotionsButton/EventPaymentMethodsAndPromotionsButton';
import EventPaymentMethodsAndPromotionsModal from './EventPaymentMethodsAndPromotionsModal/EventPaymentMethodsAndPromotionsModal';

const EventPaymentMethodsAndPromotions = ({
  eventPromotions,
  availableCards,
  textButtonVariant = 'contained',
  showArrowIcon = false,
}) => {
  const [opened, setOpened] = useState(false);
  const hasEventPromotions = !!eventPromotions?.length;
  const hasAvailableCards = !!availableCards?.length;

  const buttonTitle = hasAvailableCards
    ? hasEventPromotions
      ? 'Medios de pagos y promociones'
      : 'Medios de pagos'
    : 'Ver promociones bancarias';

  const modalTitle = hasAvailableCards
    ? hasEventPromotions
      ? 'Medios de pagos y promociones'
      : 'Medios de pagos'
    : 'Promociones bancarias';

  const modalSubtitle = hasAvailableCards
    ? 'Podés pagar tus compras con cualquiera de estos medios. Es rápido y seguro, siempre.'
    : 'Aprovecha de los mejores beneficios a la hora de pagar.';

  const handleClose = () => {
    setOpened(false);
  };

  const handleOpenModal = () => {
    setOpened(true);
  };

  return (
    <>
      <EventPaymentMethodsAndPromotionsButton
        onClick={handleOpenModal}
        variant={textButtonVariant}
        icon={showArrowIcon && <ArrowForwardIcon size={20} />}
        title={buttonTitle}
      />
      <EventPaymentMethodsAndPromotionsModal
        open={opened}
        onClose={handleClose}
        title={modalTitle}
        subtitle={modalSubtitle}
        eventPromotions={eventPromotions}
        availableCards={availableCards}
      />
    </>
  );
};
export default EventPaymentMethodsAndPromotions;
