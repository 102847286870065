import React, { useEffect, useCallback } from 'react';
import { useIntercom } from 'react-use-intercom';

const IntercomButton = () => {
  const { boot } = useIntercom();
  const bootWithProps = useCallback(() => boot({ name: '' }), [boot]);

  useEffect(() => {
    bootWithProps();
  }, []);

  return <div />;
};

export default IntercomButton;
