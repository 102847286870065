import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { CircularProgress, Typography, withStyles } from '@material-ui/core';
import Button from '../Buttons/Button';
import styles from './AlertDialog.styles';

const AlertDialog = ({
  open,
  title,
  message,
  children,
  onClose,
  handleConfirm,
  classes,
  confirmButtonTitle,
}) => {
  const [sending, setSending] = useState(false);

  const onConfirm = async () => {
    setSending(true);
    await handleConfirm();
    setSending(false);
  };

  return (
    <Dialog
      open={open}
      PaperProps={{ className: classes.container }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Typography id="alert-dialog-title" variant="subtitle1" className={classes.title}>
        {title}
      </Typography>
      {message && <Typography id="alert-dialog-description">{message}</Typography>}
      {children}
      {!sending ? (
        <div className={classes.buttonsContainer}>
          <Button color="transparent" onClick={onClose}>
            Cancelar
          </Button>
          <Button color="main" onClick={onConfirm}>
            {confirmButtonTitle || 'Confirmar'}
          </Button>
        </div>
      ) : (
        <CircularProgress className={classes.circularProgress} />
      )}
    </Dialog>
  );
};

export default withStyles(styles)(AlertDialog);
