import React from 'react';
import { Stack } from '@mui/material';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import BottomSheet from '../../../Venti-UI-Kit/BottomSheet/BottomSheet';
import { handleRequestHelper } from '../../../utils/helpers';
import Button from '../../Buttons/Button/Button';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { NOTIFICATION_VARIANT } from '../../../constants/types';
import api from '../../../api/api';

const ReturnQrBottomSheet = ({ open, onClose, userName, eventName, qrExternalId, getEventQrs }) => {
  const { showNotification } = useNotifications();

  const returnQr = async () => {
    await handleRequestHelper({
      endpoint: () => api.returnQrTransfer(qrExternalId),
      onSuccess: () => {
        onClose();
        showNotification('El ticket fue devuelto con éxito.', NOTIFICATION_VARIANT.SUCCESS);
        getEventQrs();
      },
      onFailure: () => onClose(),
      showNotification,
    });
  };

  return (
    <BottomSheet open={open} onClose={onClose}>
      <Stack padding="16px" gap={2.5}>
        <Stack gap={1} paddingBottom="32px">
          <VTypography variant="h4">Devolver ticket</VTypography>
          <VTypography variant="body2">
            Estás por devolver el ticket a <strong>{userName}</strong> para el evento{' '}
            <strong>{eventName}</strong>.
          </VTypography>
          <VTypography variant="body2">
            Al hacerlo, vas a dejar de verlo listado entre tus tickets.
          </VTypography>
        </Stack>
        <Button color="alternative" title="Devolver" onClick={returnQr} />
        <Button color="alternative" variant="outlined" title="Cancelar" onClick={onClose} />
      </Stack>
    </BottomSheet>
  );
};

export default ReturnQrBottomSheet;
