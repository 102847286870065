import { createStyles } from '@material-ui/core';

const styles = (theme) => {
  return createStyles({
    producerBanner: {
      width: '100%',
      height: '55vh',
      objectFit: 'cover',
      [theme.breakpoints.down('md')]: {
        height: '20vh',
      },
    },

    producerDataBox: {
      textAlign: 'left',
      maxWidth: 1132,
      width: '100%',
      alignSelf: 'center',
      padding: '0 16px',
      marginBottom: 64,
      gap: 32,
    },

    producerInfoBox: {
      '&.MuiStack-root': {
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
        },
      },
    },

    producerLogo: {
      width: 200,
      height: 200,
      objectFit: 'cover',
      borderRadius: 16,
      border: `2px solid ${theme.palette.background.default}`,
      marginTop: '-100px',
      [theme.breakpoints.down('md')]: {
        width: 100,
        height: 100,
        border: `1px solid ${theme.palette.background.default}`,
        borderRadius: 8,
        marginTop: '-50px',
      },
    },

    socialsBox: {
      justifyContent: 'right',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'left',
      },
    },
  });
};

export default styles;
