import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    selector: {
      backgroundColor: theme.palette.grey[200],
      borderRadius: 8,
      padding: '10px 0',
      minHeight: 35,
      transition: 'background-color 0.25s, border-color 0.25s',
      color: theme.palette.text.color,
      width: 220,
      '& .MuiSelect-root': {
        padding: 0,
      },
    },
    menuItem: {
      padding: 10,
    },
  });

export default styles;
