import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    container: {
      width: '100%',
      gap: 24,
      alignSelf: 'center',
      maxWidth: 600,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        padding: 16,
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    pricesContainer: {
      maxWidth: 600,
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 'unset',
      },
    },
    codeButton: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    stepTitle: {
      textAlign: 'center',
      fontSize: 32,
      fontWeight: 'bold',
    },
    dicountCodeContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        gap: 8,
        alignItems: 'unset',
      },
    },
    inputWithButtonContainer: {
      display: 'flex',
      columnGap: 7,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      },
    },
    inputContainer: {
      width: '100%',
      paddingTop: 2,
    },
    errorTextContainer: {
      height: 25,
      padding: '1px 0px 1px 4px',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        width: '100%',
      },
    },
    errorText: {
      color: theme.palette.error.main,
      fontSize: 13,
    },
    successText: {
      color: theme.palette.success.main,
      fontSize: 13,
    },
    validateCodeButtonContainer: {
      [theme.breakpoints.down('xs')]: {
        width: '55%',
      },
    },
    ticketPricesContainer: {
      display: 'flex',
      columnGap: 7,
      paddingLeft: 8,
    },
    buttonsContainer: {
      marginTop: 20,
      display: 'flex',
      columnGap: 15,
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        rowGap: 15,
      },
    },
    button: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    priceText: {
      textJustify: 'inherit',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    lemonDiscount: {
      color: theme.palette.primary.main,
      fontSize: 15,
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
      },
    },
    serviceCost: {
      fontSize: 14,
    },
    crossedText: {
      textDecoration: 'line-through',
      color: theme.palette.text.secondary,
    },
    divider: {
      margin: '10px 0',
    },
  });

export default styles;
