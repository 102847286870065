import { createTheme } from '@material-ui/core/styles';
import darkTheme from '../darkTheme';

const darkVentiTheme = createTheme(darkTheme, {
  palette: {
    primary: {
      main: '#5758F5',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#A8A6F2',
      contrastText: '#000000',
    },
    footer: {
      text: '#FFFFFF',
      background: '#5758F5',
    },
    header: {
      background: '#5758F5',
      buttonText: '#5758F5',
      buttonBackground: '#FFFFFF',
      subHeaderBackground: '#000000',
      text: '#FFFFFF',
    },
    buttons: {
      disabled: '#5C5C5C',
      disabledText: '#2A2A2A',
      main: {
        background: '#5758F5',
        text: '#FFFFFF',
        hover: '#4142B9',
      },
      alternative: {
        background: '#FFFFFF',
        text: '#000000',
        hover: '#CCCCCC',
      },
    },
    charts: {
      primary: '#5758F5',
      secondary: '#A8A6F2',
      text: '#FFFFFF',
    },
  },
});

export default darkVentiTheme;
