import React from 'react';
import { withStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import styles from './EventFiltersButtons.styles';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import { useEventFiltersButtons } from './EventFiltersButtons.hook';

const EventFiltersButtons = ({ classes }) => {
  const { alignment, handleAlignment, filtersButtons, filters, toggleOpenFilter } =
    useEventFiltersButtons();

  return (
    <ToggleButtonGroup
      className={`animate__animated animate__fadeIn ${classes.filterButtonsContainer}`}
      value={alignment}
      exclusive
      onChange={handleAlignment}
    >
      {filtersButtons.map(({ value, title }) => (
        <ToggleButton
          key={value}
          onClick={() => toggleOpenFilter(value)}
          value={value}
          className={classes.dateButton}
        >
          <VTypography variant="button1">{title}</VTypography>
        </ToggleButton>
      ))}
      {filtersButtons.map(({ value, Filter }) => filters[value] && Filter)}
    </ToggleButtonGroup>
  );
};

export default withStyles(styles)(EventFiltersButtons);
