import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    text: {
      fontSize: 17,
      textAlign: 'start',
    },
    paper: {
      borderRadius: 8,
      backgroundColor: theme.palette.grey[50],
      width: '80%',
      height: 'auto',
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 20,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    textAndButtonsContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'space-between',
      marginBottom: -10,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'start',
        rowGap: 20,
      },
    },
    selectorContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    selector: {
      backgroundColor: theme.palette.grey[200],
      borderRadius: 8,
      padding: '0 12px',
      height: 46,
      transition: 'background-color 0.25s, border-color 0.25s',
      color: theme.palette.text.color,
      width: '25%',
      minWidth: 220,
      marginLeft: 20,
    },
    headerButtonsContainer: {
      display: 'flex',
      columnGap: 10,
    },
    inputContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    textFieldRoot: {
      width: '50%',
      background: '#E7E7E7',
      borderRadius: 5,
      '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
        {
          display: 'none',
        },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'transparent',
        },
        '&:hover fieldset': {
          borderColor: 'transparent',
        },
      },
    },
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '70px 0',
    },
  });

export default styles;
