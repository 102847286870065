import React, { useEffect, useState } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import styles from './TeamLeaderPanel.styles';
import TeamLeaderCard from '../../../../Components/Cards/TeamLeaderCard/TeamLeaderCard';
import { NOTIFICATION_VARIANT } from '../../../../constants/types';
import PageContainer from '../../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import { handleRequestHelper } from '../../../../utils/helpers';
import { useNotifications } from '../../../../Providers/NotificationsProvider/NotificationsProvider';
import api from '../../../../api/api';

const TeamLeaderPanel = ({ classes, urlParams }) => {
  const { showNotification } = useNotifications();
  const [teamLeaders, setTeamLeaders] = useState([]);
  const { producerId } = urlParams;

  const getTeamLeaders = async () => {
    await handleRequestHelper({
      endpoint: () => api.getProducerTeamLeaders(producerId),
      onSuccess: ({ teamLeaders }) => setTeamLeaders(teamLeaders),
      onFailure: () =>
        showNotification('Hubo un error al obtener los team leaders', NOTIFICATION_VARIANT.ERROR),
    });
  };

  const deleteTeamLeader = async (teamLeader) => {
    await handleRequestHelper({
      endpoint: () => api.deleteTeamLeader(teamLeader.id),
      onFailure: () =>
        showNotification(
          `Hubo un error al eliminar al team leader: ${teamLeader.code}`,
          NOTIFICATION_VARIANT.ERROR
        ),
      onSuccess: () => {
        getTeamLeaders();
        showNotification(
          `Team leader: ${teamLeader.code} eliminado correctamente`,
          NOTIFICATION_VARIANT.SUCCESS
        );
      },
    });
  };

  useEffect(() => {
    getTeamLeaders();
  }, []);

  return (
    <PageContainer title="Backoffice - Team Leaders">
      <VBreadcrumbs />
      <div className={classes.pageContent}>
        {teamLeaders.length ? (
          <>
            <Typography variant="h4" className={classes.pageTitle}>
              Team Leaders
            </Typography>
            {teamLeaders.map((teamLeader) => (
              <TeamLeaderCard
                key={teamLeader.id}
                teamLeader={teamLeader}
                onDelete={deleteTeamLeader}
              />
            ))}
          </>
        ) : (
          <Typography variant="h4" className={classes.noTeamLeaderMessage}>
            No tienes team leaders todavía
          </Typography>
        )}
      </div>
    </PageContainer>
  );
};

export default withStyles(styles)(TeamLeaderPanel);
