import React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { useIntercom } from 'react-use-intercom';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import styles from './RedirectUrlsStyles';
import TextButton from '../../../Components/Buttons/TextButton/TextButton';

const CAPTCHA_PAGE_LABELS = {
  login:
    'Hemos detectado un problema en tu comportamiento. Si esto continúa, intentá ingresar nuevamente desde un dispositivo diferente o ',
  signup:
    'Hemos detectado un problema en tu comportamiento. Si esto continúa, intentá crear tu cuenta nuevamente desde un dispositivo diferente o ',
  checkout:
    'Hemos detectado un problema en tu comportamiento. Si esto continúa, intentá realizar tu compra nuevamente desde un dispositivo diferente o ',
  createOrder:
    'Hemos detectado un problema en tu comportamiento. Si esto continúa, intentá realizar tu compra nuevamente desde un dispositivo diferente o ',
};

const CaptchaError = ({ classes, pathState }) => {
  const { page } = pathState;
  const { show } = useIntercom();

  return (
    <PageContainer withFooter>
      <div className={classes.contentContainer}>
        <Typography variant="h3">¡Lo sentimos!</Typography>
        <Typography variant="subtitle2">
          {CAPTCHA_PAGE_LABELS[page]}
          <TextButton
            title="comunicate con nosotros."
            variant="subtitle2"
            underline
            onClick={show}
          />
        </Typography>
      </div>
    </PageContainer>
  );
};

export default withStyles(styles)(CaptchaError);
