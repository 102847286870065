import { createStyles } from '@material-ui/core';

const styles = () => {
  return createStyles({
    marquee: {
      display: 'flex',
      overflow: 'hidden',
      position: 'relative',
      width: '100%',
      height: '100%',
      alignItems: 'center',

      '& .marqueeContent': {
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
        minWidth: '100%',
        height: '100%',
      },

      '& .rightToLeft': {
        animation: `$marqueeAnimationRightToLeft linear infinite`,
      },

      '& .leftToRight': {
        animation: `$marqueeAnimationLeftToRight linear infinite`,
      },
    },

    '@keyframes marqueeAnimationLeftToRight': {
      from: {
        transform: 'translateX(calc(-100% - 1rem))',
      },
      to: {
        transform: 'translateX(0)',
      },
    },

    '@keyframes marqueeAnimationRightToLeft': {
      from: {
        transform: 'translateX(0)',
      },
      to: {
        transform: 'translateX(calc(-100% - 1rem))',
      },
    },
  });
};

export default styles;
