import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    confirmationStepContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      width: '100%',
      maxWidth: 800,

      '& .confirmingActivityIndicatorContainer': {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
        height: '100%',
        minHeight: 100,
      },

      '& .confirmationStepContent': {
        alignSelf: 'center',
        gap: 20,
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },

      '& .confirmationStepTitle': {
        fontSize: theme.typography.h5.fontSize,
        textAlign: 'center',
      },

      '& .confirmationStepResumeContainer': {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      },

      '& .confirmationStepTicketsContainer': {
        padding: '0 0 10px 10px',
      },

      '& .confirmationStepLemonDiscountLabel': {
        color: theme.palette.primary.main,
        fontSize: 14,
      },

      '& .divider': {
        background: theme.palette.text.primary,
        width: '120%',
        left: '-10%',
        position: 'relative',
      },

      '& .resumeSection': {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
      },
    },

    termsButtonsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 20,
      width: '100%',
    },
    terms: {
      fontSize: 13,
      color: 'inherit',
      textAlign: 'center',
    },
    buttonsContainer: {
      marginTop: 20,
      display: 'flex',
      columnGap: 15,
      justifyContent: 'center',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
        gap: 15,
      },
    },
    button: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },

    disclaimerIcon: {
      color: '#E89A26',
    },

    disclaimerText: {
      display: 'flex',
      columnGap: 5,
      alignSelf: 'center',
      margin: '20px 10px 0',
    },
  });

export default styles;
