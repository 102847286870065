import React from 'react';
import MUIDataTable from 'mui-datatables';
import { tableTranslations } from './tableTranslations';
import priceProvider from '../../../Providers/Price/Price';

const columns = ['Fecha', 'Cantidad de tickets', 'Total ($)'];

const tableOptions = {
  ...tableTranslations,
  downloadOptions: {
    filename: 'SalesPerDayReports.csv',
  },
};

const SalesTicketsPerDayTable = ({ title, data }) => {
  const transformData = (dataset) => {
    return dataset.map(({ date, totalIncome, ticketsNumber }) => ({
      Fecha: date,
      'Cantidad de tickets': ticketsNumber,
      'Total ($)': `$${priceProvider.replaceDecimalSymbol(totalIncome)}`,
    }));
  };

  return (
    <MUIDataTable
      title={title}
      data={transformData(data)}
      columns={columns}
      options={tableOptions}
    />
  );
};

export default SalesTicketsPerDayTable;
