import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    ticketCardContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      padding: 24,
      minHeight: 150,
      textAlign: 'left',

      '& .ticketCardSectionTypeContainer': {
        display: 'flex',
      },

      '& .sectionChip': {
        borderRadius: 50,
        padding: '2px 8px',
        fontSize: 12,
        color: 'white',
        fontWeight: 'bold',
        height: 20,
      },

      '& .ticketCardTitle': {
        fontSize: 24,
        fontWeight: 500,
      },

      '& .ticketCardDescription': {
        fontSize: 14,
        fontWeight: 400,
      },

      '& .ticketCardAvailableLabel': {
        fontSize: 14,
        fontWeight: 500,
      },

      '& .ticketCardMainPrice': {
        fontSize: 24,
        fontWeight: 700,
        lineHeight: '20px',
      },

      '& .ticketCardSecondPrice': {
        fontSize: 20,
        fontWeight: 600,
        lineHeight: '20px',
        color: theme.palette.primary.main,
      },

      '& .priceInfoIcon': {
        padding: 0,
        color: theme.palette.text.primary,

        '& svg': {
          fontSize: 18,
        },
      },

      '& .ticketCardPriceSubtitle': {
        color: theme.palette.primary.main,
        fontSize: 14,
        fontWeight: 500,
      },

      '& .ticketCardImage': {
        height: 230,
        objectFit: 'cover',
        borderRadius: 16,
      },
    },
  });

export default styles;
