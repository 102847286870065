import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    container: {
      width: '100%',
      '& +div': {
        marginTop: 16,
      },
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      width: 20,
      height: 20,
    },
  });

export default styles;
