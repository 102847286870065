import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import styles from './CustomTooltipStyles';

const CustomTooltip = ({
  classes,
  active,
  label,
  payload,
  text = 'Value',
  formatter = (x) => x,
}) => {
  return (
    active &&
    payload &&
    payload.length && (
      <div className={classes.tooltip}>
        <Typography>{payload[0].payload.fullLabel || label}</Typography>
        <Typography>
          {text}:&nbsp;
          <b>{formatter(payload[0].value)}</b>
        </Typography>
      </div>
    )
  );
};

export default withStyles(styles)(CustomTooltip);
