import React from 'react';
import { useTheme } from '@material-ui/core';
import { Stack } from '@mui/material';
import VTypography from '../../VTypography/VTypography';
import VIcon from '../../VIcon/VIcon';

const VFormCard = ({ children, label, icon, description }) => {
  const theme = useTheme();

  return (
    <Stack
      borderRadius={2}
      bgcolor={theme.palette.grey[50]}
      padding={2}
      width="100%"
      maxWidth={990}
      gap={2}
    >
      <Stack>
        <Stack flexDirection="row" gap={1} alignItems="center">
          {icon && <VIcon name={icon} size={20} />}
          <VTypography variant="h5">{label}</VTypography>
        </Stack>
        <VTypography variant="subtitle6">{description}</VTypography>
      </Stack>
      <Stack gap={1}>{children}</Stack>
    </Stack>
  );
};

export default VFormCard;
