import { createTheme } from '@material-ui/core/styles';
import defaultTheme from './defaultTheme';

const lightTheme = createTheme(defaultTheme, {
  palette: {
    type: 'light',
    text: {
      primary: '#121212',
      secondary: '#383838',
    },
    background: {
      default: '#F3F3F3',
      alternative: '#E9E9E9',
      toolbar: '#121212',
      paper: '#FFFFFF',
    },
    grey: {
      50: '#FFFFFF',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#F5F5F5',
      A200: '#EEEEEE',
      A400: '#BDBDBD',
      A700: '#616161',
    },
    table: {
      background: '#FFFFFF',
      text: '#000000',
      border: '1px solid rgba(0,0,0,0.4)',
      hover: '#F4F4F4',
      disabled: '#E8E8E8',
    },
  },
});

export default lightTheme;
