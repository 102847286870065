import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    floatingButtonContainer: {
      [theme.breakpoints.down('md')]: {
        padding: '11px 16px 38px',
        position: 'sticky',
        bottom: 0,
        marginLeft: -16,
        width: '100vw',
        backgroundColor: theme.palette.background.default,
      },
    },
    submitButton: {
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  });

export default styles;
