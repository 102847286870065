import { REGULAR_EXPRESSION } from './constants';

const IDENTIFICATION_TYPES_DATA = {
  DNI: {
    label: 'DNI',
    regex: REGULAR_EXPRESSION.DNI_VALIDATION,
    errorMessage: 'Debe contener entre 7 y 8 dígitos. No uses letras ni caracteres especiales.',
  },
  PASSPORT: {
    label: 'PASAPORTE',
    regex: REGULAR_EXPRESSION.PASSPORT_VALIDATION,
    errorMessage: 'No uses caracteres especiales.',
  },
  LE: {
    label: 'L.E.',
    regex: REGULAR_EXPRESSION.LE_VALIDATION,
    errorMessage: 'Debe contener 7 dígitos. No uses letras ni caracteres especiales.',
  },
  LC: {
    label: 'L.C.',
    regex: REGULAR_EXPRESSION.LC_VALIDATION,
    errorMessage: 'Debe contener 7 dígitos. No uses letras ni caracteres especiales.',
  },
  CI: {
    label: 'C.I.',
    regex: REGULAR_EXPRESSION.CI_VALIDATION,
    errorMessage: 'Debe contener 8 dígitos. No uses letras ni caracteres especiales.',
  },
  CDI: {
    label: 'CDI',
    regex: REGULAR_EXPRESSION.CDI_VALIDATION,
    errorMessage: 'Debe contener 11 dígitos. No uses letras ni caracteres especiales.',
  },
  CUIL: {
    label: 'CUIT / CUIL',
    regex: REGULAR_EXPRESSION.CUIL_VALIDATION,
    errorMessage: 'Debe contener 11 dígitos. No uses letras ni caracteres especiales.',
  },
  OTHER: {
    label: 'OTRO',
    regex: '',
    errorMessage: '',
  },
};

export const LINE_IDENTIFICATION_TYPES = {
  DNI: {
    ...IDENTIFICATION_TYPES_DATA.DNI,
    value: 'DNI',
  },
  PASAPORTE: {
    ...IDENTIFICATION_TYPES_DATA.PASSPORT,
    value: 'PASAPORTE',
  },
  LE: {
    ...IDENTIFICATION_TYPES_DATA.LE,
    value: 'LE',
  },
  LC: {
    ...IDENTIFICATION_TYPES_DATA.LC,
    value: 'LC',
  },
};

export const MP_IDENTIFICATION_TYPES = {
  DNI: {
    ...IDENTIFICATION_TYPES_DATA.DNI,
    value: 'DNI',
  },
  CI: {
    ...IDENTIFICATION_TYPES_DATA.CI,
    value: 'CI',
  },
  LC: {
    ...IDENTIFICATION_TYPES_DATA.LC,
    value: 'LC',
  },
  LE: {
    ...IDENTIFICATION_TYPES_DATA.LE,
    value: 'LE',
  },
  Otro: {
    ...IDENTIFICATION_TYPES_DATA.OTHER,
    value: 'Otro',
  },
};

export const FACTURANTE_IDENTIFICATION_TYPES = {
  DNI: {
    ...IDENTIFICATION_TYPES_DATA.DNI,
    value: 'DNI',
  },
  'CI-EXTRANJERA': {
    ...IDENTIFICATION_TYPES_DATA.CI,
    value: 'CI-EXTRANJERA',
  },
  CDI: {
    ...IDENTIFICATION_TYPES_DATA.CDI,
    value: 'CDI',
  },
  PASAPORTE: {
    ...IDENTIFICATION_TYPES_DATA.PASSPORT,
    value: 'PASAPORTE',
  },
  CUIL: {
    ...IDENTIFICATION_TYPES_DATA.CUIL,
    value: 'CUIL',
  },
  OTHER: {
    ...IDENTIFICATION_TYPES_DATA.OTHER,
    value: 'OTHER',
  },
};

export const IDENTIFICATION_TYPES_VALIDATIONS = {
  ...FACTURANTE_IDENTIFICATION_TYPES,
  ...MP_IDENTIFICATION_TYPES,
  ...LINE_IDENTIFICATION_TYPES,
};

export const ID_TYPE_OPTIONS = Object.keys(FACTURANTE_IDENTIFICATION_TYPES).map((type) => {
  const { label, value } = FACTURANTE_IDENTIFICATION_TYPES[type];
  return { label, value };
});
