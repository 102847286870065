import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    pageContainer: {
      flex: 1,
      minHeight: '100vh',
    },
    pageContentContainer: {
      rowGap: 40,
      display: 'flex',
      flexDirection: 'column',
      width: '80%',
      margin: '0 auto',
      [theme.breakpoints.down('md')]: {
        width: 'unset',
        margin: 'unset',
        padding: '0 16px',
      },
    },
  });

export default styles;
