import React from 'react';
import SelectInput from '../../../../Venti-UI-Kit/Inputs/SelectInput';
import VFormCard from '../../../../Venti-UI-Kit/VForms/VFormCard/VFormCard';
import { usePinnedEventForm } from './PinnedEventForm.hook';

const PinnedEventForm = ({ formState, events, onChange }) => {
  const { eventsOptions } = usePinnedEventForm(events);

  return (
    <VFormCard label="Evento destacado">
      <SelectInput
        onChange={onChange}
        options={eventsOptions}
        name="pinnedEventId"
        value={formState?.pinnedEventId.value}
      />
    </VFormCard>
  );
};

export default PinnedEventForm;
