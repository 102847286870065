import { createStyles } from '@material-ui/core';

const header = {
  fontWeight: 500,
  cursor: 'pointer',
  paddingBottom: '10px',
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
};

const styles = (theme) =>
  createStyles({
    iconButton: {
      height: 40,
      width: 40,
      padding: 0,
    },
    cell: {
      padding: '5px 0',
      textAlign: 'center',
      width: '20%',
    },
    headerWithArrow: {
      ...header,
      paddingLeft: '25px',
    },
    headerWithNoArrow: {
      ...header,
    },
    inputContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    textFieldRoot: {
      width: 70,
      height: 38,
      background: theme.palette.grey[50],
      borderRadius: 5,
      '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
        {
          display: 'none',
        },
    },
    arrow: {
      paddingBottom: 4,
    },
  });

export default styles;
