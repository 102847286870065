import React from 'react';
import { Stack } from '@mui/material';
import BottomSheet from '../../../Venti-UI-Kit/BottomSheet/BottomSheet';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import Button from '../../Buttons/Button/Button';

const BuyOnSecondaryMarketBottomSheet = ({ open, onClose, secondaryMarketLink }) => {
  const handleGoToPosts = () => {
    window.location.href = secondaryMarketLink;
  };

  return (
    <BottomSheet open={open} onClose={onClose}>
      <Stack padding="16px" gap={2.5}>
        <Stack gap={1} paddingBottom="32px">
          <VTypography variant="h4">Conseguí tu entrada en el mercado de Venti Swap</VTypography>
          <VTypography variant="body2">
            ¿No llegaste a comprar tu entrada? Ingresa a Venti Swap y mira las publicaciones
            disponibles
          </VTypography>
        </Stack>
        <Button color="alternative" title="Ir a Venti Swap" onClick={handleGoToPosts} />
      </Stack>
    </BottomSheet>
  );
};

export default BuyOnSecondaryMarketBottomSheet;
