import React from 'react';
import DateFnsUtils from '@date-io/date-fns';

import esLocale from 'date-fns/locale/es';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Stack, useTheme } from '@mui/material';
import RenderInput from './RenderInput';
import { icons } from '../../Providers/Icons/IconsProvider';
import VTypography from '../../Venti-UI-Kit/VTypography/VTypography';

const CustomDatePicker = ({
  minDate = new Date(),
  maxDate,
  value = '',
  onChange,
  helperText,
  showErrors,
  clearable = false,
  format = "d 'de' MMMM",
  title,
  required,
  leftIcon,
}) => {
  const theme = useTheme();

  return (
    <Stack>
      {title && (
        <Stack direction="row" marginBottom={0.5}>
          {leftIcon}
          <VTypography variant="body1">{title}</VTypography>
          {required && (
            <VTypography variant="body2" color={theme.palette.error.main}>
              *
            </VTypography>
          )}
        </Stack>
      )}
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <DatePicker
          label="Fecha"
          name="date"
          maxDate={maxDate}
          minDate={minDate}
          value={value}
          onChange={onChange}
          icon={icons.date}
          format={format}
          TextFieldComponent={RenderInput}
          okLabel="ok"
          cancelLabel="Cancelar"
          clearable={clearable}
          clearLabel="Eliminar"
          helperText={helperText}
          showErrors={showErrors}
        />
      </MuiPickersUtilsProvider>
    </Stack>
  );
};

export default CustomDatePicker;
