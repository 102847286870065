import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    tooltip: {
      backgroundColor: theme.palette.grey[50],
      border: `2px solid ${theme.palette.charts.primary}`,
      display: 'flex',
      borderRadius: 12,
      flexDirection: 'column',
      padding: 10,
      '& p': {
        margin: 0,
      },
    },
  });

export default styles;
