import React from 'react';
import { Stack } from '@mui/material';
import { withStyles, useTheme } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import styles from './SettlementPreview.styles';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import { useSettlementPreview } from './SettlementPreview.hook';
import TicketInvoicePreview from '../InvoicePreview/TicketInvoicePreview';
import RebateInvoicePreview from '../InvoicePreview/RebateInvoicePreview';
import SelectInput from '../../../Venti-UI-Kit/Inputs/SelectInput';
import Button from '../../Buttons/Button/Button';
import VIconButton from '../../Buttons/IconButton/IconButton';
import EditRebateModal from '../Modals/EditRebateModal';
import SendSettlementModal from '../Modals/SendSettlementModal';

const SettlementPreview = ({ classes, urlParams }) => {
  const {
    settlement,
    settlementType,
    onSettlementTypeChange,
    settlementTypeOptions,
    refreshSettlement,
    rebatePercentage,
    updateRebatePercentage,
    rebateModalOpen,
    setRebateModalOpen,
    settlementModalOpen,
    setSettlementModalOpen,
    sendSettlement,
  } = useSettlementPreview(urlParams);
  const theme = useTheme();

  return (
    <Stack className={classes.container}>
      <VTypography variant="h4" align="left" width="100%">
        Liquidación
      </VTypography>
      <Stack direction={{ xs: 'column', md: 'row' }} className={classes.actionsContainer}>
        <SelectInput
          width={{ xs: '100%', sm: 300 }}
          title="Tipo de Liquidación"
          value={settlementType}
          options={settlementTypeOptions}
          onChange={onSettlementTypeChange}
        />
        <Stack direction={{ xs: 'column', md: 'row' }} gap={1}>
          <VIconButton
            type="submit"
            Icon={<RefreshIcon />}
            style={{ color: theme.palette.text.secondary }}
            onClick={refreshSettlement}
            size={48}
            variant="outlined"
          />
          <Button
            title={rebatePercentage > 0 ? `Editar Rebate (${rebatePercentage}%)` : 'Agregar Rebate'}
            width={210}
            onClick={() => setRebateModalOpen(true)}
          />
          <Button
            title="Enviar Liquidación"
            width={200}
            onClick={() => setSettlementModalOpen(true)}
          />
        </Stack>
      </Stack>
      <Stack marginBottom={2} gap={4} width="100%" direction={{ xs: 'column', md: 'row' }}>
        <TicketInvoicePreview settlement={settlement} />
        <RebateInvoicePreview settlement={settlement} />
      </Stack>
      <EditRebateModal
        open={rebateModalOpen}
        rebatePercentage={rebatePercentage}
        handleClose={() => setRebateModalOpen(false)}
        updateRebatePercentage={updateRebatePercentage}
      />
      <SendSettlementModal
        open={settlementModalOpen}
        handleClose={() => setSettlementModalOpen(false)}
        handleCreate={sendSettlement}
      />
    </Stack>
  );
};

export default withStyles(styles)(SettlementPreview);
