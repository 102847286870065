import React from 'react';
import { withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import styles from './FlagIcon.styles';
import ArgentinaFlag from '../../assets/countryFlags/argentina.png';
import BrasilFlag from '../../assets/countryFlags/brasil.png';
import UruguayFlag from '../../assets/countryFlags/uruguay.png';
import ChileFlag from '../../assets/countryFlags/chile.png';
import World from '../../assets/countryFlags/world.png';

const FlagIcon = ({ classes, country, size = { xs: 20, md: 25 } }) => {
  if (!country) return;

  const getCountryFlagSrc = () => {
    switch (country.toLowerCase()) {
      case 'argentina':
        return ArgentinaFlag;
      case 'brasil':
        return BrasilFlag;
      case 'uruguay':
        return UruguayFlag;
      case 'chile':
        return ChileFlag;
      default:
        return World;
    }
  };
  return (
    <Stack className={classes.flagBox} width={size}>
      <img className={classes.flag} src={getCountryFlagSrc()} alt="countryFlag" />
    </Stack>
  );
};

export default withStyles(styles)(FlagIcon);
