import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    changePasswordPage: {
      textAlign: 'left',

      [theme.breakpoints.up('sm')]: {
        width: 472,
        alignSelf: 'center',
      },

      '& .changePasswordPageTitle': {
        fontSize: 36,
        fontWeight: 700,
        color: theme.palette.text.primary,
        textAlign: 'left',
        lineHeight: '42px',
      },
      '& .changePasswordPageTexts': {
        fontSize: 16,
      },
    },
    mailFromModal: {
      color: theme.palette.primary.main,
    },
  });

export default styles;
