import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    goBackButtonContainer: {
      gap: 8,
      alignItems: 'center',
    },
    goBackIconButton: {
      padding: 0,
      border: 'none',
      background: 'none',
      cursor: 'pointer',
      height: 24,

      [theme.breakpoints.down('sm')]: {
        height: 18,
      },

      '& > svg': {
        height: '100%',
      },
    },
  });

export default styles;
