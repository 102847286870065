import { FINANCIAL_OPERATIONS_TYPES } from '../../../../constants/types';
import { translate } from '../../../../utils/translate';

export const LABELS = {
  TYPE: 'Tipo',
  AMOUNT: 'Valor',
  NOTES: 'Notas',
  PAYMENT_DATE: 'Fecha de pago',
  CREATED_AT: 'Fecha de creación',
  ACTIONS: 'Acciones',
};

export const TYPES_OPTIONS = Object.values(FINANCIAL_OPERATIONS_TYPES).map((type) => ({
  label: translate(type),
  value: type,
}));
