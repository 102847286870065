import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 20,
      alignItems: 'center',
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      rowGap: 20,
      maxWidth: '80%',
      wordBreak: 'break-word',
    },
    newTicketButton: {
      background: theme.palette.buttons.background,
      borderRadius: 8,
      width: 'fit-content',
      color: theme.palette.buttons.text,
      [theme.breakpoints.down('sm')]: {
        position: 'unset',
      },
    },
    desktopContainer: {
      width: '90%',
      backgroundColor: theme.palette.grey[50],
      height: '100%',
      borderRadius: 15,
      [theme.breakpoints.down('md')]: {
        width: 'calc(100% - 50px)',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    mobileContainer: {
      padding: '0 0 40px',
      width: '90%',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  });

export default styles;
