import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { Box, IconButton, Modal, Stack } from '@mui/material';
import ReactHtmlParser from 'react-html-parser';
import CloseIcon from '@mui/icons-material/Close';
import modalStyles from '../../../Venti-UI-Kit/Modals/ModalStyles';
import CustomLink from '../../CustomLink/CustomLink';
import styles from './EventPaymentMethodsAndPromotionsModalStyles';
import { ReactComponent as MastercardImage } from '../../../assets/images/mastercard.svg';
import { ReactComponent as VisaImage } from '../../../assets/images/visa.svg';
import { ReactComponent as AmexImage } from '../../../assets/images/amex.svg';
import { ReactComponent as CabalImage } from '../../../assets/images/cabal.svg';

const cardImages = {
  AMEX: <AmexImage />,
  VISA: <VisaImage />,
  MASTER: <MastercardImage />,
  CABAL: <CabalImage />,
};

const EventPromotionsModal = ({
  classes,
  open,
  title,
  subtitle,
  onClose,
  eventPromotions,
  availableCards,
}) => {
  const hasEventPromotions = !!eventPromotions?.length;
  const hasAvailableCards = !!availableCards?.length;

  return (
    <Modal open={open} className={classes.modalContainer} onClose={onClose}>
      <Box className="modalBoxContainer bankPromotionModal">
        <IconButton className="closeButton" onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Typography className="modalTitle">{title}</Typography>
        <Stack overflow="auto" gap={4}>
          <Typography className="modalSubtitle">{subtitle}</Typography>

          {hasAvailableCards && (
            <Stack gap={3}>
              <Typography className="modalTitleH2">Tarjetas de débito y crédito</Typography>
              <Box display="flex" gap={2} flexWrap="wrap">
                {availableCards.map(({ issuerCode }) => cardImages[issuerCode])}
              </Box>
            </Stack>
          )}

          {hasEventPromotions && (
            <Stack gap={3}>
              {hasAvailableCards && (
                <Typography className="modalTitleH2">Promociones bancarias</Typography>
              )}

              {eventPromotions.map(({ promotionText, promotionImages, promotionLink }, index) => (
                <Stack gap={1.5} key={promotionText}>
                  {ReactHtmlParser(promotionText)}
                  <Box display="flex" gap={2} key={index} flexWrap="wrap">
                    {promotionImages.map((image) => (
                      <img src={image} alt="card" key={image} className="promotionImage" />
                    ))}
                  </Box>
                  {promotionLink && (
                    <CustomLink title="Ver más información" target="_blank" href={promotionLink} />
                  )}
                </Stack>
              ))}
            </Stack>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

export default withStyles(styles)(withStyles(modalStyles)(EventPromotionsModal));
