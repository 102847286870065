import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    goBackButton: {
      width: '18px !important',
      height: '28px !important',

      [theme.breakpoints.down('md')]: {
        width: '18px !important',
        height: '28px !important',
      },
    },
  });

export default styles;
