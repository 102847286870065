import { useCallback, useEffect, useState } from 'react';

export const useInfinitScroll = ({
  endpoint,
  params = [],
  options = {},
  formatter,
  itemsLimit = 10,
}) => {
  const initialState = {
    totalPages: 1,
    currentPage: 1,
    items: [],
  };

  const [fetchingState, setFetchingState] = useState(initialState);
  const [isGettingMoreItems, setIsGettingMoreItems] = useState(false);
  const [debounce, setDebounce] = useState(false);

  const getMoreItems = useCallback(async () => {
    setIsGettingMoreItems(true);

    const { data, totalPages } = await endpoint(...params, {
      page: fetchingState.currentPage,
      limit: itemsLimit,
      ...options,
    });

    setFetchingState((prev) => ({
      items: formatter ? formatter(prev.items, data) : [...prev.items, ...data],
      currentPage: prev.currentPage + 1,
      totalPages,
    }));

    setIsGettingMoreItems(false);
  }, [fetchingState.currentPage, params, itemsLimit, formatter]);

  const shouldLoadMoreItems = useCallback(() => {
    if (debounce) return;

    const { scrollHeight } = document.body;
    const { innerHeight, scrollY } = window;
    const isNearEnd = scrollHeight - (scrollY + innerHeight) < innerHeight * 0.2;

    if (
      isNearEnd &&
      !isGettingMoreItems &&
      fetchingState.currentPage - 1 < fetchingState.totalPages
    ) {
      setDebounce(true);
      getMoreItems();
      setTimeout(() => setDebounce(false), 200);
    }
  }, [isGettingMoreItems, fetchingState.currentPage, fetchingState.totalPages, getMoreItems]);

  useEffect(() => {
    window.addEventListener('scroll', shouldLoadMoreItems);
    return () => window.removeEventListener('scroll', shouldLoadMoreItems);
  }, [shouldLoadMoreItems]);

  useEffect(() => {
    shouldLoadMoreItems();
  }, [shouldLoadMoreItems]);

  useEffect(() => {
    setFetchingState(initialState);
  }, [JSON.stringify(options)]);

  return { ...fetchingState, isGettingMoreItems };
};
