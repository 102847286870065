import { REGULAR_EXPRESSION } from '../../../../constants/constants';

const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

const splitDate = (dateToSplit) => {
  const date = new Date(dateToSplit);
  const options = {
    weekday: 'short',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    year: 'numeric',
    hour12: false,
  };
  const localDateFormatted = date.toLocaleString('es-ES', options);

  const regex = REGULAR_EXPRESSION.DATE_INFO_SPLITTER;
  const [, dayOfWeek, day, month, year, time] = localDateFormatted.match(regex);
  return {
    dayOfWeek,
    day,
    month,
    year,
    time,
  };
};

export const formatEventCardDate = (eventDate) => {
  if (!eventDate) return '';
  const { dayOfWeek, day, month, time } = splitDate(eventDate);
  return `${capitalize(dayOfWeek)}, ${capitalize(month)}.${day} - ${time}HS`;
};

export const formatCattaneoEventCardDate = (eventDate) => {
  if (!eventDate) return '';
  const { dayOfWeek, day, month } = splitDate(eventDate);

  return `${capitalize(dayOfWeek)}, ${capitalize(month)}.${day} - ${capitalize(
    'dom'
  )}, ${capitalize(month)}.${parseInt(day) + 1}`;
};

export const formatEventTicketCardDate = (eventDate) => {
  if (!eventDate) return '';
  const { day, month, year } = splitDate(eventDate);
  return `${day} de ${capitalize(month)} ${year}`;
};

export const formatCattaneoEventTicketCardDate = (eventDate) => {
  if (!eventDate) return '';
  const { day, month, year } = splitDate(eventDate);
  return `${day} de ${capitalize(month)} ${year} - ${parseInt(day) + 1} de ${capitalize(
    month
  )} ${year}`;
};
