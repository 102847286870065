import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    pageContainer: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 40,
    },
    usersContainer: {
      alignSelf: 'center',
      rowGap: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '55%',
      [theme.breakpoints.down('sm')]: {
        width: '75%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '80%',
        padding: 0,
      },
    },
    users: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: 110,
      marginTop: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'auto',
      width: '90%',
      paddingLeft: '5rem',
      paddingRight: '5rem',
      [theme.breakpoints.down('sm')]: {
        width: '98%',
        paddingLeft: '0rem',
        paddingRight: '0rem',
      },
    },
  });

export default styles;
