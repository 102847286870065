import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '../../../Venti-UI-Kit/Buttons/Button';

import styles from './InfoCard.styles';

const InfoCard = ({ classes, newTab, icon, title, text, href, button }) => {
  const target = newTab ? '_blank' : '_self';

  return (
    <Card elevation={0} className={classes.card}>
      <div className={classes.descriptionInfo}>
        <img src={icon} className={classes.image} alt="icon" />
        <Typography className={classes.title} variant="body1">
          {title}
        </Typography>
        <Typography className={classes.description} variant="caption">
          {text}
        </Typography>
      </div>
      {button || (
        <Button className={classes.button} target={target} href={href}>
          Ir
        </Button>
      )}
    </Card>
  );
};

export default withStyles(styles)(InfoCard);
