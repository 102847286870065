import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 32,
      alignItems: 'center',
      padding: '40px 16px 32px',

      [theme.breakpoints.up('md')]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridAutoColumns: 'auto',
        gridAutoRows: 'auto',
        gridGap: 24,
      },

      [theme.breakpoints.up('xl')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
    },
  });

export default styles;
