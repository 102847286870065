export const changeThemeFontFamily = (fontFamily, currentTypography) => {
  const newTypography = Object.entries(currentTypography).reduce(
    (newTypography, [variant, value]) => {
      if (typeof value === 'object') newTypography[variant] = { ...value, fontFamily };

      return newTypography;
    },
    {}
  );

  return { ...newTypography, fontFamily };
};
