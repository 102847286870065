import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import styles from './TicketTypesReportsDataAccordionHeaderStyles';
import { validateUserRole } from '../../../../utils/utils';
import { USER_PERMISSIONS } from '../../../../constants/types';
import { useAuth } from '../../../../Providers/AuthProvider/AuthProvider';

const TicketTypesReportsDataAccordionHeader = ({
  classes,
  title,
  subtitle,
  totalValue,
  currentValue,
}) => {
  const { user } = useAuth();
  return (
    <>
      <div className={classes.accordionHeader}>
        <Typography className={classes.accordionTitle}>{title}</Typography>
        <Typography className={classes.accordionSubtitle}>
          {currentValue}
          {totalValue > 0 && `/${totalValue}`}
        </Typography>
      </div>

      {validateUserRole(user.role, USER_PERMISSIONS.ADMIN_WITH_READ_ONLY) && (
        <div className={classes.accordionSubHeader}>
          <Typography>
            <b>{subtitle}</b>
          </Typography>
          <Typography className={classes.textSubHeader}>
            <b>{totalValue - currentValue}</b>
          </Typography>
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(TicketTypesReportsDataAccordionHeader);
