import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    searchButton: {
      '&.MuiButtonBase-root': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 'unset',
        backgroundColor: 'transparent',
        color: theme.palette.header.text,

        '&:hover': {
          backgroundColor: 'transparent',
          opacity: 0.9,
        },
      },

      '& .MuiIcon-root': {
        fontSize: 36,
      },
    },
    modalBackground: {
      backdropFilter: 'blur(2px)',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
    modalContent: {
      position: 'relative',
      top: '10%',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 16px',
      maxWidth: 700,
      margin: '0 auto',
      display: 'flex',
    },
    searchersContainer: {
      width: '100%',
      gap: 16,
    },
  });

export default styles;
