import React from 'react';
import { withStyles } from '@material-ui/core';
import { Button, Stack } from '@mui/material';
import styles from './DatePickerHeader.styles';
import VIcon from '../../../../../Venti-UI-Kit/VIcon/VIcon';

const DatePickerHeader = ({
  classes,
  monthDate,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  locale,
}) => {
  const month = monthDate.toLocaleString(locale, { month: 'long' });
  const year = monthDate.toLocaleString(locale, { year: 'numeric' });
  const formattedDate = `${month} ${year}`;

  return (
    <Stack justifyContent="space-between" direction="row">
      <Button
        disableRipple
        className={classes.navigationButton}
        type="button"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <VIcon name="arrow_back_ios" fontSize="small" />
      </Button>
      <span className={classes.monthLabel}>{formattedDate}</span>
      <Button
        disableRipple
        className={classes.navigationButton}
        type="button"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <VIcon name="arrow_forward_ios" fontSize="small" />
      </Button>
    </Stack>
  );
};

export default withStyles(styles)(DatePickerHeader);
