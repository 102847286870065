import { createTheme } from '@material-ui/core/styles';
import darkTheme from '../darkTheme';

const underClubTheme = createTheme(darkTheme, {
  palette: {
    primary: {
      main: '#9C7FF6',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fff',
      contrastText: '#000',
    },
    footer: {
      text: '#fff',
      background: '#9c7ff6',
    },
    header: {
      background: '#000000',
      buttonText: '#9c7ff6',
      buttonBackground: '#fff',
      subHeaderBackground: '#000000',
      text: '#FFFFFF',
    },
    buttons: {
      disabled: '#5C5C5C',
      disabledText: '#2A2A2A',
      main: {
        background: '#9c7ff6',
        text: '#FFF',
        hover: '#413261',
      },
      alternative: {
        background: '#fff',
        text: '#000',
        hover: '#CCCCCC',
      },
    },
    charts: {
      primary: '#6d4bb4',
      secondary: '#cabee4',
      text: '#fff',
    },
  },
});

export default underClubTheme;
