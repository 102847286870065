import api from '../api/api';
import { USER_PERMISSIONS } from '../constants/types';
import { useAuth } from '../Providers/AuthProvider/AuthProvider';
import { useNotifications } from '../Providers/NotificationsProvider/NotificationsProvider';
import { handleRequestHelper } from '../utils/helpers';
import { validateUserRole } from '../utils/utils';

export const useUser = () => {
  const { user } = useAuth();
  const { showNotification } = useNotifications();
  const loggedUserProducersIds = user?.producers.map(({ id }) => id);
  const loggedUserIsSuperAdmin = validateUserRole(user.role, USER_PERMISSIONS.SUPER_ADMIN);

  const getUserByMail = async (email) => {
    return handleRequestHelper({
      endpoint: () => api.getUserByMail(email),
      showNotification,
      errorCodesNotShowingNotification: [404],
      onSuccess: ({ user }) => user,
    });
  };

  const checkUserProducerBelongsToLoggedUserProducers = (producerId) => {
    return loggedUserIsSuperAdmin ? true : loggedUserProducersIds.includes(producerId);
  };

  const formatInitialProducersData = (initialProducers) => {
    return initialProducers
      .filter(({ id }) => checkUserProducerBelongsToLoggedUserProducers(id))
      .reduce(
        (formattedData, { id }) => ({
          ...formattedData,
          [id]: true,
        }),
        {}
      );
  };

  const formatInitialTeamLeadersData = (initialTeamLeaders) => {
    return initialTeamLeaders.reduce(
      (formattedData, { TeamLeader: { id, code, producerId } }) =>
        checkUserProducerBelongsToLoggedUserProducers(producerId) && {
          ...formattedData,
          [producerId]: { initialTeamLeaderId: id, teamLeaderId: id, code },
        },
      {}
    );
  };

  const formatInitialEventsAccessesData = (initialEventAccesses) => {
    return initialEventAccesses.reduce((formattedData, { producerId, events }) => {
      formattedData[producerId] = {};
      formattedData[producerId].allEvents = false;

      events.forEach(({ id, isBlacklisted }) => {
        formattedData[producerId][id] = !isBlacklisted;
      });

      return formattedData;
    }, {});
  };

  const getUserDataFormatted = (user) => {
    const { mail, alias, role, producers, sellerTeamLeaders, id } = user;

    const initialUserInfo = { mail, alias, role, id };
    const initialProducers = formatInitialProducersData(producers);
    const initialTeamLeaders = formatInitialTeamLeadersData(sellerTeamLeaders);

    return {
      initialUserInfo,
      initialProducers,
      initialTeamLeaders,
    };
  };

  const getUserDataFormInfo = async (userId) => {
    return handleRequestHelper({
      endpoint: () => api.getUser(userId),
      showNotification,
      onSuccess: ({ user }) => getUserDataFormatted(user),
    });
  };

  const getUserEventsRestrictedAccesses = async (userId) => {
    return handleRequestHelper({
      endpoint: () => api.getUserEventsRestrictedAccesses(userId),
      showNotification,
      onSuccess: ({ eventsRestrictedAccesses }) =>
        formatInitialEventsAccessesData(eventsRestrictedAccesses),
    });
  };

  return {
    getUserByMail,
    getUserDataFormInfo,
    getUserEventsRestrictedAccesses,
    getUserDataFormatted,
  };
};
