import React from 'react';
import { useTheme } from '@material-ui/core';
import { Divider, Stack } from '@mui/material';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';
import CardField from '../CardField/CardField';
import Chip from '../../../../Venti-UI-Kit/Chip/Chip';
import { formatDatetime, formatPrice } from '../../../../utils/utils';
import { getOrderColorsByStatus } from '../../../../Components/OrderCard/OrderCard.utils';
import { translate } from '../../../../utils/translate';
import { ORDER_STATUS, ORDER_TYPE } from '../../../../constants/types';

const OrderInfoCard = ({ order }) => {
  const theme = useTheme();
  const { backgroundColor, color } = getOrderColorsByStatus(order.status, theme);
  const orderStatusIsRefunded =
    order.status === ORDER_STATUS.REFUNDED_WITH_FEE ||
    order.status === ORDER_STATUS.REFUNDED_WITHOUT_FEE;

  const ticketsPriceColor =
    order.status === ORDER_STATUS.REFUNDED_WITHOUT_FEE ||
    order.status === ORDER_STATUS.REFUNDED_WITH_FEE
      ? theme.palette.error
      : theme.palette.success;

  const feePriceColor =
    order.status === ORDER_STATUS.REFUNDED_WITH_FEE ? theme.palette.error : theme.palette.success;

  const isAnOnlineOrder = order.type === ORDER_TYPE.ONLINE;

  return (
    <Stack
      direction="column"
      flex={1}
      padding={2}
      borderRadius={2}
      boxShadow="0px 2px 4px 0px #00000040"
      backgroundColor="white"
      gap={2}
    >
      <Stack direction="row" justifyContent="space-between">
        <VTypography variant="subtitle3">Orden: #{order.id}</VTypography>
        <Chip label={translate(order.status)} bgcolor={backgroundColor} labelColor={color} />
      </Stack>

      <CardField
        title="Total orden"
        componentValue={
          <Chip
            label={formatPrice(order.payment.total, false, true, order.payment.currency)}
            labelVariant="subtitle1"
          />
        }
      />

      <Stack direction="row" gap={2}>
        {isAnOnlineOrder && <CardField title="Método de pago" textValue={order.payment.method} />}
        <CardField title="Tipo de orden" textValue={translate(order.type)} />
      </Stack>

      {isAnOnlineOrder && <CardField title="ID de pago" textValue={order.payment.paymentId} />}

      {orderStatusIsRefunded && (
        <CardField title="ID de devolución" textValue={order.payment.refundId} />
      )}
      <Divider />

      <Stack direction="row" gap={2}>
        <CardField
          title="Tickets"
          componentValue={
            <Chip
              label={formatPrice(order.payment.tickets, false, true, order.payment.currency)}
              labelVariant="button1"
              labelColor={ticketsPriceColor.main}
              bgcolor={ticketsPriceColor.light}
            />
          }
        />
        <CardField
          title="Fee"
          componentValue={
            <Chip
              label={formatPrice(order.payment.fee, false, true, order.payment.currency)}
              labelVariant="button1"
              labelColor={feePriceColor.main}
              bgcolor={feePriceColor.light}
            />
          }
        />
      </Stack>

      <Stack direction="row" gap={2}>
        <CardField
          title="Fecha de creación"
          textValue={formatDatetime(order.createdAt).fullTimeDate}
        />
        <CardField
          title="Fecha de actualización"
          textValue={formatDatetime(order.updatedAt).fullTimeDate}
        />
      </Stack>
    </Stack>
  );
};

export default OrderInfoCard;
