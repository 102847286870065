import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    searchFileInput: {
      display: 'none',
    },
    canvasContainer: {
      backgroundColor: theme.palette.grey[300],
      border: `1px solid ${theme.palette.grey[600]}`,
      overflow: 'hidden',
      borderRadius: 8,
      alignItems: 'center',
      justifyContent: 'center',

      '& .showOnHover': {
        display: 'none',
      },

      '& .showAlways': {
        display: 'flex',
      },

      '& .uploaderText': {
        position: 'absolute',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(10px)',
        color: theme.palette.common.black,
        alignItems: 'center',
        padding: 8,
        borderRadius: 8,
      },

      '&:hover': {
        cursor: 'pointer',

        '& .showOnHover': {
          display: 'flex',
        },
      },
    },
    canvas: {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
    },
    cropperImagePreview: {
      objectFit: 'contain',
      maxHeight: 550,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    cropper: {
      borderRadius: 8,
      '& .ReactCrop__crop-mask': {
        display: 'none',
      },
      '& .ReactCrop__child-wrapper > img': {
        maxWidth: 500,
      },
    },
  });

export default styles;
