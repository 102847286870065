import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { Box } from '@mui/material';
import styles from './MailValidationPagesStyles';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import TextButton from '../../../Components/Buttons/TextButton/TextButton';
import CustomLink from '../../../Components/CustomLink/CustomLink';
import { handleRequestHelper } from '../../../utils/helpers';
import { ReactComponent as Image } from '../../../assets/images/mailValidationSent.svg';
import { NOTIFICATION_VARIANT } from '../../../constants/types';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import api from '../../../api/api';

const MailValidationSentPage = ({ classes, pathState }) => {
  const userMail = pathState?.mail;
  const { showNotification } = useNotifications();

  const resendMailBody = {
    mail: userMail,
    hostname: window.location.hostname,
  };

  const resendMail = async () => {
    handleRequestHelper({
      endpoint: () => api.sendCustomerVerificationMail(resendMailBody),
      showNotification,
      onSuccess: () =>
        showNotification(
          'Te reenviamos el email para validar tu cuenta. ¡Revisá tu bandeja de entrada!',
          NOTIFICATION_VARIANT.SUCCESS
        ),
    });
  };

  return (
    <PageContainer title="Verificá tu email">
      <Box className={classes.mailValidationPage}>
        <Box display="flex" gap={2} flexDirection="column">
          <Box className="mailValidationPageImage">
            <Image />
          </Box>

          <Box display="flex" gap={2} flexDirection="column">
            <Typography className="mailValidationPageTitle">Verificá tu email</Typography>
            <Typography className="mailValidationPageText16" component="span">
              Te enviamos un email a
              <Typography className="mailValidationPageMailText">{userMail}</Typography>
              ¡Revisalo!
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box display="flex" gap={3} flexDirection="column">
            <Typography className="mailValidationPageText14">
              ¿No recibiste nuestro email? No te olvides revisar en “Promociones” o “Spam”.
              <br />
              <TextButton title="Reenviar email" onClick={resendMail} />
            </Typography>
            <Typography className="mailValidationPageText14">
              ¿Ingresaste mal tu email? <CustomLink to="/signup" title="Ir atrás" />
            </Typography>
          </Box>
        </Box>
      </Box>
    </PageContainer>
  );
};

export default withStyles(styles)(MailValidationSentPage);
