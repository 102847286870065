import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { Box, Divider } from '@mui/material';
import { parse } from 'date-fns';
import styles from './SignupPageStyles';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import { useForm } from '../../../hooks/useForm';
import { INPUT_TYPE, HTTP_CODE, NOTIFICATION_VARIANT } from '../../../constants/types';
import TextInput from '../../../Venti-UI-Kit/Inputs/TextInput';
import CustomLink from '../../../Components/CustomLink/CustomLink';
import Button from '../../../Components/Buttons/Button/Button';
import api from '../../../api/api';
import ReCaptchaMessage from '../../../Components/ReCaptchaMessage/ReCaptchaMessage';
import PowerByVenti from '../../../Components/PowerByVenti/PowerByVenti';
import { handleRequestHelper } from '../../../utils/helpers';
import { useReCaptcha } from '../../../hooks/useReCaptcha';
import GoogleReCaptcha from '../../../Components/GoogleReCaptcha/GoogleReCaptcha';
import TermsAndConditionsLabel from '../LoginPage/TermsAndConditionsModal/TermsAndConditionsLabel';
import CheckBox from '../../../Venti-UI-Kit/CheckBox/CheckBox';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import history from '../../../appHistory';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { ARGENTINA_PROVINCES } from '../../../constants/constants';
import SelectInput from '../../../Venti-UI-Kit/Inputs/SelectInput';
import { ID_TYPE_OPTIONS } from '../../../constants/identificationTypes';
import { GENDERS } from '../../../constants/selectorOptions';

const SignupPage = ({ classes }) => {
  const { showNotification } = useNotifications();
  const [isSignupButtonDisabled, setIsSignupButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const { buttonActionDisabled, validateRecaptchaToken } = useReCaptcha({
    history,
    page: 'signup',
    action: 'login',
  });

  const params = new URLSearchParams(location.search);
  const email = params.get('email');

  const {
    formValues,
    formState,
    isFormComplete,
    handleInputChange,
    validateFormInputs,
    setInputError,
  } = useForm({
    firstName: {
      title: 'Nombre',
      type: INPUT_TYPE.ALPHA,
    },
    lastName: {
      title: 'Apellido',
      type: INPUT_TYPE.ALPHA,
    },
    idType: {
      title: 'Tipo de identificación',
      type: INPUT_TYPE.ID_TYPE,
    },
    idNumber: {
      title: 'Número de identificación',
      type: INPUT_TYPE.ID_NUMBER,
    },
    mail: {
      title: 'Email',
      type: INPUT_TYPE.MAIL,
      value: email,
    },
    phone: {
      title: 'Número de teléfono',
      type: INPUT_TYPE.NUMBER,
      required: false,
    },
    password: {
      title: 'Contraseña',
      type: INPUT_TYPE.PASSWORD,
    },
    confirmPassword: {
      title: 'Confirmar contraseña',
      type: INPUT_TYPE.CONFIRM_PASSWORD,
    },
    residenceLocation: {
      title: 'Lugar de residencia',
      type: INPUT_TYPE.TEXT,
    },
    birthDate: {
      title: 'Fecha de nacimiento (DD/MM/AAAA)',
      type: INPUT_TYPE.BIRTH_DATE,
    },
    gender: {
      title: 'Género',
      type: INPUT_TYPE.TEXT,
    },
  });

  const signup = async () => {
    setIsLoading(true);
    const isCaptchaValid = await validateRecaptchaToken();
    if (!isCaptchaValid) {
      setIsLoading(false);
      return;
    }

    setIsSignupButtonDisabled(true);
    const isFormValid = validateFormInputs();

    if (!isFormValid) {
      setIsSignupButtonDisabled(false);
      showNotification('Complete correctamente los campos', NOTIFICATION_VARIANT.ERROR);
      setIsLoading(false);
      return;
    }

    const signupBody = {
      user: {
        firstName: formState.firstName.value,
        lastName: formState.lastName.value,
        identificationType: formState.idType.value,
        identificationValue: formState.idNumber.value,
        mail: formState.mail.value,
        phone: formState.phone.value,
        password: formState.password.value,
        residenceLocation: formState.residenceLocation.value,
        birthDate: parse(formState.birthDate.value, 'dd/MM/yyyy', new Date()),
        gender: formState.gender.value,
      },
      hostname: window.location.hostname,
    };

    await handleRequestHelper({
      endpoint: () => api.signup(signupBody),
      showNotification,
      errorCodesNotShowingSnackbar: [HTTP_CODE.CONFLICT],
      onSuccess: () =>
        history.push({
          pathname: '/mailvalidation/sent',
          state: { mail: formState.mail.value },
        }),
      onFailure: ({ statusCode }) => {
        if (statusCode === HTTP_CODE.CONFLICT)
          setInputError(
            'mail',
            'El email que ingresaste ya está vinculado a una cuenta. Iniciá sesión o usá otro email.'
          );
      },
    });
    setIsLoading(false);
    setIsSignupButtonDisabled(false);
  };

  const handleToggleCheckTerms = () => {
    setIsTermsChecked(!isTermsChecked);
  };

  useEffect(
    () => setIsSignupButtonDisabled(!isFormComplete() || buttonActionDisabled || !isTermsChecked),
    [formValues, buttonActionDisabled, isTermsChecked]
  );

  return (
    <PageContainer showLoginButton={false} title="Registro">
      <Box className={classes.signupPage}>
        <Box gap={3} display="flex" flexDirection="column">
          <VTypography variant="h1">
            Creá <br /> tu cuenta
          </VTypography>
          <Box className={classes.formContainer}>
            <TextInput
              title={formState.firstName.title}
              onChange={handleInputChange}
              name="firstName"
              errorMessage={formState.firstName.error}
              required
            />
            <TextInput
              title={formState.lastName.title}
              onChange={handleInputChange}
              name="lastName"
              errorMessage={formState.lastName.error}
              required
            />
            <SelectInput
              title={formState.idType.title}
              onChange={handleInputChange}
              name="idType"
              options={ID_TYPE_OPTIONS}
              value={formState.idType.value}
              errorMessage={formState.idType.error}
              required
            />
            <TextInput
              title={formState.idNumber.title}
              onChange={handleInputChange}
              name="idNumber"
              errorMessage={formState.idNumber.error}
              required
            />
            <TextInput
              type="email"
              name="mail"
              onChange={handleInputChange}
              title={formState.mail.title}
              errorMessage={formState.mail.error}
              required
              defaultValue={formState.mail.value}
              autoComplete="email"
            />
            <TextInput
              title={formState.phone.title}
              onChange={handleInputChange}
              name="phone"
              errorMessage={formState.phone.error}
            />
            <TextInput
              type="password"
              name="password"
              hint="Mínimo 8 caracteres e incluir al menos una mayúscula, una minúscula, un carácter especial y dos números."
              onChange={handleInputChange}
              title={formState.password.title}
              errorMessage={formState.password.error}
              required
              autoComplete="new-password"
            />
            <TextInput
              type="password"
              name="confirmPassword"
              onChange={handleInputChange}
              title={formState.confirmPassword.title}
              errorMessage={formState.confirmPassword.error}
              required
              autoComplete="new-password"
            />
            <TextInput
              title={formState.birthDate.title}
              onChange={handleInputChange}
              name="birthDate"
              type="tel"
              mask="99/99/9999"
              errorMessage={formState.birthDate.error}
              required
              maskChar={'_'}
            />
            <SelectInput
              title={formState.gender.title}
              onChange={handleInputChange}
              name="gender"
              value={formState.gender.value}
              options={GENDERS}
              errorMessage={formState.gender.error}
              required
            />
            <SelectInput
              title={formState.residenceLocation.title}
              onChange={handleInputChange}
              name="residenceLocation"
              value={formState.residenceLocation.value}
              options={ARGENTINA_PROVINCES.map((province) => ({
                label: province.label,
                value: province.label,
                isCategory: province.isCategory,
              }))}
              errorMessage={formState.residenceLocation.error}
              required
            />
          </Box>
        </Box>

        <Box>
          <Box gap={4} display="flex" flexDirection="column" marginBottom={4}>
            <GoogleReCaptcha mt={2} validation={validateRecaptchaToken} />
            <CheckBox
              label={<TermsAndConditionsLabel leftText="Aceptar los" />}
              onChange={handleToggleCheckTerms}
            />
            <Box display="flex" justifyContent="center">
              <Button
                onClick={signup}
                fullWidth={false}
                title="Crear cuenta"
                disabled={isSignupButtonDisabled}
                width={200}
                loading={isLoading}
              />
            </Box>
            <VTypography variant="body2" align="center">
              ¿Ya tenés una cuenta? <CustomLink to="/login" title="Ingresar" />
            </VTypography>
          </Box>
          <Divider />
          <Box display="flex" flexDirection="column" gap={1.5} marginTop={1.5}>
            <PowerByVenti />
            <ReCaptchaMessage />
          </Box>
        </Box>
      </Box>
    </PageContainer>
  );
};

export default withStyles(styles)(SignupPage);
