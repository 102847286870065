import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    base: {
      padding: '4px 8px',
      gap: 4,
      borderRadius: 32,
      width: 'max-content',
      alignItems: 'center',
      justifyContent: 'center',
    },
    outline: {
      border: `1px solid ${theme.palette.grey[900]}`,
      backgroundColor: 'transparent',
    },
    filled: {
      backgroundColor: theme.palette.grey[200],
      border: 'none',
    },
  });

export default styles;
