import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
    },
    paperContainer: {
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      maxWidth: 900,
      width: '100%',
      rowGap: 16,
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
    },
    sectionContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    userInputs: {
      display: 'flex',
      width: '100%',
      columnGap: 20,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        rowGap: 10,
      },
    },
    invitationAndQuantityInputs: {
      display: 'flex',
    },
    selectTicketContainer: {
      display: 'flex',
      columnGap: 20,
    },
    button: {
      alignSelf: 'center',
    },
    icon: {
      display: 'flex',
      alignItems: 'left',
      width: '1.3em',
      height: '1.3em',
      [theme.breakpoints.down('xs')]: {
        marginTop: 15,
      },
    },
  });

export default styles;
