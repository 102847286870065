import { ORDER_STATUS } from '../../constants/types';

export const getOrderColorsByStatus = (status, theme) => {
  switch (status) {
    case ORDER_STATUS.PAID:
      return {
        color: theme.palette.success.main,
        backgroundColor: theme.palette.success.light,
        border: `1px solid ${theme.palette.success.main}`,
      };
    case ORDER_STATUS.REFUNDED_WITHOUT_FEE:
    case ORDER_STATUS.REFUNDED_WITH_FEE:
      return {
        color: theme.palette.grey[700],
        backgroundColor: theme.palette.grey[200],
        border: `1px solid ${theme.palette.grey[400]}`,
      };
    case ORDER_STATUS.NOT_PAID:
    case ORDER_STATUS.CANCELED:
      return {
        color: theme.palette.error.main,
        backgroundColor: theme.palette.error.light,
        border: `1px solid ${theme.palette.error.main}`,
      };
    case ORDER_STATUS.PENDING:
    default:
      return {
        color: theme.palette.warning.main,
        backgroundColor: theme.palette.warning.light,
        border: `1px solid ${theme.palette.warning.main}`,
      };
  }
};
