import React from 'react';
import { withStyles } from '@material-ui/core';
import { Box } from '@mui/material';
import styles from './AlertStyles';

import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import VTypography from '../VTypography/VTypography';

const severityIcon = {
  error: ErrorIcon,
  success: SuccessIcon,
  warning: WarningIcon,
  info: InfoIcon,
};

const Alert = ({ classes, severity, message }) => {
  const Icon = severityIcon[severity];

  return (
    <Box className={`${classes.alertBaseStyle} ${classes[severity]}`}>
      {severity && <Icon className="alertIcon" />}
      <VTypography variant="body2" linesNumber={3}>
        {message}
      </VTypography>
    </Box>
  );
};

export default withStyles(styles)(Alert);
