import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 20,
      padding: '0 40px',
      flex: 1,
    },
    title: {
      fontWeight: 600,
      color: theme.palette.text.primary,
    },
  });

export default styles;
