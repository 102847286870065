import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    cardContainer: {
      maxWidth: 312,
      height: '100%',
      overflow: 'hidden',
      position: 'relative',
      margin: '10px 0',
      display: 'flex',
      borderRadius: 12,

      [theme.breakpoints.up('md')]: {
        maxWidth: 'unset',
        width: 740,
        height: 320,
      },

      [theme.breakpoints.up('xl')]: {
        width: 1120,
        height: 430,
      },
    },

    image: {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      aspectRatio: 3 / 4,

      '&:hover, &:active': {
        opacity: 0.85,
      },
    },
  });

export default styles;
