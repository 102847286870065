import React, { useState } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { Key } from '@mui/icons-material';
import styles from './ResetPassword.styles';
import { useFormDeprecated } from '../../../../hooks/useFormDeprecated';
import CustomInput from '../../../../Components/CustomInput/CustomInput';
import Button from '../../../../Venti-UI-Kit/Buttons/Button';
import { NOTIFICATION_VARIANT } from '../../../../constants/types';
import PageContainer from '../../../../Components/PageContainer/PageContainer';
import { getUrlSearchParams } from '../../../../utils/utils';
import { useNotifications } from '../../../../Providers/NotificationsProvider/NotificationsProvider';
import { handleRequestHelper } from '../../../../utils/helpers';
import history from '../../../../appHistory';
import api from '../../../../api/api';

const PASSWORD_REQUIREMENTS = [
  'Al menos 8 caracteres.',
  'Al menos dos números.',
  'Al menos una letra mayúscula.',
  'Al menos una letra minúscula.',
  'Al menos un carácter especial.',
  'La contraseña no debe ser igual al mail.',
];

const ResetPassword = ({ classes }) => {
  const { mail, token } = getUrlSearchParams();
  const { showNotification } = useNotifications();
  const {
    formState,
    handleInputChange,
    validateNewPassword,
    validateConfirmField,
    isFormComplete,
  } = useFormDeprecated({
    password: '',
    confirmPassword: '',
  });
  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {
      password: validateNewPassword(formState.password, mail),
      confirmPassword: validateConfirmField(
        formState.password,
        formState.confirmPassword,
        'Las contraseñas no coinciden'
      ),
    };
    setFormErrors((prevState) => ({ ...prevState, ...errors }));

    const validationsError = Object.values(errors).reduce(
      (prevErrors, currentError) => prevErrors + currentError,
      ''
    );
    return validationsError === '';
  };

  const onSubmit = async () => {
    if (!validateForm()) {
      showNotification('Revisa los campos con errores.', NOTIFICATION_VARIANT.ERROR);
      return;
    }

    const changePasswordBody = {
      resetPasswordToken: token,
      newPassword: formState.password,
      mail,
    };

    await handleRequestHelper({
      endpoint: () => api.changePassword(changePasswordBody),
      showNotification,
      onSuccess: () => {
        showNotification(
          'La contraseña fue cambiada con exito! Redireccionando...',
          NOTIFICATION_VARIANT.SUCCESS
        );
        setTimeout(() => {
          history.push('/login');
        }, 1500);
      },
    });
  };

  return (
    <PageContainer title="Cambio de contraseña">
      <div className={classes.contentContainer}>
        <Typography className={classes.title}>Cambiá tu contraseña</Typography>
        <div className={classes.requirementsContainer}>
          <Typography className={classes.subtitle}>
            Ingresá tu nueva contraseña. La misma debe contener:
          </Typography>
          <ul className={classes.requirementsList}>
            {PASSWORD_REQUIREMENTS.map((requirement, index) => (
              <li key={index}>
                <Typography className={classes.requirement}>{requirement}</Typography>
              </li>
            ))}
          </ul>
        </div>
        <CustomInput
          title="Nueva contraseña"
          passwordIcon
          type="password"
          onChange={handleInputChange}
          error={formErrors.password}
          titleIcon={<Key />}
          placeholder="Ingresa la contraseña"
          name="password"
          value={formState.password}
        />
        <CustomInput
          title="Confirmar la contraseña"
          passwordIcon
          type="password"
          onChange={handleInputChange}
          error={formErrors.confirmPassword}
          titleIcon={<Key />}
          placeholder="Confirma la contraseña"
          name="confirmPassword"
          value={formState.confirmPassword}
        />
        <Button className={classes.button} disabled={!isFormComplete(formState)} onClick={onSubmit}>
          Aceptar
        </Button>
      </div>
    </PageContainer>
  );
};

export default withStyles(styles)(ResetPassword);
