import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import styles from './ReportsAccordionItemBodyStyles';
import { REPORTS_DATA_TYPE } from '../../../../constants/types';
import ReportsTicketTypeAccordionBody from './ReportsTicketTypeAccordionBody';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';

const ReportsSectionsAccordionBody = ({
  classes,
  sections,
  dataType = REPORTS_DATA_TYPE.SALES,
}) => {
  const getTotalValue = (totalSold, totalStock) =>
    dataType === REPORTS_DATA_TYPE.SALES ? totalStock : totalSold;

  const getCurrentValue = (totalSold, totalUsed) =>
    dataType === REPORTS_DATA_TYPE.SALES ? totalSold : totalUsed;

  const getTotalLabel = (totalSold, totalStock, totalUsed) => {
    return dataType === REPORTS_DATA_TYPE.SALES
      ? `${totalSold}/${totalStock}`
      : `Faltan: ${totalSold - totalUsed}`;
  };

  return (
    <div className={classes.accordionsContainer}>
      {Object.values(sections).map(
        ({ sectionName, totalSold, totalStock, totalUsed, ticketsByType, color }, index) => (
          <Accordion key={index} classes={{ root: classes.accordionContainer }}>
            <AccordionSummary
              sx={{ borderLeft: `12px solid ${color} !important` }}
              expandIcon={<ArrowBackIosNewIcon className="accordionItemExpandIcon" />}
              id={index}
            >
              <Typography className="accordionItemTitle section" component="div">
                {sectionName}&nbsp;
                <Typography className="accordionItemSubtitle">
                  {getTotalLabel(totalSold, totalStock, totalUsed)}
                </Typography>
              </Typography>
              {totalStock && (
                <ProgressCircle
                  totalValue={getTotalValue(totalSold, totalStock)}
                  currentValue={getCurrentValue(totalSold, totalUsed)}
                />
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0px !important' }}>
              <ReportsTicketTypeAccordionBody sections={ticketsByType} dataType={dataType} />
            </AccordionDetails>
          </Accordion>
        )
      )}
    </div>
  );
};

export default withStyles(styles)(ReportsSectionsAccordionBody);
