import { useEffect, useState } from 'react';
import { CARD_PATTERNS, getCardType } from '../Pages/Public/Event/Checkout/creditCardUtils';

export const useCreditCard = ({ cardNumber }) => {
  const [focused, setFocused] = useState('name');
  const [cardPattern, setCardPattern] = useState(CARD_PATTERNS.DEFAULT);
  const [isLemonCard, setIsLemonCard] = useState(false);

  useEffect(() => {
    const { isLemonCard, mask } = getCardType(cardNumber);
    setIsLemonCard(isLemonCard);
    setCardPattern(mask);
  }, [cardNumber]);

  const handleInputFocus = ({ target: { name } }) => {
    setFocused(name);
  };

  return {
    focused,
    isLemonCard,
    cardPattern,
    handleInputFocus,
  };
};
