import React from 'react';
import { Stack } from '@mui/material';

import Button from '../../Buttons/Button/Button';
import VModal from '../../../Venti-UI-Kit/VModal/VModal';
import TextInput from '../../../Venti-UI-Kit/Inputs/TextInput';
import { useEditRebateModal } from './EditRebateModal.hook';

const EditRebateModal = ({ open, rebatePercentage, updateRebatePercentage, handleClose }) => {
  const { formState, handleInputChange, handleEdit, handleCancel } = useEditRebateModal(
    updateRebatePercentage,
    handleClose,
    rebatePercentage
  );

  return (
    <VModal
      open={open}
      title="Bonificación Service Charge"
      onClose={handleClose}
      width="100%"
      maxWidth={400}
    >
      <TextInput
        onChange={handleInputChange}
        defaultValue={formState.amount.value}
        title="Porcentaje"
        name="amount"
        type="number"
        errorMessage={formState.amount.error}
        endAdornment={<Stack marginRight={2}>%</Stack>}
      />
      <Stack spacing={2}>
        <Button fullWidth title="Cancelar" onClick={handleCancel} variant="outlined" />
        <Button fullWidth title="Editar" onClick={handleEdit} />
      </Stack>
    </VModal>
  );
};

export default EditRebateModal;
