import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    invoice: {
      background: theme.palette.background.paper,
      borderRadius: 10,
      width: '49%',
      padding: 30,
      [theme.breakpoints.down('md')]: {
        padding: 16,
        width: '100%',
      },
    },
    summary: {
      margin: '0 0 0 auto',
      paddingRight: 10,
      minWidth: 300,
    },
    summaryItem: {
      width: '100%',
      marginTop: 10,
      justifyContent: 'space-between',
    },
    highlight: {
      color: 'deeppink',
    },
  });

export default styles;
