import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { withStyles } from '@material-ui/core';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import EventsSlider from './EventsSlider/EventsSlider';
import styles from './UserEvents.styles';

const INCOMMING_TAB = 0;
const PAST_TAB = 1;

const UserEvents = ({ classes }) => {
  const [currentTabSelected, setCurrentTabSelected] = useState(0);

  const handleChange = (event, newValue) => {
    setCurrentTabSelected(newValue);
  };

  return (
    <PageContainer
      title="Mis tickets"
      withPaddingTop={false}
      headerChildren={
        <>
          <VTypography variant="h3">Mis tickets</VTypography>
          <Tabs
            value={currentTabSelected}
            onChange={handleChange}
            className={classes.userEventsTabsContainer}
          >
            <Tab label="Próximos" className={classes.userEventsTab} disableRipple />
            <Tab label="Anteriores" className={classes.userEventsTab} disableRipple />
          </Tabs>
        </>
      }
    >
      <Box gap={2} display="flex" flexDirection="column">
        <Box gap={5} display="flex" flexDirection="column">
          {currentTabSelected === INCOMMING_TAB && <EventsSlider title="Próximos eventos" />}
          {currentTabSelected === PAST_TAB && (
            <EventsSlider title="Eventos anteriores" onlyPastEvents />
          )}
        </Box>
      </Box>
    </PageContainer>
  );
};

export default withStyles(styles)(UserEvents);
