import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    pageContainer: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default,
      position: 'relative',
    },
    pageContent: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 24,
    },
  });

export default styles;
