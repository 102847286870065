import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    lineCheckoutCounterHeaderContainer: {
      height: 56,
      display: 'flex',
      flexDirection: 'column',
      padding: '8px 16px',
      boxShadow: '0 4px 4px -2px rgba(0,0,0,0.25)',
      backgroundColor: theme.palette.grey[50],
      position: 'sticky',
      top: 60,
      zIndex: 10,

      '& .headerRow': {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 8,
      },

      '& .counter': {
        color: theme.palette.primary.main,
        fontSize: 16,
        lineHeight: '20px',
        fontWeight: 600,
      },

      '& .title': {
        fontSize: 16,
        lineHeight: '20px',
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        '-webkit-box-orient': 'vertical',
        textAlign: 'left',
        width: '80%',
      },

      '& .subtitle': {
        fontSize: 12,
        lineHeight: '20px',
      },
    },
    lineCheckoutPageTitle: {
      alignSelf: 'center',
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '23px',
      marginTop: 24,

      [theme.breakpoints.up('lg')]: {
        lineHeight: '38px',
        fontSize: 32,
      },
    },
    checkoutContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
      alignSelf: 'center',
      width: '100%',
      position: 'relative',
      maxWidth: 1024,
      padding: '24px 16px',

      [theme.breakpoints.up('lg')]: {
        alignSelf: 'center',
      },

      '& .formAndResumeContainer': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',

        [theme.breakpoints.up('lg')]: {
          flexDirection: 'row',
          justifyContent: 'unset',
          alignItems: 'start',
          gap: 24,
        },
      },

      '& .resumeAndButtonContainer': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 24,

        [theme.breakpoints.up('lg')]: {
          maxWidth: 328,
          marginBottom: 0,
        },
      },
    },
  });

export default styles;
