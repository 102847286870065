import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    previewContainer: {
      backgroundColor: 'red',
      border: `1px solid ${theme.palette.grey[400]}`,
      padding: 16,
      borderRadius: 8,
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        padding: 8,
      },
    },
    buttonPreview: {
      width: '100%',
      maxWidth: 250,
      borderRadius: 8,
      height: 48,
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down('md')]: {
        maxWidth: 150,
      },
    },
    logoImgContainer: {
      position: 'absolute',
      bottom: '-27%',
      left: '5%',
    },
    logoBannerContainer: {
      maxHeight: 355,
    },
  });

export default styles;
