import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    ticketsSlider: {
      overflowX: 'auto',
      width: '100%',
      flexDirection: 'row',
      overflow: 'scroll',
    },
  });

export default styles;
