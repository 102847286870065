import React from 'react';
import { Box, withStyles } from '@material-ui/core';
import styles from './BluredBackgroundImage.styles';

const BluredBackgroundImage = ({ classes, bannerImg }) => {
  if (!bannerImg) return;
  return (
    <>
      <Box className={classes.backgroundContainer}>
        <img className={classes.backgroundImg} src={bannerImg} alt="blured background" />
      </Box>
      <Box className={classes.gradient} />
    </>
  );
};

export default withStyles(styles)(BluredBackgroundImage);
