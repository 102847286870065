import React from 'react';
import { useTheme, withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import VTypography from '../VTypography/VTypography';
import styles from './Chip.styles';

const Chip = ({ classes, variant = 'filled', label, labelVariant, bgcolor, labelColor }) => {
  const theme = useTheme();

  return (
    <Stack
      bgcolor={variant !== 'filled' ? '' : bgcolor}
      className={` ${classes.base} ${classes[variant]}`}
    >
      <VTypography
        variant={labelVariant || 'overline'}
        color={labelColor || theme.palette.grey[900]}
      >
        {label.toUpperCase()}
      </VTypography>
    </Stack>
  );
};

export default withStyles(styles)(Chip);
