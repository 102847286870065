export const SOCIALS_CONFIG = {
  web: { label: 'Web link', icon: 'link', baseURL: 'https://', startAdornment: 'https://' },
  tiktok: {
    label: 'Tiktok',
    icon: 'tiktok',
    baseURL: 'https://www.tiktok.com/@',
    startAdornment: '@',
  },
  instagram: {
    label: 'Instagram',
    icon: 'instagram',
    baseURL: 'https://www.instagram.com/',
    startAdornment: '@',
  },
  x: { label: 'Twitter', icon: 'x', baseURL: 'https://x.com/', startAdornment: '@' },
  youtube: {
    label: 'Youtube',
    icon: 'youtube',
    baseURL: 'https://www.youtube.com/@',
    startAdornment: '@',
  },
  facebook: {
    label: 'Facebook',
    icon: 'facebook',
    baseURL: 'https://www.facebook.com/',
    startAdornment: '@',
  },
};

export const DEFAULT_IMAGES = {
  LOGO_IMAGE:
    'https://venti-pre-public-images.s3.sa-east-1.amazonaws.com/user-images/1728057791747-lg.jpg',
  BANNER_IMAGE:
    'https://venti-pre-public-images.s3.sa-east-1.amazonaws.com/user-images/1728057730685-lg.jpg',
};

export const NO_PINNED_EVENT_VALUE = '';
