import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    bankPromotionButton: {
      height: 32,
      width: 'max-content',
      textTransform: 'none',
      boxShadow: 'none',
    },

    contained: {
      padding: '6px 12px 6px 16px',
      borderRadius: 29,
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.grey[50],
      ...theme.typography.button2,

      '&:hover': {
        backgroundColor: theme.palette.grey[700],
      },
    },

    text: {
      color: theme.palette.primary.main,
      ...theme.typography.button2,
      backgroundColor: 'transparent',

      '&:hover': {
        color: theme.palette.primary.dark,
        backgroundColor: 'transparent',
      },
    },
  });

export default styles;
