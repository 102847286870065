const TO_RADIANS = Math.PI / 180;

export const setCanvasImage = (canvas, src) => {
  const context = canvas.getContext('2d');
  const img = new Image();

  img.onload = () => {
    canvas.width = img.width;
    canvas.height = img.height;

    context.drawImage(img, 0, 0, img.width, img.height);
  };
  img.crossOrigin = 'anonymous';
  img.src = src;
};

export const canvasPreview = async (image, canvas, crop) => {
  const canvasContext = canvas.getContext('2d');
  const scale = 1;
  const rotate = 0;

  if (!canvasContext) throw new Error('No 2d context');

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  canvasContext.scale(pixelRatio, pixelRatio);
  canvasContext.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  canvasContext.save();

  canvasContext.translate(-cropX, -cropY);
  canvasContext.translate(centerX, centerY);
  canvasContext.rotate(rotateRads);
  canvasContext.scale(scale, scale);
  canvasContext.translate(-centerX, -centerY);
  canvasContext.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  canvasContext.restore();
};
