import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import styles from './TitledRadioButtons.styles';
import InputTitle from '../../Components/InputTitle/InputTitle';

class TitledRadioButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHelperText: false,
    };
  }

  render() {
    const { classes } = this.props;
    const currentValue = this.props.currentValue ? this.props.currentValue : undefined;

    function HelperText(props) {
      if (props.helperText !== '' && (props.showHelperText || props.showErrors)) {
        return <FormHelperText className={classes.helper}>{props.helperText}</FormHelperText>;
      }
      return null;
    }

    function RadioItems(props) {
      if (props.values) {
        return (
          <div>
            <RadioGroup
              aria-label={props.name}
              name={props.name}
              value={currentValue}
              onChange={props.onChange}
              row={props.row}
            >
              {props.values.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item.value}
                  control={<Radio color="primary" />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </div>
        );
      }
      return null;
    }

    function RadioItemsWithImages(props) {
      if (props.values) {
        return (
          <div>
            <RadioGroup
              aria-label={props.name}
              name={props.name}
              value={currentValue}
              onChange={props.onChange}
              className={classes.radioItems}
              row={props.row}
            >
              {props.values.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item.value}
                  control={<Radio color="primary" />}
                  labelPlacement="top"
                  label={
                    <div className={classes.label}>
                      <Typography className={classes.labelTitle} component="h7" variant="h5">
                        {item.name}
                      </Typography>
                      <img src={item.src} className={classes.themeImage} alt={item.value} />
                    </div>
                  }
                />
              ))}
            </RadioGroup>
          </div>
        );
      }
      return null;
    }

    return (
      <div>
        <div className={classes.iconTitle}>
          <InputTitle
            title={this.props.title}
            icon={this.props.icon}
            description={this.props.subtitle}
          />
        </div>

        <FormControl margin="none" required fullWidth disabled={this.props.disabled}>
          {this.props.withImages ? (
            <RadioItemsWithImages
              values={this.props.values}
              name={this.props.name}
              currentValue={this.props.currentValue}
              onChange={this.props.onChange}
              row={this.props.row}
            />
          ) : (
            <RadioItems
              values={this.props.values}
              name={this.props.name}
              currentValue={this.props.currentValue}
              onChange={this.props.onChange}
              row={this.props.row}
            />
          )}
        </FormControl>
        <HelperText
          showHelperText={this.state.showHelperText}
          showErrors={this.props.showErrors}
          helperText={this.props.helperText}
        />
      </div>
    );
  }
}

export default withStyles(styles)(TitledRadioButtons);
