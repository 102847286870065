import React, { useState } from 'react';
import { Stack } from '@mui/material';
import InformationModal from '../../../../Venti-UI-Kit/Modals/InformationModal';
import TermsAndConditionsLabel from './TermsAndConditionsLabel';
import { handleRequestHelper } from '../../../../utils/helpers';
import api from '../../../../api/api';
import CheckBox from '../../../../Venti-UI-Kit/CheckBox/CheckBox';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';

const TermsAndConditionsModal = ({ open, handleClose, handleAccept }) => {
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const handleToggleCheckTerms = () => {
    setAcceptedTerms(!acceptedTerms);
  };

  const handleCloseModal = () => {
    setAcceptedTerms(false);
    handleClose();
  };

  const handleAcceptTerms = async () => {
    await handleRequestHelper({
      endpoint: () => api.acceptTermsAndConditions(),
      onSuccess: () => handleAccept(),
      onFailure: () => handleAccept(),
    });
  };

  return (
    <InformationModal
      open={open}
      handleClose={handleCloseModal}
      handleAccept={handleAcceptTerms}
      title="Actualizamos nuestros Términos de Uso."
      subtitle={
        <Stack gap={2}>
          <TermsAndConditionsLabel leftText="Antes de continuar, necesitamos que leas y aceptes nuestros" />
          <CheckBox
            label={
              <VTypography variant="body2">
                Leí y acepto la actualización de los Términos de Uso.
              </VTypography>
            }
            onChange={handleToggleCheckTerms}
          />
        </Stack>
      }
      okLabel="Aceptar"
      disabled={!acceptedTerms}
    />
  );
};

export default TermsAndConditionsModal;
