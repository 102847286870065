import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './VentiTextureStyles';
import Marquee from '../../Animations/Marquee/Marquee';
import { ReactComponent as VPlot } from '../../../assets/vPlot/vPlot.svg';

const VentiTexture = ({ classes, animationTime = '0s' }) => {
  return (
    <div className={classes.container}>
      <Marquee animationTime={animationTime} gap={0}>
        <VPlot />
      </Marquee>
    </div>
  );
};

export default withStyles(styles)(VentiTexture);
