import { styled } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';

const DESKTOP_DRAWER_WIDTH = 240;
const MOBILE_DRAWER_WIDTH = 50;

const openedMixin = (theme) => ({
  width: DESKTOP_DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme) => ({
  width: MOBILE_DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    minWidth: MOBILE_DRAWER_WIDTH,
    maxWidth: DESKTOP_DRAWER_WIDTH,
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
    [theme.breakpoints.down('sm')]: {
      maxWidth: MOBILE_DRAWER_WIDTH,
    },
  })
);
