import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    optionBox: {
      padding: '8px 24px',
      flexDirection: 'row !important',
      gap: 4,
    },
  });

export default styles;
