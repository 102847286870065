import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    filterButtonsContainer: {
      position: 'relative',

      '&.MuiToggleButtonGroup-root': {
        gap: 12,
        borderRadius: 8,
        display: 'flex',

        '& .MuiToggleButtonGroup-grouped': {
          borderRadius: '8px !important',
          backgroundColor: 'transparent',
          border: `1px solid ${theme.palette.primary.main} !important`,
          color: '#FFFFFF',
          padding: '8px 12px',
          transition: 'background-color 0.3s ease-out, border-color 0.3s ease-out',

          '&:hover': {
            backgroundColor: '#5758F545',
            backdropFilter: 'blur(2px)',
          },

          '&.Mui-selected': {
            backgroundColor: '#5758F545',
            backdropFilter: 'blur(2px)',
          },
        },
      },
    },
  });

export default styles;
