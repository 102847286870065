import { createStyles } from '@material-ui/core';

const MOBILE_BREAKPOINT = 631;
const SMALL_BREAKPOINT = 'sm';

const styles = (theme) =>
  createStyles({
    footerContainer: {
      display: 'flex',
      justifyContent: 'space-evenly',
      backgroundColor: theme.palette.footer.background,
      borderRadius: '55px 55px 0 0',
      padding: '34px 0',
      marginTop: 24,
      [theme.breakpoints.down(SMALL_BREAKPOINT)]: {
        padding: 34,
        borderRadius: '30px 30px 0 0',
        columnGap: 30,
      },
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: 30,
      },
    },
    footerColumnContainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 32,
      justifyContent: 'space-between',
      '&#footerLeftColumn': {
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
          alignItems: 'start',
        },
      },
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        rowGap: 20,
      },
    },
    footerAssetContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
    },
    footerButtonsRowContainer: {
      display: 'flex',
      columnGap: 60,
      width: 450,
      [theme.breakpoints.down(SMALL_BREAKPOINT)]: {
        width: 'unset',
      },
      '&#dataFiscalContainer': {
        justifyContent: 'space-between',
        alignItems: 'end',
      },
    },
    footerButtonsColumnContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    termsAndPolitycsContainer: {
      borderTop: `0.5px solid ${theme.palette.footer.text}`,
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: 17,
      columnGap: 10,
      rowGap: 10,
      width: '100%',
    },
    footerAsset: {
      maxHeight: 210,
      maxWidth: 312,
      objectFit: 'scale-down',
      [theme.breakpoints.down(SMALL_BREAKPOINT)]: {
        maxHeight: 210,
        maxWidth: 250,
      },
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        maxHeight: 180,
        width: 230,
        margin: '30px 0',
      },
    },
    footerLink: {
      color: theme.palette.footer.text,
      fontWeight: '800',
      fontFamily: '"PP Neue Montreal", "Roboto"',
      fontSize: 21,
      lineHeight: '24px',
      textDecoration: 'none',
      background: 'transparent',
      cursor: 'pointer',
      padding: 0,
      border: 'none',
      textAlign: 'left',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        fontSize: 16,
      },
    },
    footerBottomText: {
      fontSize: 12,
      fontWeight: 450,
      lineHeight: '15px',
      fontFamily: '"PP Neue Montreal", "Roboto"',
      color: theme.palette.footer.text,
      textDecoration: 'none',
      textAlign: 'left',
    },
    mobileHidden: {
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        display: 'none',
      },
    },
    desktopHidden: {
      [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
        display: 'none',
      },
    },
  });

export default styles;
