import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    alertBaseStyle: {
      width: '100%',
      border: '1px solid black',
      borderRadius: 8,
      padding: 12,
      backgroundColor: 'transparent',
      display: 'flex',
      gap: 8,
      alignItems: 'center',

      '& .alertIcon': {
        minWidth: 20,
      },
    },
    error: {
      backgroundColor: theme.palette.error.light,
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
    success: {
      backgroundColor: theme.palette.success.light,
      borderColor: theme.palette.success.main,
      color: theme.palette.success.main,
    },
    info: {
      backgroundColor: theme.palette.info.light,
      borderColor: theme.palette.info.main,
      color: theme.palette.info.main,
    },
    warning: {
      backgroundColor: theme.palette.warning.light,
      borderColor: theme.palette.warning.main,
      color: theme.palette.warning.main,
    },
  });

export default styles;
