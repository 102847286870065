import React from 'react';
import MUIDataTable from 'mui-datatables';
import { tableTranslations } from './tableTranslations';

const TicketSalesTable = ({ data, tableType }) => {
  const formattedData = data.map(
    ({
      sellerAlias,
      sellerMail,
      totalAccesses,
      totalTickets,
      orderPrice,
      ticketName,
      teamLeaderCode,
      customerMail,
      customerName,
    }) => ({
      'Team Leader': teamLeaderCode || '-',
      'Alias del publica': sellerAlias || '-',
      'Mail del publica': sellerMail || '-',
      Nombre: customerName,
      Mail: customerMail,
      'Cantidad de ingresados': totalAccesses,
      'Cantidad de tickets': totalTickets,
      'Total ($)': `$${orderPrice}`,
      'Nombre del ticket': ticketName,
    })
  );

  const tableOptions = {
    ...tableTranslations,
    downloadOptions: {
      filename: `Ticket${tableType}Report.csv`,
    },
  };

  const columns = [
    'Team Leader',
    'Alias del publica',
    'Mail del publica',
    'Nombre',
    'Mail',
    'Cantidad de ingresados',
    'Cantidad de tickets',
    'Total ($)',
    'Nombre del ticket',
  ];

  return <MUIDataTable data={formattedData} columns={columns} options={tableOptions} />;
};

export default TicketSalesTable;
