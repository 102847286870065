import React, { useRef, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import Button from '../../../Venti-UI-Kit/Buttons/Button';
import styles from './CSVDownloadQrsButton.styles';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';

const CSVDownloadQrsButton = ({ eventId, eventName, withoutHeaders = false, style, classes }) => {
  const { showNotification } = useNotifications();

  const headers = [
    { label: 'Qr_id', key: 'id' },
    { label: 'id', key: 'ticketTypeId' },
    { label: 'name', key: 'ticketTypeName' },
  ];

  const [qrs, setQrs] = useState([]);
  const csvLink = useRef();

  const formatData = (data) => {
    const labels = !withoutHeaders ? headers.map(({ label }) => label).join(',') : '';
    const keys = headers.map(({ key }) => key);

    const formatedValues =
      data && data.length
        ? data
            .map((row) => {
              return keys
                .reduce((acumm, key) => {
                  return `${acumm}${row[key]},`;
                }, '')
                .slice(0, -1);
            })
            .join('\n')
        : '';

    return withoutHeaders ? formatedValues : `${labels}\n${formatedValues}`;
  };

  const downloadQrs = async () => {
    await handleRequestHelper({
      endpoint: () => api.getEventOrdersQrs(eventId),
      onSuccess: ({ qrs }) => {
        setQrs(formatData(qrs));
        csvLink.current.link.click();
      },
      showNotification,
    });
  };

  return (
    <>
      <Button onClick={downloadQrs} className={style}>
        Descargar
      </Button>
      <CSVLink
        data={qrs}
        filename={`${eventName}_QRs.csv`}
        className={classes.hidden}
        ref={csvLink}
        target="_blank"
      />
    </>
  );
};

export default withStyles(styles)(CSVDownloadQrsButton);
