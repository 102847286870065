import { createStyles } from '@material-ui/core';

const styles = (theme) => {
  const buttonShape = {
    width: 200,
    height: 40,
    border: `1px solid ${theme.palette.background.toolbar}`,
    borderRadius: 38,
    cursor: 'pointer',
  };

  return createStyles({
    ventiButtonContainer: {
      position: 'relative',
      padding: 0,
      ...buttonShape,
      marginTop: 40,

      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: theme.palette.background.toolbar,

          '& .ventiButtonText': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            fontWeight: 'bold',
          },
        },
      },

      '&:active': {
        border: 'none',

        '& .ventiButtonText': {
          transform: 'translate(-0px, -0px)',
          transition: 'all 50ms ease-in-out',
        },
      },

      '& .ventiButtonText': {
        ...buttonShape,
        display: 'flex',
        padding: 0,
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        transform: 'translate(-4px, -4px)',
        top: 0,
        left: 0,
        backgroundColor: theme.palette.background.default,
        fontSize: 16,
        fontWeight: 400,
        color: theme.palette.text.primary,
      },
    },
  });
};

export default styles;
