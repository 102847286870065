import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import styles from './HomeStyles';
import NextEventsSection from './Components/NextEventsSection/NextEventsSection';
import ShadowButton from '../../../Components/ShadowButton/ShadowButton';
import { useHome } from '../../../hooks/useHome';
import PageContainer from '../../../Components/PageContainer/PageContainer';

const WhiteLabelHome = ({ classes, urlParams }) => {
  const { userAlias } = urlParams;

  const { events, getHomeEvents, hasMoreEvents } = useHome(urlParams);

  return (
    <PageContainer withFooter>
      <div className={classes.pageContentContainer}>
        <Typography className={classes.homeTitle}>Nuestros eventos</Typography>
        <NextEventsSection events={events} userAlias={userAlias} showSectionButton={false} />
        {hasMoreEvents() && <ShadowButton text="Cargar más" onClick={getHomeEvents} />}
      </div>
    </PageContainer>
  );
};

export default withStyles(styles)(WhiteLabelHome);
