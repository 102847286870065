import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    modalContainer: {
      color: theme.palette.grey[700],

      '& .modalTitle': {
        color: theme.palette.grey[700],
        fontSize: 20,
        lineHeight: '24px',
      },

      '& .bankPromotionModal': {
        maxHeight: 360,
        width: 610,
        gap: 12,
        position: 'relative',

        [theme.breakpoints.down('sm')]: {
          maxHeight: '90vh',
          maxWidth: '90vw',
        },
      },

      '& p': {
        margin: 0,
      },

      '& .closeButton': {
        position: 'absolute',
        right: 10,
        top: 10,
      },

      '& .promotionImage': {
        objectFit: 'contain',
        borderRadius: 6,
        maxHeight: 84,
        maxWidth: 256,
      },

      '& .modalTitleH2': {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '19px',
        letterSpacing: 0.5,
      },
    },
  });

export default styles;
