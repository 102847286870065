import { useCallback, useEffect, useState } from 'react';
import { INPUT_TYPE, USER_ROLES } from '../../../constants/types';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';
import { useForm } from '../../../hooks/useForm';
import { useReCaptcha } from '../../../hooks/useReCaptcha';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';
import history from '../../../appHistory';
import { getUrlSearchParams } from '../../../utils/utils';

export const useLoginPage = () => {
  const { user, login, logout } = useAuth();
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
  const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(true);
  const [showLoginErrorAlert, setShowLoginErrorAlert] = useState(false);
  const [openTermsAndConditionsModal, setOpenTermsAndConditionsModal] = useState(false);
  const { email, returnTo } = getUrlSearchParams();
  const [openCompleteUserDataModal, setOpenCompleteUserDataModal] = useState(false);

  const { buttonActionDisabled, validateRecaptchaToken } = useReCaptcha({
    history,
    page: 'login',
    action: 'login',
  });

  const loginForm = useForm({
    mail: {
      title: 'Email',
      type: INPUT_TYPE.MAIL,
      value: email,
    },
    password: {
      title: 'Contraseña',
      type: INPUT_TYPE.TEXT,
    },
  });

  const getRedirectToMentaURL = async () => {
    const { token } = await api.getMentaLoginToken();
    window.location.href = `${returnTo}&oneTimeToken=${token}`;
  };

  const redirectUser = async () => {
    const { role, producers } = user;
    const userHasManyProducers = producers?.length > 1;

    let reditectPath = '';

    if (returnTo) await getRedirectToMentaURL();
    else {
      if (email || role === USER_ROLES.CUSTOMER) reditectPath = '/mis-tickets';
      else if (role === USER_ROLES.SUPER_ADMIN) reditectPath = '/backoffice/productoras';
      else if (userHasManyProducers) reditectPath = '/backoffice/productoras';
      else reditectPath = `/backoffice/productoras/${producers[0].id}`;

      history.push(reditectPath);
    }
  };

  const shoudCompleteUserInfo =
    !returnTo &&
    user &&
    (!user.firstName ||
      !user.lastName ||
      !user.identificationType ||
      !user.identificationValue ||
      !user.gender ||
      !user.birthDate ||
      !user.residenceLocation);

  const checkTermsAndConditions = useCallback(async () => {
    if (!user) return;

    handleRequestHelper({
      endpoint: () => api.verifyTermsAndConditions(),
      onSuccess: ({ accepted }) =>
        accepted
          ? !shoudCompleteUserInfo
            ? redirectUser()
            : setOpenCompleteUserDataModal(true)
          : setOpenTermsAndConditionsModal(true),
      onFailure: redirectUser,
    });
  }, [user]);

  const handleLogin = async () => {
    setIsLoginButtonDisabled(true);

    const isCaptchaValid = await validateRecaptchaToken();
    if (!isCaptchaValid) return;

    const isFormValid = loginForm.validateFormInputs();

    if (!isFormValid) {
      setIsLoginButtonDisabled(false);
      return;
    }

    const userMail = loginForm.formState.mail.value;
    const userPassword = loginForm.formState.password.value;

    await login(userMail, userPassword, () => {
      setShowLoginErrorAlert(true);
      setIsLoginButtonDisabled(false);
    });
  };

  const showForgotPasswordModal = () => {
    setOpenForgotPasswordModal(true);
  };

  const handleCloseForgotPasswordModal = () => {
    setOpenForgotPasswordModal(false);
  };

  const handleCloseTermsAndConditionsModal = async () => {
    await logout();
    setOpenTermsAndConditionsModal(false);
  };

  const handleCancelCompleteUserDataModal = async () => {
    setOpenCompleteUserDataModal(false);
    await redirectUser();
  };

  const handleAcceptCompleteUserDataModal = () => {
    setOpenCompleteUserDataModal(false);
    return history.push('/mis-datos?returnTo=/mis-tickets');
  };

  useEffect(() => {
    setIsLoginButtonDisabled(buttonActionDisabled || !loginForm.isFormComplete());
    setShowLoginErrorAlert(false);
  }, [loginForm.formValues, buttonActionDisabled]);

  useEffect(() => {
    checkTermsAndConditions();
  }, [checkTermsAndConditions]);

  return {
    loginForm,
    openForgotPasswordModal,
    isLoginButtonDisabled,
    showLoginErrorAlert,
    openTermsAndConditionsModal,
    validateRecaptchaToken,
    redirectUser,
    handleLogin,
    handleCloseTermsAndConditionsModal,
    handleCloseForgotPasswordModal,
    showForgotPasswordModal,
    openCompleteUserDataModal,
    handleAcceptCompleteUserDataModal,
    handleCancelCompleteUserDataModal,
  };
};
