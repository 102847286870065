import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    optionImage: {
      width: 80,
      height: 90,
      borderRadius: 8,
      objectFit: 'cover',
    },
  });

export default styles;
