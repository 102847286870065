import React from 'react';
import { Stack } from '@mui/material';
import { useTheme } from '@material-ui/core';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';
import CardField from '../CardField/CardField';
import Chip from '../../../../Venti-UI-Kit/Chip/Chip';
import { getQrColorByStatus } from './OrderTicketInfoCard.utils';
import { translate } from '../../../../utils/translate';

const OrderTicketInfoCard = ({ ticket, orderId, display }) => {
  const { orderCountNumber, owner, name, type, section, sectionColor, status, externalId } = ticket;

  const theme = useTheme();
  const { backgroundColor, color } = getQrColorByStatus(status, theme);

  return (
    <Stack
      display={display}
      gap={2}
      border="1px solid #e0e0e0"
      padding={2}
      borderRadius={2}
      minWidth={296}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <VTypography variant="subtitle4">
          Ticket: {orderId}-{orderCountNumber}
        </VTypography>
        <Chip label={translate(status)} bgcolor={backgroundColor} labelColor={color} />
      </Stack>
      <CardField title="Nombre y Apellido" textValue={`${owner.firstName} ${owner.lastName}`} />
      <CardField title="Mail" textValue={owner.mail} />
      <CardField title="Customer ID" textValue={owner.customerId} />
      <CardField title="External ID" textValue={externalId} />
      <CardField title="Tipo de ticket" textValue={translate(type)} />
      <CardField title="Nombre del Ticket" textValue={name} />
      {section && (
        <CardField
          title="Sector"
          componentValue={
            <Chip
              bgcolor={sectionColor}
              labelVariant="subtitle5"
              label={section}
              labelColor="white"
            />
          }
        />
      )}
    </Stack>
  );
};

export default OrderTicketInfoCard;
