import { createTheme } from '@material-ui/core/styles';

const theme = createTheme();
const BREAKPOINT_VALUE = 'sm';

const defaultTheme = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  palette: {
    error: { main: '#F44B42', light: '#FFE9E8', dark: '#C20000', contrastText: '#000000' },
    warning: { main: '#F7831D', light: '#FFF3E5', dark: '#7C420F', contrastText: '#000000' },
    info: { main: '#467CDD', light: '#E8F0FF', dark: '#0039A0', contrastText: '#000000' },
    success: { main: '#2CB80F', light: '#E4FBF1', dark: '#005600', contrastText: '#000000' },
  },
  typography: {
    fontFamily: "'Work Sans', 'Roboto'",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    letterSpacing: 0,
    h1: {
      fontSize: 48,
      fontWeight: 700,
      [theme.breakpoints.down(BREAKPOINT_VALUE)]: {
        fontSize: 32,
      },
    },
    h2: {
      fontSize: 40,
      fontWeight: 700,
      [theme.breakpoints.down(BREAKPOINT_VALUE)]: {
        fontSize: 28,
      },
    },
    h3: {
      fontSize: 32,
      fontWeight: 700,
      [theme.breakpoints.down(BREAKPOINT_VALUE)]: {
        fontSize: 24,
      },
    },
    h4: {
      fontSize: 28,
      fontWeight: 600,
      [theme.breakpoints.down(BREAKPOINT_VALUE)]: {
        fontSize: 20,
      },
    },
    h5: {
      fontSize: 24,
      fontWeight: 600,
      [theme.breakpoints.down(BREAKPOINT_VALUE)]: {
        fontSize: 16,
      },
    },
    h6: {
      fontSize: 24,
      fontWeight: 500,
      [theme.breakpoints.down(BREAKPOINT_VALUE)]: {
        fontSize: 16,
      },
    },
    h7: {
      fontSize: 18,
      fontWeight: 500,
      [theme.breakpoints.down(BREAKPOINT_VALUE)]: {
        fontSize: 16,
      },
    },
    subtitle1: {
      fontSize: 20,
      fontWeight: 600,
      [theme.breakpoints.down(BREAKPOINT_VALUE)]: {
        fontSize: 18.5,
      },
    },
    subtitle2: {
      fontSize: 20,
      fontWeight: 400,
      [theme.breakpoints.down(BREAKPOINT_VALUE)]: {
        fontSize: 18.5,
      },
    },
    subtitle3: {
      fontSize: 18.5,
      fontWeight: 600,
      [theme.breakpoints.down(BREAKPOINT_VALUE)]: {
        fontSize: 16,
      },
    },
    subtitle4: {
      fontSize: 16,
      fontWeight: 600,
      [theme.breakpoints.down(BREAKPOINT_VALUE)]: {
        fontSize: 14,
      },
    },
    subtitle5: {
      fontSize: 14,
      fontWeight: 600,
      [theme.breakpoints.down(BREAKPOINT_VALUE)]: {
        fontSize: 12,
      },
    },
    subtitle6: {
      fontSize: 14,
      fontWeight: 500,
      [theme.breakpoints.down(BREAKPOINT_VALUE)]: {
        fontSize: 12,
      },
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.2,
    },
    button1: {
      fontSize: 16,
      fontWeight: 500,
      textTransform: 'initial',
    },
    button2: {
      fontSize: 14,
      fontWeight: 500,
      textTransform: 'initial',
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
    },
    overline: {
      fontSize: 10,
      fontWeight: 500,
      textTransform: 'none',
      lineHeight: 1.3,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    },
  },
  overrides: {
    MUIDataTableBody: {
      emptyTitle: {
        fontSize: 14,
        fontWeight: 500,
      },
    },
  },
});

export default defaultTheme;
