import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    background: {
      stroke: theme.palette.charts.secondary,
    },
    percertageBar: {
      stroke: theme.palette.charts.primary,
    },
    text: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 19,
      fontFamily: theme.typography.fontFamily,
      fill: theme.palette.charts.text,
    },
  });

export default styles;
