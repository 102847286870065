import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '70%',
      margin: 'auto',
      gap: 16,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: '0 16px',
      },
    },
    subtitle: {
      fontWeight: 500,
      fontSize: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
    reportsContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 32,
    },
    accordionsContainer: {
      width: '100%',
      display: 'flex',
      columnGap: 24,
      alignItems: 'stretch',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        rowGap: 32,
      },
    },
    paper: {
      filter: 'drop-shadow(0px 4px 10px rgba(174, 166, 166, 0.25))',
      backgroundColor: theme.palette.grey[50],
      borderRadius: 12,
      width: '100%',
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 20,
    },
    csvButtons: {
      display: 'flex',
      columnGap: 20,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paperTitle: {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '24px',
      color: theme.palette.text.primary,
    },
    downloadButton: {
      textDecoration: 'none',
    },
  });

export default styles;
