import React from 'react';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { Stack, Switch } from '@mui/material';
import styles from './TitledSwitch.styles';
import InputTitle from '../../Components/InputTitle/InputTitle';
import VTypography from '../VTypography/VTypography';

const TitledSwitch = ({
  classes,
  icon,
  title,
  checked,
  onChange,
  name,
  placeholder,
  disabled,
  switchOnTheRight = true,
  titleVariant,
  materialIcon,
  description,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Stack {...props}>
      <Stack
        direction={switchOnTheRight ? 'row' : 'row-reverse'}
        justifyContent="space-between"
        alignItems="center"
        gap={2}
      >
        <InputTitle
          titleVariant={titleVariant}
          icon={icon}
          materialIcon={materialIcon}
          title={title}
        />
        <Switch
          checked={checked || false}
          className={classes.switch}
          onChange={onChange}
          disabled={disabled}
          name={name}
          placeholder={placeholder}
        />
      </Stack>
      {description && (
        <VTypography variant="subtitle6" color={theme.palette.grey[600]}>
          {description}
        </VTypography>
      )}
    </Stack>
  );
};

export default withStyles(styles)(TitledSwitch);
