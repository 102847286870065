import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';

const styles = (theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.grey[200],
    borderRadius: '10px',
    border: '2px solid transparent',
    transition: 'background-color 0.25s, border-color 0.25s',
    padding: '5px 10px',
    height: 40,
  },
  focused: {
    backgroundColor: 'transparent',
    borderColor: theme.palette.primary.main,
  },
});

const AccessInput = withStyles(styles)(Input);
export default AccessInput;
