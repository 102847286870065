import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    autocompleteInput: {
      '&.MuiTextField-root': {
        backgroundColor: theme.palette.grey[200],
        borderRadius: '10px',
        width: '100%',
        height: 40,
        minHeight: 'unset',
        outline: 'none',
        padding: '6px 16px',

        '&:before': {
          borderBottom: 0,
        },
      },
      '& .MuiInput-input': {
        padding: '3px 0 !important',
        lineHeight: 1,
      },
      '& .MuiInput-root': {
        alignItems: 'center',
        gap: 8,
        display: 'flex',
        marginBottom: 10,
        backgroundColor: 'transparent',
        color: '#121212',
        '&:before': {
          borderBottom: '0 !important',
        },
        '&:after': {
          borderBottom: '0 !important',
        },
        '& .MuiAutocomplete-endAdornment': {
          display: 'none',
        },
      },
    },
    errorInput: {
      '&.MuiTextField-root': {
        backgroundColor: 'transparent',
        border: '2px solid red',
        padding: '4px 16px',
      },
    },
  });

export default styles;
