import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    formContainer: {
      '&.MuiPaper-root': {
        padding: 16,
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        backgroundColor: theme.palette.grey[50],
      },
    },
  });

export default styles;
