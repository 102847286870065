import React from 'react';
import { Stack } from '@mui/material';
import VTypography from '../../../../../Venti-UI-Kit/VTypography/VTypography';
import VTextArea from '../../../../../Venti-UI-Kit/VInputs/VTextArea/VTextArea';

const NotesCell = ({ rowIndex, value, edit, handleInputChange }) => {
  return (
    <Stack>
      {edit ? (
        <Stack width="100%">
          <VTextArea
            onChange={(event) => handleInputChange(event, rowIndex)}
            name="notes"
            value={value}
            showHelpText={false}
          />
        </Stack>
      ) : (
        <VTypography>{value}</VTypography>
      )}
    </Stack>
  );
};

export default NotesCell;
