import React, { useCallback, useRef } from 'react';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import { Box } from '@mui/material';
import { withStyles } from '@material-ui/core';
import styles from './StadiumImageStyles';

const StadiumImage = ({ image, classes }) => {
  const imgRef = useRef();
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });

      img.style.setProperty('transform', value);
    }
  }, []);

  return (
    <Box className={classes.zoomContainer}>
      <QuickPinchZoom doubleTapToggleZoom onUpdate={onUpdate} minZoom={1} centerContained>
        <img ref={imgRef} alt="" src={image} className={classes.stadiumImage} />
      </QuickPinchZoom>
    </Box>
  );
};

export default withStyles(styles)(StadiumImage);
