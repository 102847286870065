import React, { useRef } from 'react';
import { TableSortLabel, withStyles, Checkbox } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import styles from './BlacklistTable.styles';
import { sortObjectArray } from '../../../utils/utils';
import { SORT_DIRECTION } from '../../../constants/types';

const LABELS = {
  ALIAS: 'Alias',
  EMAIL: 'Email',
  BLACKLISTED: 'Inhabilitado',
};

const getOptions = () => ({
  selectableRows: 'none',
  responsive: 'scrollMaxHeight',
  download: false,
  print: false,
  filter: false,
  viewColumns: false,
  rowsPerPage: 25,
  rowsPerPageOptions: [25, 50, 100],
  textLabels: {
    body: {
      noMatch: 'No hay sellers',
      toolTip: 'Ordenar',
      columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
    },
    pagination: {
      next: 'Página siguiente',
      previous: 'Página anterior',
      rowsPerPage: 'Filas por página:',
      displayRows: 'de',
    },
    toolbar: {
      search: 'Buscar',
    },
  },
});

const InvitationsTable = ({ sellers, classes, setSellers }) => {
  const sortIndex = useRef();
  const sortDirection = useRef(SORT_DIRECTION.ASC);

  const sortSellers = (index, label, name) => {
    if (label === LABELS.BLACKLISTED || !sellers.length) return;

    if (sortIndex.current === index) {
      sortDirection.current =
        sortDirection.current === SORT_DIRECTION.DESC ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC;
    } else {
      sortDirection.current = SORT_DIRECTION.DESC;
      sortIndex.current = index;
    }

    const orderedSellers = sortObjectArray(sellers, name, sortDirection.current);

    setSellers([...orderedSellers]);
  };

  const getStyleProps = () => ({
    setCellProps: () => ({
      className: classes.cell,
    }),
    customHeadRender: ({ index, label, name }) => (
      <th
        onClick={() => sortSellers(index, label, name)}
        className={
          label !== LABELS.BLACKLISTED ? classes.headerWithArrow : classes.headerWithNoArrow
        }
      >
        {label}
        {label !== LABELS.BLACKLISTED && (
          <TableSortLabel
            active={sortIndex.current === index}
            direction={sortDirection.current || SORT_DIRECTION.ASC}
            className={classes.arrow}
          />
        )}
      </th>
    ),
  });

  const columns = [
    {
      name: 'alias',
      label: LABELS.ALIAS,
      options: {
        ...getStyleProps(),
      },
    },
    {
      name: 'mail',
      label: LABELS.EMAIL,
      options: {
        ...getStyleProps(),
      },
    },
    {
      name: 'blacklistCheckbox',
      label: LABELS.BLACKLISTED,
      options: {
        sort: false,
        ...getStyleProps(),
      },
    },
  ];

  const handleCheckBoxChange = (index, value) => {
    setSellers((prevState) => {
      prevState[index].blacklisted = value;
      return [...prevState];
    });
  };

  const addCheckBox = (sellers) =>
    sellers.map((seller, index) => ({
      ...seller,
      blacklistCheckbox: (
        <Checkbox
          checked={seller.blacklisted}
          onChange={() => handleCheckBoxChange(index, !seller.blacklisted)}
          color="primary"
        />
      ),
    }));

  return (
    <div>
      <MUIDataTable data={addCheckBox(sellers)} columns={columns} options={getOptions()} />
    </div>
  );
};

export default withStyles(styles)(InvitationsTable);
