import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography, withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import styles from './AdminBlacklist.styles';
import Button from '../../../Venti-UI-Kit/Buttons/Button';
import BlacklistTable from './BlacklistTable';
import api from '../../../api/api';
import { NOTIFICATION_VARIANT, REQUEST_RESPONSE, USER_ROLES } from '../../../constants/types';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import { handleRequestHelper } from '../../../utils/helpers';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';

const AdminBlacklist = ({ classes, urlParams }) => {
  const { eventId, producerId } = urlParams;
  const { showNotification } = useNotifications();

  const [loading, setLoading] = useState(true);
  const [numberOfBlacklistedSellers, setNumberOfBlacklistedSellers] = useState(0);
  const [sellers, setSellers] = useState([]);
  const [shouldBlacklistSellers, setShouldBlacklistSellers] = useState(false);

  const isUserBlacklisted = (eventsBlacklisted) => {
    return eventsBlacklisted.some(({ id }) => id === parseInt(eventId));
  };

  const getSellers = async () => {
    await handleRequestHelper({
      endpoint: () =>
        api.getAllProducerUsersWithEventsBlacklisted(producerId, {
          roles: [USER_ROLES.SELLER_WITH_CASH, USER_ROLES.SELLER],
        }),
      showNotification,
      onSuccess: ({ users }) => {
        const sellers = users.map(({ id, alias, mail, eventsBlacklisted }) => ({
          id,
          alias,
          mail,
          blacklisted: isUserBlacklisted(eventsBlacklisted),
        }));
        setSellers(sellers);
      },
    });
    setLoading(false);
  };

  const getEvent = async () => {
    return handleRequestHelper({
      endpoint: () => api.getEvent(eventId),
      showNotification,
      onSuccess: ({ event }) => event,
    });
  };

  const getEventAndSellers = async () => {
    const event = await getEvent();

    setShouldBlacklistSellers(event?.shouldBlacklistSellers);

    if (!event || event?.shouldBlacklistSellers) {
      setLoading(false);
      return;
    }

    await getSellers();
  };

  useEffect(() => {
    getEventAndSellers();
  }, []);

  useEffect(() => {
    setNumberOfBlacklistedSellers(sellers.filter(({ blacklisted }) => blacklisted).length);
  }, [sellers]);

  const selectAllSellers = () => {
    setSellers(
      sellers.map(({ blacklisted, ...sellerData }) => ({ ...sellerData, blacklisted: true }))
    );
  };

  const unselectAllSellers = () => {
    setSellers(
      sellers.map(({ blacklisted, ...sellerData }) => ({ ...sellerData, blacklisted: false }))
    );
  };

  const oneOfTheResponsesIsError = (responses) => {
    return responses.reduce(
      (error, response) =>
        error || (response !== 0 && response.status !== REQUEST_RESPONSE.SUCCESS),
      false
    );
  };

  const onSaveChanges = async () => {
    const sellerToBlacklist = sellers.filter(({ blacklisted }) => blacklisted).map(({ id }) => id);
    const sellerToUnblacklist = sellers
      .filter(({ blacklisted }) => !blacklisted)
      .map(({ id }) => id);

    // TODO: add promise all on handleRequestHelper
    try {
      const responses = await Promise.all([
        sellerToBlacklist.length && api.blacklistUsers(eventId, sellerToBlacklist),
        sellerToUnblacklist.length && api.unblacklistUsers(eventId, sellerToUnblacklist),
      ]);

      if (oneOfTheResponsesIsError(responses)) {
        showNotification('Ocurrió un error actualizando la blacklist', NOTIFICATION_VARIANT.ERROR);
        return;
      }

      showNotification('Se actualizó la blacklist con éxito!', NOTIFICATION_VARIANT.SUCCESS);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PageContainer title="Backoffice - Administrar Blacklist">
      <VBreadcrumbs pageTitle="Blacklist" />
      <Stack alignItems="center" gap={3} px={2}>
        <Typography variant="h3">Administrar blacklist en este evento</Typography>
        {loading ? (
          <CircularProgress size={40} />
        ) : shouldBlacklistSellers ? (
          <div className={classes.containerBlacklistText}>
            <Typography className={classes.blacklistText}>
              El evento no tiene habilitada esta opción.
            </Typography>
            <Typography className={classes.blacklistText}>
              Para poder seleccionar qué sellers podrán visualizar el evento con normalidad,
              habilitá la opción en{' '}
              <a
                className={classes.linkText}
                href={`/backoffice/productoras/${producerId}/eventos/${eventId}/editar`}
                rel="noreferrer"
              >
                Editar Evento
              </a>
              .
            </Typography>
          </div>
        ) : (
          <>
            <div className={classes.paper}>
              <div className={classes.textAndButtonsContainer}>
                <Typography className={classes.text} variant="h6">
                  {`Cantidad total de usuarios inhablilitados: ${numberOfBlacklistedSellers}`}
                </Typography>
                <div className={classes.buttonsContainer}>
                  <Button onClick={selectAllSellers} disabled={!sellers || !sellers.length}>
                    Seleccionar todos
                  </Button>
                  <Button onClick={unselectAllSellers} disabled={!sellers || !sellers.length}>
                    Deseleccionar todos
                  </Button>
                </div>
              </div>
              <BlacklistTable sellers={sellers ?? []} setSellers={setSellers} />
            </div>
            <Button color="main" onClick={onSaveChanges} disabled={!sellers || !sellers.length}>
              Guardar
            </Button>
          </>
        )}
      </Stack>
    </PageContainer>
  );
};

export default withStyles(styles)(AdminBlacklist);
