import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    container: {
      padding: 20,
      rowGap: 20,
    },
    dialogContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'end',
    },
    title: {
      textAlign: 'center',
    },
    buttonsContainer: {
      with: '100%',
      display: 'flex',
      justifyContent: 'end',
      columnGap: 10,
    },
    circularProgress: {
      margin: 'auto',
    },
  });

export default styles;
