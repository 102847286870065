import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './ErrorPageStyles';
import PageContainer from '../../../Components/PageContainer/PageContainer';

const NotFound404 = ({ classes }) => {
  return (
    <PageContainer withFooter>
      <div className={classes.pageContainer}>
        <Paper elevation={0} className={classes.paperContainer}>
          <Typography variant="h3">404</Typography>
          <Typography variant="subtitle2">¡Oops! Esta pagina no existe.</Typography>
        </Paper>
      </div>
    </PageContainer>
  );
};

export default withStyles(styles)(NotFound404);
