import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    container: {
      width: '70%',
      borderRadius: 15,
      background: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[200]}`,
      display: 'flex',
      padding: 20,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        padding: '10px 20px',
      },
    },
    leftColumnContent: {
      display: 'flex',
      wordWrap: 'break-word',
      alignItems: 'center',
      flex: 1,
      [theme.breakpoints.down('xs')]: {
        display: 'unset',
        wordWrap: 'break-word',
      },
    },
    rightColumnContent: {
      columnGap: 10,
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'row',
        justifyContent: 'space-around',
      },
    },
    iconButton: {
      color: theme.palette.text.primary,
    },
    cardTitle: {
      color: theme.palette.text.primary,
    },
  });

export default styles;
