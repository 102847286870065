import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    homePage: {
      padding: '0 16px',
      height: 'auto',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',

      [theme.breakpoints.up('sm')]: {
        width: 472,
        alignSelf: 'center',
      },

      '& .loginPageTitle': {
        fontSize: 36,
        fontWeight: 700,
        color: theme.palette.text.primary,
        textAlign: 'left',
        lineHeight: '42px',
      },
    },
  });

export default styles;
