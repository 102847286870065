import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { Paper } from '@mui/material';
import styles from './ResetPasswordCardStyles';
import Button from '../../../../Venti-UI-Kit/Buttons/Button';
import { NOTIFICATION_VARIANT } from '../../../../constants/types';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';
import { handleRequestHelper } from '../../../../utils/helpers';
import { useNotifications } from '../../../../Providers/NotificationsProvider/NotificationsProvider';
import api from '../../../../api/api';

const ResetPasswordCard = ({ classes, userMail }) => {
  const { showNotification } = useNotifications();

  const resetPasswordTokenBody = {
    mail: userMail,
    hostname: window.location.hostname,
  };

  const sendMailWithToken = async () => {
    await handleRequestHelper({
      endpoint: () => api.sendResetPasswordToken(resetPasswordTokenBody),
      showNotification,
      onSuccess: () => showNotification('El mail fue enviado', NOTIFICATION_VARIANT.SUCCESS),
    });
  };

  return (
    <div className={classes.container}>
      <VTypography variant="subtitle1" mb={8}>
        Restablecer contraseña
      </VTypography>
      <Paper className={classes.paper}>
        <Typography>
          Se le enviará un mail a la casilla <b>{userMail}</b> para que pueda restablecer la
          contraseña
        </Typography>
        <Button color="main" onClick={sendMailWithToken} className={classes.submitButton}>
          Enviar mail
        </Button>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(ResetPasswordCard);
