import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    base: {
      '&.MuiButtonBase-root': {
        borderRadius: 8,
        padding: 10,
      },
    },

    contained: {
      '&.MuiButtonBase-root': {
        background: theme.palette.buttons.main.background,
        color: theme.palette.buttons.main.text,

        '&:hover': {
          background: theme.palette.buttons.main.hover,
        },
      },

      '&:disabled': {
        '&.MuiButtonBase-root': {
          background: theme.palette.buttons.disabled,
          color: theme.palette.buttons.main.text,
        },
      },
    },

    outlined: {
      '&.MuiButtonBase-root': {
        background: 'transparent',
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.text.primary}`,
      },

      '&:disabled': {
        '&.MuiButtonBase-root': {
          border: `1px solid ${theme.palette.buttons.disabled}`,
        },
      },
    },
  });

export default styles;
