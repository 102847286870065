import { useTheme, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Stack } from '@mui/material';
import styles from './InputTitle.styles';
import VTypography from '../../Venti-UI-Kit/VTypography/VTypography';

const InputTitle = ({ classes, title, icon, materialIcon, description, titleVariant }) => {
  const theme = useTheme();

  return (
    <Stack className={classes.container}>
      <Stack direction="row" alignItems="center">
        {!!icon && <img alt="icon" src={icon} className={classes.icon} />}
        {materialIcon}
        <VTypography variant={titleVariant ?? 'subtitle1'}>{title}</VTypography>
      </Stack>
      {description && (
        <VTypography variant="subtitle6" color={theme.palette.grey[600]}>
          {description}
        </VTypography>
      )}
    </Stack>
  );
};

export default withStyles(styles)(InputTitle);
