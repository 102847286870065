import React from 'react';
import { withStyles } from '@material-ui/core';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, Stack } from '@mui/material';
import { withRouter } from 'react-router-dom';
import styles from './GoBackButton.styles';
import VTypography from '../../Venti-UI-Kit/VTypography/VTypography';
import history from '../../appHistory';

const GoBackButton = ({ classes, returnTo, label, labelVariant = 'h5' }) => {
  const goBack = () => {
    if (returnTo) history.push(returnTo);
    else history.goBack();
  };
  return (
    <Stack direction="row" className={classes.goBackButtonContainer}>
      <Box component="button" onClick={goBack} className={classes.goBackIconButton}>
        <ArrowBackIosNewIcon />
      </Box>
      {label && <VTypography variant={labelVariant}>{label}</VTypography>}
    </Stack>
  );
};

export default withRouter(withStyles(styles)(GoBackButton));
