import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { handleRequestHelper } from '../../../utils/helpers';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';

export const useProducerListPage = () => {
  const { showNotification } = useNotifications();
  const [producers, setProducers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  const getProducers = async (searchQuery) => {
    setLoading(true);

    handleRequestHelper({
      endpoint: () => api.getAllProducers({ page: currentPage, searchQuery, limit: 12 }),
      showNotification,
      onSuccess: ({ producers, totalPages }) => {
        setLoading(false);
        setProducers(producers);
        setTotalPages(totalPages);
      },
      onFailure: () => setLoading(false),
    });
  };

  const handleSearch = async (searchQuery) => {
    setCurrentPage(1);
    await getProducers(searchQuery);
  };

  useEffect(() => {
    getProducers();
  }, [currentPage]);

  const handleNewPage = async (_, newPageNumber) => {
    setCurrentPage(newPageNumber);
  };

  return {
    showNotification,
    producers,
    currentPage,
    totalPages,
    loading,
    user,
    handleSearch,
    handleNewPage,
  };
};
