import React, { useRef } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import styles from './MoreEventsSectionStyles';
import SmallEventCard from '../SmallEventCard/SmallEventCard';
import SectionButton from '../SectionButton/SectionButton';

const MoreEventsSection = ({ classes, events, userAlias }) => {
  const moreEventsSectionRef = useRef();

  return (
    <>
      <SectionButton animationTime="40s" sectionRef={moreEventsSectionRef} position={1}>
        <Typography>
          MORE EVENTS <b>MÁS EVENTOS</b>
        </Typography>
      </SectionButton>
      <div className={classes.container}>
        {events?.map((event) => (
          <SmallEventCard event={event} key={event.id} id={event.id} userAlias={userAlias} />
        ))}
      </div>
    </>
  );
};

export default withStyles(styles)(MoreEventsSection);
