import React from 'react';
import CustomLink from '../CustomLink/CustomLink';
import VTypography from '../../Venti-UI-Kit/VTypography/VTypography';

const ReCaptchaMessage = () => {
  const privacyPolicy = (
    <CustomLink href="https://policies.google.com/privacy" title="Privacy Policy" fontSize={10} />
  );

  const termsOfService = (
    <CustomLink href="https://policies.google.com/terms" title="Terms of Service" fontSize={10} />
  );

  return (
    <VTypography variant="overline" align="center">
      This site is protected by reCAPTCHA and the Google <br />
      {privacyPolicy} and {termsOfService} apply.
    </VTypography>
  );
};

export default ReCaptchaMessage;
