import React from 'react';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { withStyles } from '@material-ui/core';
import ChartLayout from '../../Components/Charts/ChartLayout';
import styles from './VTable.styles';
import VTypography from '../VTypography/VTypography';

const VTable = ({ title, data = [], columns, page, onPageChange, rowsPerPage = 5, classes }) => {
  return (
    <ChartLayout title={title}>
      {data.length && (
        <Stack height="100%" justifyContent="space-between">
          <Stack className={classes.tableContainer}>
            <Table size="medium" className={classes.table}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align="center">
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => (
                    <TableRow className={classes.tableRow} key={index}>
                      {columns.map((column) => (
                        <TableCell key={column.id} align={column.align || 'center'}>
                          <VTypography align="inherit" variant="body2" linesNumber={1}>
                            {row[column.id]}
                          </VTypography>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Stack>
          <TablePagination
            component="div"
            count={data.length}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
            className={classes.tablePagination}
          />
        </Stack>
      )}
    </ChartLayout>
  );
};

export default withStyles(styles)(VTable);
