import { NO_PINNED_EVENT_VALUE } from '../ProducerForm.constants';

export const usePinnedEventForm = (events) => {
  const formatedEvents = events.map(({ id, name }) => ({ value: id, label: name }));

  const defaultOption = {
    value: NO_PINNED_EVENT_VALUE,
    label: !events.length ? 'No tienes eventos para destacar' : 'Sin evento destacado',
  };

  const eventsOptions = [defaultOption, ...formatedEvents];

  return { eventsOptions };
};
