import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, Typography, withStyles } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import styles from './NewTeamLeader.styles';
import CustomInput from '../../../../Components/CustomInput/CustomInput';
import { useFormDeprecated } from '../../../../hooks/useFormDeprecated';
import api from '../../../../api/api';
import { NOTIFICATION_VARIANT } from '../../../../constants/types';
import { moveItemToFirstPlaceInList } from '../../../../utils/utils';
import PageContainer from '../../../../Components/PageContainer/PageContainer';
import Button from '../../../../Components/Buttons/Button/Button';
import VBreadcrumbs from '../../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import { useNotifications } from '../../../../Providers/NotificationsProvider/NotificationsProvider';
import { handleRequestHelper } from '../../../../utils/helpers';

const NewTeamLeader = ({ classes, urlParams }) => {
  const { showNotification } = useNotifications();
  const { producerId: currentProducerId } = urlParams;

  const formatProducerList = (producers) => {
    if (!producers || !Array.isArray(producers) || !producers.length) return;

    const currentProducer = producers.find(
      (producer) => producer.id === parseInt(currentProducerId)
    );

    return moveItemToFirstPlaceInList(
      currentProducer,
      producers.filter((producer) => producer.generatesSellerReports)
    );
  };

  const [formErrors, setFormErrors] = useState({});
  const [userProducers, setUserProducers] = useState([]);
  const [selectedProducersIds, setSelectedProducersIds] = useState([currentProducerId]);
  const { formState, handleInputChange, validateText, resetForm } = useFormDeprecated({
    teamLeaderCode: '',
  });

  const getProducersList = async () => {
    await handleRequestHelper({
      endpoint: () => api.getAllProducers(),
      onSuccess: ({ producers }) => setUserProducers(formatProducerList(producers)),
    });
  };

  useEffect(() => {
    getProducersList();
  }, []);

  const validateForm = () => {
    const errors = {
      teamLeaderCode: validateText(formState.teamLeaderCode),
    };
    setFormErrors((prevState) => ({ ...prevState, ...errors }));
    const validationsError = Object.values(errors).reduce(
      (prevErrors, currentError) => prevErrors + currentError,
      ''
    );
    return validationsError === '';
  };

  const producerIsInList = (producerId) => {
    return selectedProducersIds.includes(producerId);
  };

  const handleCheck = (producerId) => () => {
    if (producerIsInList(producerId)) {
      setSelectedProducersIds(selectedProducersIds.filter((id) => id !== producerId));
    } else {
      setSelectedProducersIds([...selectedProducersIds, producerId]);
    }
  };

  const setInitialState = () => {
    resetForm();
    setSelectedProducersIds([currentProducerId]);
  };

  const onSubmit = async () => {
    if (!validateForm()) {
      showNotification('Revisa los campos con errores', NOTIFICATION_VARIANT.ERROR);
      return;
    }
    const code = formState.teamLeaderCode;
    const selectedProducers = selectedProducersIds.map((producerId) => ({ producerId, code }));

    await handleRequestHelper({
      endpoint: () => api.createTeamLeader(selectedProducers),
      showNotification,
      onSuccess: () => {
        showNotification(`Team leader ${code} creado con éxito`, NOTIFICATION_VARIANT.SUCCESS);
        setInitialState();
      },
      onFailure: () =>
        showNotification(
          `Ocurrio un error al crear al team leader: ${code}`,
          NOTIFICATION_VARIANT.ERROR
        ),
    });
  };

  return (
    <PageContainer title="Crear Team Leader">
      <VBreadcrumbs pageTitle="Crear" />
      <Typography className={classes.title} variant="h5">
        Agregar nuevo Team Leader
      </Typography>
      <div className={classes.pageContent}>
        <CustomInput
          placeholder="Código"
          error={formErrors.teamLeaderCode}
          title="Código de team leader"
          titleIcon={<Person />}
          onChange={handleInputChange}
          name="teamLeaderCode"
          value={formState.teamLeaderCode}
        />
        <Typography className={classes.producerTitle} align="left" variant="h6">
          Productoras asociadas
        </Typography>
        <FormControl margin="normal" required fullWidth>
          {userProducers?.map((producer, index) => (
            <FormControlLabel
              key={index}
              label={producer?.name}
              control={
                <Checkbox
                  color="primary"
                  checked={producerIsInList(producer?.id.toString())}
                  onChange={handleCheck(producer?.id.toString())}
                  name={`${producer?.id}`}
                />
              }
            />
          ))}
        </FormControl>
        <Button title="Agregar" fullwidth={false} onClick={onSubmit} />
      </div>
    </PageContainer>
  );
};

export default withStyles(styles)(NewTeamLeader);
