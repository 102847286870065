import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './CheckoutFormStyles';
import CreditCard from '../CreditCard/CreditCard';
import TextInput from '../../../../../Venti-UI-Kit/Inputs/TextInput';
import SelectInput from '../../../../../Venti-UI-Kit/Inputs/SelectInput';

const CheckoutForm = ({
  classes,
  identificationTypes,
  formState,
  showForm,
  installmentsOptions,
  cardTypeData,
}) => {
  const { formState: formInputs, focused, handleInputChange, resetForm } = formState;

  const { cardName, cardNumber, cardExpirationDate, cvc, idType, idValue, installments } =
    formInputs;

  const handleCardNumberChange = (e) => {
    if (e.target.value.match(/\d/) === null) {
      resetForm();
      return;
    }

    handleInputChange(e);
  };

  return (
    <div className={classes.lineCheckoutFormContainer}>
      <CreditCard
        bank="bankName"
        cvc={cvc.value}
        expiry={cardExpirationDate.value}
        focused={focused}
        name={cardName.value}
        number={cardNumber.value}
      />
      <div className={classes.formContainer}>
        <TextInput
          title={cardNumber.title}
          onChange={handleCardNumberChange}
          name="cardNumber"
          type="tel"
          mask={cardTypeData.mask}
          errorMessage={cardNumber.error}
        />
        {showForm && (
          <>
            <TextInput
              title={cardName.title}
              onChange={handleInputChange}
              defaultValue={cardName.value}
              name="cardName"
              type="text"
              errorMessage={cardName.error}
              upperCase
            />
            <div className="doubleInputContainer">
              <TextInput
                title={cardExpirationDate.title}
                onChange={handleInputChange}
                name="cardExpirationDate"
                defaultValue={cardExpirationDate.value}
                type="tel"
                mask="99/99"
                errorMessage={cardExpirationDate.error}
              />
              <TextInput
                title={cvc.title}
                onChange={handleInputChange}
                name="cvc"
                type="tel"
                defaultValue={cvc.value}
                maxLength={4}
                errorMessage={cvc.error}
              />
            </div>
            <div className="doubleInputContainer">
              <SelectInput
                title={idType.title}
                onChange={handleInputChange}
                name="idType"
                value={idType.value}
                options={identificationTypes}
                errorMessage={idType.error}
                flex={1}
              />
              <TextInput
                title={idValue.title}
                flex={2}
                onChange={handleInputChange}
                defaultValue={idValue.value}
                name="idValue"
                errorMessage={idValue.error}
              />
            </div>
            {!!installmentsOptions.length && (
              <SelectInput
                title={installments.title}
                onChange={handleInputChange}
                name="installments"
                value={installments.value}
                options={installmentsOptions}
                errorMessage={installments.error}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(CheckoutForm);
