import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    pageContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: '0 40px',
      width: 'calc(100%-50px)',
      alignSelf: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    subtitle: {
      fontStyle: 'italic',
      fontSize: 21,
    },
    qrInputsContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: '40px 0',
      rowGap: 20,
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        marginTop: 40,
      },
    },
    title: {
      fontSize: 32,
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightBold,
    },
    claimedTicketsContainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
    },
    claimedSubtitleContainer: {
      display: 'flex',
      columnGap: 20,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    claimedListHeaderText: {
      fontSize: 20,
      color: theme.palette.primary.main,
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightMedium,
    },
    orderText: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 10,
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.h6.fontSize,
    },
    noNFTSubtitle: {
      fontSize: 12,
    },
  });

export default styles;
