import { createStyles } from '@material-ui/core';
import disabledCreditCardBackground from '../../../../../assets/creditCardBackgrounds/disabledCreditCardBackground.svg';
import frontCreditCardBackground from '../../../../../assets/creditCardBackgrounds/frontCreditCardBackground.svg';
import backCreditCardBackground from '../../../../../assets/creditCardBackgrounds/backCreditCardBackground.svg';

const styles = (theme) => {
  const textStyle = {
    textAlign: 'left',
    fontSize: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    opacity: '1 !important',

    [theme.breakpoints.up('sm')]: {
      fontSize: 12,
    },
  };

  const positionStyle = {
    top: 'unset',
    left: 'unset',
    bottom: 'unset',
    right: 'unset',
    // transition: 'unset',
  };

  return createStyles({
    creditCardContainer: {
      position: 'relative',
      width: 256,
      height: 148,
      borderRadius: 8,

      '& *': {
        boxShadow: 'none',
      },

      '& div': {
        ...positionStyle,
        ...textStyle,
      },

      '& .rccs': {
        width: '100%',
        height: '100%',

        '&__bank': {
          ...textStyle,
          top: 16,
          left: 16,
          position: 'absolute',
          fontSize: '18px !important',
        },

        '&__issuer': {
          right: 16,
          bottom: 16,
          height: 22,
          width: 33,
        },

        '&__number': {
          ...textStyle,
          top: 65,
          left: 16,
          fontSize: '18px !important',
        },

        '&__name': {
          top: 120,
          left: 16,
        },

        '&__expiry': {
          left: 16,
          top: 104,
        },

        '&__cvc': {
          color: '#FFF',
          top: 92,
          right: 16,
        },

        '&__cvc__front': {
          top: 70,
          right: 16,
        },

        '&__chip, &__expiry__valid, &__stripe, &__signature': {
          display: 'none',
        },

        '&__card': {
          width: '100%',
          height: '100%',
          boxShadow: '0px 2px 4px rgba(0,0,0,.25)',
          borderRadius: 8,

          '&__background': {
            transform: 'unset',
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            left: '0 !important',
          },

          '&--unknown > div': {
            background: 'none',
          },

          '&.rccs__card--unknown': {
            '& .rccs__card--front': {
              color: '#000',
              borderRadius: 8,
              '& .rccs__card__background': {
                background: `url(${disabledCreditCardBackground})`,
              },
            },
          },

          '&:not(.rccs__card--unknown)': {
            '& .rccs__card--front': {
              color: '#FFF',
              borderRadius: 8,
              '& .rccs__card__background': {
                background: `url(${frontCreditCardBackground})`,
              },
            },
          },

          '&--back': {
            borderRadius: 8,
            '& .rccs__card__background': {
              background: `url(${backCreditCardBackground})`,
            },
          },
        },
      },
    },
  });
};

export default styles;
