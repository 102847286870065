import { createStyles } from '@material-ui/core';

const styles = (theme) => {
  return createStyles({
    eventImgBox: {
      '&:hover': {
        '& $eventDescriptionBox': {
          [theme.breakpoints.up('md')]: {
            '&.MuiStack-root': {
              opacity: 1,
              animation: `$fadeIn 0.5s ease-in-out`,
            },
          },
        },
        '& $banner': {
          [theme.breakpoints.up('md')]: {
            transform: 'scale(1.03)',
            animation: `$scaleUp 0.5s ease-in-out`,
          },
        },
      },
      overflow: 'hidden',
      borderRadius: 8,
      border: `1px solid ${theme.palette.text.primary}`,
      [theme.breakpoints.down('md')]: {
        alignSelf: 'center',
        maxWidth: 400,
      },
    },

    banner: {
      width: '100%',
      objectFit: 'cover',
      transition: 'transform 0.5s ease-in-out',
    },

    eventDescriptionBox: {
      position: 'absolute',
      width: '27%',
      height: '100%',
      backdropFilter: 'blur(20px)',
      backgroundColor: 'rgb(0 0 0 / 34%)',
      padding: theme.spacing(2),
      border: `1px solid transparent`,
      transition: 'opacity 0.5s ease-in-out',
      justifyContent: 'space-between',
      '&.MuiStack-root': {
        opacity: 0,
        animation: `$fadeOut 0.5s ease-in-out`,
      },
      [theme.breakpoints.down('md')]: {
        top: '65%',
        alignSelf: 'center',
        width: '100%',
        height: '35%',
        maxWidth: 400,
        '&.MuiStack-root': {
          opacity: 1,
        },
      },
    },

    '@keyframes fadeIn': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },

    '@keyframes fadeOut': {
      from: { opacity: 1 },
      to: { opacity: 0 },
    },

    '@keyframes scaleUp': {
      from: { transform: 'scale(1)' },
      to: { transform: 'scale(1.03)' },
    },

    '@keyframes scaleDown': {
      from: { transform: 'scale(1.03)' },
      to: { transform: 'scale(1)' },
    },
  });
};

export default styles;
