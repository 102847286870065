const styles = (theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.grey[200],
    borderRadius: '20px',
    border: '2px solid transparent',
    transition: 'background-color 0.25s, border-color 0.25s',
    padding: '0.8em',
  },
  iconTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  helper: {
    color: 'red',
    marginBottom: 10,
    display: 'inline',
    float: 'left',
  },
  ImgIcon: {
    display: 'flex',
    alignItems: 'left',
    width: '1em',
    height: '1em',
    marginTop: 10,
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelTitle: {
    fontSize: '1.1em',
    fontWeight: 500,
    marginBottom: 10,
  },
  radioItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 15,
    columnGap: 15,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  themeImage: {
    width: 'auto',
    height: 100,
    borderRadius: 5,
    border: '1px solid #121212',
  },
});

export default styles;
