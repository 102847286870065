import { createStyles } from '@material-ui/core';

const header = {
  fontWeight: 500,
  cursor: 'pointer',
  paddingBottom: '10px',
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
};

const styles = () =>
  createStyles({
    iconButton: {
      height: 40,
      width: 40,
      padding: 0,
    },
    cell: {
      padding: '5px 0',
      textAlign: 'center',
      width: '30%',
    },
    headerWithArrow: {
      ...header,
      paddingLeft: '25px',
    },
    headerWithNoArrow: {
      ...header,
    },
    arrow: {
      paddingBottom: 4,
    },
  });

export default styles;
