import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TERMS_BY_COUNTRY } from '../../constants/constants';

class Disclaimer extends React.Component {
  render() {
    const { classes, eventWithLemon, country } = this.props;

    const termsLink = TERMS_BY_COUNTRY[country] ?? TERMS_BY_COUNTRY.AR;

    return (
      <div className={classes.main}>
        <Typography variant="body2" align="left" className={classes.infoText}>
          {`La carga de productos y textos en el sistema licenciado son de exclusiva responsabilidad 
					del comerciante. Venti no se hace responsable civil o jurídicamente por las declaraciones 
					emitidas por estos en lo relativo a los productos vendidos en su plataforma. El comerciante 
					es el único y exclusivo responsable de la venta, en forma oportuna y en conformidad a la 
					ley vigente. `}
          <a style={{ color: '#616266' }} target="_blank" href={termsLink} rel="noreferrer">
            Ver términos y condiciones.
          </a>
          {eventWithLemon && (
            <>
              {' '}
              También{' '}
              <a
                style={{ color: '#616266' }}
                target="_blank"
                href="https://www.lemon.me/legales/terminos-y-condiciones"
                rel="noreferrer"
              >
                Ver términos y condiciones de Lemon.
              </a>
            </>
          )}
        </Typography>
      </div>
    );
  }
}
const styles = (theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    marginTop: 20,
    maxWidth: 840,

    [theme.breakpoints.down('md')]: {
      padding: '0 16px',
    },
  },
  arrow: {
    height: 80,
    marginRight: 20,
    marginLeft: 20,
    [theme.breakpoints.down('xs')]: {
      transform: 'rotate(90deg)',
      height: 50,
      margin: 0,
    },
  },
  info: {
    marginTop: 60,
    marginBottom: 30,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      margin: 'auto',
    },
  },
  infoBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 15,
    marginLeft: 15,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      margin: 0,
    },
  },
  processStep: {
    width: '140px',
    margin: 5,
  },
  processStepIcon: {
    width: 22,
    margin: 'auto',
  },
  processTop: {
    display: 'flex',
    flexDirection: 'row',
  },
  infoText: {
    color: '#616266',
    [theme.breakpoints.down('xs')]: {
      marginTop: 2,
    },
  },
});

export default withStyles(styles)(Disclaimer);
