import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    autocompleteContainer: {
      width: '100%',
      height: '100%',
    },
    helper: {
      color: 'red',
      height: 15,
      display: 'inline',
      float: 'left',
    },
    suggestions: {
      marginBottom: 15,
      marginTop: 10,
    },
  });

export default styles;
