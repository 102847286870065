import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import styles from './AllTicketTypesTab.styles';
import MobileTicketTypeInfoCard from '../Cards/TicketTypeInfoCard/MobileTicketTypeInfoCard/MobileTicketTypeInfoCard';

const AllTicketTypesTab = ({ classes, sections, handleDeleteTicket, handleCopyTicketLink }) => {
  return (
    <div className={classes.container}>
      <div className={classes.sectionsHeader}>
        {sections.map(({ label, index }) => (
          <Typography key={`${label}_${index}`} className={classes.sectionTitle}>
            {label}
          </Typography>
        ))}
      </div>
      <div className={classes.sectionsContent}>
        {sections.map(({ label, data, index }) => (
          <div key={`${label}_${index}`} className={classes.sectionContent}>
            {data.map((ticket) => (
              <MobileTicketTypeInfoCard
                ticket={ticket}
                key={`${ticket.id}_${ticket.name}`}
                handleDelete={handleDeleteTicket}
                handleCopyTicketLink={handleCopyTicketLink}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(AllTicketTypesTab);
