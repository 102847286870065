/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import { withStyles } from '@material-ui/core';
import { Link } from '@mui/icons-material';
import styles from './CopyLinkButtonStyles';

const CopyLinkButton = ({ classes: { button, copyLinkIcon }, onClick }) => {
  return (
    <button type="button" className={button} color="alternative" onClick={onClick}>
      <Link className={copyLinkIcon} />
    </button>
  );
};

export default withStyles(styles)(CopyLinkButton);
