import { useState } from 'react';
import { REGULAR_EXPRESSION } from '../constants/constants';

export const useFormDeprecated = (initialState = {}, optionalFields = []) => {
  const [formState, setFormState] = useState(initialState);

  const resetForm = () => {
    setFormState(initialState);
  };

  const handleInputChange = ({ target }) => {
    setFormState({
      ...formState,
      [target.name]: target.value,
    });
  };

  const validateRequiredField = (field) => {
    if (field.toString().trim() === '') return 'Este campo es requerido';
    return '';
  };

  const validateMail = (mail) => {
    if (!mail.trim().match(REGULAR_EXPRESSION.MAIL_VALIDATION))
      return 'Por favor ingrese un mail válido.';
    return '';
  };

  const validateConfirmField = (field, confirmField, errorMessage) => {
    if (field !== confirmField) return errorMessage;
    return '';
  };

  const validateNewPassword = (password, mail) => {
    if (!password.match(REGULAR_EXPRESSION.PASSWORD_VALIDATION))
      return 'La contraseña ingresada no cumple con los requisitos.';
    if (password.trim() === mail.trim()) {
      return 'La contraseña no puede ser igual al mail';
    }
    return '';
  };

  const validateTextAndNumbers = (string) => {
    if (!string.toString().trim().match(REGULAR_EXPRESSION.TEXT_AND_NUMBERS_VALIDATION))
      return 'Solo se permiten letras y números';
    return '';
  };

  const validateText = (text) => {
    if (!text.trim().match(REGULAR_EXPRESSION.TEXT_VALIDATION)) return 'Solo se permiten letras';
    return '';
  };

  const validateNumber = (number) => {
    if (!number.toString().trim().match(REGULAR_EXPRESSION.NUMBER_VALIDATION))
      return 'Solo se permiten solo números';
    return '';
  };

  const filterUnusedFields = (form) => {
    // eslint-disable-next-line no-unused-vars
    return Object.fromEntries(Object.entries(form).filter(([field, value]) => !!value));
  };

  const isFormComplete = (form) => {
    let isComplete = true;
    for (const [fieldName, fieldData] of Object.entries(form)) {
      if (optionalFields.includes(fieldName)) continue;
      isComplete = isComplete && fieldData.toString().trim() !== '';
    }
    return isComplete;
  };

  const formHasErrors = (errors) => {
    const validationsError = Object.values(errors).reduce(
      (prevErrors, currentError) => prevErrors + currentError,
      ''
    );
    return validationsError !== '';
  };

  const validateAlias = (alias) => {
    if (!alias.match(REGULAR_EXPRESSION.ALIAS_VALIDATION))
      return 'El formato del alias es inválido, solo se permiten letras, números, guiones altos y bajos';
    return '';
  };

  return {
    formState,
    validateNewPassword,
    validateRequiredField,
    isFormComplete,
    validateMail,
    validateNumber,
    validateText,
    validateTextAndNumbers,
    validateConfirmField,
    validateAlias,
    handleInputChange,
    resetForm,
    formHasErrors,
    filterUnusedFields,
    setFormState,
  };
};
