import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    pageContent: {
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      display: 'flex',
      rowGap: 20,
    },
    pageTitle: {
      fontWeight: 600,
      marginBottom: 20,
    },
    noTeamLeaderMessage: {
      marginTop: 20,
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
  });

export default styles;
