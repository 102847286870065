import React, { useEffect, useState } from 'react';
import { Divider, Typography, withStyles } from '@material-ui/core';
import styles from './CheckOrderStep.styles';
import CheckoutTicketCard from '../../Cards/CheckoutTicketCard/CheckoutTicketCard';
import { formatPriceWithCurrency } from '../../../utils/utils';
import api from '../../../api/api';
import { CURRENCY, REQUEST_RESPONSE } from '../../../constants/types';
import Button from '../../Buttons/Button/Button';
import TextInput from '../../../Venti-UI-Kit/Inputs/TextInput';

const CheckOrderStep = ({ classes, serviceCharge, cart, cartActions, setOrder, handleNext }) => {
  const [canContinue, setCanContinue] = useState(true);
  const [lemonDiscount, setLemonDiscount] = useState(0);
  const [isLemonExclusive, setIsLemonExclusive] = useState(false);
  const [orderHasTicketWithDiscountCode, setOrderHasTicketWithDiscountCode] = useState(false);
  const [code, setCode] = useState();
  const [validCode, setValidCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const currency = cart.every(({ shouldConvertPriceFromUSD }) => shouldConvertPriceFromUSD)
    ? CURRENCY.USD
    : CURRENCY.ARS;

  const getPrice = (price, priceInUSD) => (currency === CURRENCY.ARS ? price : priceInUSD);

  const getTicketsTotalPrice = (hasLemonDiscount = false) => {
    let totalPrice = 0;
    cart.forEach(({ price, priceInUSD, quantity, discountCode }) => {
      totalPrice +=
        !hasLemonDiscount && discountCode?.percentage
          ? getPrice(price, priceInUSD) * quantity * ((100 - discountCode.percentage) / 100)
          : getPrice(price, priceInUSD) * quantity;
    });
    return totalPrice;
  };

  const hasLemonExclusive = () => {
    let isExclusive = false;
    cart.forEach((ticket) => {
      if (ticket.isLemonExclusive) isExclusive = true;
    });
    setIsLemonExclusive(isExclusive);
  };

  const getLemonDiscount = () => {
    const totalPriceWithLemon = cart.reduce((totalPrice, ticket) => {
      return totalPrice + Number(ticket.priceWithLemon * ticket.quantity);
    }, 0);

    const discount = Math.floor(100 - (totalPriceWithLemon * 100) / getTicketsTotalPrice(true));
    setLemonDiscount(discount);
  };

  const getServiceChargePrice = () => {
    return getTicketsTotalPrice() * serviceCharge;
  };

  const getTotalOrderPrice = () => {
    return getTicketsTotalPrice() + getServiceChargePrice();
  };

  const checkCanContinue = () => {
    if (cart.length <= 0) {
      setCanContinue(false);
      return;
    }
    let cont = true;
    cart.forEach((ticket) => {
      const { quantity, maxQuantity, maxQuantityPerOrder, soldUnits } = ticket;
      cont = cont && !(quantity > maxQuantityPerOrder || quantity + soldUnits > maxQuantity);
    });
    setCanContinue(cont);
  };

  const setTicketHasDiscountCode = () => {
    const hasDiscountCode = cart.some(({ hasDiscountCode }) => hasDiscountCode);
    setOrderHasTicketWithDiscountCode(hasDiscountCode);
  };

  const setValidCodeEntered = () => {
    const validCodeEntered = cart.some(({ discountCode }) => discountCode?.code);
    setValidCode(validCodeEntered);
  };

  useEffect(() => {
    checkCanContinue();
    hasLemonExclusive();
    getLemonDiscount();
    setTicketHasDiscountCode();
    setValidCodeEntered();
  }, [cart]);

  const onSubmit = () => {
    setOrder((prevState) => ({
      ...prevState,
      prices: {
        lemonDiscount,
        isLemonExclusive,
        totalOrderPrice: getTotalOrderPrice(),
        servicePrice: getServiceChargePrice(),
        totalTicketsPrice: getTicketsTotalPrice(),
      },
    }));

    handleNext();
  };

  const validateDiscountCode = async () => {
    if (!code) return;

    try {
      const codesPromises = cart
        .filter(({ hasDiscountCode }) => hasDiscountCode)
        .map(({ id }) =>
          api.validateDiscountCode(id, {
            code,
          })
        );

      const responses = await Promise.all(codesPromises);

      let codeValid = false;
      let errorMessage = '';
      let successMessage = '';

      responses.forEach(({ status, esmessage, discountCode }) => {
        if (status === REQUEST_RESPONSE.SUCCESS) {
          codeValid = true;
          const cartIndex = cart.findIndex(({ id }) => id === discountCode.ticketTypeId);
          cart[cartIndex].discountCode = discountCode;
        } else {
          errorMessage = esmessage;
          successMessage = '';
        }
      });

      if (codeValid) {
        setCode('');
        setValidCode(codeValid);
        errorMessage = '';
        successMessage = 'Código aplicado con éxito';
      }

      setSuccessMessage(successMessage);
      setErrorMessage(errorMessage);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = ({ target }) => {
    setErrorMessage('');
    setCode(target.value);
  };

  const getPriceWithDiscount = (quantity, priceInCurrency, percentage) => {
    return formatPriceWithCurrency(
      quantity * priceInCurrency * ((100 - percentage) / 100),
      currency
    );
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.stepTitle}>Tus entradas</Typography>
      <div className={classes.content}>
        {cart.map((ticket) => (
          <CheckoutTicketCard
            key={ticket.id}
            ticket={ticket}
            actions={cartActions}
            checkCanContinue={checkCanContinue}
          />
        ))}
      </div>
      <div className={classes.pricesContainer}>
        {orderHasTicketWithDiscountCode && (
          <div className={classes.dicountCodeContainer}>
            <TextInput
              title="¿Tenés un código de descuento?"
              placeholder={validCode ? '' : 'Código'}
              onChange={handleInputChange}
              name="discountCode"
              disabled={validCode}
              errorMessage={errorMessage}
              successMessage={successMessage}
              flex={2}
            />
            <div className={classes.codeButton}>
              <Button
                className={classes.button}
                variant="outlined"
                onClick={validateDiscountCode}
                disabled={validCode}
                title="Aplicar"
              />
            </div>
          </div>
        )}
      </div>
      <div>
        <Typography className={classes.priceText} component="span">
          <b>Costo de entradas:</b>
        </Typography>
        <Divider className={classes.divider} />
        {cart.map(({ id, price, priceInUSD, name, quantity, discountCode }) => (
          <Typography
            key={id}
            className={`${classes.priceText} ${classes.serviceCost}`}
            component="span"
          >
            <b>{`${quantity} x ${name}`}</b>
            <div className={classes.ticketPricesContainer}>
              <Typography
                className={`${classes.serviceCost} ${
                  discountCode?.percentage && classes.crossedText
                }`}
              >
                {formatPriceWithCurrency(quantity * getPrice(price, priceInUSD), currency)}
              </Typography>
              {discountCode?.percentage && (
                <Typography className={classes.serviceCost}>
                  {getPriceWithDiscount(
                    quantity,
                    getPrice(price, priceInUSD),
                    discountCode.percentage
                  )}
                </Typography>
              )}
            </div>
          </Typography>
        ))}
        {lemonDiscount > 0 && (
          <Typography className={classes.lemonDiscount}>
            {lemonDiscount}% Off pagando con Brubank
          </Typography>
        )}
        {isLemonExclusive && (
          <Typography className={classes.lemonDiscount}>Venta exclusiva con Brubank</Typography>
        )}
        <Typography className={`${classes.priceText} ${classes.serviceCost}`} component="span">
          <b>Costo del servicio:</b>
          <Typography className={classes.serviceCost}>
            {formatPriceWithCurrency(getServiceChargePrice(), currency)}
          </Typography>
        </Typography>
        <Divider className={classes.divider} />
        <Typography className={classes.priceText} component="span">
          <b>Total a pagar:</b>
          <Typography>{formatPriceWithCurrency(getTotalOrderPrice(), currency)}</Typography>
        </Typography>
      </div>

      <div className={classes.buttonsContainer}>
        <Button onClick={onSubmit} disabled={!canContinue} title="Siguiente" />
      </div>
    </div>
  );
};

export default withStyles(styles)(CheckOrderStep);
