import { MenuItem, Stack } from '@mui/material';
import React from 'react';
import { withStyles } from '@material-ui/core';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import VIcon from '../../../Venti-UI-Kit/VIcon/VIcon';

import styles from './UserMenuItem.styles';
import lightTheme from '../../../themes/lightTheme';

const UserMenuItem = ({
  classes,
  label,
  color,
  variant = 'body2',
  rightIcon,
  leftIcon,
  onClick,
}) => {
  const { palette } = lightTheme;
  const textColor = onClick ? color || palette.grey[900] : palette.grey[700];

  return (
    <MenuItem
      dense
      onClick={onClick}
      className={!onClick ? classes.descriptionMenuItem : ''}
      disableTouchRipple={!onClick}
    >
      <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" gap={1}>
          <VIcon name={leftIcon} sx={{ color: textColor }} />
          <VTypography color={textColor} variant={variant} align="center">
            {label}
          </VTypography>
        </Stack>
        <VIcon name={rightIcon} />
      </Stack>
    </MenuItem>
  );
};

export default withStyles(styles)(UserMenuItem);
