import { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';

export const useActiveEventsVolumeBarChart = (producerId) => {
  const [metrics, setMetrics] = useState();
  const theme = useTheme();

  const getMetrics = async () => {
    await handleRequestHelper({
      endpoint: () => api.getActiveEventsVolumeMetrics(producerId),
      onSuccess: ({ activeEventsVolume }) => setMetrics(activeEventsVolume),
    });
  };

  useEffect(() => {
    getMetrics();
  }, []);

  return { metrics, theme };
};
