import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    containerBlacklistText: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      textAlign: 'center',
    },
    blacklistText: {
      fontSize: 20,
      fontWeight: 500,
    },
    linkText: {
      color: theme.palette.text.primary,
    },
    paper: {
      borderRadius: 8,
      maxWidth: 900,
      width: '100%',
      backgroundColor: theme.palette.grey[50],
      height: 'auto',
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    textAndButtonsContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 16,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    text: {
      fontSize: 17,
      textAlign: 'start',
    },
    buttonsContainer: {
      display: 'flex',
      gap: 16,
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
  });

export default styles;
