export const COUNTRIES = [
  { label: 'Argentina', value: 'Argentina' },
  { label: 'Brasil', value: 'Brasil' },
  { label: 'Chile', value: 'Chile' },
  { label: 'Uruguay', value: 'Uruguay' },
  { label: 'Otro país', value: 'other' },
];

export const CITIES = {
  argentina: [
    { label: 'Buenos Aires', value: 'Buenos Aires' },
    { label: 'Ciudad Autónoma de Buenos Aires', value: 'Ciudad Autónoma de Buenos Aires' },
    { label: 'Catamarca', value: 'Catamarca' },
    { label: 'Chaco', value: 'Chaco' },
    { label: 'Chubut', value: 'Chubut' },
    { label: 'Córdoba', value: 'Córdoba' },
    { label: 'Corrientes', value: 'Corrientes' },
    { label: 'Entre Ríos', value: 'Entre Ríos' },
    { label: 'Formosa', value: 'Formosa' },
    { label: 'Jujuy', value: 'Jujuy' },
    { label: 'La Pampa', value: 'La Pampa' },
    { label: 'La Rioja', value: 'La Rioja' },
    { label: 'Mendoza', value: 'Mendoza' },
    { label: 'Misiones', value: 'Misiones' },
    { label: 'Neuquén', value: 'Neuquén' },
    { label: 'Río Negro', value: 'Río Negro' },
    { label: 'Salta', value: 'Salta' },
    { label: 'San Juan', value: 'San Juan' },
    { label: 'San Luis', value: 'San Luis' },
    { label: 'Santa Cruz', value: 'Santa Cruz' },
    { label: 'Santa Fe', value: 'Santa Fe' },
    { label: 'Santiago del Estero', value: 'Santiago del Estero' },
    {
      label: 'Tierra del Fuego, Antártida e Islas del Atlántico Sur',
      value: 'Tierra del Fuego, Antártida e Islas del Atlántico Sur',
    },
    { label: 'Tucumán', value: 'Tucumán' },
  ],
  brasil: [
    { label: 'Acre', value: 'Acre' },
    { label: 'Alagoas', value: 'Alagoas' },
    { label: 'Amapá', value: 'Amapá' },
    { label: 'Amazonas', value: 'Amazonas' },
    { label: 'Bahía', value: 'Bahía' },
    { label: 'Ceará', value: 'Ceará' },
    { label: 'Distrito Federal', value: 'Distrito Federal' },
    { label: 'Espírito Santo', value: 'Espírito Santo' },
    { label: 'Goiás', value: 'Goiás' },
    { label: 'Maranhão', value: 'Maranhão' },
    { label: 'Mato Grosso', value: 'Mato Grosso' },
    { label: 'Mato Grosso do Sul', value: 'Mato Grosso do Sul' },
    { label: 'Minas Gerais', value: 'Minas Gerais' },
    { label: 'Pará', value: 'Pará' },
    { label: 'Paraíba', value: 'Paraíba' },
    { label: 'Paraná', value: 'Paraná' },
    { label: 'Pernambuco', value: 'Pernambuco' },
    { label: 'Piauí', value: 'Piauí' },
    { label: 'Rio de Janeiro', value: 'Rio de Janeiro' },
    { label: 'Rio Grande do Norte', value: 'Rio Grande do Norte' },
    { label: 'Rio Grande do Sul', value: 'Rio Grande do Sul' },
    { label: 'Rondônia', value: 'Rondônia' },
    { label: 'Roraima', value: 'Roraima' },
    { label: 'Santa Catarina', value: 'Santa Catarina' },
    { label: 'São Paulo', value: 'São Paulo' },
    { label: 'Sergipe', value: 'Sergipe' },
    { label: 'Tocantins', value: 'Tocantins' },
  ],
  chile: [
    { label: 'Arica y Parinacota', value: 'Arica y Parinacota' },
    { label: 'Tarapacá', value: 'Tarapacá' },
    { label: 'Antofagasta', value: 'Antofagasta' },
    { label: 'Atacama', value: 'Atacama' },
    { label: 'Coquimbo', value: 'Coquimbo' },
    { label: 'Valparaíso', value: 'Valparaíso' },
    { label: 'Metropolitana de Santiago', value: 'Metropolitana de Santiago' },
    {
      label: "Libertador General Bernardo O'Higgins",
      value: "Libertador General Bernardo O'Higgins",
    },
    { label: 'Maule', value: 'Maule' },
    { label: 'Ñuble', value: 'Ñuble' },
    { label: 'Biobío', value: 'Biobío' },
    { label: 'La Araucanía', value: 'La Araucanía' },
    { label: 'Los Ríos', value: 'Los Ríos' },
    { label: 'Los Lagos', value: 'Los Lagos' },
    {
      label: 'Aysén del General Carlos Ibáñez del Campo',
      value: 'Aysén del General Carlos Ibáñez del Campo',
    },
    {
      label: 'Magallanes y de la Antártica Chilena',
      value: 'Magallanes y de la Antártica Chilena',
    },
  ],
  uruguay: [
    { label: 'Artigas', value: 'Artigas' },
    { label: 'Canelones', value: 'Canelones' },
    { label: 'Cerro Largo', value: 'Cerro Largo' },
    { label: 'Colonia', value: 'Colonia' },
    { label: 'Durazno', value: 'Durazno' },
    { label: 'Flores', value: 'Flores' },
    { label: 'Florida', value: 'Florida' },
    { label: 'Lavalleja', value: 'Lavalleja' },
    { label: 'Maldonado', value: 'Maldonado' },
    { label: 'Montevideo', value: 'Montevideo' },
    { label: 'Paysandú', value: 'Paysandú' },
    { label: 'Río Negro', value: 'Río Negro' },
    { label: 'Rivera', value: 'Rivera' },
    { label: 'Rocha', value: 'Rocha' },
    { label: 'Salto', value: 'Salto' },
    { label: 'San José', value: 'San José' },
    { label: 'Soriano', value: 'Soriano' },
    { label: 'Tacuarembó', value: 'Tacuarembó' },
    { label: 'Treinta y Tres', value: 'Treinta y Tres' },
  ],
  other: [],
};
