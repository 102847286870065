import React from 'react';
import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '../../Venti-UI-Kit/Buttons/Button';
import ImageCrop from './ImageCrop';
import api from '../../api/api';
import { handleRequestHelper } from '../../utils/helpers';

class ImageDropzone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  onDrop(picture) {
    const file = picture[0];
    this.setImageToCrop(file);
  }

  setImageToCrop(file) {
    const reader = new FileReader();
    reader.addEventListener('load', () => this.setState({ image: reader.result }));
    reader.readAsDataURL(file);
  }

  removeUrl() {
    this.props.handleImgChange(undefined, this.props.name);
    this.setState({ image: null });
  }

  removeUrlByIndex(index) {
    this.props.handleImgRemove(index);
    this.setState({ image: null });
  }

  async uploadPicture(file) {
    const formData = new FormData();
    formData.append('picture', file);
    this.setState({ loading: true });

    await handleRequestHelper({
      endpoint: () => api.uploadImage(formData),
      onSuccess: ({ data }) => {
        if (this.props.multiple) {
          this.props.handleImgCreate(data.Location);
        } else {
          this.props.handleImgChange(data.Location, this.props.name);
        }
        this.setState({ loading: false, image: null });
      },
    });
  }

  render() {
    const maxSize = 5242880;
    const { classes } = this.props;
    const multipleImages = !!this.props.multiple;
    const aspect = this.props.width / this.props.height;

    if (this.state.loading)
      return (
        <div className={classes.bannerContainerInput}>
          <div className={classes.loading}>
            <CircularProgress className={classes.progress} color="secondary" />
            <Typography className={classes.promoBox} align="center">
              Cargando...
            </Typography>
          </div>
        </div>
      );

    if (this.props.value) {
      if (multipleImages) {
        return (
          <div className={classes.bannerContainerInput}>
            <img className={classes.img} src={this.props.value.url} alt="Imagen del evento" />
            <Button
              onClick={this.removeUrlByIndex.bind(this, this.props.imageIndex)}
              className={classes.removeButton}
            >
              Eliminar Imagen
            </Button>
          </div>
        );
      }
      return (
        <div className={classes.bannerContainerInput}>
          <img className={classes.img} src={this.props.value} alt="Imagen del evento" />
          <Button onClick={this.removeUrl.bind(this)} className={classes.removeButton}>
            Eliminar Imagen
          </Button>
        </div>
      );
    }

    function ClickOrDrop(props) {
      if (props.isDragActive) return null;
      return (
        <div className={classes.text}>
          <Typography className={classes.promoBox} align="center">
            ¡Suelta tu imagen aquí o haz click para agregar una!
          </Typography>
          {props.width && props.height ? (
            <Typography className={classes.promoBox} align="center">
              Recomendado: {`${props.width}x${props.height} px`}
            </Typography>
          ) : null}
        </div>
      );
    }

    function DropIt(props) {
      if (!props.isDragActive) return null;
      if (props.isDragReject)
        return (
          <div className={classes.text}>
            <Typography className={classes.promoBox} align="center">
              El archivo no es del formato correcto
            </Typography>
          </div>
        );
      return (
        <div className={classes.text}>
          <Typography className={classes.promoBox} align="center">
            ¡Suelta tu imagen para agregarla!
          </Typography>
        </div>
      );
    }

    return (
      <div>
        <ImageCrop
          image={this.state.image}
          width={this.props.width}
          height={this.props.height}
          aspect={aspect}
          uploadPicture={this.uploadPicture.bind(this)}
          cancel={() => this.setState({ image: null })}
          fixed={this.props.fixed}
        />
        <Dropzone onDrop={this.onDrop.bind(this)} accept="image/*" minSize={0} maxSize={maxSize}>
          {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
            <div {...getRootProps()} className={classes.bannerContainerInput}>
              <input {...getInputProps()} />
              <ClickOrDrop
                isDragActive={isDragActive}
                height={this.props.height}
                width={this.props.width}
              />
              <DropIt isDragActive={isDragActive} isDragReject={isDragReject} />
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}

const styles = (theme) => ({
  bannerContainerInput: {
    position: 'relative',
    width: '100%',
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[50],
    borderRadius: '20px',
    marginTop: 10,
  },
  img: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    objectFit: 'cover',
  },
  promoBox: {
    fontSIze: '.8rem',
  },
  text: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loading: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progress: {
    marginTop: 20,
    marginBottom: 20,
    margin: 'auto',
  },
  removeButton: {
    position: 'absolute',
    bottom: '1%',
    left: '10%',
    width: '80%',
    float: 'center',
  },
});

export default withStyles(styles)(ImageDropzone);
