import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    card: {
      marginTop: 20,
      flexDirection: 'column',
      maxWidth: 350,
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
    },
  });

export default styles;
