import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    successContainer: {
      padding: 16,
      gap: 48,
    },
    textWithColor: {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  });

export default styles;
