import { useEffect, useRef, useState } from 'react';
import { handleRequestHelper } from '../utils/helpers';
import history from '../appHistory';
import api from '../api/api';
import { useNotifications } from '../Providers/NotificationsProvider/NotificationsProvider';

const EVENTS_PER_PAGE = 12;

const MANDARINE_PRODUCER_ID = 6;
const MANDARINE_ASSOCIATED_PRODUCERS = [45, 46, 47, 48];

const BNP_PRODUCER_ID = 103;
const BNP_ASSOCIATED_PRODUCERS = [148];

export const useHome = (params, elementsPerPages = EVENTS_PER_PAGE) => {
  const { userAlias, producerId: initialProdId, producerUrlName } = params;
  const [producer, setProducer] = useState();
  const [producerId, setProducerId] = useState(initialProdId);
  const [featuredEvents, setFeaturedEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const currentPage = useRef(1);
  const isVentiHome = !initialProdId && !producerUrlName;

  const { showNotification } = useNotifications();

  const getProducer = async () => {
    if (!producerUrlName) return;
    await handleRequestHelper({
      endpoint: () => api.getProducerHome(producerUrlName),
      onSuccess: ({ producer }) => {
        setProducer(producer);
        setProducerId(producer.id);
      },
      showNotification,
    });
  };

  const getProducersIdsIncluded = () => {
    switch (parseInt(producerId)) {
      case MANDARINE_PRODUCER_ID:
        return MANDARINE_ASSOCIATED_PRODUCERS;
      case BNP_PRODUCER_ID:
        return BNP_ASSOCIATED_PRODUCERS;
      default:
        return [];
    }
  };

  const getFeaturedEvents = async () => {
    await handleRequestHelper({
      endpoint: () => api.getFeaturedEvents(),
      onSuccess: ({ events }) => setFeaturedEvents(events),
    });
  };

  const checkIfUserIsInProducerByAlias = async () => {
    if (!userAlias) return;

    await handleRequestHelper({
      endpoint: () => api.validateUserInProducerByAlias(userAlias, producerId),
      onSuccess: ({ aliasIsInProducer }) => aliasIsInProducer,
      onFailure: () => history.push('/error/404'),
    });
  };

  const getHomeEvents = async () => {
    checkIfUserIsInProducerByAlias();

    await handleRequestHelper({
      endpoint: () =>
        api.getHomeEvents({
          page: currentPage.current,
          limit: elementsPerPages,
          producersIdsExcluded: [],
          producersIdsIncluded: producerId ? [producerId, ...getProducersIdsIncluded()] : undefined,
          userAlias: userAlias || undefined,
        }),
      onSuccess: ({ events, totalPages }) => {
        currentPage.current += 1;
        setEvents((prevState) => prevState.concat(events));
        setTotalPages(totalPages);
      },
    });
  };

  const hasMoreEvents = () => {
    return currentPage.current <= totalPages;
  };

  useEffect(() => {
    getProducer();
  }, []);

  useEffect(() => {
    if (producerUrlName && !producerId) return;
    if (isVentiHome) getFeaturedEvents();
    getHomeEvents();
  }, [producerId]);

  return {
    featuredEvents,
    events,
    getHomeEvents,
    hasMoreEvents,
    producer,
  };
};
