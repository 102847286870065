import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { Stack, Box } from '@mui/material';
import TitledInput from '../../../Venti-UI-Kit/Input/TitledInput';
import styles from './SectionFormStyles';

const MAX_SECTION_NAME_LENGTH = 32;

const SectionForm = ({ isSuperAdmin, section, index, event, handleEventChange, classes }) => {
  const { name, capacity, color } = section;

  const handleSectionChange = (field, value, index) => {
    const newEvent = event;
    newEvent.stadium.sections[index][field] = value || '';
    handleEventChange(newEvent);
  };

  const handleSectionNameChange = (value, index) => handleSectionChange('name', value, index);

  const handleSectionCapacityChange = (value, index) =>
    handleSectionChange('capacity', value ? parseInt(value, 10) : '', index);

  return (
    <Box display="flex" gap={1}>
      <Stack>
        <Typography className={classes.inputLabel}>Sector</Typography>
        <Box className={classes.sectionColorContainer}>
          <Box width={16} height={16} borderRadius={19} bgcolor={color} />
        </Box>
      </Stack>
      <Stack width="100%">
        <Typography className={classes.inputLabel}>Nombre</Typography>
        <TitledInput
          name={`sectionName${index}`}
          value={name}
          onChange={({ target }) => handleSectionNameChange(target.value, index)}
          disabled={!isSuperAdmin}
          inputProps={{ maxLength: MAX_SECTION_NAME_LENGTH }}
        />
      </Stack>
      <Stack width="100%">
        <Typography className={classes.inputLabel}>Capacidad</Typography>
        <TitledInput
          name={`sectionCapacity${index}`}
          value={capacity}
          onChange={({ target }) => handleSectionCapacityChange(target.value, index)}
          disabled={!isSuperAdmin}
        />
      </Stack>
    </Box>
  );
};

export default withStyles(styles)(SectionForm);
