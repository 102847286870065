class CartStorage {
  deleteCart() {
    localStorage.removeItem('storedCart');
  }

  getStoredEventCart(eventId) {
    let storedCart = localStorage.getItem('storedCart');

    storedCart = JSON.parse(storedCart);
    const cart = [];
    if (storedCart && storedCart.eventId === parseInt(eventId)) {
      for (let i = 0; i < storedCart.cart.length; i++) {
        const qr = storedCart.cart[i];
        cart[qr.id] = qr;
      }
    }

    return cart;
  }

  setStoredEventCart(eventId, cart) {
    const filteredCart = Object.values(cart);
    const storedCart = {
      eventId: parseInt(eventId),
      cart: filteredCart,
    };
    localStorage.setItem('storedCart', JSON.stringify(storedCart));
  }

  getStoredUserCart() {
    const storedUser = localStorage.getItem('storedUser');

    return JSON.parse(storedUser);
  }

  setStoredUserCart(eventId, user) {
    const storedUser = {
      eventId: parseInt(eventId),
      user,
    };
    localStorage.setItem('storedUser', JSON.stringify(storedUser));
  }

  deleteStoredUserCart() {
    localStorage.removeItem('storedUser');
  }

  getStoredMercadoPagoKey() {
    const storedMercadoPagoKey = localStorage.getItem('storedMercadoPagoKey');

    return JSON.parse(storedMercadoPagoKey).key;
  }

  setStoredMercadoPagoKey(key) {
    const storedMercadoPagoKey = {
      key,
    };
    localStorage.setItem('storedMercadoPagoKey', JSON.stringify(storedMercadoPagoKey));
  }

  deleteStoredMercadoPagoKey() {
    localStorage.removeItem('storedMercadoPagoKey');
  }
}
const cart = new CartStorage();

export default cart;
