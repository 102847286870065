import React from 'react';
import { withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import styles from './PinnedEventCard.styles';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';
import { formatDatetime, getEventVenueName } from '../../../../utils/utils';
import VIcon from '../../../../Venti-UI-Kit/VIcon/VIcon';

const PinnedEventCard = ({ classes, event, userAlias }) => {
  const { bannerImg, promoImg, urlName, name, description, startDate } = event;
  const { dayOfWeek, date, time } = formatDatetime(startDate);
  const eventUrl = userAlias ? `/evento/${urlName}/${userAlias}` : `/evento/${urlName}`;
  const { isDesktop } = useWindowDimensions();
  const img = isDesktop ? bannerImg : promoImg;
  const eventVenue = getEventVenueName(event);

  return (
    <Stack position="relative" className={classes.eventImgBox} component="a" href={eventUrl}>
      <img className={classes.banner} src={img} alt="producerBanner" />
      <Stack className={classes.eventDescriptionBox}>
        <Stack>
          <VTypography linesNumber={isDesktop ? 3 : 2} variant="h4" color="white">
            {name}
          </VTypography>
          {description && (
            <VTypography linesNumber={isDesktop ? 7 : 1} color="white">
              {description}
            </VTypography>
          )}
        </Stack>
        <Stack>
          <VTypography textTransform="capitalize" color="white">
            {dayOfWeek}, {date}, {time}hs
          </VTypography>
          {eventVenue && (
            <Stack flexDirection="row" alignItems="center" gap={0.5}>
              <VIcon name="fmd_good" size={18} color="white" />
              <VTypography variant="body2" color="white">
                {eventVenue}
              </VTypography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default withStyles(styles)(PinnedEventCard);
