import { useEffect, useState } from 'react';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';

export const useSettlementPreview = (urlParams) => {
  const { eventId } = urlParams;
  const [settlement, setSettlement] = useState(null);
  const [settlementType, setSettlementType] = useState('final');
  const [rebatePercentage, setRebatePercentage] = useState(0);
  const [rebateModalOpen, setRebateModalOpen] = useState(false);
  const [settlementModalOpen, setSettlementModalOpen] = useState(false);

  const settlementTypeOptions = [
    { value: 'final', label: 'Final' },
    { value: 'progress', label: 'Parcial' },
  ];

  const onSettlementTypeChange = ({ target: { value } }) => {
    setSettlementType(value);
  };

  const { showNotification } = useNotifications();

  const getSettlement = async () => {
    await handleRequestHelper({
      endpoint: () => api.getSettlement(eventId, settlementType),
      onSuccess: ({ settlement }) => {
        setSettlement(settlement);
      },
      showNotification,
    });
  };

  const getRebatePercentage = async () => {
    await handleRequestHelper({
      endpoint: () => api.getEvent(eventId),
      onSuccess: ({ event }) => {
        setRebatePercentage(event?.settings?.rebatePercentage ?? 0);
      },
      showNotification,
    });
  };

  const updateRebatePercentage = async (rebatePercentage) => {
    await handleRequestHelper({
      endpoint: () => api.updateEventSettings(eventId, { rebatePercentage }),
      onSuccess: () => {
        setRebatePercentage(rebatePercentage);
      },
      showNotification,
    });
  };

  useEffect(() => {
    if (eventId) {
      getSettlement();
      getRebatePercentage();
    }
  }, [eventId, settlementType]);

  const refreshSettlement = () => {
    getSettlement();
    showNotification('Previsualización actualizada', 'success');
  };

  const sendSettlement = async (receiverMails) => {
    const body = {
      eventId,
      receiverMails,
      type: settlementType,
    };

    await handleRequestHelper({
      endpoint: () => api.sendSettlement(body),
      onSuccess: () => {
        showNotification('Liquidación enviada', 'success');
        setSettlementModalOpen(false);
        getSettlement();
      },
      showNotification,
    });
  };

  return {
    settlement,
    settlementType,
    onSettlementTypeChange,
    settlementTypeOptions,
    refreshSettlement,
    rebatePercentage,
    updateRebatePercentage,
    rebateModalOpen,
    setRebateModalOpen,
    settlementModalOpen,
    setSettlementModalOpen,
    sendSettlement,
  };
};
