import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    container: {
      flex: 1,
      filter: 'drop-shadow(0px 4px 10px rgba(174, 166, 166, 0.25))',
      width: '100%',
      '& .Mui-expanded': {
        margin: '0px !important',
        minHeight: 'unset !important',
      },
    },
  });

export default styles;
