import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { withStyles, Typography } from '@material-ui/core';
import styles from './CreateUserStyles';
import UserForm from '../../../Components/Forms/UserForm/UserForm';
import useProducers from '../../../hooks/useProducers';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';

const CreateUser = ({ classes, urlParams }) => {
  const { producerId, eventId } = urlParams;

  const isEventUserCreationPage = eventId && producerId;

  const { producers, getUserProducers } = useProducers(producerId);

  const formatInitialData = () => {
    return producers?.reduce(
      (initialProducerData, { id, events }) => {
        if (id !== parseInt(producerId)) return initialProducerData;

        events.forEach(({ id }) => {
          initialProducerData[id] = id === parseInt(eventId);
        });

        return initialProducerData;
      },
      { allEvents: false }
    );
  };

  useEffect(() => {
    isEventUserCreationPage && getUserProducers();
  }, []);

  const initialUserData = isEventUserCreationPage
    ? { initialEventsAccesses: { [producerId]: formatInitialData() } }
    : {};

  return (
    <PageContainer title="Backoffice - Crear usuario">
      <VBreadcrumbs pageTitle={eventId ? 'Crear Usuario' : 'Crear'} />
      <Box className={classes.pageContainer}>
        <Box className={classes.pageContentContainer}>
          <Typography variant="h3">Crear usuario</Typography>
          <UserForm producerId={producerId} initialData={initialUserData} />
        </Box>
      </Box>
    </PageContainer>
  );
};

export default withStyles(styles)(CreateUser);
