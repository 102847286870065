export function getNumericHelperText(number, acceptVoid = false) {
  let helperText = '';

  if (!number && !acceptVoid) {
    helperText = 'El campo no puede ser vacío';
  } else if (number < 0) {
    helperText = 'El campo no puede ser negativo';
  } else if (isNaN(number)) {
    helperText = 'El campo solo debe contener caracteres numéricos';
  }

  return helperText;
}

export function getOptionalPositiveNumHelperText(number) {
  let helperText = '';

  if (number !== undefined) {
    if (number < 0) {
      helperText = 'El campo no puede ser negativo';
    } else if (isNaN(number)) {
      helperText = 'El campo solo debe contener caracteres numéricos';
    }
  }

  return helperText;
}

export function getTextHelperText(text) {
  let helperText = '';

  if (!text) {
    helperText = 'El campo debe contener al menos un caracter';
  }

  return helperText;
}

export function getTextHelperObligatoryField(field) {
  let helperText = '';

  if (!field) {
    helperText = 'Este campo es obligatorio';
  }

  return helperText;
}

export function getPasswordHelperText(text) {
  let helperText = '';

  if (!text || text.length < 8) {
    helperText = 'El campo debe contener al menos 8 caracteres';
  }

  return helperText;
}

export function getCoordinatesHelperText(coordinates) {
  let helperText = '';

  if (!coordinates) {
    helperText = 'El campo no puede ser vacío';
  } else if (isNaN(coordinates)) {
    helperText = 'El campo solo debe contener caracteres numéricos';
  }

  return helperText;
}

export const getMailHelperText = (mail, userAlreadyExistsInThisProducer = false) => {
  // eslint-disable-next-line
  if (!mail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/)) return 'Por favor ingrese un mail válido.';

  if (userAlreadyExistsInThisProducer) return 'El usuario ya existe en esta productora.';

  return '';
};

export const getAliasHelperText = (alias) => {
  if (alias === '') return 'El campo no puede ser vacío';

  if (!alias.match(/^[a-z]+$/))
    return 'Por favor ingrese un alias válido. Solo se permiten letras minúsculas sin espacios.';

  return '';
};
