import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import Typography from '@material-ui/core/Typography';
import ImageDropzone from './ImageDropzone';

class MultipleImages extends React.Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.handleImgSort(oldIndex, newIndex);
  };

  handleImgRemove = (index) => {
    const { images } = this.props;
    images.splice(index, 1);
    this.props.handleImgChange(images);
  };

  handleImgSort = (oldIndex, newIndex) => {
    let { images } = this.props;
    images = arrayMove(images, oldIndex, newIndex);
    this.props.handleImgChange(images);
  };

  handleImgCreate = (url) => {
    const { images } = this.props;
    const newLength = images.length + 1;
    if (newLength > this.props.limit) return;

    if (newLength === this.props.limit) {
      this.props.showNotification('La cantidad máxima de imágenes se ha alcanzado', 'warning');
    }

    const newImage = { url, type: 'image' };
    images.push(newImage);
    this.props.handleImgChange(images, 'create');
  };

  render() {
    const { classes } = this.props;
    const { images } = this.props;
    const uploadEnabled = !!(images && images.length < this.props.limit);

    const SortableItem = SortableElement(({ value, imageIndex }) => (
      <div className={classes.images}>
        <div className={classes.innerImage}>
          <ImageDropzone
            imageIndex={imageIndex}
            handleImgRemove={this.handleImgRemove.bind(this)}
            handleImgCreate={this.handleImgCreate.bind(this)}
            value={value}
            multiple
          />
        </div>
      </div>
    ));

    const SortableList = SortableContainer(({ images }) => (
      <div className={classes.container}>
        {images &&
          images.map((image, index) => (
            <SortableItem key={`${image.id}`} index={index} imageIndex={index} value={image} />
          ))}
      </div>
    ));

    return (
      <div>
        <div className={classes.imgDropContainer}>
          {uploadEnabled ? (
            <ImageDropzone
              handleImgCreate={this.handleImgCreate.bind(this)}
              multiple
              showNotification={this.props.showNotification}
            />
          ) : null}
        </div>
        <div className={classes.wrapper}>
          <div>
            <SortableList axis="xy" images={images} onSortEnd={this.onSortEnd} distance={3} />
          </div>
        </div>
        <Typography align="center">¡Arrastra las imagenes para ordenarlas!</Typography>
      </div>
    );
  }
}

const styles = (theme) => ({
  imgDropContainer: {
    maxWidth: '35%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    textAlign: 'center',
  },
  images: {
    margin: 10,
    display: 'flex',
    position: 'relative',
    maxWidth: 180,
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 150,
      minWidth: 150,
    },
    flexBasis: '100%',
    flexDirection: 'column',
  },
  innerImage: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: 10,
    overflowY: 'auto',
  },
});

export default withStyles(styles)(MultipleImages);
