import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    modalContainer: {
      padding: 40,
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 8,
      maxWidth: '50%',
      rowGap: 10,
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        maxWidth: '80%',
      },
    },
    modalButtonsContainer: {
      display: 'flex',
      columnGap: 20,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        rowGap: 20,
      },
    },
    modalTitle: {
      fontSize: 24,
      fontWeight: 600,
      fontFamily: theme.typography.fontFamily,
    },
    modalMessage: {
      fontSize: 15,
      fontWeight: 400,
      fontFamily: theme.typography.fontFamily,
    },
    downloadTemplateText: {
      alignSelf: 'start',
      color: theme.palette.text.secondary,
      fontWeight: 700,
    },
    dropZoneContainer: {
      width: '100%',
      padding: '10px 0 20px',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 20,
    },
  });

export default styles;
