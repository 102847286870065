import { createStyles } from '@material-ui/core';

export const styles = () =>
  createStyles({
    cardsContainer: {
      maxWidth: '90%',
      margin: '0 auto',
      display: 'grid',
      gap: 32,
      gridTemplateColumns: 'repeat( auto-fit, minmax(250px, 1fr) )',
    },
    downloadButton: {
      fontSize: 14,
      height: '30px',
    },
  });
