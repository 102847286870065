import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    userEventTicketCard: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 8,
      maxWidth: 328,
      width: 328,
      boxShadow: '0px 2px 4px 0px rgba(33, 33, 33, 0.25)',
      overflow: 'hidden',
    },
    bannerImg: {
      width: '100%',
      objectFit: 'cover',
      height: 126,
      position: 'relative',
      left: 0,
      top: 0,
    },
    transferInfoBox: {
      width: '100%',
      padding: 16,
      alignItems: 'center',
      gap: 8,
      borderRadius: 8,
      border: `1px solid ${theme.palette.grey[700]}`,
      background: theme.palette.grey[200],
    },
    sectionValueContainer: {
      borderRadius: 30,
      padding: '1px 8px 1px 8px',
      width: 'max-content',
      maxWidth: 140,
      color: theme.palette.common.white,
    },
    userEventTicketChip: {
      backgroundColor: theme.palette.buttons.alternative.background,
      color: theme.palette.buttons.alternative.text,
      padding: '4px 8px 4px 8px',
      borderRadius: 30,
    },
  });

export default styles;
