import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { Box, Button, Modal, Stack } from '@mui/material';
import styles from './EventSearcher.styles';
import VIcon from '../../Venti-UI-Kit/VIcon/VIcon';
import EventAutocomplete from './EventAutocomplete/EventAutocomplete';
import EventFiltersButtons from './EventFiltersButtons/EventFiltersButtons';

const EventSearcher = ({ classes }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button disableRipple onClick={handleOpen} className={classes.searchButton}>
        <VIcon name="search" />
      </Button>

      <Modal open={open} onClose={handleClose} className={classes.modalBackground}>
        <Box className={classes.modalContent}>
          <Stack className={classes.searchersContainer}>
            <EventAutocomplete />
            <EventFiltersButtons />
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default withStyles(styles)(EventSearcher);
