import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { Modal, Box } from '@mui/material';
import styles from './InformationWithActionModalStyles';
import Button from '../../../Venti-UI-Kit/Buttons/Button';

const InformationWithActionModal = ({
  classes,
  open,
  title,
  description,
  acceptLabel,
  onClose,
  onAccept,
}) => {
  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modalContainer}
      disableEscapeKeyDown
    >
      <Box className="modalBoxContainer">
        <Box className="modalTextContainer">
          <Typography className="modalTitle">{title}</Typography>
          <Typography className="modalSubtitle">{description}</Typography>
        </Box>
        <Button fullWidth onClick={onAccept}>
          {acceptLabel}
        </Button>
      </Box>
    </Modal>
  );
};

export default withStyles(styles)(InformationWithActionModal);
