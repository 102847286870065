import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Stack } from '@mui/material';
import styles from './AdminInvitations.styles';
import Button from '../../../Venti-UI-Kit/Buttons/Button';
import InvitationsTable from './InvitationsTable';
import api from '../../../api/api';
import {
  TICKET_ACTIVITY_STATUS,
  USER_ROLES,
  TICKET_TYPE,
  NOTIFICATION_VARIANT,
} from '../../../constants/types';
import AlertDialog from '../../../Venti-UI-Kit/AlertDialog/AlertDialog';
import { sortObjectArray } from '../../../utils/utils';
import CSVUploaderModal from '../../../Components/Modals/CSVUploaderModal/CSVUploaderModal';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import { handleRequestHelper } from '../../../utils/helpers';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';

const NO_INVITATIONS_MESSAGE = 'No hay invitaciones disponibles';

const AdminInvitations = ({ classes, urlParams }) => {
  const { showNotification } = useNotifications();
  const [invitations, setInvitations] = useState([]);
  const [invitation, setInvitation] = useState();
  const [sellers, setSellers] = useState();
  const [asignToAllValue, setAsignToAllValue] = useState('');
  const [acceptTeamLeaders, setAcceptTeamLeaders] = useState();
  const [openAsignToAllModal, setOpenAsignToAllModal] = useState(false);
  const [placeholder, setPlaceholder] = useState('Ingrese la cantidad');
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(false);
  const [openCSVUploadModal, setOpenCSVUploadModal] = useState(false);

  const { producerId, eventId } = urlParams;

  const getProducerSellers = async () => {
    return handleRequestHelper({
      endpoint: () =>
        api.getAllProducerUsersWithTeamleaders(producerId, {
          roles: [USER_ROLES.SELLER_WITH_CASH, USER_ROLES.SELLER],
        }),
      onSuccess: ({ users }) => users,
      onFailure: () =>
        showNotification('Ocurrió un error obteniendo los vendedores', NOTIFICATION_VARIANT.ERROR),
    });
  };

  const getSellerInvitations = async (invitationId) => {
    setLoadingTable(true);
    const allProducerSellers = await getProducerSellers();

    if (!allProducerSellers || !Array.isArray(allProducerSellers) || !allProducerSellers.length) {
      setLoadingTable(false);
      return;
    }

    await handleRequestHelper({
      endpoint: () => api.getInvitationUsers(invitationId),
      onSuccess: ({ userInvitations }) => {
        const userInvitationsMappedByUserId = userInvitations.reduce(
          (accum, { userId, ...userInvitation }) => {
            accum[userId] = { userId, ...userInvitation };
            return accum;
          },
          {}
        );

        setSellers(
          sortObjectArray(
            allProducerSellers.map(({ id, alias, sellerTeamLeaders }) => {
              const user = userInvitationsMappedByUserId[id];

              return {
                userId: id,
                alias,
                teamLeaderCode:
                  sellerTeamLeaders.find(({ producerId }) => producerId === parseInt(producerId))
                    ?.TeamLeader.code || '',
                amountSent: !user ? 0 : user.amountSent,
                amountAsigned: !user ? 0 : user.amountAsigned,
                edit: false,
              };
            }),
            'alias'
          )
        );
      },
    });

    setLoadingTable(false);
  };

  const getTicketTypes = async (setFirstInvitation = false) => {
    return handleRequestHelper({
      endpoint: () => api.getEvent(eventId),
      onFailure: () => {
        showNotification(
          'Ocurrió un error obteniendo los tickets del evento',
          NOTIFICATION_VARIANT.ERROR
        );
        setLoading(false);
      },
      onSuccess: async ({ event }) => {
        const activeInvitations = event?.TicketTypes.filter(
          ({ activityStatus, type }) =>
            activityStatus === TICKET_ACTIVITY_STATUS.ACTIVE && type === TICKET_TYPE.INVITATION
        );
        setInvitations(activeInvitations);

        if (activeInvitations.length && setFirstInvitation) {
          setInvitation(activeInvitations[0]);
          await getSellerInvitations(activeInvitations[0].id);
        }

        setLoading(false);
        return activeInvitations;
      },
    });
  };

  const getProducerTeamLeaders = async () => {
    await handleRequestHelper({
      endpoint: () => api.getProducer(producerId),
      showNotification,
      onSuccess: ({ producer }) => {
        setAcceptTeamLeaders(!!producer?.generatesSellerReports);
      },
    });
  };

  useEffect(() => {
    getTicketTypes(true);
    getProducerTeamLeaders();
  }, []);

  const handleInvitationChange = async (event) => {
    const { value } = event.target;
    const selectedInvitation = invitations.find((inv) => inv.id === value);
    setInvitation(selectedInvitation);
    await getSellerInvitations(selectedInvitation.id);
  };

  const onSaveChanges = async () => {
    if (!sellers || !Array.isArray(sellers) || !sellers.length) return;

    const userInvitations = sellers
      .filter(({ edit }) => edit)
      .map(({ userId, amountAsigned, amountSent }) => ({ userId, amountAsigned, amountSent }));

    if (!userInvitations.length) return;

    for (let index = 0; index < userInvitations.length; index++) {
      const { amountAsigned, amountSent } = userInvitations[index];
      userInvitations[index].amountAsigned = isNaN(amountAsigned) ? 0 : parseInt(amountAsigned);

      if (userInvitations[index].amountAsigned < amountSent) {
        showNotification(
          `No se puede asignar menos cantidad de invitaciones a las ya enviadas.`,
          NOTIFICATION_VARIANT.ERROR
        );
        return;
      }
    }

    await handleRequestHelper({
      endpoint: () => api.updateInvitationUsers(invitation.id, userInvitations),
      showNotification,
      onSuccess: async () => {
        showNotification(
          'Se actualizaron las invitaciones con éxito',
          NOTIFICATION_VARIANT.SUCCESS
        );
        getSellerInvitations(invitation.id);
        await getSellerInvitations(invitation.id);
        const updatedInvitations = await getTicketTypes();
        setInvitation((prevState) => updatedInvitations.find(({ id }) => id === prevState.id));
      },
    });
  };

  const handleCloseModal = () => {
    setAsignToAllValue('');
    setOpenAsignToAllModal(false);
  };

  const handleConfirmModal = () => {
    if (!asignToAllValue || !sellers || !Array.isArray(sellers) || !sellers.length) return;
    setSellers((prevState) =>
      prevState.map((seller) => ({
        ...seller,
        edit: true,
        amountAsigned: parseInt(asignToAllValue),
      }))
    );
    handleCloseModal();
  };

  const onAsignInvitationsToAll = () => {
    if (!sellers || !Array.isArray(sellers) || !sellers.length) return;
    setOpenAsignToAllModal(true);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setAsignToAllValue(value);
  };

  const handleCloseCSVUploadModal = () => {
    setOpenCSVUploadModal(false);
  };

  const handleUploadCSVFile = () => {
    setOpenCSVUploadModal(true);
  };

  const handleAssignInvitationsByCSV = (file) => {
    let isValid = true;
    const sellersCopy = JSON.parse(JSON.stringify(sellers));
    for (const row of file) {
      const sellerIndex = sellersCopy.findIndex((seller) => seller.alias === row.alias);

      if (sellerIndex < 0) {
        showNotification(`El alias ${row.alias} no existe.`, NOTIFICATION_VARIANT.ERROR);
        isValid = false;
        break;
      }

      const seller = sellersCopy[sellerIndex];
      const newAmount = isNaN(row.amount) ? 0 : parseInt(row.amount);

      if (newAmount < 0) {
        showNotification(
          `La cantidad asignada a ${seller.alias} tiene que ser mayor a 0`,
          NOTIFICATION_VARIANT.ERROR
        );
        isValid = false;
        break;
      }

      if (seller.amountSent > newAmount) {
        showNotification(
          `La cantidad que se le quiere asignar a ${seller.alias} es menor a las ya envidas.`,
          NOTIFICATION_VARIANT.ERROR
        );
        isValid = false;
        break;
      }

      sellersCopy[sellerIndex].amountAsigned = newAmount;
      sellersCopy[sellerIndex].edit = true;
    }

    if (isValid) {
      setSellers(sellersCopy);
    }
  };

  const csvColumns = [
    {
      value: 'teamLeader',
      label: 'Team Leader',
    },
    {
      value: 'alias',
      label: 'Alias',
    },
    {
      value: 'amount',
      label: 'Cantidad',
    },
  ];

  return (
    <PageContainer title="Backoffice - Asignar invitaciones">
      <VBreadcrumbs pageTitle="Asignar Invitaciones" />
      <Stack alignItems="center" gap={3} px={2}>
        <Typography variant="h3">Asignación de invitaciones</Typography>
        {loading ? (
          <CircularProgress size={40} />
        ) : (
          <div className={classes.paper}>
            <div className={classes.selectorContainer}>
              <Typography className={classes.text} variant="h6">
                Invitación:
              </Typography>
              <Select
                className={classes.selector}
                id="invitation"
                name="invitation"
                value={invitations.length ? invitation?.id : NO_INVITATIONS_MESSAGE}
                onChange={handleInvitationChange}
                disableUnderline
              >
                {invitations.length ? (
                  invitations.map(({ id, name }, index) => (
                    <MenuItem key={index} value={id}>
                      {name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={NO_INVITATIONS_MESSAGE}>{NO_INVITATIONS_MESSAGE}</MenuItem>
                )}
              </Select>
            </div>
            <div className={classes.textAndButtonsContainer}>
              <Typography className={classes.text} variant="h6">
                {`Cantidad total de invitaciones: ${invitation?.maxQuantity || '-'}`}
                <br />
                {`Cantidad de invitaciones disponibles: ${
                  invitation?.maxQuantity ? invitation.maxQuantity - invitation.soldUnits : '-'
                }`}
              </Typography>
              <div className={classes.headerButtonsContainer}>
                <Button onClick={onAsignInvitationsToAll} disabled={!sellers || !sellers.length}>
                  Asignar cantidad a todos
                </Button>
                <Button onClick={handleUploadCSVFile}>Subir archivo CSV</Button>
              </div>
            </div>
            {loadingTable ? (
              <div className={classes.loaderContainer}>
                <CircularProgress size={50} />
              </div>
            ) : (
              <InvitationsTable
                sellers={sellers ?? []}
                setSellers={setSellers}
                teamLeader={acceptTeamLeaders}
                invitation={invitation}
              />
            )}
            {openAsignToAllModal && (
              <AlertDialog
                open={openAsignToAllModal}
                title="Cantidad de invitaciones a asignar a todos"
                handleConfirm={handleConfirmModal}
                onClose={handleCloseModal}
                confirmButtonTitle="Asignar"
              >
                <div className={classes.inputContainer}>
                  <TextField
                    onChange={handleInputChange}
                    value={asignToAllValue}
                    classes={{
                      root: `${classes.textFieldRoot}`,
                    }}
                    inputProps={{ style: { textAlign: 'center' } }}
                    variant="outlined"
                    size="small"
                    type="number"
                    onFocus={() => setPlaceholder('')}
                    onBlur={() => setPlaceholder('Ingrese la cantidad')}
                    placeholder={placeholder}
                  />
                </div>
              </AlertDialog>
            )}
            {openCSVUploadModal && (
              <CSVUploaderModal
                handleOpen={openCSVUploadModal}
                title="Subir archivo CSV"
                confirmButtonTitle="Cargar"
                showNotification={showNotification}
                handleClose={handleCloseCSVUploadModal}
                handleConfirm={handleAssignInvitationsByCSV}
                columns={csvColumns}
                message={
                  <span>
                    Subí tu archivo csv para asignar invitaciones, el mismo debera tener las
                    columnas 'Team Leader', 'Alias' y 'Cantidad'. Si no tenes Team Leader, deja el
                    campo vacio. Las invitaciones se asignaran al presionar 'Guardar'.
                    <br />
                    <b>Importante: el mismo no debe constar de espacios vacíos.</b>
                  </span>
                }
              />
            )}
          </div>
        )}
        {!loadingTable && (
          <Button color="main" onClick={onSaveChanges} disabled={!sellers || !sellers.length}>
            Guardar
          </Button>
        )}
      </Stack>
    </PageContainer>
  );
};

export default withStyles(styles)(AdminInvitations);
