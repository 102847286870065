import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    itemContainer: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[200]}`,
      borderRadius: 10,
      height: 140,
      padding: '11px 15px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        rowGap: 20,
        padding: 15,
        height: 'auto',
        width: '100%',
        margin: 'auto',
      },
    },
    imageAndInfoContainer: {
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 20,
      marginLeft: 'auto',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-evenly',
        columnGap: 10,
      },
    },
    image: {
      width: 120,
      aspectRatio: 1,
      objectFit: 'cover',
      borderRadius: 10,
      alignSelf: 'center',
    },
    infoContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      margin: '0 10px',
      [theme.breakpoints.down('sm')]: {
        flex: 1,
      },
    },
    titleDesktop: {
      fontSize: 24,
      fontWeight: 500,
      color: theme.palette.text.primary,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      '-webkit-box-orient': 'vertical',
      width: 'fit-content',
      lineClamp: 1,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    titleMobile: {
      fontSize: 20,
      fontWeight: 500,
      color: theme.palette.text.primary,
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    startDateDesktop: {
      fontWeight: 300,
      fontSize: 18,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    startDateMobile: {
      fontWeight: 300,
      fontSize: 18,
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    description: {
      fontSize: 16,
      fontWeight: 300,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      '-webkit-box-orient': 'vertical',
      lineClamp: 2,
      wordBreak: 'break-word',
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        WebkitLineClamp: 5,
        lineClamp: 5,
      },
    },
    button: {
      width: 'max-content',
      [theme.breakpoints.down('xs')]: {
        minWidth: 'unset',
      },
    },
    optionsButton: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        minWidth: 'unset',
        width: 'max-content',
      },
    },
  });

export default styles;
