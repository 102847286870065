import { QR_STATUS } from '../../../../constants/types';

export const getQrColorByStatus = (status, theme) => {
  switch (status) {
    case QR_STATUS.PAID:
      return {
        color: theme.palette.success.main,
        backgroundColor: theme.palette.success.light,
      };
    case QR_STATUS.USED:
      return {
        color: theme.palette.info.main,
        backgroundColor: theme.palette.info.light,
      };
    case QR_STATUS.EXPIRED:
    case QR_STATUS.CANCELED:
      return {
        color: theme.palette.error.main,
        backgroundColor: theme.palette.error.light,
      };
    case QR_STATUS.PENDING:
    default:
      return {
        color: theme.palette.warning.main,
        backgroundColor: theme.palette.warning.light,
      };
  }
};
