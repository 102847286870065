import { useMemo, useState } from 'react';
import { debounce } from '@mui/material';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';

export const useEventAutocomplete = () => {
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [eventOptions, setEventOptions] = useState([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const getEvents = async (searchQuery) => {
    setSearchQuery(searchQuery);

    if (!searchQuery) {
      setEventOptions([]);
      return;
    }
    setIsLoadingOptions(true);

    await handleRequestHelper({
      endpoint: () => api.getActiveEvents({ searchQuery }),
      onSuccess: ({ events }) =>
        setEventOptions(events.map((event) => ({ ...event, label: event.name }))),
    });

    setIsLoadingOptions(false);
  };

  const onChangeSearch = useMemo(
    () => debounce(({ target: { value } }) => getEvents(value), 500),
    [getEvents]
  );

  const handleOpenAutocomplete = () => setOpenAutocomplete(true);
  const handleCloseAutocomplete = () => setOpenAutocomplete(false);

  return {
    openAutocomplete,
    onChangeSearch,
    eventOptions,
    searchQuery,
    isLoadingOptions,
    handleOpenAutocomplete,
    handleCloseAutocomplete,
  };
};
