import React, { useState } from 'react';
import { IconButton, Typography, withStyles } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import styles from './TeamLeaderCard.styles';
import AlertDialog from '../../../Venti-UI-Kit/AlertDialog/AlertDialog';
import history from '../../../appHistory';

const TeamLeaderCard = ({ classes, teamLeader, onDelete }) => {
  const { id: teamLeaderId, code } = teamLeader;
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);

  const handleDeleteUser = () => {
    setOpenDeleteConfirmModal(true);
  };

  const handleCloseModal = () => {
    setOpenDeleteConfirmModal(false);
  };

  const handleConfirmDelete = () => {
    handleCloseModal();
    onDelete(teamLeader);
  };

  const handleEditTeamLeader = () => {
    history.push(`${window.location.pathname}/${teamLeaderId}`);
  };

  return (
    <div className={classes.container}>
      <div className={classes.leftColumnContent}>
        <Typography variant="h6" className={classes.cardTitle}>
          {code}
        </Typography>
      </div>
      <div className={classes.rightColumnContent}>
        <IconButton
          onClick={handleEditTeamLeader}
          key={`${teamLeaderId}editTeamLeaderButton`}
          className={classes.iconButton}
        >
          <Edit />
        </IconButton>
        <IconButton
          onClick={handleDeleteUser}
          key={`${teamLeaderId}editDeleteTeamLeaderButton`}
          className={classes.iconButton}
        >
          <Delete />
        </IconButton>
      </div>
      {openDeleteConfirmModal && (
        <AlertDialog
          open={openDeleteConfirmModal}
          title={`¿Seguro que quiere eliminar al Team Leader "${code}"?`}
          handleConfirm={handleConfirmDelete}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(TeamLeaderCard);
