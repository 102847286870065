import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    hidden: {
      display: 'none',
    },
  });

export default styles;
