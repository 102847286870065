import React from 'react';
import { withStyles } from '@material-ui/core';
import { CircularProgress, TextField } from '@mui/material';
import VIcon from '../../../../Venti-UI-Kit/VIcon/VIcon';
import styles from './AutocompleteInput.styles';
import history from '../../../../appHistory';

const AutocompleteInput = ({ classes, onChange, isLoadingOptions, ...params }) => {
  return (
    <TextField
      {...params}
      variant="standard"
      onChange={onChange}
      className={classes.autocompleteInput}
      placeholder="Encuentra un evento"
      onKeyDown={({ code, target: { value } }) => {
        if (code === 'Enter') history.push(`/eventos/search?query=${value}`);
      }}
      autoFocus
      InputProps={{
        ...params.InputProps,
        startAdornment: <VIcon name="search" />,
        endAdornment: (
          <>
            {isLoadingOptions ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );
};

export default withStyles(styles)(AutocompleteInput);
