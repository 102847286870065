import React from 'react';
import { withStyles } from '@material-ui/core';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { withRouter, Link } from 'react-router-dom';
import styles from './VBreadcrumbs.styles';
import { getBreadcrumbRoute } from './VBreadcrumbs.routes';
import VTypography from '../VTypography/VTypography';

const VBreadcrumbs = ({ classes, match, pageTitle }) => {
  const pathnames = window.location.pathname.split('/');
  const { producerId, eventId } = match.params;

  return (
    <Breadcrumbs className={classes.breadcrumb} aria-label="breadcrumb">
      <Link className={classes.link} to={`/backoffice/productoras/${producerId}`}>
        <VTypography variant="body1">Productora</VTypography>
      </Link>

      {getBreadcrumbRoute(producerId, eventId).map(
        ({ link, key, text, show }) =>
          pathnames.includes(key) &&
          show && (
            <Link className={classes.link} key={text} to={link}>
              <VTypography variant="body1">{text}</VTypography>
            </Link>
          )
      )}

      {pageTitle && <VTypography variant="body1">{pageTitle}</VTypography>}
    </Breadcrumbs>
  );
};

export default withRouter(withStyles(styles)(VBreadcrumbs));
