import React from 'react';
import { withStyles } from '@material-ui/core';
import { TextField } from '@mui/material';
import styles from './AutocompleteInput.styles';

const AutocompleteInput = ({ classes, onChange, showErrors, ...props }) => {
  return (
    <TextField
      {...props}
      variant="standard"
      name="placeName"
      onChange={onChange}
      className={`${classes.autocompleteInput} ${showErrors && classes.errorInput}`}
      placeholder="Busca tu venue"
      autoFocus
    />
  );
};

export default withStyles(styles)(AutocompleteInput);
