import React, { useEffect, useState } from 'react';
import { Typography, useTheme, withStyles } from '@material-ui/core';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styles from './CheckoutResumeStyles';
import { formatPrice } from '../../../../../utils/utils';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';

const CheckoutResume = ({ classes, resumeData, isLemonCard }) => {
  const {
    totalToPay,
    totalToPayWithLemon,
    serviceCharge,
    serviceChargeWithLemon,
    tickets,
    totalTicketsAmount,
  } = resumeData;
  const [desktopView, setDesktopView] = useState(false);
  const [resumeContainerClass, setResumeContainerClass] = useState('lineCheckoutResumeContainer');
  const [open, setOpen] = useState(false);
  const { width } = useWindowDimensions();
  const { breakpoints } = useTheme();

  const handleToggle = () => {
    if (desktopView) return;
    setOpen(!open);
    setResumeContainerClass(
      open ? 'lineCheckoutResumeContainer' : 'lineCheckoutResumeContainer open'
    );
  };

  useEffect(() => {
    setDesktopView(width > breakpoints.values.lg);
  }, [width, breakpoints.values]);

  useEffect(() => {
    setOpen(desktopView);
  }, [desktopView]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.lineCheckoutModalResumeContainer}>
      {open && (
        <button className="background" type="button" onClick={handleToggle}>
          {/* checkout background */}
        </button>
      )}
      <div className={resumeContainerClass}>
        {!open ? (
          <button type="button" className="resumeTitleContainer" onClick={handleToggle}>
            <div className="resumeItemContainer">
              <Typography>Total a pagar</Typography>
              <Typography>{formatPrice(totalToPay)}</Typography>
            </div>
            <KeyboardArrowUpIcon className="arrow" />
          </button>
        ) : (
          <>
            <button type="button" className="resumeTitleContainer" onClick={handleToggle}>
              <Typography>Resumen de compra</Typography>
              {!desktopView && <KeyboardArrowDownIcon className="arrow" />}
            </button>
            <div className="ticketsContainer">
              <div className="resumeItemContainer ticketsQuantityText">
                <Typography>{totalTicketsAmount} Entradas</Typography>
              </div>
              {tickets.map(({ name, price, quantity, codeDiscount, lemonDiscount }) => (
                <div key={name} className="ticketContainer">
                  <div className="resumeItemContainer">
                    <Typography>
                      {quantity}x {name}
                    </Typography>
                    <Typography>{formatPrice(price)}</Typography>
                  </div>
                  {(codeDiscount || (!!lemonDiscount && isLemonCard)) && (
                    <div key={name} className="resumeItemContainer discountCode">
                      <Typography>
                        {lemonDiscount ? 'Descuento con Brubank' : 'Código descuento'}
                      </Typography>
                      <Typography>
                        -{formatPrice(lemonDiscount && isLemonCard ? lemonDiscount : codeDiscount)}
                      </Typography>
                    </div>
                  )}
                </div>
              ))}
              <div className="resumeItemContainer">
                <Typography>Cargo por servicio</Typography>
                <Typography>
                  {formatPrice(isLemonCard ? serviceChargeWithLemon : serviceCharge)}
                </Typography>
              </div>
            </div>
            <button type="button" className="resumeTitleContainer" onClick={handleToggle}>
              <Typography>Total a pagar</Typography>
              <Typography>{formatPrice(isLemonCard ? totalToPayWithLemon : totalToPay)}</Typography>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(CheckoutResume);
