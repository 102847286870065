const styles = (theme) => ({
  floatingButton: {
    position: 'fixed',
    bottom: 20,
    left: 20,
    zIndex: 1,
    marginRight: 10,
    background: theme.palette.background.default,
    boxShadow: 'inset -3px -1px 4px 0px rgba(0,0,0,0.03)',
    '&:hover': {
      boxShadow: 'inset -3px -1px 4px 0px rgba(0,0,0,0.0)',
      background: theme.palette.background.default,
    },
    color: theme.palette.text.primary,
  },
  card: {
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    marginBottom: 20,
    backgroundColor: theme.palette.grey[200],
    height: '140px',
    width: '90%',
    maxWidth: '1000px',
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'auto',
      width: '100%',
    },
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    width: '65%',
    paddingLeft: 30,
    paddingTop: 15,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  descriptionText: {
    color: theme.palette.text.color,
    fontSize: '120%',
    marginTop: 10,
    paddingRight: 10,
  },
  divider: {
    height: '80px',
    borderLeft: '2px solid',
    borderColor: theme.palette.grey[400],
    [theme.breakpoints.down('sm')]: {
      borderColor: theme.palette.grey[400],
      borderLeft: '0px solid',
      borderTop: '2px solid',
      height: '0px',
      marginTop: 10,
      marginBottom: 10,
      width: '80%',
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
  },
  titleInfo: {
    color: theme.palette.secondary.main,
    fontSize: '130%',
  },
});

export default styles;
