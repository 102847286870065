import React from 'react';
import { withStyles } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import styles from './FinancialOperationsTable.styles';
import TypeCell from './Components/TypeCell';
import AmountCell from './Components/AmountCell';
import NotesCell from './Components/NotesCell';
import ActionsCell from './Components/ActionsCell';
import { LABELS } from './FinancialOperationsTable.constants';
import Header from './Components/Header';
import PaymentDateCell from './Components/PaymentDateCell';

const options = {
  selectableRows: 'none',
  responsive: 'scrollMaxHeight',
  stickyHeader: true,
  download: false,
  print: false,
  filter: false,
  viewColumns: false,
  pagination: false,
  textLabels: {
    body: {
      noMatch: 'No se encontraron operaciones',
      toolTip: 'Ordenar',
      columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
    },
    toolbar: {
      search: 'Buscar',
    },
  },
};

const FinancialOperationsTable = ({
  classes,
  financialOperations,
  setFinancialOperations,
  initialFinancialOperations,
  setInitialFinancialOperations,
}) => {
  const handleInputChange = (event, rowIndex) => {
    const { value, name } = event.target;
    setFinancialOperations((prevState) => {
      prevState[rowIndex][name] = value;
      return [...prevState];
    });
  };

  const handlePaymentDateChange = (value, rowIndex) => {
    setFinancialOperations((prevState) => {
      prevState[rowIndex].paymentDate = value;
      return [...prevState];
    });
  };

  const getStyleProps = (cellStyle) => ({
    setCellProps: () => ({
      className: cellStyle,
    }),
    customHeadRender: ({ index, label, name }) => (
      <Header
        index={index}
        label={label}
        name={name}
        financialOperations={financialOperations}
        setFinancialOperations={setFinancialOperations}
      />
    ),
  });

  const columns = [
    {
      name: 'type',
      label: LABELS.TYPE,
      options: {
        ...getStyleProps(classes.typeCell),
        customBodyRender: (value, { rowIndex }) => (
          <TypeCell
            rowIndex={rowIndex}
            value={value}
            edit={financialOperations[rowIndex].edit}
            handleInputChange={handleInputChange}
          />
        ),
      },
    },
    {
      name: 'amount',
      label: LABELS.AMOUNT,
      options: {
        ...getStyleProps(classes.amountCell),
        customBodyRender: (value, { rowIndex }) => (
          <AmountCell
            rowIndex={rowIndex}
            value={value}
            edit={financialOperations[rowIndex].edit}
            handleInputChange={handleInputChange}
          />
        ),
      },
    },
    {
      name: 'notes',
      label: LABELS.NOTES,
      options: {
        ...getStyleProps(classes.notesCell),
        customBodyRender: (value, { rowIndex }) => (
          <NotesCell
            rowIndex={rowIndex}
            value={value}
            edit={financialOperations[rowIndex].edit}
            handleInputChange={handleInputChange}
          />
        ),
      },
    },
    {
      name: 'paymentDate',
      label: LABELS.PAYMENT_DATE,
      options: {
        ...getStyleProps(classes.dateCell),
        customBodyRender: (value, { rowIndex }) => (
          <PaymentDateCell
            value={value}
            rowIndex={rowIndex}
            edit={financialOperations[rowIndex].edit}
            handlePaymentDateChange={handlePaymentDateChange}
          />
        ),
      },
    },
    {
      name: 'createdAt',
      label: LABELS.CREATED_AT,
      options: {
        ...getStyleProps(classes.dateCell),
      },
    },
    {
      name: 'action',
      label: LABELS.ACTIONS,
      options: {
        ...getStyleProps(classes.actionsCell),
        customBodyRender: (_, { rowIndex }) => (
          <ActionsCell
            rowIndex={rowIndex}
            edit={financialOperations[rowIndex].edit}
            financialOperations={financialOperations}
            setFinancialOperations={setFinancialOperations}
            initialFinancialOperations={initialFinancialOperations}
            setInitialFinancialOperations={setInitialFinancialOperations}
          />
        ),
      },
    },
  ];

  return (
    <MUIDataTable
      className={classes.table}
      data={financialOperations}
      columns={columns}
      options={options}
    />
  );
};

export default withStyles(styles)(FinancialOperationsTable);
