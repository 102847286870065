import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './InfoEvent.styles';
import { infoCards } from './InfoEvent.cards';
import InfoCard from '../../../../Components/Cards/InfoCard/InfoCard';
import { userIsBlacklistedFromEvent } from '../../../../utils/utils';
import PageContainer from '../../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import { handleRequestHelper } from '../../../../utils/helpers';
import { useAuth } from '../../../../Providers/AuthProvider/AuthProvider';
import api from '../../../../api/api';

const InfoEvent = ({ classes, urlParams }) => {
  const { user } = useAuth();
  const [cards, setCards] = useState([]);
  const [event, setEvent] = useState({});

  const getEvent = async () => {
    await handleRequestHelper({
      endpoint: () => api.getEvent(urlParams.eventId),
      onSuccess: ({ event }) => {
        setEvent(event);
        const cards = !userIsBlacklistedFromEvent(event, user)
          ? infoCards({
              user,
              classes,
              event,
            }).filter(({ permissions }) => permissions)
          : [];

        setCards(cards);
      },
    });
  };

  useEffect(() => {
    getEvent();
  }, []);

  return (
    <PageContainer title={`Backoffice - ${event?.name}`}>
      <VBreadcrumbs />
      <div className={classes.cardsContainer}>
        {cards.map(({ link, icon, title, newTab, text, button }, index) => (
          <InfoCard
            key={index}
            href={link}
            icon={icon}
            title={title}
            newTab={newTab}
            text={text}
            button={button}
          />
        ))}
      </div>
    </PageContainer>
  );
};

export default withStyles(styles)(InfoEvent);
