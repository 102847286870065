import React from 'react';
import { Stack } from '@mui/material';
import VTypography from '../../../../../Venti-UI-Kit/VTypography/VTypography';
import TextInput from '../../../../../Venti-UI-Kit/Inputs/TextInput';
import { formatPrice } from '../../../../../utils/utils';
import { INPUT_TYPE } from '../../../../../constants/types';

const AmountCell = ({ rowIndex, value, edit, handleInputChange }) => {
  return (
    <Stack alignItems="center">
      {edit ? (
        <TextInput
          onChange={(event) => handleInputChange(event, rowIndex)}
          defaultValue={value}
          name="amount"
          type={INPUT_TYPE.NUMBER}
          startAdornment="$"
          showHelpText={false}
        />
      ) : (
        <VTypography>{formatPrice(value)}</VTypography>
      )}
    </Stack>
  );
};

export default AmountCell;
