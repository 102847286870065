import React, { useState } from 'react';
import { useTheme } from '@material-ui/core';
import { QR_STATUS, SECONDARY_MARKET_PUBLICATION_STATUS } from '../../../../constants/types';
import { ReactComponent as QrShieldedImage } from '../../../../assets/images/qrShielded.svg';
import { ReactComponent as QrUsedImage } from '../../../../assets/images/qrUsed.svg';
import { ReactComponent as QrExpiredImage } from '../../../../assets/images/qrExpired.svg';
import { priceFormatter } from './EventTicketCard.utils';
import { useAuth } from '../../../../Providers/AuthProvider/AuthProvider';

export const useEventTicketCard = (qr, isShieldActive) => {
  const { user } = useAuth();
  const customerId = user?.id;
  const [openTransferBottomSheet, setOpenTransferBottomSheet] = useState(false);
  const [openReturnBottomSheet, setOpenReturnBottomSheet] = useState(false);
  const [openPostBottomSheet, setOpenPostBottomSheet] = useState(false);
  const [openDeletePostBottomSheet, setOpenDeletePostBottomSheet] = useState(false);
  const theme = useTheme();

  const { id: qrId, price, status, qrTransfer, secondaryMarket } = qr;

  const formattedTicketPrice = priceFormatter(price);

  const getQrStatus = () => {
    let qrMessage = 'Se habilitará cerca del día del evento';
    let qrImage = <QrShieldedImage />;

    if (status === QR_STATUS.USED) {
      qrMessage = 'El QR fue utilizado';
      qrImage = <QrUsedImage />;
    } else if (status === QR_STATUS.EXPIRED) {
      qrMessage = 'El QR expiró';
      qrImage = <QrExpiredImage />;
    } else if (qrId) {
      qrMessage = '';
      qrImage = (
        <img
          alt=""
          src={`https://quickchart.io/qr?text=${qrId}&size=136x136&margin=0`}
          className="qr"
        />
      );
    }

    return {
      qrImage,
      qrMessage,
    };
  };

  const ticketBelongsToAnotherCustomer =
    (qrTransfer && qrTransfer.destinationCustomer.id !== customerId) ||
    secondaryMarket?.status === SECONDARY_MARKET_PUBLICATION_STATUS.SOLD;

  const showButtonsActions =
    !ticketBelongsToAnotherCustomer && !qrId && price !== 0 && isShieldActive;

  const ticketWasPublished =
    secondaryMarket?.status === SECONDARY_MARKET_PUBLICATION_STATUS.PUBLISHED;

  const goToSecondaryMarketPublication = () => {
    window.location.href = secondaryMarket.publicationLink;
  };

  const actionsButtons = showButtonsActions && [
    {
      show:
        secondaryMarket?.status === SECONDARY_MARKET_PUBLICATION_STATUS.UNPUBLISHED ||
        secondaryMarket?.status === SECONDARY_MARKET_PUBLICATION_STATUS.BOUGHT,
      key: 'post',
      label: 'Publicar en Venti Swap',
      action: () => setOpenPostBottomSheet(true),
    },
    {
      show: ticketWasPublished && secondaryMarket.publicationLink,
      key: 'editPost',
      label: 'Modificar publicación',
      action: () => goToSecondaryMarketPublication(),
    },
    {
      show: ticketWasPublished,
      key: 'deletePost',
      label: 'Dejar de publicar',
      action: () => setOpenDeletePostBottomSheet(true),
    },
    {
      show:
        !ticketWasPublished && (!qrTransfer || qrTransfer.destinationCustomer.id !== customerId),
      key: 'transfer',
      label: 'Transferir ticket',
      action: () => setOpenTransferBottomSheet(true),
    },
    {
      show: !ticketWasPublished && qrTransfer && qrTransfer.destinationCustomer.id === customerId,
      key: 'return',
      label: 'Devolver ticket',
      action: () => setOpenReturnBottomSheet(true),
    },
  ];

  const bottomSheets = {
    transfer: {
      open: openTransferBottomSheet,
      onClose: () => setOpenTransferBottomSheet(false),
    },
    return: {
      open: openReturnBottomSheet,
      onClose: () => setOpenReturnBottomSheet(false),
    },
    post: {
      open: openPostBottomSheet,
      onClose: () => setOpenPostBottomSheet(false),
    },
    deletePost: {
      open: openDeletePostBottomSheet,
      onClose: () => setOpenDeletePostBottomSheet(false),
    },
  };

  const { firstName, lastName, mail } = qrTransfer?.originCustomer || {};
  const originCustomerLabel =
    qrTransfer && firstName && lastName ? `${firstName} ${lastName}` : mail;

  const getSecondaryMarketStatusLabel = () => {
    const status = secondaryMarket?.status;

    switch (status) {
      case SECONDARY_MARKET_PUBLICATION_STATUS.SOLD:
        return {
          label: 'Vendido',
          bgcolor: theme.palette.error.main,
          show: true,
        };
      case SECONDARY_MARKET_PUBLICATION_STATUS.BOUGHT:
        return {
          label: 'Venti Swap',
          bgcolor: theme.palette.info.main,
          show: true,
        };
      case SECONDARY_MARKET_PUBLICATION_STATUS.PUBLISHED:
        return {
          label: 'Publicado',
          bgcolor: theme.palette.success.main,
          show: isShieldActive,
        };
      default:
        return {
          label: null,
          bgcolor: null,
          show: false,
        };
    }
  };

  const secondaryMarketStatusLabel = getSecondaryMarketStatusLabel();

  const getLastTransactionData = () => {
    if (!secondaryMarket) return null;

    const { soldData, boughtData } = secondaryMarket;

    if (!soldData && !boughtData) return null;

    if (!soldData) {
      return {
        ...boughtData,
        type: SECONDARY_MARKET_PUBLICATION_STATUS.BOUGHT,
      };
    }

    if (!boughtData) {
      return {
        ...soldData,
        type: SECONDARY_MARKET_PUBLICATION_STATUS.SOLD,
      };
    }

    const soldDate = new Date(soldData.date);
    const boughtDate = new Date(boughtData.date);

    return soldDate > boughtDate
      ? { ...soldData, type: SECONDARY_MARKET_PUBLICATION_STATUS.SOLD }
      : { ...boughtData, type: SECONDARY_MARKET_PUBLICATION_STATUS.BOUGHT };
  };

  return {
    formattedTicketPrice,
    actionsButtons,
    ticketBelongsToAnotherCustomer,
    bottomSheets,
    originCustomerLabel,
    secondaryMarketStatusLabel,
    secondaryMarket,
    secondaryMarketTransactionData: getLastTransactionData(),
    ...getQrStatus(),
  };
};
