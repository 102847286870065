import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    cardContainer: {
      width: '100%',
      padding: '20px 10px 20px 20px',
      background: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[200]}`,
      borderRadius: 15,
      [theme.breakpoints.down('md')]: {
        padding: 15,
      },
      [theme.breakpoints.down('xs')]: {
        padding: 7,
      },
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    userInfoContainer: {
      display: 'flex',
      flex: 5,
      flexDirection: 'column',
    },
    userInfoContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    cardTitle: {
      fontSize: theme.typography.h5.fontSize,
      fontWeight: 500,
      textTransform: 'capitalize',
      textAlign: 'left',
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
      },
    },
    cardTextInfo: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: 17,
      [theme.breakpoints.down('xs')]: {
        fontSize: 15,
      },
    },
    cardSubtitle: {
      fontSize: 17,
      textTransform: 'capitalize',
      marginRight: 5,
      [theme.breakpoints.down('xs')]: {
        fontSize: 15,
      },
    },
    buttonsContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'space-around',
      },
    },
    iconButton: {
      height: 40,
      width: 40,
      padding: 0,
      color: theme.palette.text.primary,
    },
  });

export default styles;
