import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    button: {
      width: 32,
      height: 32,
      borderRadius: 8,
      minWidth: 0,
      border: `1px solid ${theme.palette.text.primary}`,
    },
  });

export default styles;
