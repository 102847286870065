import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    sectionImage: {
      width: 182,
      borderRadius: 8,
      marginTop: 20,
    },
    sectionTitleText: {
      alignSelf: 'start',
      fontSize: 16,
      fontWeight: '500',
    },
    sectionText: {
      alignSelf: 'start',
      fontSize: 14,
    },
  });

export default styles;
