import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    formItemContainer: {
      padding: 16,
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      columnGap: 10,
      [theme.breakpoints.up('lg')]: {
        maxWidth: '33.3%',
      },
    },
    paper: {
      '&.MuiPaper-root': {
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        backgroundColor: theme.palette.grey[50],
        [theme.breakpoints.up('lg')]: {
          flexDirection: 'row',
          overflow: 'revert',
          alignItems: 'center',
        },
        '& .MuiAccordion-region': {
          '& > label:last-child': {
            borderBottom: 0,
          },
        },
      },
    },
    switchTitle: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '20px',
      color: theme.palette.text.primary,
    },
    producersContainer: {
      display: 'flex',
      rowGap: 16,
      [theme.breakpoints.down('md')]: {
        maxHeight: 'unset',
        overflowY: 'unset',
      },
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '23px',
      marginBottom: 24,
      textAlign: 'left',
      color: theme.palette.text.primary,
    },
  });

export default styles;
