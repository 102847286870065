import React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { useIntercom } from 'react-use-intercom';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import styles from './RedirectUrlsStyles';
import TextButton from '../../../Components/Buttons/TextButton/TextButton';

const EventStadiumError = ({ classes }) => {
  const { show } = useIntercom();

  return (
    <PageContainer withFooter>
      <div className={classes.contentContainer}>
        <Typography variant="h3">Tuvimos un problema al asignar un plano a tu evento.</Typography>
        <Typography variant="subtitle2">
          El evento fue creado con éxito, pero no pudimos asignarle el plano que habías ingresado,
          comunícate con{' '}
          <TextButton title="operaciones o soporte" variant="subtitle2" underline onClick={show} />{' '}
          para poder solucionar el problema.
        </Typography>
      </div>
    </PageContainer>
  );
};

export default withStyles(styles)(EventStadiumError);
