import React from 'react';
import { useTheme } from '@material-ui/core';
import { Stack } from '@mui/material';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';

const EventTicketCardDetail = ({ title, value, valueTypographyProps }) => {
  const theme = useTheme();

  return (
    <Stack flex={1}>
      <VTypography variant="caption" color={theme.palette.grey[700]}>
        {title}
      </VTypography>
      <VTypography linesNumber={1} {...valueTypographyProps} variant="subtitle6">
        {value}
      </VTypography>
    </Stack>
  );
};

export default EventTicketCardDetail;
