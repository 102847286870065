import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './TextButtonStyles';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';

const TextButton = ({
  classes,
  title,
  onClick,
  variant = 'body2',
  fontSize,
  underline = false,
}) => {
  return (
    <button
      className={classes.textButton}
      style={{ fontSize, ...(underline && { textDecoration: 'underline' }) }}
      type="button"
      onClick={onClick}
    >
      <VTypography variant={variant}>{title}</VTypography>
    </button>
  );
};

export default withStyles(styles)(TextButton);
