import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    button: {
      padding: '0 !important',
      border: 'none',
      background: 'none',
      cursor: 'pointer',
    },
    copyLinkIcon: {
      transform: 'rotate(-45deg)',
      color: theme.palette.text.primary,
    },
  });

export default styles;
