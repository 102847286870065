import React, { useRef } from 'react';
import { TableSortLabel, withStyles, IconButton, TextField } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { Edit } from '@material-ui/icons';
import styles from './InvitationsTable.styles';
import { sortObjectArray } from '../../../utils/utils';
import { SORT_DIRECTION } from '../../../constants/types';

const LABELS = {
  TEAM_LEADERS: 'Team Leader',
  ALIAS: 'Alias',
  QUANTITY_SENT: 'Cantidad enviada',
  QUANTITY_ASIGN: 'Cantidad asignada',
  ACTIONS: 'Acciones',
};

const getOptions = (invitation) => ({
  selectableRows: 'none',
  responsive: 'scrollMaxHeight',
  download: false,
  print: false,
  filter: false,
  viewColumns: false,
  rowsPerPage: 25,
  rowsPerPageOptions: [25, 50, 100],
  textLabels: {
    body: {
      noMatch: !invitation ? 'Seleccione una invitación' : 'No se encontraron vendedores',
      toolTip: 'Ordenar',
      columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
    },
    pagination: {
      next: 'Página siguiente',
      previous: 'Página anterior',
      rowsPerPage: 'Filas por página:',
      displayRows: 'de',
    },
    toolbar: {
      search: 'Buscar',
    },
  },
});

const InvitationsTable = ({ sellers, teamLeader = false, classes, setSellers, invitation }) => {
  const sortIndex = useRef();
  const sortDirection = useRef(SORT_DIRECTION.ASC);

  const sortSellers = (index, label, name) => {
    if (label === LABELS.ACTIONS || !sellers.length) return;

    if (sortIndex.current === index) {
      sortDirection.current =
        sortDirection.current === SORT_DIRECTION.DESC ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC;
    } else {
      sortDirection.current = SORT_DIRECTION.DESC;
      sortIndex.current = index;
    }

    const orderedSellers = sortObjectArray(sellers, name, sortDirection.current);

    setSellers([...orderedSellers]);
  };

  const getStyleProps = () => ({
    setCellProps: () => ({
      className: classes.cell,
    }),
    customHeadRender: ({ index, label, name }) => (
      <th
        onClick={() => sortSellers(index, label, name)}
        className={label !== LABELS.ACTIONS ? classes.headerWithArrow : classes.headerWithNoArrow}
      >
        {label}
        {label !== LABELS.ACTIONS && (
          <TableSortLabel
            active={sortIndex.current === index}
            direction={sortDirection.current || SORT_DIRECTION.ASC}
            className={classes.arrow}
          />
        )}
      </th>
    ),
  });

  const handleInputChange = (event, rowIndex) => {
    const { value } = event.target;
    setSellers((prevState) => {
      prevState[rowIndex].amountAsigned = parseInt(value);
      return [...prevState];
    });
  };

  const columns = [
    {
      name: 'alias',
      label: LABELS.ALIAS,
      options: {
        ...getStyleProps(),
      },
    },
    {
      name: 'amountSent',
      label: LABELS.QUANTITY_SENT,
      options: {
        ...getStyleProps(),
      },
    },
    {
      name: 'amountAsigned',
      label: LABELS.QUANTITY_ASIGN,
      options: {
        ...getStyleProps(),
        customBodyRender: (value, { rowIndex }) => (
          <td className={classes.inputContainer}>
            {sellers[rowIndex].edit ? (
              <div>
                <TextField
                  onChange={(event) => handleInputChange(event, rowIndex)}
                  value={value}
                  name="invitationsAsign"
                  classes={{
                    root: `${classes.textFieldRoot}`,
                  }}
                  inputProps={{ style: { textAlign: 'center', fontSize: '0.9rem' } }}
                  variant="outlined"
                  size="small"
                  type="number"
                />
              </div>
            ) : (
              value
            )}
          </td>
        ),
      },
    },
    {
      name: 'action',
      label: LABELS.ACTIONS,
      options: {
        sort: false,
        ...getStyleProps(),
      },
    },
  ];

  const getColumns = (teamLeader) => {
    if (teamLeader)
      columns.unshift({
        name: 'teamLeaderCode',
        label: LABELS.TEAM_LEADERS,
        options: {
          ...getStyleProps(),
        },
      });
    return columns;
  };

  const handleEditInvitations = (index) => {
    setSellers((prevState) => {
      prevState[index].edit = true;
      return [...prevState];
    });
  };

  const addActionButton = (sellers) =>
    sellers.map((seller, index) => ({
      ...seller,
      action: (
        <IconButton
          onClick={() => handleEditInvitations(index)}
          key={`${seller.alias}`}
          className={classes.iconButton}
        >
          <Edit />
        </IconButton>
      ),
    }));

  return (
    <div>
      <MUIDataTable
        data={addActionButton(sellers)}
        columns={getColumns(teamLeader)}
        options={getOptions(invitation)}
      />
    </div>
  );
};

export default withStyles(styles)(InvitationsTable);
