import React, { createContext, useContext, useMemo } from 'react';
import { useNotificationsContext } from './NotificationsProvider.hook';
import Snackbar from '../../Venti-UI-Kit/Snackbar/Snackbar';

export const NotificationsContext = createContext();
export const useNotifications = () => useContext(NotificationsContext);

const NotificationsProvider = ({ children }) => {
  const { showNotification, notificationState, onCloseNotification } = useNotificationsContext();

  const notificationsValue = useMemo(() => ({ showNotification }), []);

  return (
    <NotificationsContext.Provider value={notificationsValue}>
      {children}
      <Snackbar
        open={notificationState.open}
        message={notificationState.message}
        variant={notificationState.variant}
        onClose={onCloseNotification}
      />
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
