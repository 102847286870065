import React from 'react';
import { Stack } from '@mui/material';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import ProducerForm from '../../../Components/Forms/ProducerForm/ProducerForm';
import { useConfigProducerPage } from './ConfigProducerPage.hook';

const ConfigProducerPage = ({ producerId }) => {
  const { handleSubmit } = useConfigProducerPage(producerId);

  return (
    <Stack width="100%" alignItems="center" gap={3}>
      <VTypography variant="h3" align="center">
        Configuración
      </VTypography>
      <ProducerForm producerId={producerId} onSubmit={handleSubmit} submitLabel="Guardar" />
    </Stack>
  );
};

export default ConfigProducerPage;
