import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import styles from './TicketTypesTabs.styles';
import AllTicketTypesTab from '../AllTicketTypesTab/AllTicketTypesTab';
import DesktopTicketTypeInfoCard from '../Cards/TicketTypeInfoCard/DesktopTicketTypeInfoCard/DesktopTicketTypeInfoCard';

const TicketTypesTabs = ({
  classes,
  tabs,
  handleDeleteTicket,
  className,
  handleCopyTicketLink,
}) => {
  const [value, setValue] = useState('0');

  const handleSelectTab = (event, selectedTab) => {
    setValue(selectedTab);
  };

  const tabsWithoutViewAll = tabs.slice(0, -1);

  const isViewAll = (index) => {
    return parseInt(index) === tabs.length - 1;
  };

  return (
    <TabContext value={value} className={className}>
      <TabList
        onChange={handleSelectTab}
        centered
        variant="fullWidth"
        value={value}
        className={classes.tabList}
        indicatorColor="primary"
      >
        {tabs.map(({ label, data, index }) => (
          <Tab
            label={`${label} (${data.length})`}
            className={classes.tab}
            value={index}
            key={`${label}_${index}`}
          />
        ))}
      </TabList>
      {tabs.map(({ label, data, index }) =>
        !isViewAll(index) ? (
          <TabPanel value={index} key={`${label}_${index}`} className={classes.tabPanel}>
            {data.map((ticket) => (
              <DesktopTicketTypeInfoCard
                ticket={ticket}
                key={ticket.id}
                handleDelete={handleDeleteTicket}
                handleCopyTicketLink={handleCopyTicketLink}
              />
            ))}
          </TabPanel>
        ) : (
          <TabPanel key={`${label}_${index}`} value={index} className={classes.tabPanel}>
            <AllTicketTypesTab
              sections={tabsWithoutViewAll}
              handleDeleteTicket={handleDeleteTicket}
              handleCopyTicketLink={handleCopyTicketLink}
            />
          </TabPanel>
        )
      )}
    </TabContext>
  );
};

export default withStyles(styles)(TicketTypesTabs);
