import { useEffect, useState } from 'react';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';

export const useTopCustomersTable = (producerId) => {
  const [metrics, setMetrics] = useState([]);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getMetrics = async () => {
    await handleRequestHelper({
      endpoint: () => api.getProducerTopCustomersMetrics(producerId),
      onSuccess: ({ topCustomers }) => setMetrics(topCustomers),
    });
  };

  useEffect(() => {
    getMetrics();
  }, []);

  const columns = [
    { id: 'mail', label: 'Mail', align: 'left' },
    { id: 'firstName', label: 'Nombre' },
    { id: 'lastName', label: 'Apellido' },
    { id: 'totalOrders', label: 'Eventos' },
  ];

  return {
    metrics,
    columns,
    page,
    handleChangePage,
  };
};
