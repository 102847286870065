import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { useIntercom } from 'react-use-intercom';
import styles from './RestorePasswordModalStyles';
import FormModal from '../../../Venti-UI-Kit/Modals/FormModal';
import InformationModal from '../../../Venti-UI-Kit/Modals/InformationModal';
import { useForm } from '../../../hooks/useForm';
import { INPUT_TYPE } from '../../../constants/types';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';
import TextButton from '../../../Components/Buttons/TextButton/TextButton';

const RestorePasswordModal = ({ classes, open, handleClose }) => {
  const [openSentMailModal, setOpenSentMailModal] = useState(false);
  const [isRestorePasswordButtonDisabled, setIsRestorePasswordButtonDisabled] = useState(true);
  const forgotPasswordForm = useForm({ mail: { title: 'Email', type: INPUT_TYPE.MAIL } });
  const { show } = useIntercom();

  const handleCloseSendForgotPasswordMail = () => {
    setOpenSentMailModal(false);
  };

  const handleOpenIntercom = () => {
    setOpenSentMailModal(false);
    show();
  };

  const sendRestorePasswordMail = async () => {
    setIsRestorePasswordButtonDisabled(true);

    const isFormValid = forgotPasswordForm.validateFormInputs();
    if (!isFormValid) {
      setIsRestorePasswordButtonDisabled(false);
      return;
    }

    const resetPasswordTokenBody = {
      mail: forgotPasswordForm.formState.mail.value,
      hostname: window.location.hostname,
    };

    await handleRequestHelper({
      endpoint: () => api.sendResetPasswordToken(resetPasswordTokenBody),
      onSuccess: () => {
        forgotPasswordForm.resetForm();
        handleClose();
        setOpenSentMailModal(true);
      },
    });

    setIsRestorePasswordButtonDisabled(false);
  };

  useEffect(
    () => setIsRestorePasswordButtonDisabled(!forgotPasswordForm.isFormComplete()),
    [forgotPasswordForm.formValues]
  );

  return (
    <>
      <FormModal
        form={forgotPasswordForm}
        open={open}
        handleClose={handleClose}
        okLabel="Restablecer"
        okDisabled={isRestorePasswordButtonDisabled}
        onSubmit={sendRestorePasswordMail}
        title={
          <>
            Restablecé <br /> tu contraseña
          </>
        }
        subtitle="Ingresá el email asociado a tu cuenta y te vamos a enviar un enlace para recuperar el acceso a tu cuenta."
      />
      <InformationModal
        open={openSentMailModal}
        handleClose={handleCloseSendForgotPasswordMail}
        handleAccept={handleCloseSendForgotPasswordMail}
        title={
          <>
            Ya te enviamos <br /> el mail
          </>
        }
        subtitle={
          <>
            Te enviamos un email a{' '}
            <span className={classes.mailFromModal}>
              {forgotPasswordForm?.formState.mail.value}
            </span>{' '}
            con un enlace para que recuperes el acceso a tu cuenta. No olvides revisar la carpeta
            "Spam" o “Promociones”.
            <br /> <br /> Si no lo recibiste, revisa la redacción del correo o crea una cuenta.{' '}
            <TextButton
              title="Comunicate con nosotros."
              fontSize={16}
              underline
              onClick={handleOpenIntercom}
            />
          </>
        }
        okLabel="Aceptar"
      />
    </>
  );
};

export default withStyles(styles)(RestorePasswordModal);
