import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    line: {
      '& path': {
        stroke: theme.palette.charts.primary,
      },
    },
    slider: {
      maxWidth: 300,
    },
    tooltip: {
      backgroundColor: theme.palette.grey[50],
      border: `2px solid ${theme.palette.charts.primary}`,
      display: 'flex',
      borderRadius: 12,
      flexDirection: 'column',
      padding: 10,
      '& p': {
        margin: 0,
      },
    },
    activeDot: {
      stroke: theme.palette.charts.primary,
      fill: 'white',
      strokeWidth: 2,
    },
    noInformationText: {
      margin: '30px 0 20px',
    },
  });

export default styles;
