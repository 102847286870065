import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    tableContainer: {
      '&.MuiStack-root': {
        overflow: 'auto',
        height: '100%',
      },
    },
    table: {
      borderRadius: '8px !important',
      overflow: 'hidden',
    },
    tableHead: {
      backgroundColor: theme.palette.primary.main,
      '& th': {
        color: theme.palette.primary.contrastText,
        fontWeight: 600,
        padding: '10px 16px',
      },
    },
    tableBody: {
      '& td': {
        fontSize: 14,
        borderBottom: 0,
        padding: '10px 16px',
        maxWidth: 250,
        minWidth: 120,
      },
    },
    tableRow: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.grey[100],
      },
    },
    tablePagination: {
      '&.MuiTablePagination-root': {
        overflow: 'hidden',
        minHeight: 'unset',
        width: '100%',
        height: 36,
      },
    },
  });

export default styles;
