import React from 'react';
import { withStyles } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import { Chip, Stack } from '@mui/material';

import styles from './DateFilter.styles';
import DatePickerHeader from './DatePickerHeader/DatePickerHeader';
import 'react-datepicker/dist/react-datepicker.css';
import { useDateFilter } from './DateFilter.hook';

const DateFilter = ({ classes }) => {
  const { buttons, startDate, endDate, onChange } = useDateFilter();

  return (
    <Stack className={classes.datePickerContainer} direction="column" gap={1}>
      <Stack direction="row" width="100%" justifyContent="start" gap={0.5}>
        {buttons.map(({ label, onClick }) => (
          <Chip
            key={label}
            component="button"
            className={classes.dateButton}
            onClick={onClick}
            label={label}
          />
        ))}
      </Stack>
      <DatePicker
        calendarClassName={classes.datePicker}
        selected={startDate}
        onChange={onChange}
        minDate={new Date()}
        startDate={startDate}
        endDate={endDate}
        locale="es"
        popperClassName={classes.datePickerPopper}
        selectsRange
        inline
        showDisabledMonthNavigation
        renderCustomHeader={(props) => <DatePickerHeader {...props} />}
      />
    </Stack>
  );
};

export default withStyles(styles)(DateFilter);
