import { useState } from 'react';
import { registerLocale } from 'react-datepicker';
import { es } from 'date-fns/locale';
import { endOfWeek, startOfTomorrow, subDays } from 'date-fns';
import history from '../../../../appHistory';
import { formatDate } from '../../../../utils/utils';

registerLocale('es', es);

export const useDateFilter = () => {
  const [fromDate, setFromDate] = useState(null);
  const [untilDate, setUntilDate] = useState(null);

  const getTodayDate = () => {
    const today = new Date();
    history.push(`/eventos/search?from=${formatDate(today)}&until=${formatDate(today)}`);
  };

  const getTomorrowDate = () => {
    const tomorrow = startOfTomorrow();
    history.push(`/eventos/search?from=${formatDate(tomorrow)}&until=${formatDate(tomorrow)}`);
  };

  const getThisWeekDate = () => {
    const from = new Date();
    const until = endOfWeek(from, { weekStartsOn: 1 });
    history.push(`/eventos/search?from=${formatDate(from)}&until=${formatDate(until)}`);
  };

  const getWeekendDate = () => {
    const until = endOfWeek(new Date(), { weekStartsOn: 1 });
    const from = subDays(until, 2);
    history.push(`/eventos/search?from=${formatDate(from)}&until=${formatDate(until)}`);
  };

  const buttons = [
    { label: 'Hoy', onClick: () => getTodayDate() },
    { label: 'Mañana', onClick: () => getTomorrowDate() },
    { label: 'Esta Semana', onClick: () => getThisWeekDate() },
    { label: 'Este Finde', onClick: () => getWeekendDate() },
  ];

  const onChange = (dates) => {
    const [from, until] = dates;
    setFromDate(from);
    setUntilDate(until);

    if (from && until)
      history.push(`/eventos/search?from=${formatDate(from)}&until=${formatDate(until)}`);
  };

  return {
    buttons,
    startDate: fromDate,
    endDate: untilDate,
    onChange,
  };
};
