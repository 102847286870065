import React from 'react';
import { withStyles } from '@material-ui/core';
import { Stack, Typography } from '@mui/material';
import styles from './VolumeLast30DaysTrend.styles';
import ChartLayout from '../ChartLayout';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import VIcon from '../../../Venti-UI-Kit/VIcon/VIcon';
import { useVolumeLast30DaysTrend } from './VolumeLast30DaysTrend.hook';

const VolumeLast30DaysTrend = ({ classes, producerId }) => {
  const { trend, theme } = useVolumeLast30DaysTrend(producerId);

  return (
    <ChartLayout title="Volumen últimos 30 días" padding={0} height="auto">
      {trend && (
        <Stack gap={1} alignItems="center">
          <Typography className={classes.volumeText}>{trend.last30DaysVolume}</Typography>
          <VTypography variant="body1" align="center">
            Desde el {trend.last30DaysDate}
          </VTypography>
          {trend.last60DaysVolume > 0 && (
            <Stack direction={{ xs: 'column', sm: 'row' }} gap={1} alignItems="center">
              <Stack direction="row" gap={1} alignItems="center">
                {trend.percentage > 0 ? (
                  <VIcon name="trending_up" color={theme.palette.success.main} />
                ) : (
                  <VIcon name="trending_down" color={theme.palette.error.main} />
                )}
                <VTypography
                  variant="subtitle3"
                  color={trend.color}
                >{`${trend.percentage}%`}</VTypography>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} gap={1} alignItems="center">
                <VTypography variant="body1">- vs. mes anterior: </VTypography>
                <VTypography variant="h7">{trend.last60DaysVolume}</VTypography>
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
    </ChartLayout>
  );
};

export default withStyles(styles)(VolumeLast30DaysTrend);
