import React, { useState } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import styles from './MobileTicketTypeInfoCard.styles';
import { translate } from '../../../../utils/translate';
import AlertDialog from '../../../../Venti-UI-Kit/AlertDialog/AlertDialog';
import {
  TICKET_VISIBILITY_MODE,
  TICKET_ACTIVITY_STATUS,
  TICKET_TYPE,
} from '../../../../constants/types';
import CopyLinkButton from '../../../CopyLinkButton/CopyLinkButton';

const MobileTicketTypeInfoCard = ({ classes, ticket, handleDelete, handleCopyTicketLink }) => {
  const {
    id: ticketId,
    name,
    type,
    maxQuantity,
    soldUnits,
    price,
    visibilityMode,
    activityStatus,
    externalId,
  } = ticket;
  const ticketVisibilityMode = translate(visibilityMode);
  const ticketActivityStatus = translate(activityStatus);
  const canCopyLink =
    visibilityMode === TICKET_VISIBILITY_MODE.HIDDEN &&
    activityStatus === TICKET_ACTIVITY_STATUS.ACTIVE &&
    type !== TICKET_TYPE.INVITATION;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    <div className={classes.container}>
      <AlertDialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title={`¿Está seguro que desea eliminar el ticket: ${name}?`}
        message="Esta acción no puede deshacerse."
        handleConfirm={() => handleDelete(ticketId)}
      />
      <div className={classes.infoContainer}>
        <div className={classes.titleAndImageContainer}>
          <Typography className={classes.ticketName}>{name}</Typography>
        </div>
        <div className={classes.infoTextsContainer}>
          <Typography className={classes.infoText}>
            <b>Precio: </b> {price > 0 ? `$${price}` : 'Gratis'}
          </Typography>
          <Typography className={classes.infoText}>
            <b>Stock disponible: </b> {maxQuantity - soldUnits}
          </Typography>
          <Typography className={classes.infoText}>
            <b>Estado: </b> {ticketVisibilityMode} / {ticketActivityStatus}
          </Typography>
        </div>
      </div>
      <div className={classes.buttonsContainer}>
        {canCopyLink && <CopyLinkButton onClick={() => handleCopyTicketLink(externalId)} />}
        <IconButton
          href={`${window.location.pathname}/${ticketId}`}
          className={classes.button}
          aria-label="edit"
        >
          <CreateIcon className={classes.editIcon} />
        </IconButton>
        {!soldUnits && (
          <IconButton
            onClick={() => setOpenDeleteModal(true)}
            className={classes.button}
            aria-label="delete"
          >
            <DeleteIcon className={classes.deleteIcon} />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(MobileTicketTypeInfoCard);
