import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { withStyles } from '@material-ui/core';
import styles from './EditUserStyles';
import UserForm from '../../../Components/Forms/UserForm/UserForm';
import LoadingContainer from '../../../Components/LoadingContainer/LoadingContainer';
import { useUser } from '../../../hooks/useUser';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';

const EditUser = ({ classes, urlParams }) => {
  const { producerId, userId } = urlParams;

  const [initialUserData, setInitialUserData] = useState({});
  const [loading, setLoading] = useState(true);

  const { getUserDataFormInfo, getUserEventsRestrictedAccesses } = useUser();

  const getInitialData = async () => {
    const { initialUserInfo, initialProducers, initialTeamLeaders } = await getUserDataFormInfo(
      userId
    );
    const initialEventsAccesses = await getUserEventsRestrictedAccesses(userId);

    setInitialUserData((prevState) => ({
      ...prevState,
      initialUserInfo,
      initialProducers,
      initialTeamLeaders,
      initialEventsAccesses,
    }));
    setLoading(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <PageContainer title="Backoffice - Editar usuario">
      <Box className={classes.pageContentContainer}>
        <VTypography variant="h3" align="center">
          Editar usuario
        </VTypography>
        <LoadingContainer isLoading={loading}>
          <UserForm edit initialData={initialUserData} producerId={producerId} />
        </LoadingContainer>
      </Box>
    </PageContainer>
  );
};

export default withStyles(styles)(EditUser);
