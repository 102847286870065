import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    eventCardContainer: {
      width: 328,
      minWidth: 328,
      height: 409,
      textDecoration: 'none',
      backgroundSize: 'cover',
      display: 'flex',
      flexDirection: 'column',
      backgroundPosition: 'center',
      cursor: 'pointer',
      position: 'relative',
      borderRadius: 8,
      overflow: 'hidden',
      justifyContent: 'flex-end',
      padding: 16,

      '&:hover': {
        opacity: 0.9,
      },
    },
    gradient: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'linear-gradient(to top, #000000 0%, #000000 11.5%, rgba(255, 255, 255, 0) 50%)',
    },
  });

export default styles;
