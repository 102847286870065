import React, { useEffect, useState } from 'react';
import { useTheme, withStyles } from '@material-ui/core';
import { Modal, Stack, SwipeableDrawer } from '@mui/material';
import styles from './BottomSheet.styles';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const BottomSheet = ({ classes, open, onClose, children, alwaysModal = false }) => {
  const [mobile, setMobile] = useState(true);
  const { width } = useWindowDimensions();
  const theme = useTheme();

  useEffect(() => {
    setMobile(width <= theme.breakpoints.values.sm);
  }, [width]);

  return !mobile || alwaysModal ? (
    <Modal className={classes.modalContainer} open={open} onClose={onClose} disableEscapeKeyDown>
      <Stack className="modalBoxContainer">{children}</Stack>
    </Modal>
  ) : (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onOpen={() => open}
      onClose={onClose}
      className={classes.drawer}
    >
      <div className={classes.drawerContainer}>
        <div className={classes.puller} />
        {children}
      </div>
    </SwipeableDrawer>
  );
};

export default withStyles(styles)(BottomSheet);
