import React, { useState } from 'react';
import { IconButton, InputAdornment, MenuItem, TextField, withStyles } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import styles from './CustomInput.styles';
import VTypography from '../../Venti-UI-Kit/VTypography/VTypography';

const CustomInput = ({
  title = '',
  error = '',
  subtitle = '',
  titleIcon,
  options = [],
  name = '',
  type = 'text',
  passwordIcon = false,
  onChange,
  value = '',
  classes,
  button,
  hint = '',
  fullWidth = true,
  startAdornment,
  endAdornment,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const passwordType = showPassword ? 'text' : 'password';

  return (
    <div className={`${classes.container} ${fullWidth && classes.fullWidth}`}>
      <div className={classes.inputHeaderContainer}>
        <div className={classes.titleAndIconContainer}>
          {titleIcon}
          <VTypography variant="body1">{title}</VTypography>
        </div>
        {subtitle && <VTypography variant="caption">{subtitle}</VTypography>}
      </div>
      <div>
        <div className={classes.inputContainer}>
          <TextField
            {...props}
            onChange={onChange}
            value={value}
            name={name}
            classes={{
              root: `${classes.textFieldRoot} ${error !== '' && classes.textFieldRootError}`,
            }}
            variant="outlined"
            size="small"
            type={passwordIcon ? passwordType : type}
            InputProps={{
              classes: { root: classes.inputRoot },
              startAdornment: startAdornment && (
                <InputAdornment position="start">{startAdornment}</InputAdornment>
              ),
              endAdornment:
                endAdornment ||
                (passwordIcon && (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                )),
            }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    maxWidth: 300,
                    overflow: 'scroll',
                    overflowX: 'hidden',
                  },
                },
              },
            }}
          >
            {options.lenght !== 0 &&
              options.map(({ label, value }, index) => (
                <MenuItem
                  key={label + index}
                  value={value}
                  className={classes.menuItem}
                  selected={index === 0}
                >
                  {label}
                </MenuItem>
              ))}
          </TextField>
          {button}
        </div>
        {(error || hint) && (
          <div className={classes.errorAndHintContainer}>
            {!error && hint && <VTypography variant="caption">{hint}</VTypography>}
            {error && (
              <VTypography variant="caption" color="error">
                {error}
              </VTypography>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(CustomInput);
