import React from 'react';
import { CircularProgress, withStyles } from '@material-ui/core';
import styles from './CustomCircularProgressStyles';

const CustomCircularProgress = ({ classes, size = 30 }) => {
  return (
    <div className={classes.container}>
      <CircularProgress size={size} className={classes.circularProgress} />
    </div>
  );
};

export default withStyles(styles)(CustomCircularProgress);
