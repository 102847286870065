import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    container: {
      marginTop: '2rem',
      alignItems: 'center',
      width: '100%',
      gap: theme.spacing(3),
    },

    actionsContainer: {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        alignItems: 'start',
        justifyContent: 'left',
      },
    },
  });

export default styles;
