import { USER_ROLES } from '../../constants/types';

class PermissionsProvider {
  isProducerAdmin(user, producer) {
    if (!this.isSuperAdmin(user.role)) {
      const userProducers = user.producers.map((p) => Number(p.id));
      return userProducers.includes(Number(producer));
    }
    return true;
  }

  isSuperAdmin(role) {
    return role === USER_ROLES.SUPER_ADMIN;
  }

  isAdmin(role) {
    return role === USER_ROLES.ADMIN || role === USER_ROLES.SUPER_ADMIN;
  }

  isOnlyAdmin(role) {
    return role === USER_ROLES.ADMIN;
  }

  isReadOnlyAdmin(role) {
    return role === USER_ROLES.READ_ONLY_ADMIN;
  }

  isAdminOrReadOnlyAdmin(role) {
    return role === USER_ROLES.ADMIN || role === USER_ROLES.READ_ONLY_ADMIN;
  }

  canSellCash(role) {
    return (
      role === USER_ROLES.ADMIN ||
      role === USER_ROLES.SUPER_ADMIN ||
      role === USER_ROLES.SELLER_WITH_CASH
    );
  }

  isSeller(role) {
    return role === USER_ROLES.SELLER || role === USER_ROLES.SELLER_WITH_CASH;
  }

  isSellerWithCash(role) {
    return role === USER_ROLES.SELLER_WITH_CASH;
  }

  isCustomer(role) {
    return role === USER_ROLES.CUSTOMER;
  }
}
const permissions = new PermissionsProvider();

export default permissions;
