import { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core';
import { addDays, parse } from 'date-fns';
import { handleRequestHelper } from '../../../utils/helpers';
import { getUrlSearchParams } from '../../../utils/utils';
import api from '../../../api/api';

export const useSearchEventsResultsPage = () => {
  const { from, until, query } = getUrlSearchParams();
  const theme = useTheme();
  const [events, setEvents] = useState([]);

  const parsedFrom = parse(from, 'dd/MM/yyyy', new Date());
  let parsedUntil = parse(until, 'dd/MM/yyyy', new Date());
  parsedUntil = addDays(parsedUntil, 1);

  // Format dates to 9am Argentina
  let formatFromDate = from && parsedFrom.setHours(6, 0, 0, 0);
  let formatUntilDate = until && parsedUntil.setHours(6, 0, 0, 0);

  if (formatFromDate && formatUntilDate) {
    formatFromDate = new Date(formatFromDate).toISOString();
    formatUntilDate = new Date(formatUntilDate).toISOString();
  }

  const getEvents = async () => {
    await handleRequestHelper({
      endpoint: () =>
        api.getHomeEvents({
          searchQuery: query,
          from: formatFromDate,
          until: formatUntilDate,
        }),
      onSuccess: ({ events }) => setEvents(events),
    });
  };

  useEffect(() => {
    getEvents();
  }, []);

  return {
    theme,
    from,
    until,
    query,
    events,
  };
};
