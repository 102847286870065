import { useState } from 'react';
import { handleRequestHelper } from '../../utils/helpers';
import api from '../../api/api';
import { getToken } from '../../api/api.utils';
import history from '../../appHistory';

export const useAuthContext = () => {
  const [user, setUser] = useState(null);

  const clearLocalStorage = () => {
    localStorage.removeItem('jwt');
  };

  const clearUser = () => {
    clearLocalStorage();
    setUser(null);
  };

  const logout = () => {
    clearUser();
    history.push('/login');
  };

  const checkJWTToken = async () => {
    if (!getToken()) clearUser();
    else {
      await handleRequestHelper({
        endpoint: () => api.checkToken(),
        onSuccess: ({ user }) => setUser(user),
        onFailure: () => clearUser(),
      });
    }
  };

  const login = async (mail, password, onFailure) => {
    await handleRequestHelper({
      endpoint: () => api.login(mail, password),
      onSuccess: ({ user, token }) => {
        localStorage.setItem('jwt', token);
        setUser(user);
      },
      onFailure: () => {
        onFailure();
        clearUser();
      },
    });
  };

  return { user, login, logout, checkJWTToken };
};
