import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import styles from './ShadowButtonStyles';

const ShadowButton = ({ classes, text, onClick }) => {
  return (
    <button onClick={onClick} type="button" className={classes.ventiButtonContainer}>
      <Typography className="ventiButtonText">{text}</Typography>
    </button>
  );
};

export default withStyles(styles)(ShadowButton);
