import React from 'react';
import { Stack } from '@mui/material';
import { useTheme } from '@material-ui/core';
import VTypography from '../../Venti-UI-Kit/VTypography/VTypography';

const ChartLayout = ({ children, title, padding, height }) => {
  const theme = useTheme();

  return (
    <Stack
      direction="column"
      height={!children ? 'auto' : height || 400}
      bgcolor={theme.palette.grey[50]}
      borderRadius={2}
      gap={2}
      padding={padding || 2}
    >
      <VTypography variant="body1" align="center">
        {title}
      </VTypography>
      {children || (
        <VTypography align="center" variant="subtitle4" color={theme.palette.grey[500]}>
          No hay datos para mostrar
        </VTypography>
      )}
    </Stack>
  );
};

export default ChartLayout;
