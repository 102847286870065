import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import styles from './SocialIconButton.styles';
import VIcon from '../../Venti-UI-Kit/VIcon/VIcon';
import { SOCIALS_CONFIG } from '../Forms/ProducerForm/ProducerForm.constants';

const SocialIconButton = ({ classes, social, socialLink }) => {
  const link = SOCIALS_CONFIG[social].baseURL + socialLink;

  return (
    <Button className={classes.button} onClick={() => window.open(link, '_blank')}>
      <VIcon name={SOCIALS_CONFIG[social].icon} size={16} />
    </Button>
  );
};

export default withStyles(styles)(SocialIconButton);
