import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    volumeText: {
      '&.MuiTypography-root': {
        fontFamily: theme.typography.fontFamily,
        fontSize: 52,
        fontWeight: 600,
        width: 'max-content',
        lineHeight: 1,

        [theme.breakpoints.down('sm')]: {
          fontSize: 32,
        },
      },
    },
  });

export default styles;
