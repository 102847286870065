import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';

const Label = withStyles({
  root: {
    color: '#535f68',
    padding: '0 1em',
  },
  focused: {
    transform: 'translate(0, -15px) scale(0.75)',
    // color: '#744ace',
  },
})(InputLabel);

export default Label;
