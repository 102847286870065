import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { QrCodeOutlined } from '@mui/icons-material';
import EditLocationOutlinedIcon from '@mui/icons-material/EditLocationOutlined';
import TitledRadioButtons from '../../../Venti-UI-Kit/RadioButtons/TitledRadioButtons';
import TitledInput from '../../../Venti-UI-Kit/Input/TitledInput';
import TitledSwitch from '../../../Venti-UI-Kit/Switches/TitledSwitch';
import MultipleImages from '../../ImageDropzone/MultipleImages';
import InputTitle from '../../InputTitle/InputTitle';
import { icons } from '../../../Providers/Icons/IconsProvider';
import styles from './EditTicketTypeForm.styles';
import { TICKET_TYPE, TICKET_VISIBILITY_MODE, USER_PERMISSIONS } from '../../../constants/types';
import Button from '../../../Venti-UI-Kit/Buttons/Button';
import AlertDialog from '../../../Venti-UI-Kit/AlertDialog/AlertDialog';
import CustomDatePicker from '../../DatePickerComponents/CustomDatePicker';
import CustomTimePicker from '../../DatePickerComponents/CustomTimePicker';
import { translate } from '../../../utils/translate';
import StadiumSectionInfo from '../../Backoffice/StadiumSectionInfo/StadiumSectionInfo';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { validateUserRole } from '../../../utils/utils';

const MAX_TICKET_TYPE_DESCRIPTION_LENGTH = 200;
const IMAGE_LIMIT = 3;

const EditTicketTypeForm = ({
  classes,
  ticketType,
  handleInputChange,
  ticketTypeErrors,
  showErrors,
  handlePriceChange,
  handleStopOfSalesDateChange,
  handleStopOfAccessDateChange,
  handleHasLemonDiscount,
  handlePriceWithLemonChange,
  handleIsLemonExclusive,
  handleDeleteCollection,
  handleImagesChange,
  handleDiscountCodeChange,
  handleDiscountPercentageChange,
  handleHasDiscountCode,
  handleSectionChange,
  hasDiscountCode,
  hasStopOfAccessDate,
  handleHasStopOfAccessDate,
  discountCode,
  edition = false,
  event,
  stadiumSections,
}) => {
  const { user } = useAuth();
  const { showNotification } = useNotifications();
  const { defaultStopOfSalesDate, shouldExtendStopOfSalesDateToInvitations, endDate } = event;

  const {
    price,
    name,
    maxQuantity,
    maxQuantityPerOrder,
    stopOfSalesDate,
    description,
    hasLemonDiscount,
    priceWithLemon,
    isLemonExclusive,
    activityStatus,
    visibilityMode,
    stopOfAccessDate,
    onlyOnline,
    type,
    groupSize,
    images,
    lemonCollectionId,
    section,
  } = ticketType;

  const [hasCollection, setHasCollection] = useState(false);
  const [isInvitation, setIsInvitation] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    setHasCollection(!!lemonCollectionId);
  }, []);

  useEffect(() => {
    if (
      !edition &&
      (type !== TICKET_TYPE.INVITATION || shouldExtendStopOfSalesDateToInvitations) &&
      defaultStopOfSalesDate
    )
      handleStopOfSalesDateChange(defaultStopOfSalesDate);
  }, []);

  const isSuperAdmin = validateUserRole(user.role, USER_PERMISSIONS.SUPER_ADMIN);
  const isAdmin = validateUserRole(user.role, USER_PERMISSIONS.ADMIN);

  const ticketTypeActivityStatus = [
    { value: 'active', label: 'Activo' },
    { value: 'inactive', label: 'Inactivo' },
  ];
  const ticketTypeVisibilityMode = [
    { value: 'visible', label: 'Visible' },
    { value: 'hidden', label: 'Oculto' },
  ];
  const ticketTypes = [
    { value: TICKET_TYPE.PRESENTIAL, label: translate(TICKET_TYPE.PRESENTIAL) },
    { value: TICKET_TYPE.TABLE, label: translate(TICKET_TYPE.TABLE) },
    { value: TICKET_TYPE.INVITATION, label: translate(TICKET_TYPE.INVITATION) },
  ];

  useEffect(() => {
    if (type === TICKET_TYPE.INVITATION) {
      setIsInvitation(true);
      ticketType.visibilityMode = TICKET_VISIBILITY_MODE.HIDDEN;
      ticketType.price = '0';
      ticketType.priceWithLemon = '0';

      if (!edition && defaultStopOfSalesDate && !shouldExtendStopOfSalesDateToInvitations)
        handleStopOfSalesDateChange(null);
    } else {
      setIsInvitation(false);
      if (!edition && defaultStopOfSalesDate && !stopOfSalesDate)
        handleStopOfSalesDateChange(defaultStopOfSalesDate);
    }

    if (type !== TICKET_TYPE.TABLE) {
      ticketType.groupSize = 0;
    }
  }, [type]);

  const deleteCollection = () => {
    handleDeleteCollection();
    setHasCollection(false);
    setOpenDeleteModal(false);
  };

  let stadiumSectionsOptions = [];
  if (stadiumSections) {
    stadiumSectionsOptions = stadiumSections.map(({ name, capacity, id }) => ({
      label: `${name} - ${capacity} loc.`,
      value: id,
    }));
  }

  return (
    <form className={classes.container}>
      <Paper elevation={0} className={classes.paperContainer}>
        <TitledInput
          className={classes.input}
          title="Nombre del ticket"
          name="name"
          autocomplete="off"
          value={name}
          icon={icons.pencil}
          onChange={handleInputChange}
          helperText={ticketTypeErrors.name}
          showErrors={showErrors}
        />
        <div className={classes.ticketTypeSelectContainer}>
          <InputTitle title="Tipo de ticket" icon={icons.ticket} />
          <Select
            className={classes.select}
            name="type"
            value={type}
            disableUnderline
            onChange={handleInputChange}
            disabled={edition}
          >
            {ticketTypes.map(({ value, label }) => (
              <MenuItem value={value} key={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </div>
        {!!stadiumSectionsOptions.length && (
          <div className={classes.ticketTypeSelectContainer}>
            <InputTitle title="Sector" materialIcon={<EditLocationOutlinedIcon />} />
            <Select
              className={classes.select}
              name="section"
              value={section?.id}
              disableUnderline
              onChange={handleSectionChange}
              disabled={edition}
            >
              {stadiumSectionsOptions.map(({ value, label }) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
        {section && <StadiumSectionInfo section={section} />}
        <div className={classes.doubleInputContainer}>
          {!isInvitation && (
            <TitledInput
              className={classes.input}
              title="Precio"
              name="price"
              autocomplete="off"
              value={price}
              icon={icons.price}
              onChange={handlePriceChange}
              helperText={ticketTypeErrors.price}
              showErrors={showErrors}
              disabled={edition}
            />
          )}
          <TitledInput
            className={classes.input}
            title="Stock"
            name="maxQuantity"
            autocomplete="off"
            value={maxQuantity}
            icon={icons.ticket}
            onChange={handleInputChange}
            helperText={ticketTypeErrors.maxQuantity}
            showErrors={showErrors}
          />
        </div>
        {!isInvitation && (
          <div className={classes.doubleInputContainer}>
            <TitledInput
              className={classes.input}
              title="Cantidad máxima por orden"
              name="maxQuantityPerOrder"
              autocomplete="off"
              value={maxQuantityPerOrder}
              icon={icons.ticket}
              onChange={handleInputChange}
              helperText={ticketTypeErrors.maxQuantityPerOrder}
              showErrors={showErrors}
            />
            {type === TICKET_TYPE.TABLE && (
              <TitledInput
                className={classes.input}
                title="Tickets por mesa"
                name="groupSize"
                autocomplete="off"
                value={groupSize}
                icon={icons.ticket}
                onChange={handleInputChange}
                helperText={ticketTypeErrors.groupSize}
                showErrors={showErrors}
              />
            )}
          </div>
        )}
        <div className={classes.dateTimeContainer}>
          <InputTitle
            icon={icons.date}
            title="Fecha y hora de finalización de la venta del ticket"
            description="Elegí hasta que fecha y hora podrán venderse o enviarse estos accesos"
          />
          <div className={classes.doubleInputContainer}>
            <div className={classes.dateInputContainer}>
              <Typography className={classes.dateTimeInputTitle}>Fecha</Typography>
              <CustomDatePicker
                value={stopOfSalesDate}
                onChange={handleStopOfSalesDateChange}
                ampm={false}
              />
            </div>
            <div className={classes.timeInputContainer}>
              <Typography className={classes.dateTimeInputTitle}>Hora</Typography>
              <CustomTimePicker value={stopOfSalesDate} onChange={handleStopOfSalesDateChange} />
            </div>
          </div>
        </div>
        <TitledInput
          className={classes.input}
          title="Descripción del ticket"
          name="description"
          autocomplete="-"
          value={description}
          placeholder="Descripción del ticket"
          icon={icons.description}
          onChange={handleInputChange}
          helperText={ticketTypeErrors.description}
          inputProps={{ maxLength: MAX_TICKET_TYPE_DESCRIPTION_LENGTH }}
          multiline
          showLength
          maxLength={MAX_TICKET_TYPE_DESCRIPTION_LENGTH}
          showErrors={showErrors}
        />
      </Paper>
      <Paper elevation={0} className={`${classes.nftSectionContainer} ${classes.paperContainer}`}>
        <div className={classes.hasStopOfAccessDateContainer}>
          <TitledSwitch
            materialIcon={<QrCodeOutlined />}
            onChange={handleHasStopOfAccessDate}
            checked={hasStopOfAccessDate}
            value={hasStopOfAccessDate}
            title="Limitar el horario de ingreso de estos accesos"
            description="Elegí hasta qué fecha y hora son válidos. Recordá que después de este horario, se darán de baja y NO será posible escanear los QRs para acceder al evento."
          />
          {hasStopOfAccessDate && (
            <div className={classes.doubleInputContainer}>
              <div className={classes.dateInputContainer}>
                <Typography className={classes.dateTimeInputTitle}>Fecha</Typography>
                <CustomDatePicker
                  clearable
                  value={stopOfAccessDate}
                  onChange={handleStopOfAccessDateChange}
                  ampm={false}
                  maxDate={endDate}
                />
              </div>
              <div className={classes.timeInputContainer}>
                <Typography className={classes.dateTimeInputTitle}>Hora</Typography>
                <CustomTimePicker
                  value={stopOfAccessDate}
                  onChange={handleStopOfAccessDateChange}
                />
              </div>
            </div>
          )}
        </div>
      </Paper>
      {isSuperAdmin && (
        <Paper elevation={0} className={`${classes.nftSectionContainer} ${classes.paperContainer}`}>
          <InputTitle icon={icons.nft} title="Administrar la colección NFT" />
          {hasCollection ? (
            <div className={classes.nftCollectionItem}>
              <Typography variant="h6" className={classes.nftItemText}>
                Colección {lemonCollectionId}
              </Typography>
              <Button onClick={() => setOpenDeleteModal(true)} className={classes.deleteButton}>
                Eliminar
              </Button>
            </div>
          ) : (
            <TitledInput
              className={classes.input}
              name="lemonCollectionId"
              placeholder="Ingrese el ID de la colección"
              autocomplete="off"
              value={lemonCollectionId}
              onChange={handleInputChange}
              helperText={ticketTypeErrors.lemonCollectionId}
              showErrors={showErrors}
            />
          )}
        </Paper>
      )}
      {isSuperAdmin && !isInvitation && (
        <>
          <Paper elevation={0} className={classes.paperContainer}>
            <TitledSwitch
              title="Incluir descuento con Lemon"
              description=" Habilita un precio preferencial en este ticket a todos los clientes que paguen con Lemon Card"
              name="hasLemonDiscount"
              checked={hasLemonDiscount}
              icon={icons.lemon}
              onChange={handleHasLemonDiscount}
            />
            {hasLemonDiscount && (
              <>
                <TitledInput
                  title="Precio con Lemon"
                  name="priceWithLemon"
                  autocomplete="on"
                  value={priceWithLemon}
                  icon={icons.price}
                  onChange={handlePriceWithLemonChange}
                  helperText={ticketTypeErrors.priceWithLemon}
                  showErrors={showErrors}
                />
                {price > 0 && (
                  <Typography className={classes.title} align="left" component="h1" variant="h5">
                    Porcentaje aplicado: {Math.floor((1 - priceWithLemon / price) * 100)} %
                  </Typography>
                )}
              </>
            )}
          </Paper>
          <Paper elevation={0} className={classes.paperContainer}>
            <TitledSwitch
              title="Venta exclusiva con Lemon"
              description="Habilita a este ticket como exclusiva en pre-venta o venta a todos los usuarios que paguen con Lemon Card"
              name="isLemonExclusive"
              checked={isLemonExclusive}
              icon={icons.lemon}
              onChange={handleIsLemonExclusive}
            />
            {isLemonExclusive && (
              <TitledInput
                title="Precio con Lemon"
                name="priceWithLemon"
                autocomplete="on"
                value={priceWithLemon}
                icon={icons.price}
                onChange={handlePriceWithLemonChange}
                helperText={ticketTypeErrors.priceWithLemon}
                showErrors={showErrors}
              />
            )}
          </Paper>
        </>
      )}
      {(isSuperAdmin || isAdmin) && (
        <Paper elevation={0} className={classes.paperContainer}>
          <TitledSwitch
            title="Código de descuento"
            name="hasDiscountCode"
            checked={hasDiscountCode}
            disabled={isAdmin && (hasLemonDiscount || isLemonExclusive)}
            icon={icons.ticket}
            onChange={handleHasDiscountCode}
          />
          {isAdmin && (hasLemonDiscount || isLemonExclusive) && (
            <Typography align="left">
              Este ticket posee un descuento ya aplicado con Lemon. Las promociones no son
              acumulables.
            </Typography>
          )}
          {hasDiscountCode && (
            <>
              <Typography align="left">
                Ingresá el código que tus clientes van a utilizar para recibir este beneficio.
                <br /> (Máximo 15 caracteres)
              </Typography>
              <div className={classes.discountCodeContainer}>
                <TitledInput
                  name="discountCode"
                  placeholder="Ingrese el código"
                  value={discountCode?.code}
                  className={classes.fullWidth}
                  onChange={handleDiscountCodeChange}
                  inputProps={{ maxLength: 15 }}
                />
                <Typography align="left">
                  Ingresá el porcentaje de descuento que van a obtener tus clientes al realizar la
                  compra.
                </Typography>
                <TitledInput
                  name="discountCodePercentage"
                  placeholder="Ingrese el porcentaje de descuento"
                  value={discountCode?.percentage}
                  type="number"
                  onChange={handleDiscountPercentageChange}
                  className={classes.fullWidth}
                  startAdornment="%"
                  max={99}
                  min={1}
                />
              </div>
            </>
          )}
        </Paper>
      )}
      <Paper elevation={0} className={classes.paperContainer}>
        <TitledRadioButtons
          title="Visualización del ticket"
          subtitle="Al hacerlo visible, se mostrará en la página del evento"
          name="visibilityMode"
          disabled={isInvitation}
          icon={icons.visibility}
          currentValue={visibilityMode}
          onChange={handleInputChange}
          values={ticketTypeVisibilityMode}
          helperText={ticketTypeErrors.visibilityMode}
          showErrors={showErrors}
        />
        <TitledRadioButtons
          title="Estado del ticket"
          subtitle="Al activarlo, se mostrará habilitado para su compra"
          name="activityStatus"
          currentValue={activityStatus}
          onChange={handleInputChange}
          values={ticketTypeActivityStatus}
          icon={icons.visibility}
          helperText={ticketTypeErrors.activityStatus}
          showErrors={showErrors}
        />
        {!isInvitation && (
          <div className={classes.ticketTypeSelectContainer}>
            <InputTitle title="Tipo de venta" icon={icons.ticket} />
            <Select
              className={classes.select}
              title="Tipo de venta"
              name="onlyOnline"
              disableUnderline
              value={onlyOnline}
              onChange={handleInputChange}
            >
              <MenuItem value>Solo Online</MenuItem>
              <MenuItem value={false}>Online + Cash</MenuItem>
            </Select>
          </div>
        )}
      </Paper>
      <Paper elevation={0} className={classes.paperContainer}>
        <div className={classes.iconTitle}>
          <img alt="icon" src={icons.image} className={classes.ImgIcon} />
          <Typography className={classes.title} align="left" component="h3" variant="h5">
            Imágenes del ticket
          </Typography>
        </div>
        <Typography align="left" className={classes.limit}>
          {`Cantidad máxima permitida: ${IMAGE_LIMIT} imágenes`}
        </Typography>
        <MultipleImages
          handleImgChange={handleImagesChange}
          images={images}
          limit={IMAGE_LIMIT}
          showNotification={showNotification}
        />
      </Paper>
      <AlertDialog
        open={openDeleteModal}
        title={`¿Seguro que quiere eliminar la colección: ${lemonCollectionId}?`}
        message="Al guardarse los cambios, está acción será definitiva y no podrá deshacerse."
        handleConfirm={deleteCollection}
        onClose={() => setOpenDeleteModal(false)}
      />
    </form>
  );
};

export default withStyles(styles)(EditTicketTypeForm);
