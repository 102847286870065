import React from 'react';
import { withStyles } from '@material-ui/core';
import { Box, Divider } from '@mui/material';
import styles from './LoginPageStyles';
import TextInput from '../../../Venti-UI-Kit/Inputs/TextInput';
import CustomLink from '../../../Components/CustomLink/CustomLink';
import TextButton from '../../../Components/Buttons/TextButton/TextButton';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import Button from '../../../Components/Buttons/Button/Button';
import ReCaptchaMessage from '../../../Components/ReCaptchaMessage/ReCaptchaMessage';
import PowerByVenti from '../../../Components/PowerByVenti/PowerByVenti';
import Alert from '../../../Venti-UI-Kit/Alert/Alert';
import GoogleReCaptcha from '../../../Components/GoogleReCaptcha/GoogleReCaptcha';
import RestorePasswordModal from './RestorePasswordModal';
import TermsAndConditionsModal from './TermsAndConditionsModal/TermsAndConditionsModal';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import { useLoginPage } from './LoginPage.hook';
import CompleteUserDataModal from './CompleteUserDataModal';

const LoginPage = ({ classes }) => {
  const {
    loginForm,
    openForgotPasswordModal,
    isLoginButtonDisabled,
    showLoginErrorAlert,
    openTermsAndConditionsModal,
    redirectUser,
    validateRecaptchaToken,
    handleLogin,
    handleCloseTermsAndConditionsModal,
    handleCloseForgotPasswordModal,
    showForgotPasswordModal,
    openCompleteUserDataModal,
    handleAcceptCompleteUserDataModal,
    handleCancelCompleteUserDataModal,
  } = useLoginPage();

  return (
    <PageContainer showLoginButton={false} title="Inicio de Sesión">
      <Box className={classes.homePage}>
        <Box gap={3} display="flex" flexDirection="column">
          <VTypography variant="h1">
            Ingresá <br /> a tu cuenta
          </VTypography>
          <Box display="flex" gap={2} flexDirection="column">
            <TextInput
              title={loginForm.formState.mail.title}
              onChange={loginForm.handleInputChange}
              errorMessage={loginForm.formState.mail.error}
              defaultValue={loginForm.formState.mail.value}
              type="email"
              name="mail"
              autoComplete="username"
            />
            <TextInput
              title={loginForm.formState.password.title}
              onChange={loginForm.handleInputChange}
              name="password"
              errorMessage={loginForm.formState.password.error}
              type="password"
              autoComplete="current-password"
            />
          </Box>
        </Box>

        {showLoginErrorAlert && (
          <Alert
            severity="error"
            message="El email o contraseña son incorrectos. Por favor, intentá de nuevo."
          />
        )}

        <Box>
          <Box gap={4} display="flex" flexDirection="column" marginBottom={4}>
            <GoogleReCaptcha mt={2} validation={validateRecaptchaToken} />
            <Button
              fullWidth
              title="Ingresar"
              onClick={handleLogin}
              disabled={isLoginButtonDisabled}
            />
            <VTypography variant="body2" align="center">
              <TextButton onClick={showForgotPasswordModal} title="¿Olvidaste tu contraseña?" />
            </VTypography>
            <VTypography variant="body2" align="center">
              ¿No tenés cuenta? <CustomLink to="/signup" title="Crear cuenta" />
            </VTypography>
          </Box>
          <Divider />
          <Box display="flex" flexDirection="column" gap={1.5} marginTop={1.5}>
            <PowerByVenti />
            <ReCaptchaMessage />
          </Box>
        </Box>
      </Box>
      <RestorePasswordModal
        open={openForgotPasswordModal}
        handleClose={handleCloseForgotPasswordModal}
      />
      <TermsAndConditionsModal
        open={openTermsAndConditionsModal}
        handleClose={handleCloseTermsAndConditionsModal}
        handleAccept={redirectUser}
      />
      <CompleteUserDataModal
        open={openCompleteUserDataModal}
        handleAccept={handleAcceptCompleteUserDataModal}
        handleCancel={handleCancelCompleteUserDataModal}
      />
    </PageContainer>
  );
};

export default withStyles(styles)(LoginPage);
