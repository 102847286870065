import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    termsCheckbox: {
      alignItems: 'center !important',
      display: 'flex !important',
      gap: 8,
      margin: '0 !important',

      '& .MuiFormControlLabel-label': {
        display: 'flex',

        '& p': {
          lineHeight: '18.77px ',
        },
      },

      '& .checkbox': {
        alignSelf: 'flex-start',
        padding: 0,

        '&.Mui-checked': {
          color: theme.palette.primary.main,
        },
      },
    },
  });

export default styles;
