const currencies = {
  USD: {
    centsDigits: 2,
  },
  ARS: {
    centsDigits: 2,
  },
};

class PriceProvider {
  getPriceFromOrder({ Payments }) {
    if (!Payments || Payments.length === 0) return '-';

    const currency = Payments[0]?.currency;

    const totalPrice = Payments.reduce(
      (accum, { isPaidWithLemon, priceInCentsWithLemon, priceInCents }) => {
        accum += isPaidWithLemon ? priceInCentsWithLemon : priceInCents;
        return accum;
      },
      0
    );

    return this.getPrice(totalPrice, currency);
  }

  getPrice(priceInCents, currency) {
    return priceInCents / 10 ** currencies[currency].centsDigits;
  }

  getPriceInCents(price, currency) {
    return price * 10 ** currencies[currency].centsDigits;
  }

  parsePriceString(priceString, currency) {
    const price = parseFloat(priceString);
    return parseInt(price * 10 ** currencies[currency].centsDigits);
  }

  replaceDecimalSymbol(price) {
    return price.toString().replace('.', ',');
  }

  getCurrencies() {
    return currencies;
  }

  getDecimalsByCurrency(currency) {
    return currencies[currency].centsDigits;
  }
}

const priceProvider = new PriceProvider();
export default priceProvider;
