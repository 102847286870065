import React from 'react';
import { Route } from 'react-router-dom';
import CustomComponent from './CustomComponent';

const CustomRoute = ({ rolePermission, exact = true, key, path, component, setTheme }) => {
  return (
    <Route
      key={key}
      exact={exact}
      path={path}
      component={({ match: { params }, location, ...props }) => (
        <CustomComponent
          rolePermission={rolePermission}
          Component={component}
          setTheme={setTheme}
          urlParams={params}
          pathState={location.state}
          {...props}
        />
      )}
    />
  );
};

export default CustomRoute;
