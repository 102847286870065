import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import OrderInfoCard from './OrderInfoCard/OrderInfoCard';
import OrderTicketsCard from './OrderTicketsCard/OrderTicketsCard';
import { handleRequestHelper } from '../../../utils/helpers';
import OrderOwnerInfoCard from './OrderOwnerInfoCard/OrderOwnerInfoCard';
import LoadingContainer from '../../../Components/LoadingContainer/LoadingContainer';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import OrderTitle from './OrderTitle/OrderTitle';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import api from '../../../api/api';

const OrderPage = ({ urlParams }) => {
  const { showNotification } = useNotifications();
  const [order, setOrder] = useState();
  const [isFetching, setIsFetching] = useState(true);
  const { orderId } = urlParams;

  const getOrder = async () => {
    setIsFetching(true);
    await handleRequestHelper({
      endpoint: () => api.getOrderById(orderId),
      showNotification,
      onSuccess: ({ order }) => setOrder(order),
    });
    setIsFetching(false);
  };

  useEffect(() => getOrder(), []);

  return (
    <PageContainer isLoading={isFetching} title={`Backoffice - Orden N°${order?.id}`}>
      <VBreadcrumbs pageTitle={`N° ${order?.id}`} />

      <Stack
        flexWrap={{ xs: 'nowrap', sm: 'wrap' }}
        direction={{ xs: 'column', sm: 'row' }}
        maxWidth={900}
        width="100%"
        gap={2}
        padding={2}
        paddingTop={0}
        justifyContent="center"
        alignSelf="center"
      >
        <LoadingContainer isLoading={!order}>
          <OrderTitle order={order} getOrder={getOrder} />
          <OrderInfoCard order={order} />
          <OrderOwnerInfoCard order={order} getOrder={getOrder} />
          <OrderTicketsCard order={order} />
        </LoadingContainer>
      </Stack>
    </PageContainer>
  );
};

export default OrderPage;
