import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Checkbox } from '@material-ui/core';
import { Stack } from '@mui/material';
import styles from './EditEventForm.styles';
import { getRadioHelperText } from '../../../Venti-UI-Kit/RadioButtons/Errors';
import {
  getTextHelperObligatoryField,
  getTextHelperText,
} from '../../../Venti-UI-Kit/Input/Errors';
import TitledRadioButtons from '../../../Venti-UI-Kit/RadioButtons/TitledRadioButtons';
import TitledInput from '../../../Venti-UI-Kit/Input/TitledInput';
import CustomTimePicker from '../../DatePickerComponents/CustomTimePicker';
import CustomDatePicker from '../../DatePickerComponents/CustomDatePicker';
import StadiumCustomizer from '../../Backoffice/StadiumCustomizer/StadiumCustomizer';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import VFormCard from '../../../Venti-UI-Kit/VForms/VFormCard/VFormCard';
import {
  BLACKLISTED,
  EVENT_STATUS_OPTIONS,
  NO_STADIUM_ID,
  NOT_BLACKLISTED,
  SELLER_BLACKLIST_STATUS,
} from './EditEventForm.constants';
import { useEditEventForm } from './EditEventForm.hook';
import VenueAutocomplete from '../../VenueAutocomplete/VenueAutocomplete';
import VImageUploader from '../../../Venti-UI-Kit/VImageUploader/VImageUploader';

const EditEventForm = ({
  classes,
  edition,
  event,
  producerStadiums,
  handleEventChange,
  onSubmit,
  showErrors,
  changeError,
}) => {
  const {
    handleInputChange,
    handleStadiumChange,
    handleSellersBlacklistChange,
    handleImgChange,
    handleDateChange,
    handleCheckBoxChange,
    producerStadiumOptions,
    checkInputError,
    user,
    venuesOn,
  } = useEditEventForm(event, producerStadiums, handleEventChange, changeError);

  return (
    <Stack gap={3} alignItems="center" maxWidth={990} component="form" onSubmit={onSubmit}>
      {!!producerStadiumOptions.length && (
        <VFormCard label="Planimetría" icon="stadium">
          <Stack gap={4}>
            <Select
              className={classes.select}
              name="producerStadiumId"
              value={event.stadium?.id || NO_STADIUM_ID}
              disableUnderline
              onChange={handleStadiumChange}
              disabled={edition}
            >
              {producerStadiumOptions.map(({ value, label }) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            {!!event.stadium?.id && (
              <StadiumCustomizer event={event} handleEventChange={handleEventChange} />
            )}
          </Stack>
        </VFormCard>
      )}

      <VFormCard label="Información general" icon="create">
        <VTypography align="left" variant="body1">
          Nombre
        </VTypography>
        <TitledInput
          name="name"
          autocomplete="off"
          value={event.name}
          autoFocus
          onChange={handleInputChange}
          helperText={checkInputError(event.name, 'name', getTextHelperText)}
          showErrors={showErrors}
        />
        <VTypography align="left" variant="body1">
          Descripción
        </VTypography>
        <TitledInput
          name="description"
          autocomplete="off"
          value={event.description}
          onChange={handleInputChange}
          multiline
          showErrors={showErrors}
        />
      </VFormCard>

      <VFormCard label="Ubicación" icon="place">
        {venuesOn ? (
          <VenueAutocomplete
            onSelectOption={handleEventChange}
            placeName={event.placeName}
            venue={event.venue}
            checkInputError={checkInputError}
            showErrors={showErrors}
          />
        ) : (
          <TitledInput
            name="placeName"
            autocomplete="off"
            value={event.placeName}
            onChange={handleInputChange}
            showErrors={showErrors}
            helperText={checkInputError(event.placeName, 'placeName', getTextHelperObligatoryField)}
          />
        )}
      </VFormCard>

      <Stack width="100%" gap={{ xs: 3, md: 2 }} flexDirection={{ xs: 'column', md: 'row' }}>
        <VFormCard label="Visualización del Evento" icon="visibility">
          <VTypography align="left" variant="body1">
            Tipo de evento
          </VTypography>
          <VTypography align="left" variant="body2">
            Esta opción habilita que tu evento se muestre en el inicio. Luego podrás editar esta
            decisión.
          </VTypography>
          <TitledRadioButtons
            name="status"
            currentValue={event.status}
            onChange={handleInputChange}
            values={EVENT_STATUS_OPTIONS}
            helperText={checkInputError(event.status, 'status', getRadioHelperText)}
            showErrors={showErrors}
            row
          />
          <VTypography align="left" variant="body1">
            Oculto para sellers
          </VTypography>
          <VTypography align="left" variant="body2">
            Esta opción oculta el evento a todos los sellers hasta que sea desactivada desde la
            pantalla de edición del evento. Los administradores podrán visualizar el evento
            normalmente.
          </VTypography>
          <TitledRadioButtons
            name="shouldBlacklistSellers"
            currentValue={event.shouldBlacklistSellers ? NOT_BLACKLISTED : BLACKLISTED}
            onChange={handleSellersBlacklistChange}
            values={SELLER_BLACKLIST_STATUS}
            row
          />
        </VFormCard>

        <VFormCard label="Fecha y hora" icon="schedule">
          <Stack>
            <VTypography align="left" variant="body1">
              Inicio del evento
            </VTypography>
            <Stack flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
              <Stack>
                <CustomDatePicker
                  value={event.startDate}
                  onChange={handleDateChange('startDate')}
                  helperText={checkInputError(
                    event.startDate,
                    'startDate',
                    getTextHelperObligatoryField
                  )}
                  showErrors={showErrors}
                />
              </Stack>
              <Stack>
                <CustomTimePicker
                  value={event.startDate}
                  onChange={handleDateChange('startDate')}
                  helperText={checkInputError(
                    event.startDate,
                    'startDate',
                    getTextHelperObligatoryField
                  )}
                  showErrors={showErrors}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <VTypography align="left" variant="body1">
              Finalización del evento
            </VTypography>
            <Stack flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
              <Stack>
                <CustomDatePicker
                  value={event.endDate}
                  minDate={event.startDate}
                  onChange={handleDateChange('endDate')}
                  helperText={checkInputError(
                    event.endDate,
                    'endDate',
                    getTextHelperObligatoryField
                  )}
                  showErrors={showErrors}
                />
              </Stack>
              <Stack>
                <CustomTimePicker
                  value={event.endDate}
                  minDate={event.startDate}
                  onChange={handleDateChange('endDate')}
                  helperText={checkInputError(
                    event.endDate,
                    'endDate',
                    getTextHelperObligatoryField
                  )}
                  showErrors={showErrors}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack gap={1}>
            <VTypography align="left" variant="body1">
              Corte de venta de tickets
            </VTypography>
            <VTypography align="left" variant="body2">
              Seleccioná el momento en el que finalizará la venta de tickets y envío de accesos.
            </VTypography>
            <Stack flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
              <Stack>
                <CustomDatePicker
                  value={event.defaultStopOfSalesDate || null}
                  onChange={handleDateChange('defaultStopOfSalesDate')}
                  ampm={false}
                  helperText={
                    !edition &&
                    checkInputError(
                      event.defaultStopOfSalesDate,
                      'defaultStopOfSalesDate',
                      getTextHelperObligatoryField
                    )
                  }
                  showErrors={!edition && showErrors}
                />
              </Stack>
              <Stack>
                <CustomTimePicker
                  value={event.defaultStopOfSalesDate || null}
                  onChange={handleDateChange('defaultStopOfSalesDate')}
                  helperText={
                    !edition &&
                    checkInputError(
                      event.defaultStopOfSalesDate,
                      'defaultStopOfSalesDate',
                      getTextHelperObligatoryField
                    )
                  }
                  showErrors={!edition && showErrors}
                />
              </Stack>
            </Stack>
            <Stack flexDirection="row" alignItems="center">
              <Checkbox
                checked={event.shouldExtendStopOfSalesDateToInvitations}
                onChange={() =>
                  handleCheckBoxChange(
                    'shouldExtendStopOfSalesDateToInvitations',
                    !event.shouldExtendStopOfSalesDateToInvitations
                  )
                }
                color="primary"
              />
              <VTypography variant="body2">
                Cortar envío de invitaciones. (Se recomienda marcar esta opción siempre que se haga
                uso de molinetes)
              </VTypography>
            </Stack>
          </Stack>
        </VFormCard>
      </Stack>

      <VFormCard label="Imágenes" icon="photo">
        <Stack flexDirection={{ xs: 'column', md: 'row' }} gap={2} justifyContent="center">
          <VImageUploader
            width="100%"
            imageHeight={1000}
            imageWidth={2600}
            initialImageSrc={event.bannerImg}
            onChange={(url) => handleImgChange(url, 'bannerImg')}
          />
          <VImageUploader
            width={350}
            imageHeight={1250}
            imageWidth={1000}
            initialImageSrc={event.promoImg}
            onChange={(url) => handleImgChange(url, 'promoImg')}
          />
        </Stack>
      </VFormCard>

      <VFormCard label="Productora asociada" icon="create">
        <Select
          className={classes.select}
          id="producerId"
          name="producerId"
          value={event.producerId}
          defaultValue={user?.producers?.[0]?.id}
          onChange={handleInputChange}
          disableUnderline
        >
          {user.producers.map((producer) => {
            return (
              <MenuItem key={producer.id} value={producer.id}>
                {producer.name}
              </MenuItem>
            );
          })}
        </Select>
      </VFormCard>
    </Stack>
  );
};

export default withStyles(styles)(EditEventForm);
