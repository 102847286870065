import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    container: {
      height: 10,
      padding: 0,
    },
  });

export default styles;
