import React, { useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { Stack } from '@mui/material';
import EditTicketTypeForm from '../../../Components/Forms/EditTicketType/EditTicketTypeForm';
import Button from '../../../Venti-UI-Kit/Buttons/Button';
import priceProvider from '../../../Providers/Price/Price';
import api from '../../../api/api';
import {
  REQUEST_RESPONSE,
  DISCOUNT_CODES_STATUS,
  CURRENCY,
  NOTIFICATION_VARIANT,
} from '../../../constants/types';
import styles from './EditTicketType.styles';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import { useTicketType } from './useTicketType';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { handleRequestHelper } from '../../../utils/helpers';

const EditTicketType = ({ classes, urlParams }) => {
  const { showNotification } = useNotifications();
  const { ticketId, producerId, eventId } = urlParams;

  const ticketTypeInitialState = {
    name: '',
    price: '',
    priceWithLemon: '',
    isLemonExclusive: false,
    maxQuantity: '',
    description: '',
    visibilityMode: '',
    activityStatus: '',
    onlyOnline: true,
    type: '',
    maxQuantityPerOrder: '',
    groupSize: '',
    images: [],
    eventId: '',
    hasLemonDiscount: false,
    stopOfSalesDate: null,
    stopOfAccessDate: null,
  };

  const {
    ticketType,
    setTicketType,
    validateTicketType,
    validateTicketTypeSection,
    handleErrors,
    showErrors,
    ticketTypeErrors,
  } = useTicketType(ticketTypeInitialState);

  const [redirect, setRedirect] = useState(false);
  const [invalidID, setInvalidId] = useState(false);
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState();
  const [hasDiscountCode, setHasDiscountCode] = useState(false);
  const [discountCode, setDiscountCode] = useState();
  const [initialDiscountCode, setInitialDiscountCode] = useState();
  const [hasStopOfAccessDate, setHasStopOfAccessDate] = useState();

  const eventStadiumSections = useRef(null);

  const getEvent = async (id) => {
    try {
      const { event, status, esmessage } = await api.getEvent(id);

      if (status !== REQUEST_RESPONSE.SUCCESS) {
        showNotification(esmessage, NOTIFICATION_VARIANT.ERROR);
        return;
      }

      const { stadium } = await api.getEventStadium(event.id);
      if (stadium?.sections) {
        eventStadiumSections.current = stadium.sections;
      }

      setEvent(event);
    } catch (error) {
      console.error(error);
    }
  };

  const getDiscountCode = async (ticketTypeId) => {
    handleRequestHelper({
      endpoint: () => api.getDiscountCode(ticketTypeId),
      showNotification,
      onSuccess: ({ discountCodes }) => {
        if (!discountCodes || !discountCodes.length) return;

        const discountCodeData = discountCodes.find(
          ({ status }) => status === DISCOUNT_CODES_STATUS.ACTIVE
        );

        if (!discountCodeData) return;
        const { code, percentage } = discountCodeData;

        setHasDiscountCode(true);
        setInitialDiscountCode({ code, percentage: percentage.toString() });
        setDiscountCode({ code, percentage: percentage.toString() });
      },
    });
  };

  const getTicketTypeToEditAndEvent = async () => {
    try {
      const {
        ticketType: { TicketTypeCollection, ...ticketTypeData },
      } = await api.getTicketType(ticketId);

      const price = priceProvider.getPrice(ticketTypeData.priceInCents, CURRENCY.ARS);
      const priceWithLemon = priceProvider.getPrice(
        ticketTypeData.priceInCentsWithLemon,
        CURRENCY.ARS
      );
      const lemonCollectionId = TicketTypeCollection?.LemonCollection?.lemonCollectionId || '';
      ticketTypeData.images = ticketTypeData.Images;
      const { maxQuantity, maxQuantityPerOrder, stopOfAccessDate } = ticketTypeData;
      delete ticketTypeData.Images;

      await getEvent(ticketTypeData.eventId);
      await getDiscountCode(ticketTypeData.id);
      setHasStopOfAccessDate(stopOfAccessDate);

      const hasLemonDiscount = priceWithLemon !== price && !ticketTypeData.isLemonExclusive;
      const ticketSection = eventStadiumSections.current?.find(({ ticketTypes }) =>
        ticketTypes.some(({ id }) => id === ticketTypeData.id)
      );

      setTicketType({
        ...ticketTypeData,
        price: price.toString(),
        priceWithLemon: priceWithLemon.toString(),
        maxQuantity: maxQuantity.toString(),
        maxQuantityPerOrder: maxQuantityPerOrder.toString(),
        lemonCollectionId,
        hasLemonDiscount,
        section: ticketSection,
      });
    } catch (err) {
      setInvalidId(true);
      console.error(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTicketTypeToEditAndEvent();
  }, []);

  useEffect(() => {
    handleErrors('priceWithLemon', ticketType.priceWithLemon);
  }, [ticketType.priceWithLemon]);

  const handleHasLemonDiscount = ({ target }) => {
    const { checked } = target;

    setHasDiscountCode(false);
    setTicketType((prevState) => ({
      ...prevState,
      isLemonExclusive: false,
      hasLemonDiscount: checked,
      priceWithLemon: !checked ? ticketType.price : prevState.priceWithLemon,
    }));
  };

  const handleHasStopOfAccessDate = ({ target }) => {
    const { checked } = target;
    setHasStopOfAccessDate(checked);
    if (!checked) setTicketType((prevState) => ({ ...prevState, stopOfAccessDate: null }));
  };

  const handleIsLemonExclusive = ({ target }) => {
    const { checked } = target;

    setHasDiscountCode(false);
    setTicketType((prevState) => ({
      ...prevState,
      isLemonExclusive: checked,
      hasLemonDiscount: false,
      priceWithLemon: !checked ? ticketType.price : prevState.priceWithLemon,
    }));
  };

  const handleHasDiscountCode = ({ target }) => {
    const { checked } = target;

    setHasDiscountCode(checked);
    setTicketType((prevState) => ({
      ...prevState,
      isLemonExclusive: false,
      hasLemonDiscount: false,
    }));
  };

  const handleDeleteCollection = async () => {
    setTicketType((prevState) => ({ ...prevState, lemonCollectionId: '' }));
  };

  const handleInputChange = (event) => {
    const { value, name } = event.target;
    handleErrors(name, value);
    setTicketType({ ...ticketType, [name]: value });
  };

  const handlePriceWithLemonChange = (event) => {
    const { name, value } = event.target;

    handleErrors(name, value);
    setTicketType({ ...ticketType, [name]: value });
  };

  const handleSectionChange = ({ target }) => {
    const { value: sectionId } = target;
    const section = eventStadiumSections.current.find(({ id }) => id === sectionId);
    setTicketType({ ...ticketType, section });
  };

  const handleImagesChange = (images) => {
    setTicketType({ ...ticketType, images });
  };

  const formatImagesPriority = (images) => {
    return images.map((image, index) => ({ ...image, priority: index + 1 }));
  };

  const formatTicketTypeInCents = () => {
    const priceInCents = priceProvider.getPriceInCents(Number(ticketType.price), CURRENCY.ARS);
    const priceInCentsWithLemon = priceProvider.getPriceInCents(
      Number(ticketType.priceWithLemon),
      CURRENCY.ARS
    );
    return {
      ...ticketType,
      priceInCents,
      priceInCentsWithLemon,
    };
  };

  const updateTicketType = async (ticketTypeData) => {
    const { status, esmessage } = await api.updateTicketType(ticketTypeData);

    if (status !== REQUEST_RESPONSE.SUCCESS) {
      showNotification(
        esmessage || 'Ocurrió un error al actualizar el ticket',
        NOTIFICATION_VARIANT.ERROR
      );

      throw new Error(esmessage);
    }
  };

  const deleteDiscountCode = async (code) =>
    handleRequestHelper({
      endpoint: () => api.deleteDiscountCode(ticketId, code),
      onSuccess: () => true,
      onFailure: () => false,
      showNotification,
    });

  const createDiscountCode = async () =>
    handleRequestHelper({
      endpoint: () => api.createDiscountCode(ticketId, discountCode),
      onSuccess: () => true,
      onFailure: () => false,
      showNotification,
    });

  const updateDiscountCode = async () => {
    const { code: initialCode, percentage: initialPercentage } = initialDiscountCode || {};
    const { code, percentage } = discountCode || {};

    let canUpdate = true;
    const hasToDeleteCode = initialCode && (!hasDiscountCode || code !== initialCode);
    const hasToCreateCode = hasDiscountCode && code && code !== initialCode;

    if (code === initialCode && percentage !== initialPercentage) {
      showNotification(
        'El porcentaje del código no puede ser modificado',
        NOTIFICATION_VARIANT.ERROR
      );
      return false;
    }

    try {
      if (hasToDeleteCode) canUpdate = await deleteDiscountCode(initialCode);
      if (hasToCreateCode && canUpdate) canUpdate = await createDiscountCode();
    } catch (error) {
      console.error(error);
      canUpdate = false;
    }
    return canUpdate;
  };

  const uploadTicketType = async () => {
    try {
      const isValid = validateTicketType();
      const sectionIsValid = validateTicketTypeSection(!!eventStadiumSections.current);

      if (!isValid || !sectionIsValid) return;

      const discountCodeUpdateSuccessfully = await updateDiscountCode();
      if (!discountCodeUpdateSuccessfully) return;

      const imagesWithPriority = formatImagesPriority(ticketType.images);
      const ticketTypeToCreate = formatTicketTypeInCents();
      const {
        hasLemonDiscount,
        isLemonExclusive,
        priceInCentsWithLemon: priceWithLemon,
        priceInCents,
      } = ticketTypeToCreate;
      const priceInCentsWithLemon =
        hasLemonDiscount || isLemonExclusive ? priceWithLemon : priceInCents;

      await updateTicketType({
        ...ticketTypeToCreate,
        images: imagesWithPriority,
        priceInCentsWithLemon,
      });

      showNotification(
        'Se actualizó el ticket correctamente! Redirgiendo...',
        NOTIFICATION_VARIANT.SUCCESS
      );

      setTimeout(() => setRedirect(true), 2000);
    } catch (err) {
      console.error(err);
    }
  };

  const handleStopOfSalesDateChange = (value) => {
    setTicketType({ ...ticketType, stopOfSalesDate: value });
  };

  const handleStopOfAccessDateChange = (value) => {
    setTicketType({ ...ticketType, stopOfAccessDate: value });
  };

  const handleDiscountCodeChange = ({ target }) => {
    setDiscountCode((prevState) => ({ ...prevState, code: target.value }));
  };

  const handleDiscountPercentageChange = ({ target }) => {
    const percentage = target.value.toString();
    setDiscountCode((prevState) => ({ ...prevState, percentage }));
  };

  if (redirect)
    return <Redirect to={`/backoffice/productoras/${producerId}/eventos/${eventId}/tickets`} />;

  if (invalidID) return <Redirect to="/error/404" />;

  return (
    <PageContainer title="Backoffice - Editar Ticket">
      <VBreadcrumbs pageTitle="Editar" />
      <Stack alignItems="center" gap={3} px={2}>
        <Typography className={classes.title} variant="h4">
          Editar Ticket
        </Typography>

        {loading ? (
          <CircularProgress size={30} />
        ) : (
          <>
            <EditTicketTypeForm
              handleInputChange={handleInputChange}
              handleImagesChange={handleImagesChange}
              handleHasLemonDiscount={handleHasLemonDiscount}
              handleIsLemonExclusive={handleIsLemonExclusive}
              handlePriceWithLemonChange={handlePriceWithLemonChange}
              handleStopOfSalesDateChange={handleStopOfSalesDateChange}
              handleStopOfAccessDateChange={handleStopOfAccessDateChange}
              handleDeleteCollection={handleDeleteCollection}
              handleHasDiscountCode={handleHasDiscountCode}
              handleDiscountCodeChange={handleDiscountCodeChange}
              handleDiscountPercentageChange={handleDiscountPercentageChange}
              handleHasStopOfAccessDate={handleHasStopOfAccessDate}
              handleSectionChange={handleSectionChange}
              hasStopOfAccessDate={hasStopOfAccessDate}
              hasDiscountCode={hasDiscountCode}
              discountCode={discountCode}
              ticketType={ticketType}
              showErrors={showErrors}
              ticketTypeErrors={ticketTypeErrors.current}
              edition
              event={event}
              stadiumSections={eventStadiumSections.current}
            />

            <Button
              type="submit"
              color="main"
              onClick={uploadTicketType}
              className={classes.submit}
            >
              Guardar
            </Button>
          </>
        )}
      </Stack>
    </PageContainer>
  );
};

export default withStyles(styles)(EditTicketType);
