import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import styles from './ProducerUserCard.styles';
import AlertDialog from '../../../Venti-UI-Kit/AlertDialog/AlertDialog';
import permissions from '../../../Providers/Permissions/Permissions';
import history from '../../../appHistory';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';

const ProducerUserCard = ({ producerId, producerUser, classes, onConfirm }) => {
  const { user } = useAuth();
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);

  const handleEditUser = () => {
    history.push(`/backoffice/productoras/${producerId}/usuarios/${producerUser.id}`);
  };

  const handleDeleteUser = () => {
    setOpenDeleteConfirmModal(true);
  };

  const handleCloseModal = () => {
    setOpenDeleteConfirmModal(false);
  };

  const handleConfirm = () => {
    handleCloseModal();
    onConfirm(producerUser, producerId);
  };

  return (
    <Card elevation={0} className={classes.cardContainer}>
      <div className={classes.cardContent}>
        <div className={classes.userInfoContainer}>
          <Typography className={classes.cardTitle}>{producerUser.alias}</Typography>
          <div className={classes.userInfoContent}>
            <Typography className={classes.cardTextInfo}>
              <Typography className={classes.cardSubtitle} component="span">
                Email:
              </Typography>
              {producerUser.mail}
            </Typography>
            <Typography className={classes.cardTextInfo}>
              <Typography className={classes.cardSubtitle} component="span">
                Rol:
              </Typography>
              {producerUser.role}
            </Typography>
          </div>
        </div>
        <div className={classes.buttonsContainer}>
          <IconButton
            onClick={handleEditUser}
            key={`${producerUser.id}editButton`}
            className={classes.iconButton}
          >
            <Edit />
          </IconButton>
          {user.id !== producerUser.id && permissions.isAdmin(user.role) && (
            <IconButton
              onClick={handleDeleteUser}
              key={`${producerUser.id}deleteButton`}
              className={classes.iconButton}
            >
              <Delete />
            </IconButton>
          )}
        </div>
      </div>
      {openDeleteConfirmModal && (
        <AlertDialog
          open={openDeleteConfirmModal}
          title={`¿Seguro que quiere eliminar a ${producerUser.alias}?`}
          handleConfirm={handleConfirm}
          onClose={handleCloseModal}
        />
      )}
    </Card>
  );
};

export default withStyles(styles)(ProducerUserCard);
