import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    orderCardContainer: {
      backgroundColor: '#FFFFFF',
      padding: 16,
      justifyContent: 'space-between',
      borderRadius: 8,
      alignItems: 'center',
      width: '100%',
      color: theme.palette.grey[700],
    },
    orderCardItemContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      width: '100%',
    },
    statusChip: {
      color: '#2cb80f',
      backgroundColor: '#E4FBF1',
      height: 'fit-content',
      borderRadius: 100,
      padding: '4px 8px',
      width: 'fit-content',
      maxWidth: 'fit-content',
      textTransform: 'uppercase',
    },
    totalPriceChip: {
      backgroundColor: theme.palette.grey[200],
      padding: '4px 8px',
      borderRadius: 100,
      color: theme.palette.grey[700],
      maxWidth: 'fit-content',
    },
  });

export default styles;
