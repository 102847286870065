import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    container: {
      justifyContent: 'center',
      alignSelf: 'center',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 24,
    },
    infoGroupContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    input: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      flex: 2,
    },
    discountCodeContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      rowGap: 20,
    },
    paperContainer: {
      borderRadius: 8,
      backgroundColor: theme.palette.grey[50],
      width: '100%',
      maxWidth: 900,
      display: 'flex',
      gap: 24,
      flexDirection: 'column',
      padding: 16,
    },
    nftSectionContainer: {
      rowGap: 0,
    },
    nftInput: {
      display: 'flex',
      columnGap: 20,
    },
    nftItemText: {
      fontWeight: 300,
      textAlign: 'left',
      flex: 2,
    },
    nftCollectionItem: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        rowGap: 20,
        flexDirection: 'column',
      },
    },
    deleteButton: {
      backgroundColor: theme.palette.error.main,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    dateTimeContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    stopOfAccessDateContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 15,
    },
    doubleInputContainer: {
      display: 'flex',
      columnGap: 20,
      [theme.breakpoints.down('xs')]: {
        rowGap: 20,
        flexDirection: 'column',
      },
    },
    description: {
      textAlign: 'start',
      paddingLeft: 30,
    },
    dateInputContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    timeInputContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    dateTimeInputTitle: {
      alignSelf: 'start',
      paddingLeft: 10,
    },
    title: {
      width: '90%',
      fontWeight: '500',
      padding: 10,
      fontSize: '120%',
    },
    ImgIcon: {
      display: 'flex',
      alignItems: 'left',
      width: '1em',
      height: '1em',
    },
    iconTitle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
    },
    limit: {
      margin: 5,
      marginLeft: 15,
    },
    ticketTypeSelectContainer: {
      width: '100%',
    },
    select: {
      backgroundColor: theme.palette.grey[200],
      width: '100%',
      borderRadius: 10,
      padding: '5px 10px',
      height: 40,
      '& .MuiInput-input': {
        padding: '6px 0 7px',
      },
    },
    fullWidth: {
      width: '100%',
    },
    hasStopOfAccessDateContainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
    },
  });

export default styles;
