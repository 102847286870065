import { useTheme } from '@material-ui/core';
import { useState, useEffect } from 'react';

const useWindowDimensions = () => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const theme = useTheme();
  const { md, lg } = theme.breakpoints.values;

  const getDevice = () => {
    const { innerWidth: width } = window;
    return {
      isMobile: width <= md,
      isTablet: width > md && width <= lg,
      isDesktop: width > lg,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [device, setDevice] = useState(getDevice());

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
    setDevice(getDevice());
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { ...windowDimensions, ...device };
};

export default useWindowDimensions;
