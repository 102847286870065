import React, { useState } from 'react';
import { Stack } from '@mui/material';
import BottomSheet from '../../../Venti-UI-Kit/BottomSheet/BottomSheet';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import Button from '../../Buttons/Button/Button';
import { handleRequestHelper } from '../../../utils/helpers';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import api from '../../../api/api';

const CancelOrderBottomSheet = ({ open, onClose, orderId, getOrder }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showNotification } = useNotifications();

  const handleCancelOrder = async () => {
    setIsLoading(true);
    await handleRequestHelper({
      endpoint: () => api.cancelOrder(orderId),
      showNotification,
      onSuccess: () => {
        getOrder();
        showNotification('La orden fue inhabilitada con éxito.', 'success');
      },
    });
    onClose();
    setIsLoading(false);
  };

  return (
    <BottomSheet open={open} onClose={onClose}>
      <Stack padding="16px" gap={2.5}>
        <Stack gap={1} paddingBottom="32px">
          <VTypography variant="h4">Estás por inhabilitar la orden N°{orderId}</VTypography>
          <VTypography variant="body2">
            Al continuar, la orden pasará a estado inhabilitado al igual que los tickets.
          </VTypography>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={1}>
          <Button size="small" fullwidth variant="outlined" title="Volver" onClick={onClose} />
          <Button
            size="small"
            fullwidth
            title="Inhabilitar"
            color="error"
            onClick={handleCancelOrder}
            loading={isLoading}
          />
        </Stack>
      </Stack>
    </BottomSheet>
  );
};

export default CancelOrderBottomSheet;
