import React, { useEffect, useState, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import EditTicketTypeForm from '../../../Components/Forms/EditTicketType/EditTicketTypeForm';
import Button from '../../../Venti-UI-Kit/Buttons/Button';
import api from '../../../api/api';
import permissions from '../../../Providers/Permissions/Permissions';
import priceProvider from '../../../Providers/Price/Price';
import {
  REQUEST_RESPONSE,
  TICKET_ACTIVITY_STATUS,
  TICKET_VISIBILITY_MODE,
  TICKET_TYPE,
  CURRENCY,
  NOTIFICATION_VARIANT,
} from '../../../constants/types';
import styles from './NewTicketType.styles';
import AlertDialog from '../../../Venti-UI-Kit/AlertDialog/AlertDialog';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import { useTicketType } from './useTicketType';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';
import { validateUserRole } from '../../../utils/utils';
import { handleRequestHelper } from '../../../utils/helpers';

const INVITATIONS_MAX_QUANTITY_PER_ORDER = '20';

const NewTicketType = ({ classes, urlParams }) => {
  const { user } = useAuth();
  const { showNotification } = useNotifications();
  const { producerId, eventId } = urlParams;

  const ticketTypeInitialState = {
    name: '',
    price: '0',
    priceWithLemon: '0',
    isLemonExclusive: false,
    maxQuantity: '',
    description: '',
    visibilityMode: TICKET_VISIBILITY_MODE.VISIBLE,
    activityStatus: TICKET_ACTIVITY_STATUS.ACTIVE,
    onlyOnline: true,
    type: TICKET_TYPE.PRESENTIAL,
    maxQuantityPerOrder: '1',
    groupSize: '',
    images: [],
    eventId,
    hasLemonDiscount: false,
    stopOfSalesDate: null,
    lemonCollectionId: '',
    stopOfAccessDate: null,
    section: null,
  };

  const {
    ticketType,
    setTicketType,
    validateTicketType,
    validateTicketTypeSection,
    handleErrors,
    showErrors,
    ticketTypeErrors,
  } = useTicketType(ticketTypeInitialState);

  const [redirect, setRedirect] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState();
  const [hasDiscountCode, setHasDiscountCode] = useState(false);
  const [discountCode, setDiscountCode] = useState({ code: '', percentage: 0 });
  const [hasStopOfAccessDate, setHasStopOfAccessDate] = useState(false);

  const eventStadiumSections = useRef(null);

  const getEvent = async () => {
    try {
      const { event, status, esmessage } = await api.getEvent(eventId);

      if (status !== REQUEST_RESPONSE.SUCCESS) {
        showNotification(esmessage, NOTIFICATION_VARIANT.ERROR);
        setLoading(false);
        return;
      }

      const { stadium } = await api.getEventStadium(eventId);
      if (stadium?.sections) {
        eventStadiumSections.current = stadium.sections;
      }

      setEvent(event);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getEvent();
  }, []);

  useEffect(() => {
    handleErrors('priceWithLemon', ticketType.priceWithLemon);
  }, [ticketType.priceWithLemon]);

  const handleHasLemonDiscount = ({ target }) => {
    const { checked } = target;

    setHasDiscountCode(false);
    setTicketType((prevState) => ({
      ...prevState,
      isLemonExclusive: false,
      hasLemonDiscount: checked,
      priceWithLemon: !checked ? ticketType.price : prevState.priceWithLemon,
    }));
  };

  const handleIsLemonExclusive = ({ target }) => {
    const { checked } = target;

    setHasDiscountCode(false);
    setTicketType((prevState) => ({
      ...prevState,
      isLemonExclusive: checked,
      hasLemonDiscount: false,
      priceWithLemon: !checked ? ticketType.price : prevState.priceWithLemon,
    }));
  };

  const handleHasDiscountCode = ({ target }) => {
    const { checked } = target;

    setHasDiscountCode(checked);
    setTicketType((prevState) => ({
      ...prevState,
      isLemonExclusive: false,
      hasLemonDiscount: false,
      priceWithLemon: ticketType.price,
    }));
  };

  const handleInputChange = (event) => {
    const { value, name } = event.target;
    handleErrors(name, value);
    setTicketType({ ...ticketType, [name]: value });
  };

  const handlePriceChange = (event) => {
    const { value } = event.target;
    handleErrors('price', value);

    if (
      ticketType.priceWithLemon === null ||
      (!ticketType.hasLemonDiscount && !ticketType.isLemonExclusive) ||
      !validateUserRole(user.role, permissions.ROLES.SUPER_ADMIN)
    ) {
      handleErrors('priceWithLemon', value);
      setTicketType({ ...ticketType, price: value, priceWithLemon: value });
      return;
    }

    setTicketType({ ...ticketType, price: value });
  };

  const handlePriceWithLemonChange = (event) => {
    const { name, value } = event.target;

    handleErrors(name, value);
    setTicketType({ ...ticketType, [name]: value });
  };

  const handleSectionChange = ({ target }) => {
    const { value: sectionId } = target;
    const section = eventStadiumSections.current.find(({ id }) => id === sectionId);
    setTicketType({ ...ticketType, section });
  };

  const handleImagesChange = (images) => {
    setTicketType({ ...ticketType, images });
  };

  const formatImagesPriority = (images) => {
    return images.map((image, index) => ({ ...image, priority: index + 1 }));
  };

  const formatTicketTypeInCents = () => {
    const priceInCents = priceProvider.getPriceInCents(Number(ticketType.price), CURRENCY.ARS);
    const priceInCentsWithLemon = priceProvider.getPriceInCents(
      Number(ticketType.priceWithLemon),
      CURRENCY.ARS
    );
    return {
      ...ticketType,
      priceInCents,
      priceInCentsWithLemon,
    };
  };

  const setMaxQuantityPerOrder = (ticketTypeToCreate) => {
    return ticketTypeToCreate.type !== TICKET_TYPE.INVITATION
      ? ticketTypeToCreate
      : {
          ...ticketTypeToCreate,
          maxQuantityPerOrder: INVITATIONS_MAX_QUANTITY_PER_ORDER,
        };
  };

  const createDiscountCode = async (ticketTypeId) => {
    if (!hasDiscountCode) return;
    return handleRequestHelper({
      endpoint: () => api.createDiscountCode(ticketTypeId, discountCode),
      onSuccess: () => true,
      onFailure: () => false,
      showNotification,
    });
  };

  const createTicketType = async () => {
    try {
      const isValid = validateTicketType();
      const sectionIsValid = validateTicketTypeSection(!!eventStadiumSections.current);

      if (!isValid || !sectionIsValid) {
        setOpenModal(false);
        return;
      }
      const imagesWithPriority = formatImagesPriority(ticketType.images);
      let ticketTypeToCreate = formatTicketTypeInCents();

      ticketTypeToCreate = setMaxQuantityPerOrder(ticketTypeToCreate);

      ticketTypeToCreate.eventStadiumLayoutSectionId = ticketTypeToCreate.section?.id;
      delete ticketTypeToCreate.section;

      const {
        status,
        esmessage,
        ticketType: ticketTypeResponse,
      } = await api.createTicketType({
        ...ticketTypeToCreate,
        images: imagesWithPriority,
      });

      if (status !== REQUEST_RESPONSE.SUCCESS) {
        setOpenModal(false);

        showNotification(
          esmessage || 'Ocurrió un error al crear el ticket',
          NOTIFICATION_VARIANT.ERROR
        );
        return;
      }

      if (hasDiscountCode) {
        const discountCodeWasCreated = await createDiscountCode(ticketTypeResponse.id);
        if (!discountCodeWasCreated) {
          setOpenModal(false);
          return;
        }
      }

      showNotification('Se creó el tipo de ticket! Redirgiendo...', NOTIFICATION_VARIANT.SUCCESS);
      setTimeout(() => setRedirect(true), 2000);
    } catch (err) {
      console.error(err);
    }
    setOpenModal(false);
  };

  const handleStopOfSalesDateChange = (value) => {
    setTicketType({ ...ticketType, stopOfSalesDate: value });
  };

  const handleStopOfAccessDateChange = (value) => {
    setTicketType({ ...ticketType, stopOfAccessDate: value });
  };

  const handleHasStopOfAccessDate = ({ target }) => {
    const { checked } = target;
    setHasStopOfAccessDate(checked);
    if (!checked) setTicketType((prevState) => ({ ...prevState, stopOfAccessDate: null }));
  };

  const handleDiscountCodeChange = ({ target }) => {
    setDiscountCode((prevState) => ({ ...prevState, code: target.value }));
  };

  const handleDiscountPercentageChange = ({ target }) => {
    const percentage = target.value;
    setDiscountCode((prevState) => ({ ...prevState, percentage }));
  };

  if (redirect)
    return <Redirect to={`/backoffice/productoras/${producerId}/eventos/${eventId}/tickets`} />;

  return (
    <PageContainer title="Backoffice - Crear Ticket">
      <VBreadcrumbs pageTitle="Crear" />
      <Typography className={classes.title} variant="h4">
        Crear Ticket
      </Typography>

      {loading ? (
        <CircularProgress size={30} />
      ) : (
        <>
          <EditTicketTypeForm
            handleInputChange={handleInputChange}
            handlePriceChange={handlePriceChange}
            handleImagesChange={handleImagesChange}
            handleHasLemonDiscount={handleHasLemonDiscount}
            handleIsLemonExclusive={handleIsLemonExclusive}
            handlePriceWithLemonChange={handlePriceWithLemonChange}
            handleStopOfSalesDateChange={handleStopOfSalesDateChange}
            handleStopOfAccessDateChange={handleStopOfAccessDateChange}
            handleHasDiscountCode={handleHasDiscountCode}
            handleDiscountCodeChange={handleDiscountCodeChange}
            handleDiscountPercentageChange={handleDiscountPercentageChange}
            hasStopOfAccessDate={hasStopOfAccessDate}
            handleHasStopOfAccessDate={handleHasStopOfAccessDate}
            handleSectionChange={handleSectionChange}
            discountCode={discountCode}
            hasDiscountCode={hasDiscountCode}
            ticketType={ticketType}
            showErrors={showErrors}
            ticketTypeErrors={ticketTypeErrors.current}
            event={event}
            stadiumSections={eventStadiumSections.current}
          />
          <div className={classes.submit}>
            <Button
              type="submit"
              color="main"
              onClick={() => setOpenModal(true)}
              className={classes.submit}
            >
              Crear
            </Button>
          </div>
          <AlertDialog
            title="Crear este acceso"
            message="Verificá los datos ingresados. Una vez confirmado, no podrás cambiar el precio o el tipo de entrada seleccionada."
            open={openModal}
            onClose={() => setOpenModal(false)}
            handleConfirm={createTicketType}
          />
        </>
      )}
    </PageContainer>
  );
};

export default withStyles(styles)(NewTicketType);
