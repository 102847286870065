import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    tabPanel: {
      display: 'flex',
      padding: 16,
      flexDirection: 'column',
      rowGap: 20,
      alignItems: 'start',
      '&[hidden]': {
        display: 'none',
      },
    },
    tabList: {
      color: theme.palette.primary.main,
      '& button': {
        fontSize: 17,
      },
      '& [tabindex="-1"]': {
        color: theme.palette.text.primary,
      },
    },
  });

export default styles;
