import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './LoadingArrowsStyles';
import { ReactComponent as LoadingArrow } from '../../../assets/loadingArrow/loadingArrow.svg';

const LoadingArrows = ({ classes, blackAndWhite = true }) => {
  const paintedClass = blackAndWhite ? 'paintedArrow' : '';
  return (
    <div className={classes.arrowsContainer}>
      <LoadingArrow className={`leftArrow ${paintedClass}`} />
      <LoadingArrow className={`centerArrow ${paintedClass}`} />
      <LoadingArrow className={`rightArrow ${paintedClass}`} />
    </div>
  );
};

export default withStyles(styles)(LoadingArrows);
