import React, { useState } from 'react';
import { Stack } from '@mui/material';
import BottomSheet from '../../../Venti-UI-Kit/BottomSheet/BottomSheet';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import Button from '../../Buttons/Button/Button';
import { handleRequestHelper } from '../../../utils/helpers';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { NOTIFICATION_VARIANT } from '../../../constants/types';
import api from '../../../api/api';

const DeleteQrPostBottomSheet = ({ open, onClose, getEventQrs, qrExternalId }) => {
  const { showNotification } = useNotifications();

  const [isLoading, setIsLoading] = useState(false);

  const handleDeletePost = async () => {
    setIsLoading(true);
    await handleRequestHelper({
      endpoint: () => api.deleteQrPostOnSecondaryMarket(qrExternalId),
      onSuccess: () => {
        onClose();
        getEventQrs();
        showNotification('Publicación eliminada correctamente', NOTIFICATION_VARIANT.SUCCESS);
      },
      onFailure: () => onClose(),
      showNotification,
    });
    setIsLoading(false);
  };

  return (
    <BottomSheet open={open} onClose={onClose}>
      <Stack padding="16px" gap={2.5}>
        <Stack gap={1} paddingBottom="32px">
          <VTypography variant="h4">Eliminar publicación del mercado de Venti Swap</VTypography>
          <VTypography variant="body2">
            Al dejar de publicar tu entrada en Venti Swap, tu publicación se eliminara y veras tu
            entrada nuevamente en tu cuenta de Venti
          </VTypography>
        </Stack>
        <Button
          color="alternative"
          title="Eliminar publicación"
          onClick={handleDeletePost}
          loading={isLoading}
        />
      </Stack>
    </BottomSheet>
  );
};

export default DeleteQrPostBottomSheet;
